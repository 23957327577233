import {
  Alert,
  Anchor,
  Badge,
  Group,
  List,
  Loader,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { IconCloudCheck } from "@tabler/icons-react";
import { FactProvenance } from "api/src/models/Fact";
import dayjs from "dayjs";
import { useCallback } from "react";
import { useFactsContext } from "../pages/Build/FactsContext";
import { topHeaderHeights } from "../pages/layoutConfig";
import { useFacts } from "../utils/useFacts";
import { useOrganization } from "../utils/useOrganization";
import { getBlockPath } from "../utils/usePillars";
import classes from "./ScraperNotice.module.css";

export function ScraperNotice() {
  const [isCollapsed, setIsCollapsed] = useLocalStorage({
    key: "scraper-notice-collapsed",
    defaultValue: false,
  });

  const { organization } = useOrganization();

  const websiteScraper = organization?.settings.websiteScraper;
  const now = dayjs();
  const [facts] = useFacts();
  const { stacks } = useFactsContext();

  const onToggleCollapse = useCallback(() => {
    setIsCollapsed((v) => !v);
  }, [setIsCollapsed]);

  const isScraping =
    websiteScraper?.startedAt &&
    websiteScraper?.finishedAt == null &&
    dayjs(websiteScraper?.startedAt).isAfter(now.subtract(20, "minutes"));

  const finishedScrapingAt =
    websiteScraper?.finishedAt && dayjs(websiteScraper.finishedAt);

  const finishedScrapingRecently =
    !isScraping &&
    finishedScrapingAt &&
    finishedScrapingAt.isAfter(dayjs().subtract(24, "hour"));

  const updatedFacts = finishedScrapingRecently
    ? facts.filter(
        (f) =>
          f.content.provenance === FactProvenance.SiteScraper &&
          dayjs(f.createdAt).isAfter(finishedScrapingAt.subtract(15, "minute")),
      )
    : [];

  if (isCollapsed && (isScraping || finishedScrapingRecently)) {
    return (
      <Group pb="md" pt="sm">
        <Badge
          variant="light"
          className={classes.collapsedBadge}
          onClick={onToggleCollapse}
          color={finishedScrapingRecently ? "green.9" : undefined}
          leftSection={
            isScraping ? (
              <Loader size={12} type="oval" />
            ) : (
              <ThemeIcon size="xs" variant="transparent" color="green.9">
                <IconCloudCheck />
              </ThemeIcon>
            )
          }
        >
          {isScraping ? "Gathering information..." : "Smart Blocks Updated"}
        </Badge>
      </Group>
    );
  }

  if (isScraping) {
    return (
      <Alert
        withCloseButton
        onClose={onToggleCollapse}
        title="Gathering information about your company..."
        icon={<Loader type="oval" size="xs" />}
        my="sm"
      >
        <Text mb="sm" size="sm">
          NOAN is analyzing your website and preparing to fill Smart Blocks with
          your existing strategy.
        </Text>
        <Text size="sm">This usually only takes a few minutes.</Text>
      </Alert>
    );
  } else if (finishedScrapingRecently && updatedFacts.length > 0) {
    return (
      <Alert
        withCloseButton
        onClose={onToggleCollapse}
        title={
          <Text size="xs" fw={600} span c="dimmed" tt="uppercase">
            Smart Blocks updated automatically
          </Text>
        }
        color="green"
        icon={<IconCloudCheck />}
        my="sm"
      >
        <Text size="sm" mb="sm">
          The following blocks were automatically filled in from your website:
        </Text>

        <List size="sm" mb="sm">
          {updatedFacts.map((f) => {
            const block = getBlockPath({
              blockPath: f.blockPath,
              stacks,
            });

            return (
              <List.Item key={f.id}>
                <Anchor
                  size="sm"
                  fw={600}
                  onClick={() => {
                    const ref = document.getElementById(f.id);

                    if (!ref) {
                      return null;
                    }

                    const refTop = ref.getBoundingClientRect().top;
                    const totalOffset =
                      refTop + window.scrollY - topHeaderHeights.desktop * 3;

                    if (ref) {
                      window.scrollTo({
                        top: totalOffset,
                        behavior: "smooth",
                      });
                    }
                  }}
                  c="text"
                >
                  {block.title} ({block.stackName})
                </Anchor>
              </List.Item>
            );
          })}
        </List>
      </Alert>
    );
  }

  return null;
}
