export function GoogleIcon({ size }: { size?: number }) {
  return (
    <svg
      height={size ?? "13"}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
    >
      <g clipPath="url(#clip0_7090_5302)">
        <path
          d="M11.2546 6.52136C11.2546 6.11845 11.2219 5.82443 11.1511 5.51953H6.55029V7.33807H9.25088C9.19645 7.79 8.90244 8.4706 8.24905 8.92793L8.23989 8.98882L9.69459 10.1158L9.79538 10.1258C10.721 9.27097 11.2546 8.01322 11.2546 6.52136Z"
          fill="#4285F4"
        />
        <path
          d="M6.54949 11.312C7.87255 11.312 8.98327 10.8764 9.79457 10.125L8.24825 8.92715C7.83445 9.21572 7.27907 9.41718 6.54949 9.41718C5.25364 9.41718 4.1538 8.56237 3.76174 7.38086L3.70427 7.38574L2.19166 8.55637L2.17188 8.61136C2.97769 10.2121 4.63291 11.312 6.54949 11.312Z"
          fill="#34A853"
        />
        <path
          d="M3.76197 7.3812C3.65852 7.07629 3.59865 6.74958 3.59865 6.41202C3.59865 6.07443 3.65852 5.74775 3.75652 5.44285L3.75378 5.37791L2.22221 4.18848L2.1721 4.21231C1.83998 4.87658 1.64941 5.62253 1.64941 6.41202C1.64941 7.20151 1.83998 7.94743 2.1721 8.6117L3.76197 7.3812Z"
          fill="#FBBC05"
        />
        <path
          d="M6.54949 3.40649C7.46964 3.40649 8.09033 3.80396 8.44426 4.13611L9.82723 2.7858C8.97787 1.99631 7.87255 1.51172 6.54949 1.51172C4.63291 1.51172 2.97769 2.61156 2.17188 4.21231L3.7563 5.44284C4.1538 4.26133 5.25364 3.40649 6.54949 3.40649Z"
          fill="#EB4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_7090_5302">
          <rect
            width="9.61016"
            height="9.83438"
            fill="white"
            transform="translate(1.64941 1.51172)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
