import classes from "./StackIcons.module.css";

export function ManufacturingIcon() {
  return (
    <svg
      width="205"
      height="229"
      viewBox="0 0 205 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.379 65.229L119.424 65.229L119.423 104.59L119.424 104.59L136.468 114.43L136.467 65.229L153.512 65.229L153.512 104.591L153.513 104.591L170.557 114.432L170.569 153.734L34.2001 153.733L34.1992 94.7502L68.289 114.432L68.2891 94.7504L102.379 114.431L102.379 65.229ZM69.2451 124.21H51.2451V142.21H69.2451V124.21ZM102.857 124.21H84.8569V142.21H102.857V124.21Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.536 153.774L204.537 69.224C204.537 60.6496 199.963 52.7266 192.537 48.4394L114.269 3.25104C106.843 -1.03604 97.6945 -1.03603 90.269 3.25107L17.0448 45.5259C6.49753 51.6153 2.01768e-05 62.8691 0 75.048L4.77098e-05 159.599C6.84045e-05 168.173 4.57443 176.097 12 180.384L90.2681 225.572C97.6937 229.859 106.842 229.859 114.268 225.572L187.491 183.296C198.038 177.207 204.536 165.953 204.536 153.774ZM98.2688 18.3138L21.0448 62.8979C18.5695 64.327 17.0447 66.968 17.0447 69.8262L17.0448 158.996C17.0448 161.854 18.5695 164.495 21.0447 165.924L98.2683 210.509C100.743 211.938 103.793 211.938 106.268 210.509L183.491 165.924C185.966 164.495 187.491 161.854 187.491 158.996L187.492 69.8271C187.492 66.9689 185.967 64.3279 183.492 62.8988L106.269 18.3138C103.794 16.8848 100.744 16.8847 98.2688 18.3138Z"
        fill="currentColor"
      />
    </svg>
  );
}
