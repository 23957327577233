import { Button, Group, Modal, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconAlertSquare, IconCheck, IconX } from "@tabler/icons-react";
import { useRef } from "react";
import { useDeleteAllFacts } from "../../utils/useFacts";

const confirmDeletionMessage = "DELETE BLOCKS";

interface DeleteFactsFormFields {
  confirmDeletion: string;
}

export function DeleteFactsModal({
  opened,
  close,
}: {
  opened: boolean;
  close: () => void;
}) {
  const deleteFactsCheckRef = useRef<HTMLInputElement>(null);

  const { deleteAllFacts, isDeletingAllFacts } = useDeleteAllFacts();
  const {
    getInputProps,
    onSubmit,
    isValid,
    reset: resetDeleteForm,
  } = useForm<DeleteFactsFormFields>({
    validateInputOnBlur: true,
    clearInputErrorOnChange: true,
    initialValues: {
      confirmDeletion: "",
    },
    validate: {
      confirmDeletion: (val) =>
        val !== confirmDeletionMessage
          ? `Please enter '${confirmDeletionMessage}' to proceed with the deletion.`
          : null,
    },
  });

  function onConfirmDelete() {
    deleteAllFacts(undefined, {
      onSuccess() {
        notifications.show({
          color: "green",
          icon: <IconCheck size={18} />,
          message: "All your existing NOAN block content was deleted.",
        });
      },
      onError() {
        notifications.show({
          color: "red",
          icon: <IconX size={18} />,
          message:
            "There was an error deleting your NOAN block content, please try again.",
        });
      },
    });

    close();
  }

  function onCancelDelete() {
    resetDeleteForm();
    close();
  }

  return (
    <Modal
      opened={opened}
      onClose={() => {
        resetDeleteForm();
        close();
      }}
      autoFocus
      size="lg"
      title={
        <Group gap="sm">
          <IconAlertSquare size={18} />
          Delete all block content?
        </Group>
      }
    >
      <form onSubmit={onSubmit(onConfirmDelete)}>
        <Stack>
          <TextInput
            label={
              <>
                Write <code>{confirmDeletionMessage}</code> below to confirm:
              </>
            }
            ref={deleteFactsCheckRef}
            placeholder={confirmDeletionMessage}
            {...getInputProps("confirmDeletion")}
          />

          <Group gap="sm" justify="center">
            <Button size="sm" onClick={onCancelDelete} variant="transparent">
              Do not delete my block content
            </Button>
            <Button
              type="submit"
              size="sm"
              color="red"
              disabled={isDeletingAllFacts || !isValid("confirmDeletion")}
            >
              Yes, delete all my block content
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}
