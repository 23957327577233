import classes from "./StackIcons.module.css";

export function DoctorIcon() {
  return (
    <svg
      width="206"
      height="229"
      viewBox="0 0 206 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.991 45.4909L114.769 3.21495C107.344 -1.07225 98.1948 -1.07226 90.7692 3.21494L12.5009 48.4031C5.07535 52.6903 0.501045 60.6132 0.501007 69.1875L0.5 153.739C0.499947 165.918 6.99728 177.172 17.5445 183.261L90.768 225.537C98.1936 229.824 107.342 229.824 114.768 225.537L193.036 180.349C200.462 176.061 205.036 168.138 205.036 159.564L205.036 75.0131C205.036 62.8342 198.539 51.5803 187.991 45.4909ZM17.5455 74.3359L102.82 123.569C103.16 117.919 106.142 112.754 110.865 109.635L25.6997 60.4643L21.5456 62.8627C19.0704 64.2917 17.5456 66.9327 17.5456 69.7908L17.5455 74.3359ZM43.6997 50.072L128.833 99.2235L136.877 94.5791L51.7441 45.4275L43.6997 50.072ZM165.194 140.392C159.898 143.533 156.582 149.16 156.386 155.298L119.833 155.298L119.832 124.101L146.903 108.472L165.194 140.392ZM17.565 104.419L102.788 153.623L102.788 144.335L17.5454 95.1203L17.565 104.419ZM21.5444 165.889C19.0692 164.46 17.5444 161.819 17.5444 158.961L17.5646 124.1L102.788 173.304L158.205 173.298C178.951 173.298 191.946 150.874 181.632 132.874L153.665 84.5901L68.7886 35.5868L98.7689 18.2777C101.244 16.8486 104.294 16.8486 106.769 18.2777L183.991 62.863C186.466 64.2921 187.991 66.933 187.991 69.7911L187.991 158.961C187.991 161.819 186.467 164.46 183.991 165.889L106.768 210.474C104.293 211.903 101.243 211.903 98.7678 210.474L21.5444 165.889Z"
        fill="currentColor"
      />
    </svg>
  );
}
