import classes from "./StackIcons.module.css";

export function GymIcon() {
  return (
    <svg
      width="206"
      height="230"
      viewBox="0 0 206 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.924 124.799L119.923 124.8L102.878 134.64L102.878 143.93C102.878 150.36 99.4475 156.303 93.8783 159.518L77.7897 168.807C72.2202 172.023 65.3582 172.022 59.7888 168.806L43.6993 159.517C38.1305 156.302 34.7 150.36 34.7 143.929L34.6997 125.352C34.6994 118.921 38.1303 112.978 43.6998 109.763L59.7897 100.474C65.3589 97.2585 72.2203 97.2585 77.7895 100.474L85.8521 105.107L102.878 95.2767L102.879 85.9891C102.878 79.5579 106.309 73.6151 111.879 70.3996L119.923 65.7554L119.924 65.7558L127.836 61.0975C133.472 57.779 140.465 57.7789 146.101 61.0972L154.013 65.7553L162.057 70.3997C167.626 73.6152 171.057 79.5579 171.057 85.989L171.057 104.567C171.057 110.997 167.626 116.94 162.057 120.155L145.968 129.444C140.399 132.659 133.538 132.659 127.969 129.444L119.924 124.799ZM136.968 75.5962L119.924 85.4364L127.968 90.0805C133.537 93.2959 136.968 99.2382 136.968 105.669L136.968 114.958L154.012 105.119L154.012 85.4369L136.968 75.5962ZM51.7452 144.48L68.7898 154.321L85.8335 144.482L85.8335 124.8L68.7893 114.959L51.7446 124.8L51.7452 144.48Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.036 154.363L205.037 69.8125C205.037 61.2381 200.463 53.3151 193.037 49.0279L114.769 3.83954C107.343 -0.447542 98.1945 -0.447531 90.769 3.83957L17.5448 46.1144C6.99753 52.2038 0.50002 63.4576 0.5 75.6365L0.500048 160.188C0.500068 168.762 5.07443 176.685 12.5 180.972L90.7681 226.16C98.1937 230.448 107.342 230.448 114.768 226.16L187.991 183.885C198.538 177.795 205.036 166.542 205.036 154.363ZM98.7688 18.9023L21.5448 63.4864C19.0695 64.9155 17.5447 67.5565 17.5447 70.4147L17.5448 159.585C17.5448 162.443 19.0695 165.084 21.5447 166.513L98.7683 211.098C101.243 212.527 104.293 212.527 106.768 211.098L183.991 166.513C186.466 165.084 187.991 162.443 187.991 159.585L187.992 70.4156C187.992 67.5574 186.467 64.9164 183.992 63.4873L106.769 18.9023C104.294 17.4733 101.244 17.4732 98.7688 18.9023Z"
        fill="currentColor"
      />
    </svg>
  );
}
