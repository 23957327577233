import classes from "./StackIcons.module.css";

export function MaintainanceIcon() {
  return (
    <svg
      width="205"
      height="230"
      viewBox="0 0 205 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.491 183.885L114.269 226.161C106.843 230.448 97.6946 230.448 90.269 226.161L12.0006 180.973C4.57511 176.686 0.000804553 168.763 0.000766207 160.188L-0.00024414 75.6369C-0.00029606 63.458 6.49703 52.2041 17.0443 46.1146L90.2677 3.83908C97.6933 -0.448077 106.842 -0.448071 114.268 3.83911L192.536 49.0271C199.961 53.3143 204.536 61.2373 204.536 69.8117L204.536 154.363C204.536 166.542 198.038 177.795 187.491 183.885ZM17.0453 159.585L17.0444 70.4149C17.0443 67.5568 18.5691 64.9157 21.0444 63.4866L98.2677 18.9018C100.743 17.4727 103.793 17.4727 106.268 18.9018L183.491 63.4868C185.967 64.9158 187.491 67.5569 187.491 70.415L187.491 159.585C187.491 162.443 185.966 165.084 183.491 166.513L106.269 211.098C103.794 212.527 100.744 212.527 98.2688 211.098L51.245 183.949L85.2437 164.32L110.332 178.805C115.901 182.02 122.763 182.02 128.332 178.805L161.467 159.675C167.036 156.459 170.467 150.517 170.466 144.086L170.466 115.117L153.422 105.275L136.377 115.116L119.332 105.275L119.333 85.5935L136.377 75.7527L136.377 56.0712L136.376 56.072L111.287 41.5859C105.717 38.3709 98.8562 38.3711 93.2873 41.5864L60.154 60.7162C54.585 63.9314 51.1543 69.8733 51.154 76.3038L51.154 105.275L17.0658 124.956L17.0649 124.955L17.065 144.637L68.1987 115.116L68.1979 75.7524L68.1988 75.7518L102.287 56.072L119.331 65.9126L102.288 75.7528L102.288 115.116L136.377 134.797L153.421 124.957L153.421 144.638H153.422L119.332 164.319L85.2436 144.639L34.2003 174.108L21.0453 166.513C18.5702 165.084 17.0454 162.443 17.0453 159.585Z"
        fill="currentColor"
      />
    </svg>
  );
}
