import { Card, Group, Stack, Text, ThemeIcon, Tooltip } from "@mantine/core";
import { aiGenerators, type AiGeneratorOption } from "../../useGenerator";
import classes from "./GeneratorOptionsInline.module.css";
import { generatorFromId } from "./generatorFromId";
import clsx from "clsx";

export interface GeneratorOptionsInlineProps {
  onSelectGenerator: (selection: AiGeneratorOption) => void;
  options?: AiGeneratorOption[];
  disabled?: boolean;
}

export function GeneratorOptionsInline({
  onSelectGenerator,
  options = aiGenerators,
  disabled,
}: GeneratorOptionsInlineProps) {
  return (
    <Group justify="space-between" align="flex-start">
      {options.map((gen) => (
        <Card
          key={gen.value}
          variant="outline"
          className={clsx(
            classes.optionContainer,
            disabled && classes.optionContainerDisabled,
          )}
          w={{ base: "100%", md: `${95 / options.length}%` }}
          h={{ base: "auto", md: 170 }}
          onClick={() =>
            !disabled ? onSelectGenerator(generatorFromId(gen.value)) : {}
          }
        >
          <Stack h="100%" w="100%" gap="sm" justify="space-between">
            <ThemeIcon size="sm" variant="transparent">
              {gen.icon}
            </ThemeIcon>
            <Stack gap="sm" align="flex-start">
              <Text fw={600} size="lg" className={classes.title}>
                {gen.label}
              </Text>
              <Tooltip withArrow label={gen.description} multiline maw={300}>
                <Text size="sm" className={classes.description} lineClamp={2}>
                  {gen.description}
                </Text>
              </Tooltip>
            </Stack>
          </Stack>
        </Card>
      ))}
    </Group>
  );
}
