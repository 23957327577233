import classes from "./StackIcons.module.css";

export function AthleteIcon() {
  return (
    <svg
      width="206"
      height="230"
      viewBox="0 0 206 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.037 70.0998L205.036 154.65C205.036 166.829 198.538 178.083 187.991 184.172L114.768 226.448C107.342 230.735 98.1937 230.735 90.7681 226.448L12.5 181.259C5.07443 176.972 0.500068 169.049 0.500048 160.475L0.5 75.9238C0.50002 63.7449 6.99753 52.4911 17.5448 46.4017L90.769 4.12684C98.1945 -0.160269 107.343 -0.16028 114.769 4.1268L193.037 49.3151C200.463 53.6023 205.037 61.5254 205.037 70.0998ZM21.5448 63.7737L34.7003 56.1786L34.6992 75.8832L51.7446 85.7235L51.7437 85.724L51.7447 85.7245L51.7447 105.405L85.8338 125.087L77.7891 129.732C72.2198 132.947 68.7891 138.889 68.7891 145.32L68.7891 163.899C68.7891 170.329 72.2198 176.272 77.789 179.487L93.8778 188.776C99.4471 191.991 106.309 191.991 111.878 188.776L127.967 179.487C133.537 176.271 136.967 170.329 136.967 163.898L136.967 145.32C136.967 138.889 133.537 132.947 127.967 129.732L119.923 125.087L154.012 105.406L154.013 105.406L154.012 85.724L171.056 75.8828L171.056 56.3058L183.992 63.7746C186.467 65.2036 187.992 67.8447 187.992 70.7028L187.991 159.872C187.991 162.73 186.466 165.371 183.991 166.8L106.768 211.385C104.293 212.814 101.243 212.814 98.7683 211.385L21.5447 166.8C19.0695 165.371 17.5448 162.73 17.5448 159.872L17.5447 70.702C17.5447 67.8438 19.0695 65.2028 21.5448 63.7737ZM119.923 125.087L111.878 120.443C106.309 117.227 99.4475 117.227 93.8783 120.443L85.8338 125.087L85.8338 85.7241L68.7894 75.8832L68.7894 56.2018L68.7884 56.2018L51.727 46.3484L98.7688 19.1895C101.244 17.7605 104.294 17.7605 106.769 19.1896L153.922 46.4137L136.967 56.2027L136.968 75.8833L119.925 85.7236L119.923 125.087ZM94.356 169.371C89.0822 166.326 85.8335 160.699 85.8336 154.609C85.8337 148.52 89.0824 142.893 94.3559 139.848C99.6295 136.803 106.127 136.804 111.401 139.848C116.674 142.893 119.924 148.52 119.924 154.61C119.924 160.699 116.674 166.326 111.401 169.371C106.127 172.415 99.6295 172.415 94.356 169.371Z"
        fill="currentColor"
      />
    </svg>
  );
}
