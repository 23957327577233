import classes from "./StackIcons.module.css";

export function CoachIcon() {
  return (
    <svg
      width="206"
      height="229"
      viewBox="0 0 206 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M119.794 85.0051L119.793 65.3237L136.839 75.1638L153.884 65.323L153.883 85.0045L153.884 85.0056L170.927 94.846L153.883 104.687L153.883 124.368L136.839 114.528L119.795 124.368L119.795 104.687L119.794 104.687L102.749 94.8458L119.794 85.0051Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5456 183.296L90.7678 225.572C98.1934 229.86 107.342 229.86 114.768 225.572L193.036 180.384C200.462 176.097 205.036 168.174 205.036 159.6L205.037 75.0485C205.037 62.8695 198.54 51.6156 187.993 45.5262L114.769 3.25064C107.343 -1.03652 98.1947 -1.03651 90.7691 3.25067L12.501 48.4387C5.0754 52.7258 0.501019 60.6489 0.500977 69.2232L0.501176 153.774C0.501124 165.953 6.99842 177.207 17.5456 183.296ZM187.991 158.997L187.992 69.8265C187.992 66.9683 186.468 64.3273 183.992 62.8982L106.769 18.3134C104.294 16.8843 101.244 16.8843 98.769 18.3133L21.5455 62.8983C19.0702 64.3274 17.5455 66.9684 17.5455 69.8266L17.5457 134.197L94.2263 89.9248L102.749 104.686L34.5717 144.048V144.047L17.5457 134.217L17.5458 158.996C17.5458 161.854 19.0706 164.495 21.5457 165.924L43.0937 178.365L43.0943 158.808L111.273 119.449L119.795 134.21L43.2016 178.428L98.7679 210.51C101.243 211.939 104.293 211.939 106.768 210.51L183.991 165.925C186.467 164.496 187.991 161.855 187.991 158.997Z"
        fill="currentColor"
      />
    </svg>
  );
}
