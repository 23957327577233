import {
  Badge,
  Card,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import type { ConvoWithCreator } from "api/src/models/Convo";
import { Link } from "react-router-dom";
import { AssistantIcon } from "../../components/AssistantSwitcher/AssistantIcon";
import { generatorFromId } from "../../components/GeneratorSwitcher/generatorFromId";
import { relativeTimeFromNow } from "../../utils/date";
import classes from "./Create.module.css";
import { createConvoLink } from "./createConvoLink";

export function ConvoCard({
  convo,
  includeCreator = true,
}: {
  convo: ConvoWithCreator;
  includeCreator?: boolean;
}) {
  const gen = convo.latestGenerator && generatorFromId(convo.latestGenerator);

  return (
    <UnstyledButton
      key={convo.id}
      component={Link}
      to={createConvoLink(convo.id)}
    >
      <Card h="100%" classNames={{ root: classes.convoCard }}>
        <Stack justify="flex-start" mih={100} h="100%" gap="xs">
          <Group wrap="wrap" preventGrowOverflow>
            {convo.configuration.bucket && (
              <Badge size="sm" tt="none" variant="light" color="dark">
                {convo.configuration.bucket}
              </Badge>
            )}
          </Group>
          <Title order={4} flex={1}>
            {convo.title}
          </Title>
          <Group gap="sm">
            {includeCreator && (
              <Text span size="xs" fw={600}>
                {convo.creator.name ?? convo.creator.email}
              </Text>
            )}
            <Group w="100%">
              <Text size="xs" c="dimmed" flex={1}>
                {relativeTimeFromNow(convo.createdAt)}
              </Text>

              <Group gap="xs">
                {convo.configuration.assistantId && (
                  <AssistantIcon
                    assistantId={convo.configuration.assistantId}
                  />
                )}
                {gen && (
                  <Tooltip label={gen.label}>
                    <ThemeIcon size="sm" variant="transparent" color="dark">
                      {gen.icon}
                    </ThemeIcon>
                  </Tooltip>
                )}
              </Group>
            </Group>
          </Group>
        </Stack>
      </Card>
    </UnstyledButton>
  );
}
