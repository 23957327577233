export function prefilCustomerPortalLink({ email }: { email?: string }) {
  const url = new URL(customerPortal);
  const params = new URLSearchParams({
    prefilled_email: email ?? "",
  });

  url.search = params.toString();
  return url.toString();
}

export const customerPortal =
  "https://billing.stripe.com/p/login/cN26oO5lIgcYeqs288";
