import { Affix, Button, Transition } from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import { IconArrowUp } from "@tabler/icons-react";
import { ZedIndex } from "../utils/zedIndex";
import classes from "./ReturnToTop.module.css";

export function ReturnToTop() {
  const [scroll, scrollTo] = useWindowScroll();

  return (
    <Affix
      visibleFrom="md"
      position={{
        bottom: 10,
      }}
      className={classes.returnToTop}
      zIndex={ZedIndex.ReturnToTop}
    >
      <Transition transition="slide-up" mounted={scroll.y > 200}>
        {(transitionStyles) => (
          <Button
            leftSection={<IconArrowUp size={20} />}
            style={transitionStyles}
            onClick={() => scrollTo({ y: 0 })}
          >
            Return to top
          </Button>
        )}
      </Transition>
    </Affix>
  );
}
