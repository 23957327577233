import {
  Anchor,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Grid,
  Image,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { hasLength, isEmail, matchesField, useForm } from "@mantine/form";
import { useToggle } from "@mantine/hooks";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { supabase } from "../../auth/supabase";
import { useOptionalAuthContext } from "../../auth/useAuthContext";
import { NoanLogo } from "../../components/icons/NoanLogo.svg";
import { baseUrl } from "../../config";
import classes from "./Signup.module.css";
import { GoogleIcon } from "../../components/icons/Google.svg";
import { useState } from "react";

const redirectToAfterAuth =
  (import.meta.env.VITE_SIGNUP_REDIRECT_TO as string | undefined) ??
  `${baseUrl}`;

enum SignupSteps {
  Email,
  Password,
}

interface SignupFormFields {
  email: string;
  password: string;
  confirmPassword: string;
  agreeTerms: boolean;
}

function SignupForm({
  initialEmail,
  allowSetPassword,
  isPostSignup,
}: {
  allowLinkedinLogin?: boolean;
  initialEmail?: string;
  allowSetPassword: boolean;
  allowLinkIdentity: boolean;
  isPostSignup: boolean;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoadingOAuthLogin, toggleOAuthLoginLoading] = useToggle();
  const [isLoadingFormSubmission, toggleFormSubmissionLoading] = useToggle();
  const [signupStep, setSignupStep] = useState(
    allowSetPassword ? SignupSteps.Password : SignupSteps.Email,
  );

  const signupForm = useForm<Partial<SignupFormFields>>({
    name: "Signup",
    validateInputOnBlur: true,
    initialValues: {
      email: initialEmail,
      password: "",
      confirmPassword: "",
      agreeTerms: false,
    },
    validate: {
      // We disable some validations if we're only setting a password:
      ...(allowSetPassword
        ? {}
        : {
            email: isEmail("Must be a valid email address"),
          }),
      password: hasLength({ min: 8 }, "Must be at least 8 characters long"),
      confirmPassword: matchesField("password", "Must match password"),
      agreeTerms: (value) => (value !== true ? "Must agree to terms" : null),
    },
  });

  function getRedirectUrl() {
    const redirectToPaymentLinks =
      searchParams.has("sn") || searchParams.has("sf");
    const path = redirectToPaymentLinks ? "/subscribe" : "/create";
    const url = new URL(`${redirectToAfterAuth}${path}`);
    const subscriptionName = searchParams.get("sn");
    const subscriptionFrequency = searchParams.get("sf");
    const params = new URLSearchParams({
      ...(redirectToPaymentLinks ? { initPayment: "" } : {}),
      ...(subscriptionName ? { sn: subscriptionName } : {}),
      ...(subscriptionFrequency ? { sf: subscriptionFrequency } : {}),
    });

    url.search = params.toString();

    return url.toString();
  }

  function onGoogleLogin() {
    toggleOAuthLoginLoading();
    supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: getRedirectUrl(),
        skipBrowserRedirect: false,
        queryParams: {
          prompt: "consent",
          access_type: "offline",
        },
      },
    });
  }

  async function onCreateAccount(values: SignupFormFields) {
    toggleFormSubmissionLoading();

    const metadata = {
      hasSetPassword: true,
    };

    if (allowSetPassword) {
      const { error } = await supabase.auth.updateUser({
        password: values.password,
        data: metadata,
      });

      if (error != null) {
        signupForm.setFieldError("password", error.message);
      } else {
        navigate("/create");
      }
    } else {
      const { error } = await supabase.auth.signUp({
        email: values.email,
        password: values.password,
        options: {
          emailRedirectTo: getRedirectUrl(),
          data: metadata,
        },
      });

      if (error != null) {
        signupForm.setFieldError(
          "email",
          "There was an error creating your account, please try again",
        );
      } else {
        navigate("/login?view=confirm");
      }
    }
  }

  const signupStepRender = () => {
    switch (signupStep) {
      case SignupSteps.Password:
        return (
          <Stack gap="lg">
            {allowSetPassword && (
              <TextInput
                className={classes.signupFormInput}
                label="Email"
                type="email"
                placeholder="name@example.com"
                disabled
                {...signupForm.getInputProps("email")}
                required
              />
            )}
            <PasswordInput
              className={classes.signupFormPassword}
              label="Password"
              description="At least 8 characters"
              placeholder="********"
              {...signupForm.getInputProps("password")}
              required
            />
            <PasswordInput
              className={classes.signupFormPassword}
              label="Confirm password"
              description="Repeat your password"
              placeholder="********"
              {...signupForm.getInputProps("confirmPassword")}
              required
            />
            <Checkbox
              required
              label={
                <>
                  I agree to the{" "}
                  <Anchor
                    size="sm"
                    c="teal.6"
                    td="underline"
                    target="_blank"
                    component={Link}
                    to="https://www.getnoan.com/terms"
                  >
                    terms and conditions
                  </Anchor>
                  .
                </>
              }
              {...signupForm.getInputProps("agreeTerms")}
            />
            <Button
              size="xl"
              type="submit"
              fullWidth
              loading={isLoadingFormSubmission}
              disabled={!signupForm.isValid()}
            >
              <Text>
                {allowSetPassword ? "Update Account" : "Start Free Trial"}
              </Text>
            </Button>
            {!allowSetPassword && (
              <Button
                size="xl"
                h="fit-content"
                fullWidth
                variant="transparent"
                td="underline"
                disabled={isLoadingFormSubmission}
                color="teal.6"
                onClick={() => setSignupStep(SignupSteps.Email)}
              >
                <Text>Go back</Text>
              </Button>
            )}
          </Stack>
        );
      case SignupSteps.Email:
      default:
        return (
          <Stack gap="lg">
            <TextInput
              className={classes.signupFormInput}
              label="Email"
              type="email"
              placeholder="name@example.com"
              {...signupForm.getInputProps("email")}
              required
            />
            <Button
              size="xl"
              fullWidth
              disabled={!signupForm.isValid("email")}
              onClick={() => setSignupStep(SignupSteps.Password)}
            >
              <Text>Continue</Text>
            </Button>
            <Text ta="center">
              Already have an account?{" "}
              <Anchor component={Link} to="/login" c="teal.6" td="underline">
                Log in
              </Anchor>
            </Text>
          </Stack>
        );
    }
  };

  return (
    <Stack gap="lg">
      {signupStep === SignupSteps.Email && (
        <>
          <Button
            size="xl"
            variant="outline"
            color="teal.6"
            loading={isLoadingOAuthLogin}
            disabled={isLoadingFormSubmission}
            onClick={onGoogleLogin}
            leftSection={<GoogleIcon size={22} />}
          >
            <Text span>Sign up with Google</Text>
          </Button>
          <Divider
            label={
              <Text size="sm" className={classes.divider}>
                OR
              </Text>
            }
          />
        </>
      )}

      {(!isPostSignup || allowSetPassword) && (
        <form
          onSubmit={signupForm.onSubmit((values) => {
            /** We know these are not optional because we validated them */
            onCreateAccount(values as SignupFormFields);
          })}
        >
          {signupStepRender()}
        </form>
      )}
    </Stack>
  );
}

export function Signup() {
  const [searchParams] = useSearchParams();
  const {
    user,
    mustSetPassword,
    hasThirdPartyProvider,
    mustCompletePostSignup,
  } = useOptionalAuthContext();

  /**
   * If the user has no additional post-signup steps to complete, we're done:
   */
  if (user != null && !mustCompletePostSignup) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Grid columns={6} gutter={0}>
        <Grid.Col span={{ base: 6, sm: 3 }}>
          <Container
            py={{ base: "xl", sm: 45 }}
            px={{ base: "xl", sm: 0 }}
            maw={390}
          >
            <NoanLogo width={110} />
            <Stack gap="xl" py="xl" mt={{ sm: 60 }}>
              <Box>
                <Title order={1} tt="uppercase" fw="bold" size={38}>
                  {mustCompletePostSignup
                    ? "Complete your Account"
                    : "You're one step away from trying NOAN"}
                </Title>
                <Title order={4} c="base.7">
                  {mustCompletePostSignup
                    ? "Set an account password, or link a third-party provider."
                    : "Create your account"}
                </Title>
              </Box>
              <SignupForm
                initialEmail={user?.email ?? searchParams.get("email") ?? ""}
                isPostSignup={mustCompletePostSignup}
                allowLinkIdentity={!hasThirdPartyProvider}
                allowSetPassword={mustSetPassword}
              />
            </Stack>
          </Container>
        </Grid.Col>
        <Grid.Col
          visibleFrom="sm"
          span={{ base: 6, sm: 3 }}
          bg="base.11"
          p={0}
          mih="100vh"
        >
          <Stack pl={65} pt={80} gap="xl">
            <Title order={1} tt="uppercase" fw="bold" size={38} c="base.0">
              Try NOAN For free
            </Title>
            <Image src="/create-mode.png" w="100%" />
          </Stack>
        </Grid.Col>
      </Grid>
    </>
  );
}
