import type { Fact } from "api/src/models/Fact";
import { createContext, useContext } from "react";
import type { BlocksGroupedByStack } from "../../utils/usePillars";

interface FactsContextProps {
  facts: Fact[];
  stacks: BlocksGroupedByStack[];
}

export const FactsContext = createContext<FactsContextProps | null>(null);

export function useFactsContext() {
  const ctx = useContext(FactsContext);

  if (ctx == null) {
    throw new Error("useFactsContext must be used within FactsContext");
  }

  return ctx;
}
