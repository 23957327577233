import classes from "./StackIcons.module.css";

export function PersonalTrainerIcon() {
  return (
    <svg
      width="206"
      height="230"
      viewBox="0 0 206 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M112.599 94.6895L134.24 80.9486L118.616 71.5997L96.4607 85.559L112.599 94.6895Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.094 95.2536C171.094 83.0896 164.584 71.8374 154.05 65.7553L102.915 36.234L34.7369 75.595L34.7358 75.5961L34.7368 114.958L34.7359 114.959L68.826 134.641L68.8258 143.93C68.8258 150.36 72.2566 156.303 77.8259 159.518L110.961 178.648C116.53 181.864 123.391 181.864 128.96 178.648L162.094 159.518C167.663 156.303 171.094 150.361 171.094 143.93L171.094 95.2776L171.094 95.2536ZM137.005 125.355C137.005 118.927 133.576 112.986 128.01 109.77L68.8262 75.5958L102.914 55.9148L154.049 85.4377L154.049 85.4368L154.049 144.481L154.049 144.48L137.004 154.321L137.005 125.355ZM51.7805 105.118L51.7805 85.4368L119.96 124.798L119.96 164.162L119.959 164.163L85.8706 144.481L85.8706 124.8L51.7814 105.118L51.7805 105.118Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.074 69.8125L205.073 154.363C205.073 166.542 198.575 177.795 188.028 183.885L114.805 226.16C107.379 230.448 98.2305 230.448 90.8049 226.16L12.5369 180.972C5.1113 176.685 0.536934 168.762 0.536913 160.188L0.536865 75.6365C0.536885 63.4576 7.03439 52.2038 17.5817 46.1144L90.8058 3.83957C98.2314 -0.447531 107.38 -0.447542 114.806 3.83954L193.074 49.0279C200.499 53.3151 205.074 61.2381 205.074 69.8125ZM21.5816 63.4864L98.8056 18.9023C101.281 17.4732 104.33 17.4733 106.806 18.9023L184.029 63.4873C186.504 64.9164 188.029 67.5574 188.029 70.4156L188.028 159.585C188.028 162.443 186.503 165.084 184.028 166.513L106.805 211.098C104.33 212.527 101.28 212.527 98.8051 211.098L21.5816 166.513C19.1064 165.084 17.5816 162.443 17.5816 159.585L17.5815 70.4147C17.5815 67.5565 19.1064 64.9155 21.5816 63.4864Z"
        fill="currentColor"
      />
    </svg>
  );
}
