import type { Module } from "./usePillars";

export function slugToIntelPath(slug: string, pathPrefix = "/") {
  if (slug.startsWith("intel/")) {
    return `${pathPrefix}${slug}`;
  } else {
    return `${pathPrefix}intel/${slug}`;
  }
}

export function contentToQuestions(content?: string) {
  if (!content) return [];

  const questions = content
    .trim()
    .split("\n")
    .map((q) => q.trim());

  return questions;
}

export function getLearningMaterialSlug(
  content: Module["casestudy"] | Module["guidecontent"],
) {
  const url = content?.cached_url ?? content?.url;

  if (url === "") {
    return null;
  }

  return url;
}
