import { Box, Card, Divider, Group, Skeleton, Stack } from "@mantine/core";
import { useAuthContext } from "../../auth/useAuthContext";
import { useBuildBuiltFirstJwtToken } from "./hooks";
import { BackButton } from "../../components/BackButton";
import { accountPageSections } from "./sections";
import { Can } from "../../components/auth/Can";
import classes from "./BenefitsSection.module.css";

const builtFirstMarketplaceId = "2398";

function buildBuiltFirstAuthUrl({
  cid = builtFirstMarketplaceId,
  jwt,
}: {
  cid?: string;
  jwt: string;
}) {
  const url = new URL("https://noan.builtfirst.com");
  const params = new URLSearchParams({
    cid,
    jwt,
  });

  url.search = params.toString();
  return url.toString();
}

function IframeSkeleton() {
  return (
    <Group pt="lg" align="start" justify="center" gap="50px">
      <Stack gap="xl">
        <Group justify="space-between">
          <Skeleton w={120} h={40} />
          <Skeleton w={200} h={40} radius="xl" />
        </Group>
        <Group justify="space-between">
          <Skeleton w={180} h={40} />
          <Skeleton w={40} h={40} radius="xl" />
        </Group>
        <Group justify="space-between">
          <Skeleton w={170} h={40} />
          <Skeleton w={40} h={40} radius="xl" />
        </Group>
      </Stack>
      <Stack gap="xl">
        <Stack>
          <Skeleton w={140} h={40} />
          <Group w={800}>
            <Skeleton w={250} h={280} radius="lg" />
            <Skeleton w={250} h={280} radius="lg" />
          </Group>
        </Stack>
        <Stack>
          <Skeleton w={80} h={40} />
          <Group w={800}>
            <Skeleton w={250} h={280} radius="lg" />
            <Skeleton w={250} h={280} radius="lg" />
            <Skeleton w={250} h={280} radius="lg" />
            <Skeleton w={250} h={280} radius="lg" />
            <Skeleton w={250} h={280} radius="lg" />
          </Group>
        </Stack>
      </Stack>
    </Group>
  );
}

export function BenefitsSection() {
  const { data: jwtToken } = useBuildBuiltFirstJwtToken();
  const { isPayingCustomer } = useAuthContext();

  const builtFirstUrl =
    jwtToken && isPayingCustomer
      ? buildBuiltFirstAuthUrl({ jwt: jwtToken })
      : "";

  return (
    <Box>
      <BackButton
        label="Return to Account Settings"
        to={accountPageSections.profile.path}
        visibleFrom="sm"
      />
      <Divider my="sm" />
      <Can execute="benefit.read">
        <>
          {!jwtToken ? (
            <IframeSkeleton />
          ) : (
            isPayingCustomer && (
              <Card h={1000} w={"100%"} p={0} withBorder shadow="lg">
                <iframe
                  src={builtFirstUrl}
                  height="100%"
                  width="100%"
                  title="Member Benefits iFrame"
                  className={classes.iframeWrapper}
                />
              </Card>
            )
          )}
        </>
      </Can>
    </Box>
  );
}
