import {
  ActionIcon,
  Alert,
  Anchor,
  Box,
  Button,
  Center,
  Group,
  Loader,
  Overlay,
  Pill,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconListDetails, IconLock, IconSend2 } from "@tabler/icons-react";
import { FeatureFlag } from "api/src/featureFlags";
import type { AssistantId } from "api/src/generated/storyblok/assistants";
import { ConvoEntryProvenance, ConvoEntryType } from "api/src/models/Convo";
import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AssistantSwitcher } from "../../components/AssistantSwitcher/AssistantSwitcher";
import { GeneratorSwitcherButton } from "../../components/GeneratorSwitcher/GeneratorSwitcherButton";
import { Page } from "../../components/Page";
import { RestrictedFeature } from "../../components/RestrictedFeature";
import { aiGenerators, type AiGeneratorOption } from "../../useGenerator";
import {
  useGetPreviewConstraints,
  useIsPreviewMode,
} from "../../utils/useAccount";
import { useFacts } from "../../utils/useFacts";
import { useIsFeatureEnabled } from "../../utils/useIsFeatureEnabled";
import { useOrganization } from "../../utils/useOrganization";
import { usePillars, type Module, type Pillar } from "../../utils/usePillars";
import { ConvoCard } from "./ConvoCard";
import classes from "./Create.module.css";
import { createConvoLink } from "./createConvoLink";
import { useConvoActivitySummary } from "./useConvoActivitySummary";
import { useCreateConvoEntry } from "./useCreateConvoEntry";

interface CreateConvoForm {
  prompt: string;
  generator: AiGeneratorOption["value"];
  assistantId?: AssistantId;
}

const defaultPromptIdeas = [
  "Write an about page for our website",
  "Write an employee onboarding document outlining our brand strategy",
  "Create an email for our customers detailing our mission",
];

function useCreatorRequirements() {
  const [facts] = useFacts();
  const { pillars } = usePillars();
  const { data: preview } = useGetPreviewConstraints();
  const isPreviewMode = useIsPreviewMode();

  const factSlugs = useMemo<string[]>(
    () => facts?.map((f) => f.blockPath) ?? [],
    [facts],
  );
  const pillarsSlugs = useMemo<string[]>(
    () => pillars?.flatMap((p) => p.slug) ?? [],
    [pillars],
  );
  const pillarsLockingCreator = useMemo<Pillar[]>(
    () =>
      pillars?.filter((p: Pillar) =>
        p.content.modules.some((m) => !!m.lockingcreator),
      ) ?? [],
    [pillars],
  );
  const modulesLockingCreator = useMemo<Module[]>(
    () =>
      pillarsLockingCreator?.flatMap((p) =>
        p.content.modules.filter((m) => !!m.lockingcreator),
      ) ?? [],
    [pillarsLockingCreator],
  );

  const hasReachedMaxGeneration =
    isPreviewMode && !!preview?.isMaxCreatorGenerationReached;
  const isMissingSomeRequiredFacts = modulesLockingCreator.some(
    (m) => !factSlugs.includes(m.knowledgeslug!),
  );

  return {
    pillarsSlugs,
    pillarsLockingCreatorSlugs: pillarsLockingCreator.map((p) => p.slug),
    isCreatorLocked: hasReachedMaxGeneration || isMissingSomeRequiredFacts,
    hasReachedMaxGeneration,
  };
}

function ConvoListLink({
  filter,
}: {
  filter?: {
    createdBy?: "self" | string;
  };
}) {
  const useConvoList = useIsFeatureEnabled(FeatureFlag.UseCreatorList);

  const filterSearchParam = useMemo<string | undefined>(() => {
    if (!filter) {
      return undefined;
    }

    return new URLSearchParams(filter).toString();
  }, [filter]);

  if (!useConvoList) {
    return null;
  }

  return (
    <Button
      size="xs"
      variant="subtle"
      color="dark"
      component={Link}
      to={{
        pathname: "/create/all",
        search: filterSearchParam,
      }}
      leftSection={
        <ThemeIcon color="dark" size="xs" variant="transparent">
          <IconListDetails />
        </ThemeIcon>
      }
    >
      View All
    </Button>
  );
}

function Creator() {
  const [activity] = useConvoActivitySummary();
  const { organization } = useOrganization();
  const { createConvoEntry, isLoading } = useCreateConvoEntry();
  const navigate = useNavigate();

  const {
    pillarsSlugs,
    pillarsLockingCreatorSlugs,
    isCreatorLocked,
    hasReachedMaxGeneration,
  } = useCreatorRequirements();

  const filtersMinusRequiredPillars = pillarsSlugs
    .filter((slug) => !pillarsLockingCreatorSlugs.includes(slug))
    .join(encodeURIComponent("+"));

  const useAssistantSelector = useIsFeatureEnabled(
    FeatureFlag.UseCreatorPersonas,
  );

  const defaultGenerator = aiGenerators.at(0)!;

  const promptIdeas =
    activity.promptSuggestions && activity.promptSuggestions.length > 0
      ? activity.promptSuggestions
      : defaultPromptIdeas;

  const form = useForm<CreateConvoForm>({
    initialValues: {
      prompt: "",
      generator: defaultGenerator.value,
    },
    validate: {
      prompt: (p) => {
        if (p.trim().length === 0) {
          return "Must not be empty";
        }

        if (p.trim().length <= 6) {
          return "Your instructions are too short. Try being more descriptive.";
        }
      },
    },
  });

  const onStartConvo = useCallback(
    (values: CreateConvoForm) => {
      createConvoEntry(
        {
          convoId: null,
          content: {
            entryType: ConvoEntryType.Initial,
            provenance: {
              source: ConvoEntryProvenance.User,
              generator: values.generator,
            },
            format: {
              type: "text",
            },
            plainText: values.prompt,
            assistantId: values.assistantId,
          },
        },
        {
          onSuccess: (data) => {
            navigate(createConvoLink(data.convoId));
          },
        },
      );
    },
    [navigate, createConvoEntry],
  );

  return (
    <Stack gap="lg">
      <RestrictedFeature
        isRestricted={hasReachedMaxGeneration}
        title="Feature Locked"
        description="You have reached the maximum number of free creations. To continue using this feature, subscribe to a NOAN plan."
      >
        <Box mb="lg" pos="relative">
          <form
            className={clsx(isCreatorLocked && classes.createFormLocked)}
            onSubmit={
              useAssistantSelector ? undefined : form.onSubmit(onStartConvo)
            }
          >
            <Textarea
              minRows={1}
              autosize
              maxRows={5}
              resize="vertical"
              size="xl"
              placeholder="Describe what you'd like to create"
              {...form.getInputProps("prompt")}
              disabled={isLoading}
              rightSectionWidth={100}
              rightSectionProps={{
                className: classes.convoNewPromptActions,
              }}
              rightSection={
                <Group gap={"xs"} wrap="nowrap">
                  <GeneratorSwitcherButton
                    options={aiGenerators.slice(0, 2)}
                    selectedGenerator={
                      aiGenerators.find(
                        (g) => g.value === form.values.generator,
                      ) || defaultGenerator
                    }
                    disabled={isCreatorLocked}
                    onSelectGenerator={(option) =>
                      form.setFieldValue("generator", option.value)
                    }
                  />

                  {!useAssistantSelector && (
                    <ActionIcon
                      disabled={isCreatorLocked || !form.isValid() || isLoading}
                      className={classes.promptSubmit}
                      variant="transparent"
                      type="submit"
                      size="xl"
                      color="dark"
                    >
                      {isLoading ? <Loader /> : <IconSend2 />}
                    </ActionIcon>
                  )}

                  {useAssistantSelector && (
                    <AssistantSwitcher
                      selectedAssistant={form.values.assistantId}
                      onSelectAssistant={(assistant) => {
                        form.setFieldValue(
                          "assistantId",
                          assistant.id as AssistantId,
                        );

                        onStartConvo(form.getValues());
                      }}
                    >
                      {({ onToggle }) => (
                        <ActionIcon
                          disabled={
                            isCreatorLocked || !form.isValid() || isLoading
                          }
                          className={classes.promptSubmit}
                          onClick={onToggle}
                          variant="transparent"
                          size="xl"
                          color="dark"
                        >
                          {isLoading ? <Loader /> : <IconSend2 />}
                        </ActionIcon>
                      )}
                    </AssistantSwitcher>
                  )}
                </Group>
              }
            />
          </form>

          <Stack
            mt="sm"
            className={clsx(isCreatorLocked && classes.createSuggestionsLocked)}
          >
            <Text span size="sm" c="dimmed">
              Suggestions
            </Text>
            <Group wrap="wrap" gap="sm">
              {promptIdeas.map((idea) => (
                <Pill
                  size="md"
                  key={idea}
                  onClick={() => form.setFieldValue("prompt", idea)}
                  classNames={{
                    label: classes.promptSuggestionLabel,
                  }}
                >
                  {idea}
                </Pill>
              ))}
            </Group>
          </Stack>

          {isCreatorLocked && (
            <Overlay
              fixed={false}
              classNames={{
                root: classes.createLockOverlay,
              }}
            >
              <Center h="100%">
                <Alert variant="outline" icon={<IconLock size={24} />}>
                  <Text>
                    Begin completing your Smart Blocks in{" "}
                    <Anchor
                      to={`/strategy?filtered=${filtersMinusRequiredPillars}`}
                      component={Link}
                    >
                      Build Mode
                    </Anchor>{" "}
                    to unlock Create Mode and supercharge your business.
                  </Text>
                </Alert>
              </Center>
            </Overlay>
          )}
        </Box>
      </RestrictedFeature>

      {activity.recent.length > 0 && (
        <Stack>
          <Group gap="sm">
            <Title order={3} flex={1}>
              Created by you
            </Title>
            <ConvoListLink
              filter={{
                createdBy: "self",
              }}
            />
          </Group>
          <SimpleGrid cols={{ base: 1, sm: 2, md: 3 }}>
            {activity.recent.slice(0, 6).map((r) => (
              <ConvoCard convo={r} key={r.id} includeCreator={false} />
            ))}
          </SimpleGrid>
        </Stack>
      )}

      {activity.organizationRecent.length > 0 && (
        <Stack>
          <Group gap="sm">
            <Title order={3} flex={1}>
              Created by others at {organization?.name ?? "your organization"}
            </Title>
            <ConvoListLink />
          </Group>

          <SimpleGrid cols={{ base: 2, md: 3 }}>
            {activity.organizationRecent.map((r) => (
              <ConvoCard convo={r} key={r.id} />
            ))}
          </SimpleGrid>
        </Stack>
      )}
    </Stack>
  );
}

export function Create() {
  return (
    <Page
      title="Create"
      description="What would you like to make today?"
      fullWidth={false}
      size="md"
    >
      <Creator />
    </Page>
  );
}
