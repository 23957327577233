import { Navigate, type RouteObject } from "react-router-dom";
import { Account } from "./Account";
import { ErrorPage } from "./ErrorPage";
import { Insights } from "./Insights/Insights";

import { Login, Signup } from "./Auth";
import { Build } from "./Build";
import { BlockPage } from "./Build/BlockPage";
import { StackPage } from "./Build/StackPage";
import { Create } from "./Create";
import { Convo } from "./Create/Convo";
import { ConvoList } from "./Create/ConvoList";
import { InsightsReportBuilder } from "./Insights/ReportBuilder";
import { ReportResult } from "./Insights/ReportResult";
import { Intel } from "./Intel";
import { AuthedLayout, Layout } from "./Layout";
import { Subscribe } from "./Subscribe";

export const routeDefinitions: RouteObject[] = [
  {
    // Optional authed routes without navigation
    element: <Layout withPadding={false} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/join",
        element: <Signup />,
      },
      {
        path: "/404",
        element: <ErrorPage code={404} />,
      },
    ],
  },
  {
    // Optional authed routes with default navigation
    element: <Layout variant="default" />,
    errorElement: <ErrorPage />,
    children: [
      {
        // Intel is under optional auth for now, so we can link it externally,
        // and handle unauthed users differently if we want.
        path: "/intel/*?",
        element: <Intel />,
      },
    ],
  },
  {
    // Required authed routes with default navigation
    element: <AuthedLayout variant="sidebar" />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Navigate to="/create" />,
      },
      {
        path: "/subscribe",
        element: <Subscribe />,
      },
      {
        path: "/account/:section?/:subSection?",
        element: <Account />,
      },
      {
        path: "/strategy",
        element: <Navigate to="/build" />,
      },
      {
        path: "/build",
        element: <Build />,
      },
      {
        path: "/build/b/:blockPath",
        element: <BlockPage />,
      },
      {
        path: "/build/s/:stackSlug",
        element: <StackPage />,
      },
      {
        path: "/create",
        element: <Create />,
      },
      {
        path: "/create/c/:convoId",
        element: <Convo />,
      },
      {
        path: "/create/all",
        element: <ConvoList />,
      },
    ],
  },
  {
    element: <AuthedLayout variant="sidebar" />,
    errorElement: <ErrorPage code={500} />,
    children: [
      {
        path: "/insights",
        children: [
          {
            path: "",
            element: <Insights />,
          },
          {
            path: "/insights/reports",
            element: <InsightsReportBuilder />,
          },
          {
            path: "/insights/reports/:reportId/results",
            element: <ReportResult />,
          },
        ],
      },
    ],
  },
];
