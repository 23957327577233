import {
  Alert,
  Anchor,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Group,
  Progress,
  Skeleton,
  Stack,
  Switch,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconAlertTriangle,
  IconEdit,
  IconEye,
  IconHexagon,
  IconHexagons,
} from "@tabler/icons-react";
import { rolesWithLimitedBlockAccess } from "api/src/auth/permissions";
import { FeatureFlag } from "api/src/featureFlags";
import type { Fact } from "api/src/models/Fact";
import { IdentityRole } from "api/src/models/Identity";
import { useCallback, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useAuthContext } from "../../auth/useAuthContext";
import { useIsRole } from "../../auth/useIdentity";
import { OnboardingWizardModal } from "../../components/OnboardingWizard";
import { Page } from "../../components/Page";
import { RestrictedFeature } from "../../components/RestrictedFeature";
import { ReturnToTop } from "../../components/ReturnToTop";
import { ScraperNotice } from "../../components/ScraperNotice";
import { Can } from "../../components/auth/Can";
import { StackKey, stackStyle } from "../../components/icons/stacks";
import { useIsPreviewMode } from "../../utils/useAccount";
import { useAllowedBlocks, useFacts } from "../../utils/useFacts";
import { useIsFeatureEnabled } from "../../utils/useIsFeatureEnabled";
import { useOnboardingModal } from "../../utils/useOnboardingModal";
import {
  Module,
  Pillar,
  usePillars,
  type BlocksGroupedByStack,
} from "../../utils/usePillars";
import { BlockCard } from "./BlockCard";
import classes from "./Build.module.css";
import { StoryView, type ContentStories } from "./Content";
import { FactsContext } from "./FactsContext";
import { useBlocksStats, useNotifyOnCreateUnlocked } from "./useBlocksStats";
import { useStrategyBrowserFilters } from "./useStrategyBrowserFilters";

const previewBlocks = ["foundations", "brand"];

function PillarGroup({
  pillar,
  children,
}: React.PropsWithChildren<{ pillar: BlocksGroupedByStack["stack"] }>) {
  const isPreviewMode = useIsPreviewMode();
  const canAccessPillar =
    !isPreviewMode || (isPreviewMode && previewBlocks.includes(pillar.slug));
  const blockStackStyle = stackStyle[pillar.slug.toLowerCase() as StackKey];

  return (
    <Box mb="lg" pos="relative">
      <RestrictedFeature
        my="xl"
        title={`Block ${pillar.name} Locked`}
        description="This block is available to paid NOAN subscribers only"
        isRestricted={!canAccessPillar}
      >
        {({ restrictedClassName }) => (
          <>
            <Divider
              label={
                <Group gap="xs">
                  <ThemeIcon variant="transparent" size="sm" color="dimmed">
                    {blockStackStyle?.icon ?? <IconHexagon />}
                  </ThemeIcon>
                  <Text span size="sm" tt="uppercase">
                    {pillar.name}
                  </Text>
                </Group>
              }
              mb="sm"
            />

            <Stack gap="xs" className={restrictedClassName}>
              {children}
            </Stack>
          </>
        )}
      </RestrictedFeature>
    </Box>
  );
}

function BrowserOptions({
  heading,
  icon,
  description,
  children,
}: React.PropsWithChildren<{
  heading: string;
  icon: JSX.Element;
  description?: string;
}>) {
  return (
    <Box>
      <Group preventGrowOverflow wrap="nowrap">
        {icon}
        <Stack gap={0}>
          <Title order={4}>{heading}</Title>
          {description != null && <Text size="sm">{description}</Text>}
        </Stack>
      </Group>
      <Box py={"sm"}>{children}</Box>
    </Box>
  );
}

export function StrategyBrowser({
  pillars: allPillars,
  facts,
}: {
  pillars: Pillar[];
  facts: Fact[];
}) {
  const [_, setSearchParams] = useSearchParams();
  const canUseBlocksRestricted = useIsFeatureEnabled(
    FeatureFlag.UseBlocksRestricted,
  );

  const { identity } = useAuthContext();
  const isClient = useIsRole(IdentityRole.Client);

  const { blocks: modulesAllowed } = useAllowedBlocks({
    role: identity.primaryRole,
  });

  const [isStoryViewOpen, { close: closeStoryView, open: openStoryView }] =
    useDisclosure();

  const [contentStories, setContentStories] = useState<
    ContentStories | undefined
  >();

  /** A module is readable when:
   * - Available for the current logged in user role
   * AND
   * - It is not restricted from Storyblok
   * - OR it is restricted from Storyblok and available to the users set from the feature flag
   */
  const isModuleReadable = useCallback(
    (module: Module) => {
      const isRestrictedRole = rolesWithLimitedBlockAccess.includes(
        identity.primaryRole,
      );
      return (
        (!isRestrictedRole ||
          (isRestrictedRole &&
            modulesAllowed?.includes(module.knowledgeslug!))) &&
        (!module.restricted || (module.restricted && canUseBlocksRestricted))
      );
    },
    [canUseBlocksRestricted, identity.primaryRole, modulesAllowed],
  );

  const pillars = useMemo(
    () => allPillars.filter((p) => p.content.modules.some(isModuleReadable)),
    [allPillars, isModuleReadable],
  );

  const {
    filteredStacks,
    setFilteredStacks,
    hideCompleteInputBlocks,
    setHideCompleteInputBlocks,
    hideInputBlocks,
    setHideInputBlocks,
  } = useStrategyBrowserFilters({
    stacks: pillars,
    facts,
  });

  /**
   * Filters the list of pillars down to the pillar selection.
   */
  const pillarsSubset = pillars.filter(
    ({ slug }) => !filteredStacks.includes(slug),
  );

  const canReadAllBlocks = !rolesWithLimitedBlockAccess.includes(
    identity.primaryRole,
  );

  const { completeInputBlocks, totalInputBlocks, stacksLockingCreate } =
    useBlocksStats({
      filteredStacks: pillarsSubset,
      stacks: pillars,
      facts,
    });

  const percentInputModulesCompleteOrNaN = Math.round(
    (completeInputBlocks / totalInputBlocks) * 100,
  );

  const percentInputModulesComplete = isNaN(percentInputModulesCompleteOrNaN)
    ? 0
    : percentInputModulesCompleteOrNaN;

  const isCreateBlocksRequiredIncomplete = stacksLockingCreate.some(
    (p) => p.stackPercentComplete !== 100,
  );

  useNotifyOnCreateUnlocked({
    isCreateBlocksRequiredIncomplete,
    stacksLockingCreate,
  });

  /**
   * Mashes all the strategy data together into a convenient
   * shape:
   */
  const strategyData = useMemo<BlocksGroupedByStack[]>(
    () =>
      pillarsSubset
        .map((pillar) => ({
          stack: pillar,
          blocks: pillar.content.modules
            .filter((mod) => {
              if (
                hideCompleteInputBlocks &&
                facts.find((f) => f.blockPath === mod.knowledgeslug)
              ) {
                return false;
              }

              if (hideInputBlocks && mod.component === "robot-input-module") {
                return false;
              }

              return true;
            })
            // Match module with knowledge data:
            .map((mod) => ({
              ...mod,
              knowledge: facts.find(
                ({ blockPath }) => blockPath === mod.knowledgeslug,
              ),
              fact: facts.find(
                ({ blockPath }) => blockPath === mod.knowledgeslug,
              ),
              stackName: pillar.name,
              stackSlug: pillar.slug,
            })),
        }))
        // Somewhat wastefully, remove all pillars with no modules after all filtering is done:
        .filter((p) => p.blocks.length > 0),
    [hideCompleteInputBlocks, hideInputBlocks, pillarsSubset, facts],
  );

  function isPillarFilterEnabled(pillarSlug: string): boolean {
    return !filteredStacks.includes(pillarSlug);
  }

  function togglePillarFilter(pillarSlug: string) {
    setFilteredStacks((filtered) => {
      if (filtered.includes(pillarSlug)) {
        return filtered.filter((slug) => slug !== pillarSlug);
      } else {
        return [...filtered, pillarSlug];
      }
    });
  }

  function resetFilters() {
    setFilteredStacks([]);
    setHideCompleteInputBlocks(false);
    setHideInputBlocks(false);
  }

  function onHideContent() {
    closeStoryView();

    setContentStories(undefined);
    setSearchParams((p) => {
      p.delete("learn");
      return p;
    });
  }

  const hasFilters =
    filteredStacks.length > 0 || hideCompleteInputBlocks || hideInputBlocks;

  const totalPercentCompletedLabel = `${
    isNaN(percentInputModulesComplete) ? "0" : percentInputModulesComplete
  }%`;

  return (
    <Grid columns={6}>
      <Grid.Col span={{ base: 6, sm: 2 }}>
        <Card withBorder p="sm">
          <Card.Section mb="lg">
            <Group
              p="xs"
              gap="xs"
              justify="flex-end"
              className={classes.filterHeading}
            >
              {hasFilters && (
                <Tooltip
                  label="Some results may not be included because of your filters."
                  position="bottom"
                >
                  <ThemeIcon size="sm" variant="transparent">
                    <IconAlertTriangle />
                  </ThemeIcon>
                </Tooltip>
              )}

              <Button
                size="xs"
                variant="light"
                color="dark"
                onClick={resetFilters}
                disabled={!hasFilters}
              >
                Reset filters
              </Button>
            </Group>
          </Card.Section>
          <Stack gap="md">
            <BrowserOptions
              icon={
                <ThemeIcon size="md" color="dark" variant="transparent">
                  <IconHexagon />
                </ThemeIcon>
              }
              heading="Blocks"
              description={
                !isClient
                  ? "Select the strategy blocks most relevant to you"
                  : undefined
              }
            >
              <Group wrap="wrap" gap={4} preventGrowOverflow>
                {pillars.map(({ uuid, slug, name }) => {
                  const isChecked = isPillarFilterEnabled(slug);
                  return (
                    <Chip
                      key={uuid}
                      checked={isChecked}
                      onChange={() => togglePillarFilter(slug)}
                      size="sm"
                    >
                      {name}
                    </Chip>
                  );
                })}
              </Group>
            </BrowserOptions>
            <Can execute="facts.read">
              <BrowserOptions
                heading="Visibility"
                icon={
                  <ThemeIcon size="md" color="dark" variant="transparent">
                    <IconEye />
                  </ThemeIcon>
                }
              >
                <Stack>
                  <Switch
                    label="Only show incomplete blocks"
                    onChange={() =>
                      setHideCompleteInputBlocks(!hideCompleteInputBlocks)
                    }
                    checked={hideCompleteInputBlocks}
                  />
                </Stack>
              </BrowserOptions>
            </Can>
          </Stack>
        </Card>
      </Grid.Col>
      <Grid.Col span={{ base: 6, sm: 4 }}>
        {canReadAllBlocks && percentInputModulesComplete > 0 && (
          <Stack gap="xs" mb="sm">
            <Group gap={5}>
              <ThemeIcon size="sm" variant="light">
                <IconHexagons />
              </ThemeIcon>

              <Text size="sm">Blocks done: </Text>
            </Group>
            <Progress.Root
              size="xl"
              styles={{
                root: {
                  height: 15,
                  overflow: "initial",
                },
                section: {
                  height: 15,
                },
              }}
            >
              <Tooltip
                label={`${completeInputBlocks} out of ${totalInputBlocks} blocks completed.`}
                arrowPosition="side"
                position="top-end"
                withArrow
              >
                <Progress.Section
                  value={percentInputModulesComplete}
                  className={classes.progressSection}
                >
                  <Progress.Label>{totalPercentCompletedLabel}</Progress.Label>
                </Progress.Section>
              </Tooltip>
            </Progress.Root>
          </Stack>
        )}

        {isCreateBlocksRequiredIncomplete && !isClient && (
          <Can execute="facts.update">
            <Alert icon={<IconEdit />} my="md">
              <Box mb="sm">
                <Group gap="xs" mb="md">
                  <Text size="md">
                    Complete these blocks to unlock{" "}
                    <Anchor component={Link} to="/create">
                      Create Mode
                    </Anchor>
                    .
                  </Text>
                </Group>
                <Group>
                  {stacksLockingCreate.map((p) => (
                    <Stack gap="4" key={p.slug}>
                      <Text size="sm">{p.name}</Text>
                      <Text size="xl" fw="bold">
                        {p.stackPercentComplete}%
                      </Text>
                      <Tooltip
                        label={`${p.completeBlocks} of ${p.totalBlocks}`}
                        position="bottom"
                      >
                        <Progress.Root
                          size="sm"
                          styles={{
                            root: {
                              width: 75,
                            },
                          }}
                        >
                          <Progress.Section value={p.stackPercentComplete} />
                        </Progress.Root>
                      </Tooltip>
                    </Stack>
                  ))}
                </Group>
              </Box>
            </Alert>
          </Can>
        )}

        {strategyData.length === 0 && (
          <Text c="dimmed" size="sm" ta="center">
            No blocks match your filters.
          </Text>
        )}

        <FactsContext.Provider
          value={{
            facts,
            stacks: strategyData,
          }}
        >
          <ScraperNotice />

          {contentStories != null && (
            <StoryView
              isOpen={isStoryViewOpen}
              onClose={onHideContent}
              stories={contentStories}
            />
          )}

          {strategyData.map(({ stack: pillar, blocks: modules }) => (
            <PillarGroup key={pillar.uuid} pillar={pillar}>
              {modules.map((mod) => {
                if (isModuleReadable(mod))
                  return (
                    <Can key={mod._uid} execute="facts.read">
                      <BlockCard
                        mod={mod}
                        fact={mod.fact}
                        pillarName={pillar.name}
                        pillarSlug={pillar.slug}
                        onOpenLearningContent={(content) => {
                          setContentStories(content);
                          openStoryView();
                        }}
                      />
                    </Can>
                  );
              })}
            </PillarGroup>
          ))}
        </FactsContext.Provider>
      </Grid.Col>
    </Grid>
  );
}

/**
 * @deprecated This page will be deleted once 'use-new-build-mode' flag is enabled.
 */
export function LegacyBuild() {
  const { pillars } = usePillars();
  const [facts, { isLoading: isLoadingFacts }] = useFacts();

  const { onboardingModalOpened, closeOnboardingModal } = useOnboardingModal();
  const isLoading = pillars == null || isLoadingFacts;

  return (
    <Page title="Build" description="Develop and manage your company strategy">
      {isLoading ? (
        <Grid columns={6}>
          <Grid.Col span={{ base: 6, sm: 2 }}>
            <Skeleton height={300} />
          </Grid.Col>
          <Grid.Col span={{ base: 6, sm: 4 }}>
            <Skeleton height={600} />
          </Grid.Col>
        </Grid>
      ) : (
        <StrategyBrowser pillars={pillars} facts={facts} />
      )}

      <OnboardingWizardModal
        opened={onboardingModalOpened}
        onClose={closeOnboardingModal}
      />
      <ReturnToTop />
    </Page>
  );
}
