import { Card, Container, Group, LoadingOverlay } from "@mantine/core";
import { GeneratorFactory, MessageRole } from "api/src/models/GeneratorInput";
import { useParams } from "react-router-dom";
import { FullPageLoader } from "../../components/FullPageLoader";
import { MarkdownContent } from "../../components/MarkdownContent";
import { Page } from "../../components/Page";
import { TextCopier } from "../../components/TextCopier";
import { useGeneratorQuery } from "../../useGenerator";
import { trpc } from "../../utils/trpc";

function useReport(reportId: string) {
  const { data, ...query } = trpc.insights.getReport.useQuery({
    reportId: reportId,
  });

  return {
    ...query,
    report: data,
  };
}

export function ReportResult() {
  const { reportId } = useParams();
  const { report } = useReport(reportId!);
  const { generatedText, isLoadingResponse, isSampling } = useGeneratorQuery({
    cacheKey: reportId,
    enabled: report != null,
    generate: {
      factory: GeneratorFactory.InsightsReport,
      role: MessageRole.User,
      thread: {
        insightsReportId: reportId,
      },
    },
  });

  if (!report) {
    return <FullPageLoader message="Loading your report..." />;
  }

  return (
    <Container size="md" pb="lg">
      <Page
        title={report.definition.title || "Unnamed Report"}
        description={report.definition.description || "Insights Report"}
      >
        <Card>
          <Group justify="flex-end">
            <TextCopier content={generatedText ?? ""} />
          </Group>
          <MarkdownContent content={generatedText} />
          <LoadingOverlay
            opacity={0.2}
            visible={isLoadingResponse || isSampling}
          />
        </Card>
      </Page>
    </Container>
  );
}
