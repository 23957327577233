import {
  Button,
  Divider,
  Drawer,
  Group,
  Loader,
  Skeleton,
  Tabs,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useStoryblok } from "@storyblok/react";
import StoryblokStory from "@storyblok/react/story";
import { IconBook, IconExternalLink } from "@tabler/icons-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { slugToIntelPath } from "../../utils/storyblokHelpers";

export type ContentStories = Record<
  string,
  {
    title: string;
    slug: string;
  }
>;

export function StoryView({
  stories,
  isOpen,
  onClose,
  defaultStoryId,
}: {
  stories: ContentStories;
  defaultStoryId?: string;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [activeStoryId, setActiveStoryId] = useState<string>(
    defaultStoryId ?? Object.keys(stories).at(0)!,
  );
  const activeStory = stories[activeStoryId];
  const blok = useStoryblok(activeStory.slug, {
    version: "published",
  });
  const fullSlug = blok.full_slug;
  const showExternalLink =
    fullSlug != null &&
    (fullSlug.startsWith("intel/") || fullSlug.startsWith("content/"));

  return (
    <Drawer
      opened={isOpen}
      size={"xl"}
      onClose={onClose}
      title={
        <Group mih={35}>
          <Group gap="xs">
            <ThemeIcon size="md" variant="light">
              <IconBook />
            </ThemeIcon>
            <Title order={4}>{activeStory.title}</Title>
          </Group>
          {showExternalLink && (
            <Button
              size="xs"
              leftSection={<IconExternalLink size={14} />}
              component={Link}
              to={slugToIntelPath(fullSlug)}
              target="_blank"
            >
              Read in The Intel
            </Button>
          )}
        </Group>
      }
      position="right"
    >
      {!blok?.content ? (
        <>
          <Skeleton height={40} mb="md" />
          <Divider mb="lg" />
          <Skeleton height={400} />
        </>
      ) : (
        <Tabs
          mt="md"
          mb="xl"
          keepMounted={false}
          onChange={(tabId) => setActiveStoryId(tabId!)}
          defaultValue={activeStoryId}
        >
          <Tabs.List>
            {Object.entries(stories).map(([storyId, story]) => (
              <Tabs.Tab key={storyId} value={storyId}>
                {story.title}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {Object.entries(stories).map(([storyId, story]) => (
            <Tabs.Panel value={storyId} key={storyId} pt="md">
              {!blok && <Loader />}
              {fullSlug === story.slug && <StoryblokStory story={blok} />}
            </Tabs.Panel>
          ))}
        </Tabs>
      )}
    </Drawer>
  );
}
