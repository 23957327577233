import {
  Alert,
  Button,
  Card,
  Checkbox,
  Container,
  Fieldset,
  Group,
  Loader,
  LoadingOverlay,
  Pill,
  PillsInput,
  Stack,
  Text,
  TextInput,
  Textarea,
  ThemeIcon,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconBulb } from "@tabler/icons-react";
import { GeneratorFactory, MessageRole } from "api/src/models/GeneratorInput";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Page } from "../../components/Page";
import { useGeneratorQuery } from "../../useGenerator";
import { useCreateInsightsReport, useInsights, type Insight } from "./hooks";

function InsightPill({
  insight,
  onRemove,
  isIgnored = false,
}: {
  insight: Insight;
  onRemove: (insightId: string) => void;
  isIgnored?: boolean;
}) {
  return (
    <Pill size="md" onRemove={() => onRemove(insight.id)}>
      <Text lineClamp={1} size="sm" c={isIgnored ? "base" : "text"}>
        {insight.title}
      </Text>
    </Pill>
  );
}

export function InsightsReportBuilder() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { createInsightsReport, isLoading: isCreatingInsightsReport } =
    useCreateInsightsReport();

  const reportInsightIds = params.has("insights")
    ? params.get("insights")!.split("+")
    : [];

  /** Allows removing insights from a report without fully losing track of them */
  const [ignoredInsightsFromReport, setIgnoredInsightsFromReport] = useState<
    string[]
  >([]);
  const { insights } = useInsights({
    id: reportInsightIds,
  });

  const relevantInsights = (insights ?? []).filter(
    (i) => !ignoredInsightsFromReport.includes(i.id),
  );

  const relevantInsightIds = relevantInsights.map((i) => i.id);

  const form = useForm({
    initialValues: {
      title: "",
      description: "",
      insightIds: relevantInsightIds,
      includeOrganizationStrategy: false,
      additionalInstructions: "",
    },
  });

  const formValues = form.getValues();
  const { generatedText: generatedTitle, isLoadingResponse } =
    useGeneratorQuery({
      onEndGenerate() {
        if (generatedTitle) {
          form.setFieldValue("title", generatedTitle);
        }
      },
      cacheKey: "insights-report-title",
      generate: {
        factory: GeneratorFactory.InsightsReportTitle,
        role: MessageRole.User,
        generator: "openai",
        thread: {
          insightsReport: {
            title: "Unnamed Report",
            description: formValues.description,
            insights: relevantInsights.map((insight) => ({
              id: insight.id,
              includeFullContext: false,
            })),
            tone: {
              text: formValues.additionalInstructions ?? "",
            },
            includeOrganizationStrategy: formValues.includeOrganizationStrategy,
            version: 1,
          },
        },
      },
    });

  function onRemoveInsightFromReport(insightId: string) {
    if (ignoredInsightsFromReport.includes(insightId)) {
      setIgnoredInsightsFromReport((ignored) =>
        ignored.filter((i) => i !== insightId),
      );
    } else {
      setIgnoredInsightsFromReport((ignored) => [
        ...new Set([...ignored, insightId]),
      ]);
    }
  }

  return (
    <Container size="md">
      <Page
        title="Review Report"
        description="Generate a detailed strategy report based on a set of insights"
      >
        <Card>
          <Alert
            mb="md"
            color="blue"
            title="INSIGHTS REPORTS"
            icon={
              <ThemeIcon variant="transparent" c="blue.2">
                <IconBulb />
              </ThemeIcon>
            }
          >
            NOAN Insights Reports are an easy way to build your business based
            on what thousands of other successful companies are doing.
          </Alert>
          <form
            onSubmit={form.onSubmit((data) =>
              createInsightsReport(
                {
                  report: {
                    title: data.title,
                    description: data.description,
                    insights: relevantInsights.map((i) => ({
                      id: i.id,
                      includeFullContext: false,
                    })),
                    version: 1,
                    includeOrganizationStrategy:
                      data.includeOrganizationStrategy,
                    tone: {
                      text: data.additionalInstructions,
                    },
                  },
                },
                {
                  onSuccess: (result) => {
                    navigate(`/insights/reports/${result.id}/results`);
                  },
                },
              ),
            )}
          >
            <Stack gap="md">
              <TextInput
                {...form.getInputProps("title")}
                label="Title"
                description="The title of the report does not affect its result"
                size="md"
                leftSection={
                  isLoadingResponse ? <Loader size="sm" color="base" /> : null
                }
              />
              <Textarea
                label="Description"
                size="md"
                {...form.getInputProps("description")}
                description="A short description of this report. This content is considered when generating your report."
                placeholder="This report outlines the primary approaches for audience targetting in the Health industry."
              ></Textarea>
              <PillsInput label="Insights included in this report:" size="md">
                <Pill.Group p="sm">
                  {(insights ?? []).map((insight) => (
                    <InsightPill
                      key={insight.id}
                      insight={insight}
                      onRemove={onRemoveInsightFromReport}
                      isIgnored={ignoredInsightsFromReport.includes(insight.id)}
                    />
                  ))}
                </Pill.Group>
              </PillsInput>

              <Textarea
                label="Additional instructions"
                description="Specific instructions or guidelines for this report"
                placeholder="No more than 3 sentences, in Spanish, etc"
                {...form.getInputProps("additionalInstructions")}
              ></Textarea>

              <Fieldset legend="Additional options">
                <Checkbox
                  label="Include my Organization Strategy"
                  description="If selected, your organization strategy will be considered as part of the report."
                  {...form.getInputProps("includeOrganizationStrategy")}
                />
              </Fieldset>

              <Group justify="flex-end">
                <Text size="sm" c="dimmed">
                  You will have a chance to adjust this report later.
                </Text>
                <Button
                  type="submit"
                  color="blue"
                  disabled={isCreatingInsightsReport}
                >
                  Create Report
                </Button>
              </Group>
            </Stack>

            <LoadingOverlay visible={isCreatingInsightsReport} zIndex={99} />
          </form>
        </Card>
      </Page>
    </Container>
  );
}
