import classes from "./StackIcons.module.css";

export function ConstructionIcon() {
  return (
    <svg
      width="205"
      height="230"
      viewBox="0 0 205 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M162.035 61.1199C156.761 58.0752 150.264 58.0753 144.99 61.1201L85.3337 95.5626L68.289 85.7218L17.1548 115.287L34.1995 125.128L34.2002 164.447L51.2446 174.288L85.3337 154.607L85.3338 134.926L102.378 144.766L102.378 125.085L119.423 134.925L119.423 115.244L102.378 125.085L85.3338 134.926L85.3337 115.244L153.511 75.8821L170.556 85.7225L153.513 95.5624L136.469 105.403L119.424 115.243L136.469 125.084L136.469 105.403L153.513 115.244L153.513 95.5624L170.557 105.404L170.556 85.7225L170.557 75.8813C170.557 75.84 170.556 75.7987 170.556 75.7575C170.513 69.7156 167.273 64.144 162.035 61.1199ZM51.2447 154.606L68.2888 144.766V105.403L51.2444 115.244L51.2447 154.606Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.536 154.65L204.537 70.0998C204.537 61.5254 199.963 53.6023 192.537 49.3151L114.269 4.1268C106.843 -0.16028 97.6945 -0.160269 90.269 4.12684L17.0448 46.4017C6.49753 52.4911 2.01768e-05 63.7449 0 75.9238L4.77098e-05 160.475C6.84045e-05 169.049 4.57443 176.972 12 181.259L90.2681 226.448C97.6937 230.735 106.842 230.735 114.268 226.448L187.491 184.172C198.038 178.083 204.536 166.829 204.536 154.65ZM98.2688 19.1895L21.0448 63.7737C18.5695 65.2028 17.0447 67.8438 17.0447 70.702L17.0448 159.872C17.0448 162.73 18.5695 165.371 21.0447 166.8L98.2683 211.385C100.743 212.814 103.793 212.814 106.268 211.385L183.491 166.8C185.966 165.371 187.491 162.73 187.491 159.872L187.492 70.7028C187.492 67.8447 185.967 65.2036 183.492 63.7746L106.269 19.1896C103.794 17.7605 100.744 17.7605 98.2688 19.1895Z"
        fill="currentColor"
      />
    </svg>
  );
}
