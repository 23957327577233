import { useDebouncedState, useDisclosure, useHover } from "@mantine/hooks";
import { OrganizationProps } from ".";
import { useAuthContext } from "../../../auth/useAuthContext";
import { useAccountBilling } from "../../../utils/useAccount";
import { useOrganizationMutation } from "../../../utils/useOrganizationMutation";
import { useIsFeatureEnabled } from "../../../utils/useIsFeatureEnabled";
import { FeatureFlag } from "api/src/featureFlags";
import { useOrganizations } from "../../../utils/useOrganizations";
import { notifications } from "@mantine/notifications";
import {
  IconAlertSquare,
  IconBug,
  IconBuilding,
  IconCheck,
  IconExclamationMark,
  IconSwitchHorizontal,
  IconX,
} from "@tabler/icons-react";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import {
  ActionIcon,
  Alert,
  Anchor,
  Badge,
  Button,
  Checkbox,
  Fieldset,
  Group,
  Input,
  List,
  Loader,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { Can } from "../../../components/auth/Can";
import { Link } from "react-router-dom";
import {
  useIsOrganizationSlugUnique,
  useOrganization,
} from "../../../utils/useOrganization";
import { slugify } from "../../../utils/slugify";
import { useEffect } from "react";
import { useTransferOwnership } from "../hooks";
import { IdentityRole } from "api/src/models/Identity";
import { PromoCodesSelector } from "../../../components/PromoCodesSelector";
import { accountPageSections } from "../sections";
import classes from "./OrganizationSection.module.css";

function randomSequence(charNumbers = 6) {
  return Math.random().toString(36).slice(2).slice(0, charNumbers);
}

interface NewOrganizationFormFields {
  name: string;
  slug: string;
}

function NewOrganizationModal({
  opened,
  close,
}: {
  opened: boolean;
  close: () => void;
}) {
  const [slugSearch, setSlugSearch] = useDebouncedState<string>("", 300);
  const { data: isSlugUnique, isLoading: isLoadingIsSlugUnique } =
    useIsOrganizationSlugUnique(slugSearch);
  const { createOrganizationAsync, isLoadingCreateOrganization } =
    useOrganizationMutation();

  const { getInputProps, isValid, onSubmit, reset, setFieldValue, values } =
    useForm<NewOrganizationFormFields>({
      clearInputErrorOnChange: true,
      validateInputOnChange: true,
      validateInputOnBlur: true,
      initialValues: {
        name: "",
        slug: "",
      },
      onValuesChange(values, previous) {
        if (values.name !== previous.name) {
          setFieldValue("slug", slugify(values.name));
          setSlugSearch(slugify(values.name));
        }
      },
      validate: {
        name: hasLength(
          { min: 3, max: 64 },
          "Must be between 3 and 64 characters",
        ),
      },
    });

  useEffect(() => {
    if (isSlugUnique === false) {
      const uniqueSlug = slugify(`${values.name}-${randomSequence()}`);
      setSlugSearch(uniqueSlug);
      setFieldValue("slug", uniqueSlug);
    }
  }, [isSlugUnique, setFieldValue, setSlugSearch, values.name]);

  const disableSubmit =
    !isValid() || isLoadingCreateOrganization || isLoadingIsSlugUnique;

  async function onCreate() {
    if (!isSlugUnique) {
      return;
    }

    await createOrganizationAsync(
      {
        name: values.name,
        slug: slugSearch,
      },
      {
        onSuccess() {
          notifications.show({
            color: "green",
            icon: <IconCheck size={18} />,
            message: "Organization created",
          });
        },
        onError() {
          notifications.show({
            color: "red",
            icon: <IconBug size={18} />,
            message:
              "Failed to create the new organization. Please retry later.",
          });
        },
      },
    );
    cleanup();
  }

  function cleanup() {
    reset();
    close();
  }

  return (
    <Modal
      opened={opened}
      onClose={cleanup}
      autoFocus
      size="md"
      title={
        <Group gap="sm">
          <IconBuilding size={18} />
          Add New Organization
        </Group>
      }
    >
      <form onSubmit={onSubmit(onCreate)}>
        <Stack>
          <TextInput
            maxLength={64}
            label="Organization name"
            description={`This will be the display name of your organization on NOAN. ${values.name.length}/64`}
            required
            {...getInputProps("name")}
          />
          <Group gap="sm" justify="center">
            <Button size="sm" onClick={cleanup} variant="transparent">
              Cancel
            </Button>
            <Button type="submit" size="sm" disabled={disableSubmit}>
              Create
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}

interface TransferOwnershipFormFields {
  ownerIdentityId: string;
  promoCode?: string;
  isLeaving: boolean;
}

function TransferOwnershipModal({
  opened,
  close,
}: {
  opened: boolean;
  close: () => void;
}) {
  const { getInputProps, isValid, onSubmit, reset, setFieldValue, values } =
    useForm<TransferOwnershipFormFields>({
      clearInputErrorOnChange: true,
      validateInputOnChange: true,
      validateInputOnBlur: true,
      initialValues: {
        ownerIdentityId: "",
        promoCode: "",
        isLeaving: true,
      },

      validate: {
        ownerIdentityId: hasLength({ min: 1 }),
      },
    });
  const { transferOwnership, isTransferringOwnership } = useTransferOwnership();
  const { organization } = useOrganization();
  const { data: organizations } = useOrganizations();
  const { switchOrganizationAsync } = useOrganizationMutation();
  const { identity } = useAuthContext();
  const { hovered: isLeaveOrganizationHovered, ref: refLeaveOrganization } =
    useHover<HTMLInputElement>();

  const otherOrganization = organizations.find(
    (org) => org.id !== organization?.id,
  );
  const members =
    organization?.identities.filter(
      (member) =>
        member.id !== identity.id && member.primaryRole === IdentityRole.Owner,
    ) ?? [];

  const disableSubmit = !isValid() || isTransferringOwnership;

  async function onTransfer(values: TransferOwnershipFormFields) {
    const leaveOrganization = values.isLeaving;

    transferOwnership(
      {
        ownerIdentityId: values.ownerIdentityId,
        isLeavingOrganization: leaveOrganization,
        promoCode: values.promoCode,
      },
      {
        async onSuccess() {
          notifications.show({
            color: "green",
            icon: <IconCheck size={18} />,
            message: "Organization transferred successfully",
          });

          if (leaveOrganization) {
            if (otherOrganization?.id) {
              await switchOrganizationAsync({ id: otherOrganization.id });
            } else {
              notifications.show({
                color: "red",
                icon: <IconX size={18} />,
                message:
                  "You cannot leave this organization because you are part or no other organizations.",
              });
            }
          }

          cleanup();
        },
        onError() {
          notifications.show({
            color: "red",
            icon: <IconBug size={18} />,
            message:
              "Failed to transfer the organization ownership. Please retry later.",
          });
        },
      },
    );
  }

  function cleanup() {
    reset();
    close();
  }

  return (
    <Modal
      opened={opened}
      onClose={cleanup}
      autoFocus
      size="lg"
      title={
        <Group gap="sm">
          <IconSwitchHorizontal size={18} />
          <Text span truncate maw={300} title={organization?.name}>
            Transfer Ownership - {organization?.name}
          </Text>
        </Group>
      }
    >
      <form onSubmit={onSubmit(onTransfer)}>
        <Stack>
          <RadioGroup
            label="Select the new account owner"
            description="This user will be the account holder and be granted will full
            access to the organization data."
            required
            {...getInputProps("ownerIdentityId")}
          >
            <Stack mt="md">
              {members.map((m) => (
                <Radio key={m.id} label={m.email} value={m.id} />
              ))}
            </Stack>
            {members.length === 0 && (
              <Group wrap="nowrap" my="sm">
                <Alert color="red" icon={<IconExclamationMark />}>
                  <Text size="sm">
                    There are no other <code>owner</code> in this organization.
                    Please invite or change the role of one of the other team
                    members to owner in the{" "}
                    <Anchor
                      component={Link}
                      to={
                        accountPageSections.organization.subSections!.team.path
                      }
                    >
                      Team section
                    </Anchor>{" "}
                    before transferring the full ownership of this organization.
                  </Text>
                </Alert>
              </Group>
            )}
          </RadioGroup>
          <PromoCodesSelector
            selectedCode={values.promoCode}
            onSelectCode={(value) => {
              setFieldValue("promoCode", value);
            }}
          />
          <Tooltip
            label={`You cannot leave this organization as there is no other organization available to switch to.`}
            opened={isLeaveOrganizationHovered && !otherOrganization}
          >
            <Checkbox
              ref={refLeaveOrganization}
              defaultChecked
              label="Leave organization?"
              description="Do you also want to leave this organization while transferring it to the new owner? You will be redirected to one of your other organizations automatically."
              disabled={!otherOrganization}
              {...getInputProps("isLeaving")}
            />
          </Tooltip>
          <Alert icon={<IconAlertSquare />} className={classes.alertContent}>
            <Text size="sm">
              This action is irreversible. Please review the consequences below
              carefully to ensure you understand them fully.
            </Text>
            <List size="xs" spacing="xs" mt="md">
              <List.Item>
                This organization will be moved to NOAN limited preview mode,
                effective immediately.
              </List.Item>
              <List.Item>
                This organization will not be linked to any NOAN subscription,
                meaning that the seats and organizations will be limited.
              </List.Item>
              <List.Item>
                If you opt for the <b>Leave organization?</b> option, you will
                lose access to this organization. You will be able to re-join by
                invitation from another organization member.
              </List.Item>
            </List>
          </Alert>
          <Group gap="sm" justify="center">
            <Button size="sm" onClick={cleanup} variant="transparent">
              Cancel
            </Button>
            <Button
              type="submit"
              size="sm"
              disabled={disableSubmit}
              loading={isTransferringOwnership}
              loaderProps={{
                children: <Loader size={18} />,
              }}
            >
              Transfer
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}

interface GeneralPaneFormFields {
  name?: string;
}

export function GeneralSubSection({
  organization,
}: {
  organization: OrganizationProps;
}) {
  const { identity } = useAuthContext();
  const { updateOrganizationAsync, isLoadingUpdateOrganization } =
    useOrganizationMutation();
  const { isOrganizationLimitReached, isLoadingGetBilling } =
    useAccountBilling();
  const { hovered: isNewOrganizationHovered, ref: refNewOrganizationButton } =
    useHover<HTMLButtonElement>();
  const [
    newOrganizationOpened,
    { open: openNewOrganization, close: closeNewOrganization },
  ] = useDisclosure();
  const [transferOpened, { open: openTransfer, close: closeTransfer }] =
    useDisclosure();
  const isTransferOwnershipEnabled = useIsFeatureEnabled(
    FeatureFlag.UseTransferOwnership,
  );

  const { data: organizations, isLoading: isLoadingOrganizations } =
    useOrganizations();
  const { switchOrganizationAsync, isLoadingSwitchOrganization } =
    useOrganizationMutation();

  async function onSwitchOrganization(org: { id: string; name: string }) {
    await switchOrganizationAsync(
      { id: org.id },
      {
        onSuccess() {
          notifications.show({
            message: `Connected to organization ${org.name}`,
            color: "green",
            icon: <IconSwitchHorizontal size={18} />,
          });
        },
      },
    );
  }

  const { getInputProps, onSubmit, isValid, values } =
    useForm<GeneralPaneFormFields>({
      clearInputErrorOnChange: true,
      validateInputOnChange: true,
      validateInputOnBlur: true,
      initialValues: {
        name: organization?.name,
      },
      validate: {
        name: isNotEmpty(),
      },
    });

  const disableSubmit =
    !isValid() ||
    isLoadingUpdateOrganization ||
    organization?.name === values.name;

  async function onSubmitUpdate(values: GeneralPaneFormFields) {
    await updateOrganizationAsync({
      name: values.name ?? organization!.name,
    });

    notifications.show({
      message: `Organization general information updated`,
      color: "green",
      icon: <IconCheck size={18} />,
    });
  }

  function onNewOrganization() {
    openNewOrganization();
  }

  function onTransferOwnership() {
    openTransfer();
  }

  return (
    <Stack>
      <Fieldset legend="General">
        <Stack gap="sm">
          <form onSubmit={onSubmit(onSubmitUpdate)}>
            <Stack gap="sm">
              <TextInput
                label="Display Name"
                disabled={isLoadingUpdateOrganization}
                value={organization?.name}
                {...getInputProps("name")}
              />
              <Can execute="organization.update">
                <Button
                  variant="light"
                  w="fit-content"
                  type="submit"
                  loading={isLoadingUpdateOrganization}
                  disabled={disableSubmit}
                >
                  Update
                </Button>
              </Can>
              <Can execute="organization.create">
                <Input.Wrapper
                  label="New Organization"
                  description={
                    <>
                      You can increase the organizations limit in your{" "}
                      <Anchor size="xs" component={Link} to="/account/billing">
                        Billing Details
                      </Anchor>
                      .
                    </>
                  }
                >
                  <Stack align="flex-start">
                    <Tooltip
                      label={
                        <Text size="sm">
                          You have reached the maximum number of organizations
                          included in your NOAN subscription.
                        </Text>
                      }
                      opened={
                        isNewOrganizationHovered && isOrganizationLimitReached
                      }
                    >
                      <Button
                        ref={refNewOrganizationButton}
                        mt="md"
                        onClick={onNewOrganization}
                        disabled={
                          isLoadingGetBilling || isOrganizationLimitReached
                        }
                      >
                        Add new organization
                      </Button>
                    </Tooltip>
                    <NewOrganizationModal
                      opened={newOrganizationOpened}
                      close={closeNewOrganization}
                    />
                  </Stack>
                </Input.Wrapper>
              </Can>
            </Stack>
          </form>
          {isTransferOwnershipEnabled && (
            <Can execute="organization.update.ownership">
              <Input.Wrapper
                label="Transfer Ownership"
                description="Link this organization to another billing account."
              >
                <Button mt="md" onClick={onTransferOwnership}>
                  Transfer
                </Button>
                <TransferOwnershipModal
                  opened={transferOpened}
                  close={closeTransfer}
                />
              </Input.Wrapper>
            </Can>
          )}
        </Stack>
      </Fieldset>
      <Fieldset legend="Organizations" disabled={isLoadingSwitchOrganization}>
        <Stack gap="sm">
          <Input.Wrapper
            label="Organizations"
            description="Organizations you belong to"
          >
            {organizations.length === 0 ? (
              isLoadingOrganizations ? (
                <Loader mt="md" />
              ) : (
                <Text size="sm" mt="sm">
                  You do not have other organizations yet.
                </Text>
              )
            ) : (
              <Stack w="100%" gap={0} mt="md">
                <Paper
                  bg="rgba(0, 0, 0, .08)"
                  radius="sm"
                  p="md"
                  className={classes.tableHead}
                >
                  <Group justify="space-between">
                    <Group>
                      <IconBuilding size={18} />
                      <Text fw="bold">
                        {organizations.length} organizations
                      </Text>
                    </Group>
                    <Text fw="bold">Actions</Text>
                  </Group>
                </Paper>
                <Table
                  withTableBorder
                  verticalSpacing="md"
                  horizontalSpacing="md"
                  mb="lg"
                >
                  <Table.Tbody>
                    {organizations.map((o) => {
                      const currentIdentity = o.identities.find(
                        (i) => identity.email === i.email,
                      );
                      const isCurrentOrganization = o.id === organization?.id;

                      return (
                        <Table.Tr key={o.id}>
                          <Table.Td>
                            <Group>
                              <Text
                                variant="light"
                                size="sm"
                                fw={isCurrentOrganization ? "bold" : "normal"}
                              >
                                {o.name}
                              </Text>
                              <Tooltip
                                label={"Your role within the organization"}
                              >
                                <Badge variant="light" color="dark" size="xs">
                                  {currentIdentity?.primaryRole}
                                </Badge>
                              </Tooltip>
                              {isCurrentOrganization && (
                                <Tooltip label="You are connected to this organization">
                                  <Badge size="xs" variant="light">
                                    current
                                  </Badge>
                                </Tooltip>
                              )}
                            </Group>
                          </Table.Td>
                          <Table.Td align="right">
                            <Tooltip label="Switch to this organization">
                              <ActionIcon
                                variant="subtle"
                                disabled={isCurrentOrganization}
                                onClick={() => {
                                  onSwitchOrganization(o);
                                }}
                              >
                                <IconSwitchHorizontal size={18} />
                              </ActionIcon>
                            </Tooltip>
                          </Table.Td>
                        </Table.Tr>
                      );
                    })}
                  </Table.Tbody>
                </Table>
              </Stack>
            )}
          </Input.Wrapper>
        </Stack>
      </Fieldset>
    </Stack>
  );
}
