import {
  ActionIcon,
  ActionIconProps,
  Alert,
  Button,
  Group,
  Menu,
  Modal,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure, useLocalStorage, useNetwork } from "@mantine/hooks";
import {
  IconBackspace,
  IconHistory,
  IconInfoCircle,
  IconMoodSmile,
  IconPuzzle,
  IconReload,
} from "@tabler/icons-react";
import { useMemo } from "react";
import { Can } from "../auth/Can";
import { ToneHint, customInstructions } from "./config";

export function RegenerateOptions({
  onRegenerate,
  isDisabled,
  iconProps,
}: {
  onRegenerate: (toneHint: string | ToneHint) => void;
  isDisabled?: boolean;
  iconProps?: ActionIconProps;
}) {
  const { online: isOnline } = useNetwork();
  const [recentCustomInstructions, setRecentCustomInstructions] =
    useLocalStorage<string[]>({
      key: "recentCustomInstructions",
      defaultValue: [],
    });

  const form = useForm({
    initialValues: {
      customInstruction: "",
    },
  });

  function onUseCustomInstruction(instruction: string) {
    setRecentCustomInstructions([
      ...new Set([instruction, ...recentCustomInstructions.slice(0, 4)]),
    ]);

    onRegenerate(instruction);
  }

  const [opened, { close, open }] = useDisclosure();
  const example = useMemo(
    () =>
      customInstructions[Math.floor(Math.random() * customInstructions.length)],
    [],
  );

  return (
    <>
      <Can execute="generated_result.create">
        <Menu position="bottom-end" disabled={!isOnline}>
          <Menu.Target>
            <Tooltip
              withArrow
              openDelay={400}
              label="View options to regenerate block content"
              disabled={!isOnline}
            >
              <ActionIcon
                variant="subtle"
                color="dimmed"
                disabled={!isOnline || isDisabled}
                {...(iconProps ?? {})}
              >
                <IconReload size={18} />
              </ActionIcon>
            </Tooltip>
          </Menu.Target>
          <Menu.Dropdown>
            {recentCustomInstructions.length > 0 && (
              <>
                <Menu.Label>Recent custom instructions</Menu.Label>
                {recentCustomInstructions.map((inst) => (
                  <Menu.Item
                    key={inst}
                    leftSection={<IconHistory size={16} />}
                    onClick={() => {
                      // Bump this instruction back to the top:
                      onUseCustomInstruction(inst);
                    }}
                  >
                    <Text size="sm" maw={200} truncate="end">
                      {inst}
                    </Text>
                  </Menu.Item>
                ))}
                <Menu.Divider />
              </>
            )}
            <Menu.Item
              leftSection={<IconBackspace />}
              onClick={() => onRegenerate(ToneHint.Shorter)}
            >
              Use fewer words
            </Menu.Item>
            <Menu.Item
              leftSection={<IconMoodSmile />}
              onClick={() => onRegenerate(ToneHint.Simpler)}
            >
              Simplify
            </Menu.Item>
            <Menu.Item
              leftSection={<IconReload />}
              onClick={() => onRegenerate(ToneHint.Reset)}
            >
              Try again
            </Menu.Item>
            <Menu.Item leftSection={<IconPuzzle />} onClick={open}>
              Use custom instructions
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Can>
      {opened && (
        <Modal
          size="lg"
          title={
            <Group gap="sm">
              <IconPuzzle size={18} /> Regenerate with custom instructions
            </Group>
          }
          withinPortal
          keepMounted={false}
          opened={opened}
          onClose={close}
          centered
        >
          <form
            onSubmit={form.onSubmit((values) => {
              if (values.customInstruction) {
                onUseCustomInstruction(values.customInstruction);
              }

              close();
            })}
          >
            <Stack>
              <TextInput
                data-autofocus
                size="md"
                placeholder={"Your custom instruction"}
                {...form.getInputProps("customInstruction")}
              />
              <Group align="center">
                <Alert
                  color="dark"
                  variant="outline"
                  icon={<IconInfoCircle />}
                  flex={1}
                >
                  <Text size="sm" c="dimmed">
                    Try something like "{example}"
                  </Text>
                </Alert>

                <Button type="submit" style={{ justifySelf: "flex-end" }}>
                  Regenerate
                </Button>
              </Group>
            </Stack>
          </form>
        </Modal>
      )}
    </>
  );
}
