import classes from "./StackIcons.module.css";

export function TravelIcon() {
  return (
    <svg
      width="205"
      height="229"
      viewBox="0 0 205 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M102.378 94.6524L85.3339 84.8118C92.909 80.4381 92.9089 69.5037 85.3336 65.1302L68.2892 74.9718L51.2447 65.1311L51.2438 84.8125L25.676 99.5737L33.7209 104.218C39.1293 107.341 45.7564 107.431 51.2352 104.489L68.2894 94.6533L68.2892 114.335L85.3336 124.176L51.2452 143.857C43.6698 148.231 43.6694 159.165 51.2448 163.539L51.2452 163.538L59.2898 168.182C64.8586 171.397 71.7197 171.398 77.2888 168.183L119.423 143.857L145.08 158.6C150.308 161.576 156.737 161.565 161.964 158.589C167.264 155.572 170.557 149.932 170.557 143.833C170.557 137.76 167.311 132.15 162.046 129.124L153.512 124.218L153.512 143.857L136.468 134.016L170.557 114.334C178.131 109.961 178.131 99.0276 170.557 94.6538L162.513 90.0089C156.944 86.7936 150.082 86.7936 144.513 90.0087L102.378 114.334L102.378 94.6524Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.536 153.738L204.537 69.1882C204.537 60.6139 199.963 52.6908 192.537 48.4036L114.269 3.21528C106.843 -1.07181 97.6945 -1.0718 90.269 3.21531L17.0448 45.4901C6.49753 51.5795 2.01768e-05 62.8333 0 75.0123L4.77098e-05 159.563C6.84045e-05 168.138 4.57443 176.061 12 180.348L90.2681 225.536C97.6937 229.823 106.842 229.823 114.268 225.536L187.491 183.26C198.038 177.171 204.536 165.917 204.536 153.738ZM98.2688 18.278L21.0448 62.8622C18.5695 64.2912 17.0447 66.9323 17.0447 69.7904L17.0448 158.96C17.0448 161.818 18.5695 164.459 21.0447 165.888L98.2683 210.474C100.743 211.903 103.793 211.903 106.268 210.474L183.491 165.888C185.966 164.459 187.491 161.818 187.491 158.96L187.492 69.7913C187.492 66.9332 185.967 64.2921 183.492 62.863L106.269 18.278C103.794 16.849 100.744 16.849 98.2688 18.278Z"
        fill="currentColor"
      />
    </svg>
  );
}
