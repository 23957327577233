import { useDisclosure, useLocalStorage } from "@mantine/hooks";
import { useCallback, useEffect } from "react";
import { useOrganization } from "./useOrganization";

const supressionKey = "noan-onboarding-suppressed";

export function useOnboardingModal() {
  const { organization } = useOrganization();
  const [isSupressed, setIsSuppressed] = useLocalStorage<number | undefined>({
    key: supressionKey,
    getInitialValueInEffect: false,
  });

  const clearOnboardingModalSuppression = useCallback(
    () => setIsSuppressed(undefined),
    [setIsSuppressed],
  );

  const suppressOnboardingModal = useCallback(
    () => setIsSuppressed(Date.now()),
    [setIsSuppressed],
  );

  useEffect(() => {
    if (isSupressed == null) return;

    if (
      isSupressed &&
      new Date().getTime() - new Date(isSupressed).getTime() >
        24 * 60 * 60 * 1000
    ) {
      clearOnboardingModalSuppression();
    }
  }, [isSupressed, clearOnboardingModalSuppression]);

  const [opened, { close, open }] = useDisclosure();

  useEffect(() => {
    if (!organization) {
      return;
    }

    const isNecessary =
      !organization.name ||
      !organization.settings.attributes?.industry ||
      organization.settings.attributes.teamSize == null ||
      !organization.settings.attributes.targetType ||
      typeof organization.settings.attributes.website === "undefined";

    if (!opened && !isSupressed && isNecessary) {
      open();
    }
  }, [open, isSupressed, organization, opened]);

  return {
    onboardingModalOpened: opened,
    closeOnboardingModal: () => {
      suppressOnboardingModal();
      close();
    },
    isSupressed,
    clearOnboardingModalSuppression,
    suppressOnboardingModal,
  };
}
