import type { AssistantId } from "api/src/generated/storyblok/assistants";
import {
  IconBriefcase,
  IconWriting,
  IconPalette,
  IconSocial,
  IconBrandGoogleAnalytics,
  IconSpeakerphone,
  IconTools,
} from "@tabler/icons-react";
import type { TablerIcon } from "../utils/icons";

export const assistantIcons: Record<AssistantId, TablerIcon | undefined> = {
  "general-assistant": IconBriefcase,
  "content-writer": IconWriting,
  "brand-creative": IconPalette,
  "social-media-strategist": IconSocial,
  "advertising-planner": IconBrandGoogleAnalytics,
  "pr-specialist": IconSpeakerphone,
  "chief-operating-officer": IconTools,
} as const;
