import { AccommodationIcon } from "./Accommodation.svg";
import { AdvertisingIcon } from "./Advertising.svg";
import { AgencyIcon } from "./Agency.svg";
import { ArchitectIcon } from "./Architect.svg";
import { AthleteIcon } from "./Athlete.svg";
import { BeverageIcon } from "./Beverage.svg";
import { BrandIcon } from "./Brand.svg";
import { CharityIcon } from "./Charity.svg";
import { CoachIcon } from "./Coach.svg";
import { ConstructionIcon } from "./Construction.svg";
import { ConsultingIcon } from "./Consulting.svg";
import { ContentIcon } from "./Content.svg";
import { CreatorIcon } from "./Creator.svg";
import { DentistIcon } from "./Dentist.svg";
import { DoctorIcon } from "./Doctor.svg";
import { EcommIcon } from "./Ecomm.svg";
import { EducationIcon } from "./Education.svg";
import { ExperienceIcon } from "./Experience.svg";
import { FarmIcon } from "./Farm.svg";
import { FoundationsIcon } from "./Foundations.svg";
import { GrowthIcon } from "./Growth.svg";
import { GymIcon } from "./Gym.svg";
import { MaintainanceIcon } from "./Maintainance.svg";
import { ManufacturingIcon } from "./Manufacturing.svg";
import { MembershipIcon } from "./Membership.svg";
import { OperationsIcon } from "./Operations.svg";
import { PersonalBrandIcon } from "./PersonalBrand.svg";
import { PersonalTrainerIcon } from "./PersonalTrainer.svg";
import { ProductIcon } from "./Product.svg";
import { RealEstateIcon } from "./RealEstate.svg";
import { RealtorIcon } from "./Realtor.svg";
import { RestaurantIcon } from "./Restaurant.svg";
import { RetailIcon } from "./Retail.svg";
import { SalesIcon } from "./Sales.svg";
import { SportsTeamIcon } from "./SportsTeam.svg";
import { StartupIcon } from "./Startup.svg";
import { TravelIcon } from "./Travel.svg";

export const stackStyle = {
  comms: {
    icon: <AdvertisingIcon />,
  },
  brand: {
    icon: <BrandIcon />,
  },
  content: {
    icon: <ContentIcon />,
  },
  experience: {
    icon: <ExperienceIcon />,
  },
  foundations: {
    icon: <FoundationsIcon />,
  },
  growth: {
    icon: <GrowthIcon />,
  },
  membership: {
    icon: <MembershipIcon />,
  },
  operations: {
    icon: <OperationsIcon />,
  },
  product: {
    icon: <ProductIcon />,
  },
  sales: {
    icon: <SalesIcon />,
  },
  farm: {
    icon: <FarmIcon />,
  },
  ecomm: {
    icon: <EcommIcon />,
  },
  gym: {
    icon: <GymIcon />,
  },
  travel: {
    icon: <TravelIcon />,
  },
  "sports-team": {
    icon: <SportsTeamIcon />,
  },
  consulting: {
    icon: <ConsultingIcon />,
  },
  restaurant: {
    icon: <RestaurantIcon />,
  },
  education: {
    icon: <EducationIcon />,
  },
  realtor: {
    icon: <RealtorIcon />,
  },
  accommodation: {
    icon: <AccommodationIcon />,
  },
  "personal-trainer": {
    icon: <PersonalTrainerIcon />,
  },
  maintainance: {
    icon: <MaintainanceIcon />,
  },
  doctor: {
    icon: <DoctorIcon />,
  },
  agency: {
    icon: <AgencyIcon />,
  },
  architect: {
    icon: <ArchitectIcon />,
  },
  dentist: {
    icon: <DentistIcon />,
  },
  charity: {
    icon: <CharityIcon />,
  },
  athlete: {
    icon: <AthleteIcon />,
  },
  creator: {
    icon: <CreatorIcon />,
  },
  personalBrand: {
    icon: <PersonalBrandIcon />,
  },
  startup: {
    icon: <StartupIcon />,
  },
  retail: {
    icon: <RetailIcon />,
  },
  coach: {
    icon: <CoachIcon />,
  },
  manufacturing: {
    icon: <ManufacturingIcon />,
  },
  "real-estate": {
    icon: <RealEstateIcon />,
  },
  construction: {
    icon: <ConstructionIcon />,
  },
  beverage: {
    icon: <BeverageIcon />,
  },
} as const;

export type StackKey = keyof typeof stackStyle;
