import { useOs } from "@mantine/hooks";

export function useMetaKey() {
  const os = useOs();

  switch (os) {
    case "macos":
      return "⌘";
    default:
      return "Ctrl";
  }
}
