import { ActionIcon, Group, Tooltip } from "@mantine/core";
import { useInViewport, useToggle } from "@mantine/hooks";
import {
  IconCopy,
  IconCopyCheck,
  IconMinimize,
  IconRepeat,
  IconRowRemove,
} from "@tabler/icons-react";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { useCallback, useEffect, useRef } from "react";
import { Popover as TextSelectionPopover } from "react-text-selection-popover";
import rehypeSanitize from "rehype-sanitize";
import classes from "./MarkdownContent.module.css";
import { TextCopier } from "./TextCopier";

type RetrySelectionAction = {
  type: "retrySelection";
  selectionText?: string;
};

type RemoveSelectionAction = {
  type: "removeSelection";
  selectionText?: string;
};

type SimplifySelectionAction = {
  type: "simplifySelection";
  selectionText?: string;
};

function clampText(
  text: string,
  {
    length = 32,
    suffix = "...",
  }: {
    length?: number;
    suffix?: string;
  } = {},
) {
  if (text.length <= length) {
    return text;
  }

  /**
   * Clamp the text, ensuring the suffix doesn't push the output over the desired
   * limit length:
   */
  return `${text.slice(0, length - suffix.length)}${suffix}`;
}

export type TextSelectionAction =
  | SimplifySelectionAction
  | RemoveSelectionAction
  | RetrySelectionAction;

export function MarkdownContent({
  content,
  onSelectionAction,
  withSelectionActions = false,
  lazyRendering = true,
  preview = false,
  style,
}: {
  content?: string;
  onSelectionAction?: (action: TextSelectionAction) => void;

  /**
   * If true, enables the texd selection popover
   */
  withSelectionActions?: boolean;

  /**
   * If true, tries to be lazy about rendering the markdown block, for example,
   * by suppressing it if outside the active viewport.
   */
  lazyRendering?: boolean;

  /**
   * Exposed so we can use Transition
   */
  style?: React.CSSProperties;

  /**
   * Render only a small amount of (preview) content, and try to make it look OK
   */
  preview?: boolean;
}) {
  const { ref, inViewport } = useInViewport();
  const previewRef = useRef<HTMLDivElement>(null);
  const [isRendered, toggleIsRendered] = useToggle();

  useEffect(() => {
    if (inViewport && !isRendered) {
      toggleIsRendered(true);
    }
  }, [inViewport, isRendered, toggleIsRendered]);

  const onRunSelectionAction = useCallback(
    (action: TextSelectionAction) => {
      if (onSelectionAction) {
        onSelectionAction(action);
      }

      const selection = window.getSelection();
      if (selection) {
        selection.empty();
      }
    },
    [onSelectionAction],
  );

  return (
    <div ref={ref}>
      {lazyRendering === false || isRendered ? (
        <>
          <MarkdownPreview
            skipHtml
            rehypePlugins={[rehypeSanitize]}
            disallowedElements={["img", "script", "link", "iframe"]}
            className={classes.markdownWrapperElement}
            source={
              preview && content
                ? clampText(content, {
                    length: 200,
                  })
                : content
            }
            wrapperElement={{
              ref: previewRef,
              style,
            }}
            components={{
              a: (v) => <a target="_blank">{v.children}</a>,
            }}
          />
          {previewRef.current && withSelectionActions && (
            <TextSelectionPopover
              target={previewRef.current}
              render={({ clientRect, isCollapsed, textContent }) => {
                if (clientRect == null || isCollapsed) return null;

                return (
                  <div
                    className={classes.selectionPopover}
                    style={{
                      top: window.scrollY + clientRect.top - 60,
                    }}
                  >
                    <Group gap={2} className={classes.selectionPopoverActions}>
                      <Tooltip label="Regenerate, but omit this selection">
                        <ActionIcon
                          variant="default"
                          size="lg"
                          onClick={() =>
                            onRunSelectionAction({
                              selectionText: textContent,
                              type: "removeSelection",
                            })
                          }
                        >
                          <IconRowRemove />
                        </ActionIcon>
                      </Tooltip>
                      <Tooltip label="Try again, with a different result for this selection">
                        <ActionIcon
                          variant="default"
                          size="lg"
                          onClick={() =>
                            onRunSelectionAction({
                              selectionText: textContent,
                              type: "retrySelection",
                            })
                          }
                        >
                          <IconRepeat />
                        </ActionIcon>
                      </Tooltip>
                      <Tooltip label="Simplify the content for this selection">
                        <ActionIcon
                          variant="default"
                          size="lg"
                          onClick={() =>
                            onRunSelectionAction({
                              selectionText: textContent,
                              type: "simplifySelection",
                            })
                          }
                        >
                          <IconMinimize />
                        </ActionIcon>
                      </Tooltip>

                      <TextCopier content={textContent!}>
                        {(copy, copied) => (
                          <ActionIcon
                            variant={copied ? "filled" : "default"}
                            size="lg"
                            onClick={copy}
                            color={copied ? "green" : undefined}
                          >
                            {copied ? <IconCopyCheck /> : <IconCopy />}
                          </ActionIcon>
                        )}
                      </TextCopier>
                    </Group>
                  </div>
                );
              }}
            />
          )}
        </>
      ) : null}
    </div>
  );
}
