import {
  ActionIcon,
  Affix,
  Avatar,
  Box,
  Button,
  Card,
  CloseButton,
  Group,
  Loader,
  Pill,
  ScrollArea,
  ScrollAreaAutosize,
  Stack,
  Text,
  Textarea,
  Tooltip,
  Transition,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import {
  useDebouncedCallback,
  useDebouncedValue,
  useHover,
} from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconRefresh, IconSend2, IconTrash } from "@tabler/icons-react";
import { AssistantId } from "api/src/generated/storyblok/assistants";
import {
  ConvoEntryProvenance,
  ConvoEntryType,
  ConvoSource,
} from "api/src/models/Convo";
import { GeneratorFactory, MessageRole } from "api/src/models/GeneratorInput";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAuthContext } from "../../auth/useAuthContext";
import { AssistantIcon as AssistantChatIcon } from "../../components/icons/Assistant.svg";
import { useCreateConvoEntry } from "../../pages/Create/useCreateConvoEntry";
import { AiGeneratorOption } from "../../useGenerator";
import { assistantById } from "../../utils/assistantById";
import { trpc } from "../../utils/trpc";
import {
  useGetPreviewConstraints,
  useIsPreviewMode,
} from "../../utils/useAccount";
import { useConversation, useDeleteConvo } from "../../utils/useConversation";
import { ZedIndex } from "../../utils/zedIndex";
import { MarkdownContent } from "../MarkdownContent";
import { TextCopier } from "../TextCopier";
import classes from "./ChatAssistant.module.css";

function useConvo(convoId: string) {
  const { identity } = useAuthContext();
  return trpc.convos.getConvo.useQuery(
    {
      convoId,
    },
    { enabled: !!identity },
  );
}

function useConvos({ source }: { source: ConvoSource }) {
  const { identity } = useAuthContext();

  return trpc.convos.getConvos.useQuery(
    {
      source,
      createdBy: identity.id,
    },
    { enabled: !!identity },
  );
}

function AssistantIcon({
  mounted,
  isLoading = false,
}: {
  mounted: boolean;
  isLoading?: boolean;
}) {
  return (
    <Transition
      mounted={mounted}
      transition={"slide-right"}
      exitDelay={1000}
      duration={200}
    >
      {(styles) => (
        <Group>
          <Avatar variant="transparent" size="md" radius="sm" style={styles}>
            <AssistantChatIcon />
          </Avatar>
          {isLoading && <Loader type="dots" size={24} />}
        </Group>
      )}
    </Transition>
  );
}

interface ConvoEntryForm {
  prompt: string;
  generator: AiGeneratorOption["value"];
  assistantId: AssistantId;
}

function ChatButton({
  assistantId,
  onClick,
}: {
  assistantId: AssistantId;
  onClick: () => void;
}) {
  const assistant = assistantById(assistantId);
  return (
    <Button
      variant="outline"
      radius="xl"
      fw="normal"
      onClick={onClick}
      className={classes.chatAssistantButton}
      leftSection={
        <Avatar variant="transparent" size="md">
          <AssistantChatIcon />
        </Avatar>
      }
    >
      Ask your {assistant.name} anything...
    </Button>
  );
}

function AssistantOutput({
  isLast,
  isUpdatingConversation,
  isFetchingConvo,
  isCreatingConvoEntry,
  content,
  outputActions,
}: {
  isLast: boolean;
  isUpdatingConversation: boolean;
  isFetchingConvo: boolean;
  isCreatingConvoEntry: boolean;
  content: string;
  outputActions?: OutputAction[];
}) {
  const isLoading =
    isUpdatingConversation || isFetchingConvo || isCreatingConvoEntry;
  const isGenerating = isLast && isUpdatingConversation;
  const { ref: refAssistantText, hovered: isAssistantTextHovered } = useHover();

  return (
    <Group
      ref={refAssistantText}
      justify="flex-start"
      align="flex-start"
      wrap="nowrap"
      mb="sm"
    >
      <AssistantIcon mounted={isGenerating} />

      <Card
        radius="md"
        withBorder={false}
        bg="base.1"
        c="black"
        w="fit-content"
        maw="85%"
        px="md"
        py="xs"
      >
        <Box pr="sm" pb="md">
          <MarkdownContent content={content} lazyRendering={false} />
        </Box>
        {isGenerating && <Loader size={18} type="oval" />}
        {isAssistantTextHovered && (
          <Box pos="absolute" bottom={5} right={5}>
            {outputActions &&
              outputActions.map((output) => {
                return (
                  <Tooltip key={output.tooltip} label={output.tooltip}>
                    <ActionIcon
                      variant="subtle"
                      color="dimmed"
                      disabled={isLoading}
                      onClick={() => output.action(content)}
                    >
                      {output.icon}
                    </ActionIcon>
                  </Tooltip>
                );
              })}
            <TextCopier content={content} />
          </Box>
        )}
      </Card>
    </Group>
  );
}

function ChatConversation({
  type = "affix",
  convoId,
  assistantId,
  generator,
  context,
  suggestions,
  onClose,
  onReset,
  outputActions,
}: {
  type?: "affix" | "flex";
  convoId: string;
  assistantId: AssistantId;
  context?: string;
  generator: AiGeneratorOption["value"];
  suggestions?: string[];
  onClose: () => void;
  onReset: () => void;
  outputActions?: OutputAction[];
}) {
  const { ref, hovered: isHovered } = useHover();
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isHoveredDelayed] = useDebouncedValue(isHovered, 120, {
    leading: false,
  });

  const { data: convo, isFetching: isFetchingConvo } = useConvo(convoId);
  const { createConvoEntry, isLoading: isCreatingConvoEntry } =
    useCreateConvoEntry();
  const conversation = convo?.entries.sort((a, b) => {
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    if (a.createdAt > b.createdAt) {
      return 1;
    }
    return 0;
  });

  const { isValid, getInputProps, setFieldValue, values } =
    useForm<ConvoEntryForm>({
      initialValues: {
        prompt: "",
        generator,
        assistantId,
      },
      validate: {
        prompt: hasLength({ min: 1 }),
      },
    });

  const { data: constraints } = useGetPreviewConstraints();
  const isPreview = useIsPreviewMode();

  const isMaxReached =
    isPreview && constraints?.isMaxAssistantGenerationReached;

  const scrollToLatestMessage = useCallback(
    (smooth: boolean = true) => {
      if (scrollAreaRef.current) {
        scrollAreaRef.current.scrollTo({
          top: scrollAreaRef.current.scrollHeight,
          behavior: smooth ? "smooth" : "auto",
        });
      }
    },
    [scrollAreaRef],
  );

  const scrollToLatestMessageDebounced = useDebouncedCallback(
    scrollToLatestMessage,
    20,
  );

  const {
    handleInputChange,
    handleSubmit,
    input,
    messages,
    isLoading: isUpdatingConversation,
  } = useConversation({
    initialMessages: conversation
      ?.filter((conv) =>
        [MessageRole.User, MessageRole.Assistant].includes(
          conv.content.provenance?.source as unknown as MessageRole,
        ),
      )
      .map((conv) => ({
        id: conv.id,
        role: conv.content.provenance?.source as unknown as MessageRole,
        content: conv.content.plainText!,
      })),
    generate: {
      role: MessageRole.User,
      input: values.prompt,
      factory: GeneratorFactory.ChatAssistant,
      thread: {
        assistantPersona: assistantId,
        convoId,
        last: context,
      },
    },
    onEndGenerate: () => {
      scrollToLatestMessageDebounced();
      setTimeout(() => textAreaRef.current?.focus(), 1);
    },
    onStartGenerate: () => {
      createConvoEntry(
        {
          convoId: convoId!,
          content: {
            entryType: ConvoEntryType.Prompt,
            provenance: {
              source: ConvoEntryProvenance.User,
              generator,
            },
            format: {
              type: "text",
            },
            plainText: values.prompt,
          },
        },
        {
          onSuccess() {
            setFieldValue("prompt", "");
          },
        },
      );
    },
  });

  const { deleteConvo, isLoading: isDeleting } = useDeleteConvo({
    onSettled() {
      onReset();
      notifications.show({
        message: "The conversation has been reset.",
        icon: <IconTrash size={14} />,
      });
    },
  });

  const canSendPrompt = !isUpdatingConversation && !!convoId && !isMaxReached;

  function onResetChat() {
    if (!convoId) {
      return;
    }
    deleteConvo({ convoId });
  }

  useEffect(() => {
    if (!isFetchingConvo) {
      scrollToLatestMessage();
    }
  }, [isFetchingConvo, scrollToLatestMessage]);

  if (isUpdatingConversation) {
    scrollToLatestMessageDebounced();
  }

  return (
    <Box
      ref={ref}
      className={clsx(
        type === "affix" && classes.chatAssistantWindow,
        isHoveredDelayed && classes.chatAssistantWindowHovered,
      )}
      maw={type === "flex" ? "100%" : 500}
    >
      <Card shadow="xl" withBorder={true} p="sm">
        <Stack
          gap={0}
          justify="space-between"
          className={clsx(classes.chatAssistantWindowInner)}
        >
          <Group gap="xs" mb="sm">
            <Group gap="xs">
              <Tooltip label="Reset chat">
                <ActionIcon
                  size="sm"
                  color="yellow"
                  variant="subtle"
                  onClick={onResetChat}
                  disabled={isDeleting || isMaxReached}
                >
                  <IconRefresh />
                </ActionIcon>
              </Tooltip>
            </Group>
            <CloseButton className={classes.closeButton} onClick={onClose} />
          </Group>
          {isFetchingConvo && messages.length === 0 ? (
            <Loader size={18} my="sm" />
          ) : (
            <ScrollAreaAutosize
              mah={600}
              scrollbars="y"
              type="scroll"
              offsetScrollbars
              scrollHideDelay={150}
              viewportRef={scrollAreaRef}
              pb={20}
            >
              <Stack>
                {messages.map(({ id, role, content }, idx) => {
                  const isLast = messages.length - 1 === idx;

                  switch (role) {
                    case ConvoEntryProvenance.Assistant:
                      return (
                        <AssistantOutput
                          key={id}
                          isLast={isLast}
                          isUpdatingConversation={isUpdatingConversation}
                          isFetchingConvo={isFetchingConvo}
                          isCreatingConvoEntry={isCreatingConvoEntry}
                          content={content}
                          outputActions={outputActions}
                        />
                      );
                    case ConvoEntryProvenance.User:
                      return (
                        <Group key={id} justify="flex-end" mb="sm">
                          <Card
                            radius="md"
                            w="fit-content"
                            maw="85%"
                            px="md"
                            py="xs"
                          >
                            <Text>{content}</Text>
                          </Card>
                        </Group>
                      );
                  }
                })}
                <AssistantIcon mounted={isCreatingConvoEntry} isLoading />
              </Stack>
            </ScrollAreaAutosize>
          )}
          <form
            onSubmit={(e) => {
              handleSubmit(e);
              scrollToLatestMessage();
            }}
            ref={formRef}
          >
            <Stack gap="xs" bg="transparent">
              {suggestions && suggestions.length > 0 && (
                <Stack gap="xs">
                  <Text size="sm" c="dimmed">
                    Suggested prompts
                  </Text>
                  <ScrollArea pb="md" scrollbars="x">
                    <Group gap="md" wrap="nowrap">
                      {suggestions.map((sug) => (
                        <Pill
                          size="md"
                          key={sug}
                          onClick={() => setFieldValue("prompt", sug)}
                          className={classes.promptSuggestionLabel}
                        >
                          {sug}
                        </Pill>
                      ))}
                    </Group>
                  </ScrollArea>
                </Stack>
              )}
              <Tooltip
                maw={{
                  base: "90%",
                  sm: "40%",
                }}
                multiline
                label={
                  <>
                    You have reached the maximum number of free assistant
                    messages. To continue using this feature, subscribe to a
                    NOAN plan.
                  </>
                }
                disabled={!isMaxReached}
              >
                <Textarea
                  value={input}
                  disabled={!canSendPrompt}
                  size="md"
                  radius="xl"
                  autosize
                  autoFocus
                  maxRows={5}
                  placeholder="Ask AI anything..."
                  ref={textAreaRef}
                  rightSection={
                    <ActionIcon
                      type="submit"
                      variant="subtle"
                      disabled={!isValid() || !canSendPrompt}
                    >
                      <IconSend2 size={18} />
                    </ActionIcon>
                  }
                  {...getInputProps("prompt")}
                  onChange={(e) => {
                    setFieldValue("prompt", e.currentTarget.value);
                    handleInputChange(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      formRef.current?.requestSubmit();
                      e.preventDefault();
                    }
                  }}
                />
              </Tooltip>
            </Stack>
          </form>
        </Stack>
      </Card>
    </Box>
  );
}

type OutputAction = {
  tooltip: string;
  action: (output: string) => void;
  icon: JSX.Element;
};

export function ChatAssistant({
  type = "affix",
  initialMessage,
  source,
  sourceRef,
  generator = "openai",
  assistantId = "general-assistant",
  context,
  suggestions,
  isAssistantOpened,
  toggleIsAssistantOpened,
  outputActions,
}: {
  type?: "affix" | "flex";
  initialMessage: string;
  source: ConvoSource;
  sourceRef: string;
  generator?: AiGeneratorOption["value"];
  assistantId?: AssistantId;
  context?: string;
  suggestions?: string[];
  isAssistantOpened: boolean;
  toggleIsAssistantOpened: (open: boolean) => void;
  outputActions?: OutputAction[];
}) {
  const { createConvoEntry } = useCreateConvoEntry();
  const { data: convos, isLoading: isLoadingConvos } = useConvos({
    source,
  });
  const conversation = useMemo(() => {
    return convos?.convos.find(
      (conv) =>
        conv.configuration.sourceRef &&
        conv.configuration.sourceRef === sourceRef,
    );
  }, [convos?.convos, sourceRef]);
  const convoId = conversation?.id;

  function initiateConvo() {
    createConvoEntry(
      {
        convoId: null,
        convoSource: source,
        sourceRef,
        content: {
          entryType: ConvoEntryType.Initial,
          provenance: {
            source: ConvoEntryProvenance.Assistant,
            generator,
          },
          format: {
            type: "text",
          },
          plainText: initialMessage,
          assistantId,
        },
      },
      {
        onSuccess: () => {
          if (!isAssistantOpened) toggleIsAssistantOpened(true);
        },
      },
    );
  }

  function onClickChatButton() {
    if (convoId) {
      toggleIsAssistantOpened(true);
    } else {
      initiateConvo();
    }
  }

  if (isLoadingConvos) {
    return null;
  }

  const showConvo = isAssistantOpened && convoId;

  const chatElement = (
    <>
      {showConvo ? (
        <ChatConversation
          type={type}
          convoId={convoId}
          assistantId={assistantId}
          generator={generator}
          context={context}
          suggestions={suggestions}
          onReset={initiateConvo}
          onClose={() => toggleIsAssistantOpened(false)}
          outputActions={outputActions}
        />
      ) : (
        <ChatButton assistantId={assistantId} onClick={onClickChatButton} />
      )}
    </>
  );

  switch (type) {
    case "affix":
      return (
        <Affix
          withinPortal
          bottom={20}
          right={20}
          zIndex={ZedIndex.ChatButton}
          className={clsx(
            classes.chatAssistant,
            showConvo && classes.chatAssistantOpen,
          )}
        >
          {chatElement}
        </Affix>
      );

    // This allows to place the chat wherever we want, instead of being always in an absolute position (using Affix).
    case "flex":
    default:
      return showConvo ? (
        chatElement
      ) : (
        <Affix
          withinPortal
          zIndex={ZedIndex.ChatButton}
          className={clsx(classes.chatAssistant)}
        >
          {chatElement}
        </Affix>
      );
  }
}
