import classes from "./StackIcons.module.css";

export function FoundationsIcon() {
  return (
    <svg
      width="206"
      height="228"
      viewBox="0 0 206 228"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <g clipPath="url(#clip0_1444_22259)">
        <path
          d="M192.43 48.3302L115.06 3.26021C107.66 -1.04979 98.51 -1.08979 91.07 3.16021L12.09 48.2902C4.62 52.5702 0 60.5202 0 69.1302V159.16C0 167.81 4.65 175.78 12.17 180.04L90.95 224.65C98.46 228.9 107.68 228.8 115.09 224.37L193.4 177.58C200.71 173.21 205.16 165.29 205.09 156.78L204.35 68.8602C204.28 60.3902 199.75 52.5902 192.43 48.3202V48.3302ZM101.58 211.18L18.22 163.47C17.29 162.94 16.71 161.94 16.71 160.87V66.6302C16.71 65.5502 17.29 64.5602 18.22 64.0302L101.77 16.1202C102.71 15.5802 103.86 15.5902 104.79 16.1402L186.15 64.4802C187.05 65.0202 187.61 65.9902 187.62 67.0402L188.32 159.33C188.32 160.39 187.77 161.38 186.86 161.93L104.6 211.15C103.67 211.71 102.51 211.72 101.57 211.18H101.58Z"
          fill="currentColor"
        />
        <path
          d="M33.87 95.27L49.17 106.26L102.76 75.44L154.03 104.69L171.11 95.89L107.06 59.69C103.34 57.57 98.77 57.59 95.07 59.74L33.87 95.27Z"
          fill="currentColor"
        />
        <path
          d="M151.4 125.5L170.95 114.78L171.11 95.89L154.64 104.09L101.39 134.62L33.87 95.27L34.14 116.62L49.39 125.3L102.21 154.11L151.4 125.5Z"
          fill="currentColor"
        />
        <path
          d="M171.28 145.57L170.98 133.91L102.63 173.12L33.95 134.88L34.04 147.68C34.07 152 36.42 155.97 40.2 158.08L97.06 189.28C100.73 191.32 104.71 191.88 108.36 189.79L165.24 156.26C169.06 154.07 171.38 149.97 171.28 145.56V145.57Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1444_22259">
          <rect width="205.09" height="227.78" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
