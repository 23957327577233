import { useAuthContext } from "../../auth/useAuthContext";
import { trpc } from "../../utils/trpc";

export function useAvailablePromoCodes() {
  const { identity } = useAuthContext();
  const { data } = trpc.account.getPromoCodes.useQuery(undefined, {
    enabled: !!identity != null,
  });

  return {
    codes: data ?? [],
  };
}
