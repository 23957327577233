import {
  CheckIcon,
  Combobox,
  Flex,
  FloatingPosition,
  Stack,
  Text,
  ThemeIcon,
  useCombobox,
  type ComboboxStore,
} from "@mantine/core";
import { aiGenerators, type AiGeneratorOption } from "../../useGenerator";
import classes from "./GeneratorSwitcher.module.css";
import { generatorFromId } from "./generatorFromId";

export interface GeneratorSwitcherProps {
  selectedGenerator?: AiGeneratorOption;
  onSelectGenerator: (selection: AiGeneratorOption) => void;
  options?: AiGeneratorOption[];
  disabled?: boolean;
  optionsPosition?: FloatingPosition;
}

export function GeneratorSwitcher({
  selectedGenerator,
  onSelectGenerator,
  options = aiGenerators,
  children,
  disabled,
  optionsPosition,
}: GeneratorSwitcherProps & {
  children: (args: {
    combobox: ComboboxStore;
    disabled: boolean;
    selectedGenerator?: AiGeneratorOption;
  }) => JSX.Element;
}) {
  const combobox = useCombobox();

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(value) => {
        combobox.closeDropdown();
        onSelectGenerator(generatorFromId(value));
      }}
      withinPortal
      position={optionsPosition ?? "bottom-end"}
      width={300}
      shadow="md"
      disabled={disabled}
    >
      <Combobox.Target>
        {children({ selectedGenerator, combobox, disabled: disabled ?? false })}
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          {options.map((gen) => (
            <Combobox.Option key={gen.value} value={gen.value} p="xs">
              <Flex direction="row" gap="sm">
                <ThemeIcon size="md" variant="light" color="gray">
                  {gen.icon}
                </ThemeIcon>
                <Stack gap={5}>
                  <Text fw={600} size="sm">
                    {gen.label}
                  </Text>
                  <Text size="xs">{gen.description}</Text>
                </Stack>
                {selectedGenerator?.value === gen.value && (
                  <ThemeIcon
                    size="xs"
                    color="text"
                    variant="transparent"
                    className={classes.optionCheckmark}
                  >
                    <CheckIcon size={12} />
                  </ThemeIcon>
                )}
              </Flex>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
