export enum AccountSection {
  Profile = "profile",
  Referral = "referral",
  Billing = "billing",
  Benefits = "benefits",
  Organization = "organization",
}

export enum AccountSubSection {
  General = "general",
  Team = "team",
  Blocks = "blocks",
}

type NavigationProps = {
  title: string;
  description: string;
  path: string;
};

export const accountPageSections: Record<
  AccountSection,
  NavigationProps & {
    subSections?: {
      [subSection: string]: Pick<NavigationProps, "title" | "path">;
    };
  }
> = {
  profile: {
    title: "Profile",
    description: "Manage your profile details",
    path: `/account/${AccountSection.Profile}`,
  },
  organization: {
    title: "Your Organization",
    description: "Manage your organization's settings",
    path: `/account/${AccountSection.Organization}`,
    subSections: {
      general: {
        title: "General",
        path: `/account/${AccountSection.Organization}/general`,
      },

      team: {
        title: "Team",
        path: `/account/${AccountSection.Organization}/team`,
      },
      blocks: {
        title: "Blocks",
        path: `/account/${AccountSection.Organization}/blocks`,
      },
    },
  },
  benefits: {
    title: "Benefits",
    description: "Access your NOAN member benefits",
    path: `/account/${AccountSection.Benefits}`,
  },
  referral: {
    title: "Referral Program",
    description: "Join the NOAN referral program",
    path: `/account/${AccountSection.Referral}`,
  },
  billing: {
    title: "Billing Details",
    description: "Manage your NOAN subscription",
    path: `/account/${AccountSection.Billing}`,
  },
} as const;
