import { trpc } from "../../utils/trpc";

export function useCreateConvoEntry() {
  const utils = trpc.useUtils();
  const mutation = trpc.convos.createEntry.useMutation({
    onSettled: (data) => {
      utils.convos.getConvo.invalidate(
        data?.convoId ? { convoId: data.convoId } : undefined,
      );
      utils.convos.getConvos.invalidate();
    },
  });

  return {
    ...mutation,
    createConvoEntry: mutation.mutate,
  };
}
