import {
  GeneratorUntrustedInput,
  MessageRole,
} from "api/src/models/GeneratorInput";
import { useAuthContext } from "../auth/useAuthContext";
import { useState } from "react";
import { useChat } from "ai/react";
import { apiBaseUrl } from "../config";
import { generators } from "../useGenerator";
import { trpc } from "./trpc";

export function useConversation({
  generate: { role, tone, generator, input, thread, skipSaving, factory },
  initialMessages,
  onStartGenerate,
  onEndGenerate,
}: {
  generate: GeneratorUntrustedInput;
  initialMessages?: {
    id: string;
    role: MessageRole;
    content: string;
  }[];
  onStartGenerate?: () => void;
  onEndGenerate?: (generatorResultId: string) => void;
}) {
  const { jwtBearerToken } = useAuthContext();
  const [resultId, setResultId] = useState<string>(() => crypto.randomUUID());

  const chat = useChat({
    id: thread?.convoId,
    api: `${apiBaseUrl}/generate`,
    headers: {
      authorization: jwtBearerToken!,
    },
    streamMode: "text",
    keepLastMessageOnError: true,
    initialMessages,
    body: {
      input,
      role,
      thread,
      tone,
      generator:
        generator && generators.includes(generator) ? generator : "openai",
      skipSaving,
      factory,
    } as GeneratorUntrustedInput,
    onResponse(res) {
      if (!res.ok) {
        console.error("generator query error", res.statusText, res.body);

        throw new Error(`Error starting generator ${res.statusText}`);
      }

      const resultId = res.headers.get("X-Noan-Generator-Result-Id");
      if (resultId) {
        setResultId(resultId);
      }
    },
    onFinish(_msg) {
      onEndGenerate?.(resultId);
    },
  });

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    if (!jwtBearerToken) {
      throw new Error(
        `invariant: jwt bearer token is nullish when starting generator query`,
      );
    }

    onStartGenerate?.();
    chat.handleSubmit(e);
  }

  return {
    ...chat,
    handleSubmit: onSubmit,
  };
}

export function useDeleteConvo({ onSettled }: { onSettled?: () => void }) {
  const utils = trpc.useUtils();
  const mutation = trpc.convos.delete.useMutation({
    onSettled(data) {
      const convoId = data;

      if (convoId) {
        utils.convos.getConvo.invalidate({ convoId });
      }

      utils.convos.getConvos.invalidate();
      utils.convos.getActivitySummary.invalidate();

      onSettled?.();
    },
  });

  return {
    ...mutation,
    deleteConvo: mutation.mutate,
  };
}
