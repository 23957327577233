import { useState } from "react";

export function useToggle(defaultValue: boolean = false) {
  const [toggleValue, setToggleValue] = useState(defaultValue);

  function toggle(nextValue?: boolean) {
    setToggleValue(typeof nextValue === "undefined" ? !toggleValue : nextValue);
  }

  return [toggleValue, toggle] as const;
}
