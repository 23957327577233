import {
  Container,
  Group,
  Spoiler,
  Stack,
  Text,
  Title,
  type MantineSize,
} from "@mantine/core";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { BackButton } from "./BackButton";

interface PageDetails {
  title?: string;
  titleLeftSection?: JSX.Element;
  titleRightSection?: JSX.Element;
  description?: string;
}

export function PageMeta({ title, description }: PageDetails) {
  return (
    <Helmet>
      <title>NOAN - {title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:url" content={document.location.toString()} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
}

export function Page({
  children,
  setPageMeta = true,
  fullWidth = true,
  size = "xl",
  title,
  titleLeftSection,
  titleRightSection,
  description,
  documentTitle,
  pageActions,
  backButton,
}: React.PropsWithChildren<
  PageDetails & {
    setPageMeta?: boolean;

    /** If set, is used as the document's title instead of the title property */
    documentTitle?: string;

    fullWidth?: boolean;
    size?: MantineSize;
    pageActions?: JSX.Element[];

    backButton?: {
      label: string;
      to?: string;
    };
  }
>) {
  return (
    <Container
      mt="lg"
      size={size}
      p={fullWidth ? 0 : undefined}
      maw={fullWidth ? "100%" : undefined}
    >
      {setPageMeta && (
        <PageMeta title={documentTitle ?? title} description={description} />
      )}

      {backButton && <BackButton label={backButton.label} to={backButton.to} />}

      {(title || description || pageActions) && (
        <Group gap="sm">
          {(title || description) && (
            <Stack gap={0} py="lg" flex={1}>
              {title && (
                <Group>
                  {titleLeftSection}
                  <Title order={1} size={50} fw="bold">
                    {title}
                  </Title>
                  {titleRightSection}
                </Group>
              )}
              {description && (
                <Spoiler
                  hideLabel="Show less"
                  showLabel="Learn more"
                  styles={{
                    control: {
                      marginTop: 4,
                      fontSize: 12,
                    },
                  }}
                  maxHeight={50}
                >
                  <Text>{description}</Text>
                </Spoiler>
              )}
            </Stack>
          )}

          {pageActions && pageActions.length > 0 && (
            <Group gap="xs">
              {pageActions.map((p, i) => (
                <Fragment key={i}>{p}</Fragment>
              ))}
            </Group>
          )}
        </Group>
      )}

      {children}
    </Container>
  );
}
