import { Text } from "@mantine/core";
import { PerkId } from "api/src/models/Coupon";

export interface BlockPerk {
  perkId: PerkId;
  title: string;
  description: string | JSX.Element;
  conditions: string | JSX.Element;
  imageUrl: string;
  uniqueCode?: string;
  redeemUrl?: string;
}

export function blockPerkById(perkId: PerkId): BlockPerk | null {
  switch (perkId) {
    case PerkId.Squarespace20: {
      return {
        perkId: PerkId.Squarespace20,
        title: "Squarespace",
        imageUrl: "/sqsp/perk-01.jpg",
        uniqueCode: "NOAN20",
        redeemUrl: "https://squarespace.syuh.net/jrv140",
        description:
          "Take 20% off the first year of an annual website plan by entering the promo code NOAN20 during checkout.",
        conditions: (
          <Text size="xs">
            Discount valid for your first purchase only. Offer expires 11:59 PM
            EST on 12/31/24. Use of Squarespace is subject to the{" "}
            <a
              href="https://www.squarespace.com/terms-of-service"
              target="_blank"
            >
              Squarespace Terms of Service.
            </a>
          </Text>
        ),
      };
    }
    case PerkId.Squarespace: {
      return {
        perkId: PerkId.Squarespace,
        title: "Squarespace",
        imageUrl: "/sqsp/perk-02.jpg",
        redeemUrl: "https://squarespace.syuh.net/NkLnRb",
        description: "Get a free domain for your first year.",
        conditions: (
          <>
            <Text size="xs">
              Offer only applies to first payment of a domain, does not apply to
              future recurring payments, and may not be combined with any other
              offer codes or discounts.
            </Text>

            <Text size="xs" mt="sm">
              Offer expires 11:59 EST on 12/31/24. Use of Squarespace is subject
              to the{" "}
              <a
                href="https://www.squarespace.com/terms-of-service"
                target="_blank"
              >
                Squarespace Terms of Service.
              </a>
            </Text>
          </>
        ),
      };
    }
    default: {
      return null;
    }
  }
}
