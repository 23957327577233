import { Button, Group } from "@mantine/core";
import {
  RichTextEditor as BaseTextEditor,
  Link,
  type RichTextEditorProps,
} from "@mantine/tiptap";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Markdown } from "tiptap-markdown";
import {
  navigationHeaderHeights,
  topHeaderHeights,
} from "../../pages/layoutConfig";
import classes from "./RichTextEditor.module.css";

export function RichTextEditor({
  content,
  onSave,
  isSaving,
  editorProps,
}: {
  content?: string;
  onSave?: (markdownValue: string) => void;
  isSaving?: boolean;
  editorProps?: Omit<Partial<RichTextEditorProps>, "editor" | "classNames">;
}) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Markdown,
    ],
    content,
  });

  return (
    <BaseTextEditor
      editor={editor}
      classNames={{
        root: classes.editorRoot,
      }}
      {...editorProps}
    >
      <BaseTextEditor.Toolbar
        sticky
        classNames={{
          toolbar: classes.toolbar,
        }}
        stickyOffset={
          10 + topHeaderHeights.desktop + navigationHeaderHeights.default
        }
      >
        <Group
          w="100%"
          gap={"xs"}
          justify="space-evenly"
          wrap="nowrap"
          preventGrowOverflow
        >
          <BaseTextEditor.ControlsGroup>
            <BaseTextEditor.Bold />
            <BaseTextEditor.Italic />
            <BaseTextEditor.Underline />
            <BaseTextEditor.ClearFormatting />
          </BaseTextEditor.ControlsGroup>

          <BaseTextEditor.ControlsGroup>
            <BaseTextEditor.H1 />
            <BaseTextEditor.H2 />
            <BaseTextEditor.H3 />
            <BaseTextEditor.H4 />
          </BaseTextEditor.ControlsGroup>

          <BaseTextEditor.ControlsGroup>
            <BaseTextEditor.Blockquote />
            <BaseTextEditor.BulletList />
            <BaseTextEditor.OrderedList />
          </BaseTextEditor.ControlsGroup>

          <BaseTextEditor.ControlsGroup>
            <BaseTextEditor.Link initialExternal />
            <BaseTextEditor.Unlink />
          </BaseTextEditor.ControlsGroup>

          <BaseTextEditor.ControlsGroup flex={1}>
            <BaseTextEditor.Undo />
            <BaseTextEditor.Redo />
          </BaseTextEditor.ControlsGroup>

          {onSave && (
            <Button
              size="xs"
              color="black"
              type="submit"
              loading={isSaving}
              onClick={() => {
                if (!editor) {
                  return;
                }

                const markdown =
                  editor.storage.markdown.getMarkdown() as string;

                onSave(markdown);
              }}
            >
              Save Changes
            </Button>
          )}
        </Group>
      </BaseTextEditor.Toolbar>

      <BaseTextEditor.Content pb={"sm"} />
    </BaseTextEditor>
  );
}
