import {
  BackgroundImage,
  Badge,
  Box,
  Button,
  Card,
  Drawer,
  Flex,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconBug, IconCheck, IconGift, IconTag } from "@tabler/icons-react";
import { useCallback } from "react";
import { useClaimCoupon, useOrganization } from "../../utils/useOrganization";
import { TextCopier } from "../TextCopier";
import { Can } from "../auth/Can";
import type { BlockPerk } from "./perks";
import { useIsPreviewMode } from "../../utils/useAccount";
import { RestrictedFeature } from "../RestrictedFeature";

function PerkCode({
  code,
  hideCode = false,
}: {
  code?: string;
  hideCode?: boolean;
}) {
  if (!code) return null;

  return (
    <Card flex={1} m={0} ta="center" p="sm">
      <Group gap="xs" justify="space-between" wrap="nowrap">
        <Group gap="xs" ta="center" flex={1} justify="center">
          <Flex visibleFrom="xs">
            <IconTag size={16} />
          </Flex>
          <Text size="md">
            <b>{hideCode ? "CODE***" : code}</b>
          </Text>
        </Group>
        <TextCopier content={code} />
      </Group>
    </Card>
  );
}

export function PerkDrawer({
  opened = false,
  onClose,
  perks,
}: {
  opened?: boolean;
  perks: BlockPerk[];
  onClose: () => void;
}) {
  const { organization } = useOrganization();
  const coupons = organization?.coupons;
  const isPreviewMode = useIsPreviewMode();

  const { mutateAsync: redeemCoupon, isLoading: isRedeemingCoupon } =
    useClaimCoupon();

  const claimedPerkCode = useCallback(
    (perkId: string) => {
      return coupons?.find((c) => c.productId === perkId)?.code;
    },
    [coupons],
  );

  const onRedeem = useCallback(
    async (perk: BlockPerk) => {
      const hasCode = claimedPerkCode(perk.perkId) || perk.uniqueCode;

      if (perk.redeemUrl && hasCode) {
        window.open(perk.redeemUrl, "_blank");
      } else {
        await redeemCoupon(
          { productId: perk.perkId },
          {
            onError() {
              notifications.show({
                color: "red",
                icon: <IconBug size={18} />,
                message:
                  "There was an error retrieving this perk code. Please contact customer support.",
              });
            },
          },
        );
      }
    },
    [redeemCoupon, claimedPerkCode],
  );

  return (
    <Drawer
      position="right"
      size="md"
      opened={opened}
      title={
        <Stack>
          <Group gap="xs">
            <Title order={4}>NOAN Perks</Title>
            <Text size="sm" c="gray.6">
              Access exclusive deals and partnerships to help you with your
              strategy.
            </Text>
          </Group>
        </Stack>
      }
      withCloseButton
      withinPortal
      onClose={onClose}
    >
      <RestrictedFeature>
        {({ restrictedClassName }) => (
          <Stack>
            {perks.map((perk) => {
              const hasNoCode =
                !perk.uniqueCode && !claimedPerkCode(perk.perkId);

              return (
                <Card key={perk.perkId} p="xs">
                  <BackgroundImage
                    src={perk.imageUrl}
                    w="100%"
                    h={200}
                    radius="md"
                  />
                  <Box mt="md" className={restrictedClassName}>
                    <Group>
                      <Title order={3}>{perk.title}</Title>
                      {claimedPerkCode(perk.perkId) ? (
                        <Badge
                          variant="light"
                          color="green.5"
                          rightSection={<IconCheck size={16} />}
                        >
                          claimed
                        </Badge>
                      ) : null}
                    </Group>
                    <Box size="md">{perk.description}</Box>
                    <Group mt="sm">
                      {perk.uniqueCode ? (
                        <PerkCode
                          code={perk.uniqueCode}
                          hideCode={isPreviewMode}
                        />
                      ) : null}
                      {claimedPerkCode(perk.perkId) ? (
                        <PerkCode
                          code={claimedPerkCode(perk.perkId)}
                          hideCode={isPreviewMode}
                        />
                      ) : null}

                      <Can execute="coupon.update">
                        <Button
                          size="lg"
                          fullWidth={hasNoCode}
                          onClick={() => onRedeem(perk)}
                          leftSection={
                            <ThemeIcon size="sm">
                              <IconGift />
                            </ThemeIcon>
                          }
                          loading={isRedeemingCoupon}
                          disabled={isRedeemingCoupon}
                        >
                          {claimedPerkCode(perk.perkId) ? "Use code" : "Redeem"}
                        </Button>
                      </Can>
                    </Group>
                  </Box>

                  <Card.Section p="lg" withBorder mt="md">
                    <Stack>
                      <Box c="base.7" flex={1}>
                        {perk.conditions}
                      </Box>
                    </Stack>
                  </Card.Section>
                </Card>
              );
            })}
          </Stack>
        )}
      </RestrictedFeature>
    </Drawer>
  );
}
