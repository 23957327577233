import classes from "./StackIcons.module.css";

export function CreatorIcon() {
  return (
    <svg
      width="206"
      height="230"
      viewBox="0 0 206 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.7627 95.5648C77.7627 86.8176 87.2318 81.3506 94.8072 85.724L111.852 95.5639V95.5651L128.896 105.405L128.897 105.405V105.406C136.472 109.78 136.471 120.714 128.896 125.087L94.8074 144.769C87.232 149.142 77.7627 143.675 77.7627 134.928V95.5648ZM111.852 115.245L94.8076 105.405V125.086L111.852 115.245Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.1514 86.2755L35.1521 144.217C35.1522 150.648 38.5829 156.59 44.152 159.805L94.3302 188.776C99.8994 191.992 106.761 191.992 112.33 188.776L162.509 159.805C168.078 156.59 171.509 150.648 171.509 144.217L171.509 86.2755C171.509 79.8448 168.078 73.9026 162.509 70.6872L112.33 41.715C106.76 38.4994 99.8984 38.4994 94.329 41.7151L44.151 70.687C38.5819 73.9025 35.1513 79.8448 35.1514 86.2755ZM52.197 144.769L52.1966 85.724L103.33 56.2008L154.464 85.7241L154.464 144.768L103.33 174.291L52.197 144.769Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.488 154.65L205.489 70.0998C205.489 61.5254 200.915 53.6023 193.489 49.3151L115.221 4.1268C107.795 -0.16028 98.6467 -0.160269 91.2211 4.12684L17.9969 46.4017C7.44968 52.4911 0.952169 63.7449 0.952148 75.9238L0.952196 160.475C0.952217 169.049 5.52658 176.972 12.9522 181.259L91.2202 226.448C98.6458 230.735 107.795 230.735 115.22 226.448L188.443 184.172C198.991 178.083 205.488 166.829 205.488 154.65ZM99.2209 19.1895L21.9969 63.7737C19.5217 65.2028 17.9968 67.8438 17.9968 70.702L17.9969 159.872C17.9969 162.73 19.5217 165.371 21.9969 166.8L99.2204 211.385C101.696 212.814 104.745 212.814 107.22 211.385L184.443 166.8C186.919 165.371 188.443 162.73 188.443 159.872L188.444 70.7028C188.444 67.8447 186.92 65.2036 184.444 63.7746L107.221 19.1896C104.746 17.7605 101.696 17.7605 99.2209 19.1895Z"
        fill="currentColor"
      />
    </svg>
  );
}
