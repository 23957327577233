export const PROMPT_MAX_LENGTH = 10000;

export enum ToneHint {
  Shorter = "Use fewer words",
  Simpler = "Rephrase in simpler terms",
  Reset = "Reset",
}

export const customInstructions = [
  "Translate to Spanish",
  "Rephrase in a more casual tone",
  "Summarize in a single paragraph",
  "Rephrase in a friendlier tone",
];
