export function AssistantIcon() {
  return (
    <svg
      width="68"
      height="72"
      viewBox="0 0 68 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.2496 3.17915L5.50259 14.5884C2.10152 16.5534 0.00634145 20.1829 0.00558911 24.1108L0.00121636 46.9409C0.000463668 50.8707 2.0962 54.5024 5.49903 56.468L25.2494 67.8769C28.6539 69.8436 32.8492 69.8436 36.2537 67.8769L56.0057 56.4672C59.4079 54.5019 61.5035 50.8712 61.5035 46.9421L61.5036 24.113C61.5036 20.1839 59.408 16.5532 56.0058 14.5879L36.2548 3.17868C32.85 1.21187 28.6543 1.21205 25.2496 3.17915Z"
        fill="#8CE6DF"
      />
      <path
        d="M11.3191 18.2368L25.5012 10.0428C28.7511 8.16506 32.7561 8.16489 36.0062 10.0423L50.1911 18.2363C53.4386 20.1123 55.439 23.5779 55.439 27.3284L55.439 43.727C55.439 47.4775 53.4386 50.9432 50.191 52.8191L36.0051 61.0136C32.7554 62.8908 28.7508 62.8908 25.501 61.0136L11.3167 52.8199C8.06857 50.9436 6.06809 47.477 6.06881 43.7259L6.07195 27.3264C6.07267 23.577 8.07261 20.1125 11.3191 18.2368Z"
        fill="#8CE6DF"
        stroke="white"
      />
      <path
        d="M15.8337 20.8445L25.5019 15.2586C28.7517 13.3809 32.7567 13.3807 36.0068 15.2581L45.6768 20.8441C48.9244 22.72 50.9248 26.1857 50.9248 29.9362L50.9247 41.1187C50.9247 44.8691 48.9243 48.3348 45.6768 50.2108L36.0059 55.7972C32.7561 57.6744 28.7515 57.6744 25.5018 55.7972L15.8324 50.2116C12.5842 48.3353 10.5837 44.8687 10.5844 41.1175L10.5866 29.9341C10.5873 26.1847 12.5872 22.7203 15.8337 20.8445Z"
        stroke="white"
      />
      <path
        d="M22.9876 27.8675L27.9979 24.9727C29.7002 23.9891 31.7981 23.989 33.5005 24.9724L38.5118 27.8673C40.2129 28.8499 41.2607 30.6653 41.2607 32.6298L41.2607 38.4252C41.2607 40.3897 40.2129 42.2051 38.5118 43.1877L33.5 46.0828C31.7978 47.0661 29.7001 47.0661 27.9978 46.0828L22.9869 43.1882C21.2855 42.2053 20.2376 40.3895 20.238 38.4246L20.2391 32.6287C20.2395 30.6648 21.287 28.85 22.9876 27.8675Z"
        stroke="white"
      />
      <path
        d="M18.0768 25.0279L28.0018 19.2935C29.7041 18.3099 31.802 18.3098 33.5044 19.2932L43.4314 25.0276C45.1325 26.0103 46.1803 27.8256 46.1803 29.7902L46.1803 41.2648C46.1803 43.2293 45.1325 45.0446 43.4314 46.0273L33.5038 51.7619C31.8016 52.7453 29.7039 52.7453 28.0017 51.7619L18.075 46.0277C16.3736 45.0449 15.3257 43.2291 15.3261 41.2642L15.3283 29.7891C15.3286 27.8251 16.3762 26.0104 18.0768 25.0279Z"
        stroke="white"
      />
      <circle
        cx="56.2529"
        cy="54.3057"
        r="10"
        fill="#FE8647"
        stroke="#D7F6F4"
        strokeWidth="2"
      />
      <path
        d="M57.2563 49.4929L52.162 56.1428H55.5259V59.4063L60.6166 52.7157H57.2563V49.4929Z"
        fill="#DBFAF6"
      />
    </svg>
  );
}
