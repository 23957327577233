import { compact } from "api/src/utils";
import { compactString } from "../../utils/misc";
import { isGenerativeModule, type Module } from "../../utils/usePillars";
import { useFactsContext } from "./FactsContext";
import { BlockRequirement } from "../../components/ConfigurableBlockCard/BlockRequirementsAlert";

export function useModuleRequirements(block: Module): BlockRequirement[] {
  const { facts, stacks } = useFactsContext();

  const reqs = isGenerativeModule(block)
    ? [
        /**
         * Ensure prereqs are unique, lower cased, and there's no stragglers
         * (e.g empty strings, whitespace)
         */
        ...new Set(
          compactString(
            (block.prerequisites ?? "")
              .split(",")
              .map((req) => req.trim().toLowerCase()),
          ),
        ),
      ]
    : [];

  const allBlocks = stacks.flatMap((p) => p.blocks);
  return compact(
    reqs
      .filter((req) => {
        const matchingFact = facts.find(
          (f) => f.blockPath.toLowerCase() === req,
        );

        /**
         * Ignore all unknown slugs, or all slugs for which we have matching
         * fact with content.
         */
        return matchingFact == null || !matchingFact.content;
      })
      .map((req) => {
        // Annoyingly, we have to search through all stacks in the shared state,
        // navigating down the tree as needed.
        const match = allBlocks.find(
          (m) => m.knowledgeslug?.toLowerCase() === req,
        );

        if (!match) {
          return null;
        }

        return {
          label: match.title,
          blockPath: match.knowledgeslug,
        };
      }),
  ) as BlockRequirement[];
}
