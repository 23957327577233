import classes from "./StackIcons.module.css";

export function BrandIcon() {
  return (
    <svg
      width="206"
      height="228"
      viewBox="0 0 206 228"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <g clipPath="url(#clip0_1444_22265)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.06 3.26021L192.43 48.3302V48.3202C199.75 52.5902 204.28 60.3902 204.35 68.8602L205.09 156.78C205.16 165.29 200.71 173.21 193.4 177.58L115.09 224.37C107.68 228.8 98.46 228.9 90.95 224.65L12.17 180.04C4.65 175.78 0 167.81 0 159.16V69.1302C0 60.5202 4.62 52.5702 12.09 48.2902L91.07 3.16021C98.51 -1.08979 107.66 -1.04979 115.06 3.26021ZM18.22 163.47L101.58 211.18H101.57C102.51 211.72 103.67 211.71 104.6 211.15L186.86 161.93C187.77 161.38 188.32 160.39 188.32 159.33L187.62 67.0402C187.61 65.9902 187.05 65.0202 186.15 64.4802L104.79 16.1402C103.86 15.5902 102.71 15.5802 101.77 16.1202L18.22 64.0302C17.29 64.5602 16.71 65.5502 16.71 66.6302V160.87C16.71 161.94 17.29 162.94 18.22 163.47ZM69.49 174.27L69.12 55.23H69.11C69.09 46.42 78.58 40.87 86.24 45.22L86.2406 45.4485L86.25 45.23V45.22L114.58 61.39C118.32 63.53 120.63 67.51 120.63 71.82L120.63 84.46L120.63 84.47L86.29 64.4205L86.3956 105.018L114.54 120.44C118.4 122.56 120.78 126.62 120.75 131.03L120.672 143.091L137.941 152.467L138.029 114.149L126.61 107.52C122.91 105.37 120.64 101.42 120.64 97.14V84.4658L155.28 104.62L155.274 105.634L155.51 162.5L155.488 162.488C155.109 170.955 146.178 176.489 138.72 172.25L126.37 165.23C122.57 163.07 120.25 159.02 120.3 154.66L120.436 143.28L86.4459 124.332L86.6 183.55L69.49 174.27ZM120.64 84.4658L120.63 84.46H120.64V84.4658Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1444_22265">
          <rect width="205.09" height="227.78" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
