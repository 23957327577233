import classes from "./StackIcons.module.css";

export function OperationsIcon() {
  return (
    <svg
      width="206"
      height="228"
      viewBox="0 0 206 228"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <g clipPath="url(#clip0_1444_22263)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.06 3.26021L192.43 48.3302V48.3202C199.75 52.5902 204.28 60.3902 204.35 68.8602L205.09 156.78C205.16 165.29 200.71 173.21 193.4 177.58L115.09 224.37C107.68 228.8 98.46 228.9 90.95 224.65L12.17 180.04C4.65 175.78 0 167.81 0 159.16V69.1302C0 60.5202 4.62 52.5702 12.09 48.2902L91.07 3.16021C98.51 -1.08979 107.66 -1.04979 115.06 3.26021ZM18.22 163.47L101.58 211.18H101.57C102.51 211.72 103.67 211.71 104.6 211.15L186.86 161.93C187.77 161.38 188.32 160.39 188.32 159.33L187.62 67.0402C187.61 65.9902 187.05 65.0202 186.15 64.4802L104.79 16.1402C103.86 15.5902 102.71 15.5802 101.77 16.1202L18.22 64.0302C17.29 64.5602 16.71 65.5502 16.71 66.6302V160.87C16.71 161.94 17.29 162.94 18.22 163.47ZM74.97 91.91L97.66 78.81H97.65C101.35 76.67 105.91 76.66 109.62 78.79L131.63 91.41C135.37 93.56 137.67 97.55 137.66 101.86L137.58 126.77C137.57 131.05 135.27 135.01 131.55 137.14L109.4 149.85C105.74 151.95 101.26 151.97 97.58 149.92L75.12 137.38C71.32 135.26 68.97 131.25 68.97 126.9V102.3C68.97 98.02 71.26 94.05 74.97 91.91ZM102.9 94.39L85.69 104.89H85.7V124.66L103.15 134.67L120.6 124.66L120.48 104.28L102.9 94.39ZM103.48 55.78L92.4 49.22C88.65 47 84 46.99 80.24 49.18L57.44 62.48C53.75 64.63 51.48 68.58 51.49 72.86V85.376L40.4599 92C36.8499 94.17 34.6399 98.07 34.6399 102.29V127.38C34.6399 131.69 36.9499 135.67 40.6999 137.81L51.5066 143.965L51.7 157.19C51.76 161.47 54.1 165.4 57.84 167.49L80.2 179.99C83.99 182.11 88.62 182.01 92.32 179.75L103.675 172.804L115.03 179.75C118.73 182.01 123.36 182.11 127.15 179.99L149.51 166.09C153.25 164 155.59 160.07 155.65 155.79L155.847 142.343L166.65 136.19C170.4 134.05 172.71 130.07 172.71 125.76V102.29C172.71 98.07 170.5 94.17 166.89 92L155.86 85.3761V72.86C155.87 68.58 153.6 64.63 149.91 62.48L127.11 49.18C123.35 46.99 118.7 47 114.95 49.22L103.87 55.78L155.749 85.4666L155.501 142.313L103.678 172.521L51.8479 143.703L51.6005 85.4668L103.48 55.78Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1444_22263">
          <rect width="205.09" height="227.78" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
