export function normalizeUrl(rawUrl: string): string | null {
  let normalizedUrl = rawUrl;

  if (
    !normalizedUrl.startsWith("http://") &&
    !normalizedUrl.startsWith("https://")
  ) {
    normalizedUrl = `https://${normalizedUrl}`;
  }

  /**
   * Sanity check: ensure this thing actually looks like a fq url,
   * and not just something that meets the url spec.
   */
  if (!normalizedUrl.includes(".")) {
    return null;
  }

  /**
   * Finally, ensure we have a valid URL, and use the url class to normalize
   * it to a consistent format.
   */
  try {
    const asUrl = new URL(normalizedUrl);
    return asUrl.toString();
  } catch (_err) {
    return null;
  }
}
