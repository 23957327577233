import {
  MantineSpacing,
  Overlay,
  Stack,
  Title,
  Button,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconLock } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useIsPreviewMode } from "../../utils/useAccount";
import { ZedIndex } from "../../utils/zedIndex";
import classes from "./RestrictedFeature.module.css";

interface LockerOverlayProps {
  title?: string;
  description?: string;
  minimize?: boolean;
  my?: MantineSpacing;
}

function LockerOverlay({
  title = "Locked Feature",
  description = "This feature is available to paid NOAN subscribers only",
  minimize = false,
  my = "auto",
}: LockerOverlayProps) {
  return (
    <Tooltip
      label={
        <Text c="white" size="sm" maw={500} ta="center">
          {description}
        </Text>
      }
      disabled={!minimize}
    >
      <Overlay
        zIndex={ZedIndex.Overlay}
        className={classes.overlay}
        radius="lg"
        color="#000"
        backgroundOpacity={0.65}
        h="fit-content"
        w="fit-content"
        px={minimize ? "sm" : "xl"}
        py={"xs"}
        my={my}
        mx={{ base: minimize ? "auto" : "sm", xs: "auto" }}
        blur={1}
      >
        <Stack align="center" justify="center" py="md" h="100%">
          <IconLock size={50} color="white" stroke={1} />
          {!minimize && (
            <>
              <Title order={3} c="white">
                {title}
              </Title>
              <Text c="white" size="sm" maw={500} ta="center">
                {description}
              </Text>
            </>
          )}
          <Button
            component={Link}
            to="/subscribe"
            tt="uppercase"
            variant="subtle"
            size={minimize ? "compact-xs" : "sm"}
          >
            Subscribe now
          </Button>
        </Stack>
      </Overlay>
    </Tooltip>
  );
}

interface ChildrenRenderProps {
  restrictedClassName?: string;
}

interface RestrictedFeatureProps extends LockerOverlayProps {
  isRestricted?: boolean;
  withOverlay?: boolean;
  children: React.ReactNode | ((obj: ChildrenRenderProps) => React.ReactNode);
}

export function RestrictedFeature({
  children,
  isRestricted = undefined,
  withOverlay = true,
  ...props
}: RestrictedFeatureProps) {
  const isPreviewMode = useIsPreviewMode();
  /**
   * If isRestricted prop is being passed, we consider it, otherwise we based the restriction on being under the preview mode
   */
  const isFeatureRestricted =
    typeof isRestricted !== "undefined" ? isRestricted : isPreviewMode;

  const restrictedClassName = isFeatureRestricted
    ? classes.nonInteractive
    : undefined;

  return (
    <>
      {isFeatureRestricted && withOverlay && <LockerOverlay {...props} />}
      {typeof children === "function"
        ? children({ restrictedClassName })
        : children}
    </>
  );
}
