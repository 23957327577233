import classes from "./StackIcons.module.css";

export function FarmIcon() {
  return (
    <svg
      width="205"
      height="230"
      viewBox="0 0 205 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.2412 55.6274L68.2412 75.3088L34.1511 94.9903L51.1967 104.831L51.1961 153.483C51.1961 159.914 54.6271 165.857 60.1967 169.072L93.3312 188.201C98.9004 191.416 105.762 191.416 111.331 188.201L144.464 169.072C150.034 165.856 153.465 159.914 153.465 153.483L153.465 104.831L170.509 94.9904L136.42 75.3089V55.6283L119.376 65.4686L102.33 55.6274L85.2848 65.467L85.2856 65.4683L68.2412 55.6274ZM136.419 94.9911L119.376 104.831L136.42 114.671L136.42 154.035L119.375 163.875L119.375 144.194L102.33 134.353L85.2857 144.194L85.2856 163.875L68.2415 154.035V114.672L85.2859 104.831L68.2415 94.9903L102.33 75.3088L136.419 94.9911ZM85.2856 163.875L102.33 173.715L119.375 163.875L102.33 154.035L85.2856 163.875Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.536 154.363L204.537 69.8125C204.537 61.2381 199.963 53.3151 192.537 49.0279L114.269 3.83954C106.843 -0.447542 97.6945 -0.447531 90.269 3.83957L17.0448 46.1144C6.49753 52.2038 2.01768e-05 63.4576 0 75.6365L4.77098e-05 160.188C6.84045e-05 168.762 4.57443 176.685 12 180.972L90.2681 226.16C97.6937 230.448 106.842 230.448 114.268 226.16L187.491 183.885C198.038 177.795 204.536 166.542 204.536 154.363ZM98.2688 18.9023L21.0448 63.4864C18.5695 64.9155 17.0447 67.5565 17.0447 70.4147L17.0448 159.585C17.0448 162.443 18.5695 165.084 21.0447 166.513L98.2683 211.098C100.743 212.527 103.793 212.527 106.268 211.098L183.491 166.513C185.966 165.084 187.491 162.443 187.491 159.585L187.492 70.4156C187.492 67.5574 185.967 64.9164 183.492 63.4873L106.269 18.9023C103.794 17.4733 100.744 17.4732 98.2688 18.9023Z"
        fill="currentColor"
      />
    </svg>
  );
}
