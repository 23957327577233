import { trpc } from "../utils/trpc";

/**
 * Thin wrappers around react-query/trpc mutations for Identity.
 */
export function useIdentityMutation() {
  const utils = trpc.useUtils();

  const {
    mutateAsync: saveIdentitiesAsync,
    isLoading: isLoadingSaveIdentities,
  } = trpc.identity.inviteUsersAndSaveIdentities.useMutation({
    onSettled() {
      utils.organization.getById.invalidate();
      utils.account.getBilling.invalidate();
    },
  });

  const { mutate: updateIdentity, isLoading: isLoadingUpdateIdentity } =
    trpc.identity.update.useMutation({
      onSettled() {
        utils.organization.getById.invalidate();
        utils.identity.invalidate();
      },
    });

  const { mutate: deleteIdentity, isLoading: isDeletingIdentity } =
    trpc.identity.delete.useMutation({
      onSettled() {
        utils.organization.getById.invalidate();
      },
    });

  return {
    saveIdentitiesAsync,
    isLoadingSaveIdentities,
    updateIdentity,
    isLoadingUpdateIdentity,
    deleteIdentity,
    isDeletingIdentity,
  };
}
