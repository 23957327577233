import classes from "./StackIcons.module.css";

export function SalesIcon() {
  return (
    <svg
      width="206"
      height="228"
      viewBox="0 0 206 228"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <g clipPath="url(#clip0_1444_22264)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M192.43 48.3302L115.06 3.26021C107.66 -1.04979 98.51 -1.08979 91.07 3.16021L12.09 48.2902C4.62 52.5702 0 60.5202 0 69.1302V159.16C0 167.81 4.65 175.78 12.17 180.04L90.95 224.65C98.46 228.9 107.68 228.8 115.09 224.37L193.4 177.58C200.71 173.21 205.16 165.29 205.09 156.78L204.35 68.8602C204.28 60.3902 199.75 52.5902 192.43 48.3202V48.3302ZM101.58 211.18L18.22 163.47C17.29 162.94 16.71 161.94 16.71 160.87V66.6302C16.71 65.5502 17.29 64.5602 18.22 64.0302L101.77 16.1202C102.71 15.5802 103.86 15.5902 104.79 16.1402L186.15 64.4802C187.05 65.0202 187.61 65.9902 187.62 67.0402L188.32 159.33C188.32 160.39 187.77 161.38 186.86 161.93L104.6 211.15C103.67 211.71 102.51 211.72 101.57 211.18H101.58ZM104.08 75.61L86.1401 65.39L69.4638 75.0078L52.2099 64.84L34.3899 75.21L51.5198 85.3567L34.7301 95.04C26.6401 99.7 26.7501 111.41 34.9101 115.93L85.7001 144.05L102.03 134.78C109.82 130.36 109.69 119.08 101.79 114.85L85.2901 124.7L51.3001 105.59L104.08 75.61ZM120.47 162.79L102.91 153.19V153.18L154.08 123.91L120.01 104.42L101.8 114.83L101.45 114.63C93.8399 110.22 93.8799 99.21 101.54 94.87L119.63 84.62L169.8 113.3C177.83 117.89 177.87 129.46 169.87 134.1L154.091 143.264L171.02 153.24L152.55 163.28L136.25 153.625L120.47 162.79Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1444_22264">
          <rect width="205.09" height="227.78" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
