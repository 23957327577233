import {
  Box,
  Button,
  Group,
  Modal,
  Radio,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  IconBasket,
  IconBeach,
  IconBuilding,
  IconBuildingFactory,
  IconBuildingFactory2,
  IconBuildingSkyscraper,
  IconChartInfographic,
  IconCheck,
  IconChecklist,
  IconChevronRight,
  IconCloudComputing,
  IconDots,
  IconHome,
  IconLink,
  IconMasksTheater,
  IconMedicalCross,
  IconNewSection,
  IconResize,
  IconRocket,
  IconSchool,
  IconTarget,
  IconTools,
  IconTruckDelivery,
  IconUser,
  IconUserCheck,
  IconUsers,
  IconUsersGroup,
} from "@tabler/icons-react";
import { Industry, TargetType, TeamSize } from "api/src/models/Organization";
import { assertUnreachable } from "api/src/utils";
import type React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import type { TablerIcon } from "../utils/icons";
import { normalizeUrl } from "../utils/normalizeUrl";
import { getFactContentText, useFacts } from "../utils/useFacts";
import { useOrganization } from "../utils/useOrganization";
import { useOrganizationMutation } from "../utils/useOrganizationMutation";
import { FullPageLoader } from "./FullPageLoader";
import classes from "./OnboardingWizard.module.css";

enum OnboardingStepIdentifier {
  NewOrExistingProject = "new-or-existing-project",
  Name = "name",
  Website = "website",
  TargetType = "target-type",
  TeamSize = "team-size",
  Industry = "industry",
}

interface OnboardingForm {
  industry?: Industry;
  teamSize?: TeamSize;
  targetType?: TargetType;
  name?: string;
  newCompany?: boolean;
  website?: string;
}

interface OnboardingStepConfig {
  id: OnboardingStepIdentifier;
  canContinue?: boolean;
  skip?: boolean;

  title: string;
  description: string;
  Icon: TablerIcon;
}

const industryIcon: Record<Industry, TablerIcon> = {
  [Industry.Education]: IconSchool,
  [Industry.FinanceAndBanking]: IconChartInfographic,
  [Industry.Healthcare]: IconMedicalCross,
  [Industry.HospitalityAndTravel]: IconBeach,
  [Industry.Logistics]: IconTruckDelivery,
  [Industry.Manufacturing]: IconBuildingFactory2,
  [Industry.MediaAndEntertainment]: IconMasksTheater,
  [Industry.RealEstate]: IconHome,
  [Industry.RetailAndECommerce]: IconBasket,
  [Industry.Tech]: IconCloudComputing,
  [Industry.ProfessionalServices]: IconTools,
  [Industry.Other]: IconDots,
};

const companyNamePlaceholders = [
  "BubbleWrap Innovations",
  "Giggle Factory",
  "WhimsyWorks",
  "QuirkyQuest",
  "Cheerful Choices",
  "JollyJunction",
  "Playful Projects",
  "HappyHarmony",
  "ZanyZone",
  "ChuckleMakers",
  "JoyRide Creations",
  "FunkyFutures",
  "WackyWonders",
  "GleefulGizmos",
  "LivelyLab",
  "RadiantRockets",
  "SnazzySolutions",
  "PeppyPulse",
  "SparkleSprouts",
  "VividVentures",
];

function OnboardingStep({
  title,
  description,
  Icon,
  children,
  onGoBack,
  onAdvance,
  canAdvance = true,
  isFinalStep = false,
  onClose,
}: React.PropsWithChildren<{
  title: string;
  description: string;
  Icon: TablerIcon;
  onGoBack?: () => void;
  onAdvance?: () => void;
  canAdvance?: boolean;
  isFinalStep?: boolean;
  onClose?: () => void;
}>) {
  return (
    <>
      <Group gap="sm" wrap="nowrap">
        <ThemeIcon size="xl" variant="light" visibleFrom="md">
          <Icon />
        </ThemeIcon>
        <Stack gap={0}>
          <Title order={2}>{title}</Title>

          <Text size="sm" c="dimmed">
            {description}
          </Text>
        </Stack>
      </Group>
      <Box my="xl">{children}</Box>
      <Group wrap="nowrap" justify="flex-end">
        {onClose && (
          <Box flex={1}>
            <Button size="xs" variant="subtle" color="dark" onClick={onClose}>
              I'll do this later
            </Button>
          </Box>
        )}

        {onGoBack && (
          <Button onClick={onGoBack} variant="subtle" color="dark">
            Go back
          </Button>
        )}

        <Button
          disabled={!canAdvance}
          onClick={onAdvance}
          rightSection={
            <ThemeIcon variant="transparent" color="white" size="sm">
              {isFinalStep ? <IconCheck /> : <IconChevronRight />}
            </ThemeIcon>
          }
        >
          {isFinalStep ? "Finish" : "Continue"}
        </Button>
      </Group>
    </>
  );
}

function OnboardingOption({
  title,
  description,
  Icon,
  value,
  checked = false,
}: {
  title: string;
  description?: string;
  Icon: TablerIcon;
  value: string;
  checked?: boolean;
}) {
  return (
    <Radio.Card
      p="xs"
      value={value}
      className={[
        classes.onboardingRadioCard,
        checked ? classes.onboardingRadioCardChecked : "",
      ].join(" ")}
    >
      <Group wrap="nowrap" align="center">
        <ThemeIcon size="lg" variant="transparent" color="teal">
          <Icon />
        </ThemeIcon>
        <Stack gap={0}>
          <Text span size={description ? "md" : "lg"}>
            {title}
          </Text>
          {description && (
            <Text size="sm" c="dimmed">
              {description}
            </Text>
          )}
        </Stack>
      </Group>
    </Radio.Card>
  );
}

function OnboardingWizard({ onComplete }: { onComplete: () => void }) {
  const [search, setSearch] = useSearchParams();
  const { organization: org } = useOrganization();
  const [facts] = useFacts();
  const { updateOrganizationAsync } = useOrganizationMutation();

  const form = useForm<OnboardingForm>({
    name: "OnboardingWizard",
    validateInputOnBlur: true,
    initialValues: {
      name: "",
    },
    validate: {
      website: (v) => {
        if (v && !normalizeUrl(v)) {
          return "Must be a valid URL";
        }
      },
    },
    transformValues(v) {
      return {
        ...v,

        // Ensure the website is a full URL:
        website: v.website ? normalizeUrl(v.website) ?? undefined : undefined,
      };
    },
  });

  useEffect(() => {
    const existingNameFact = facts?.find((k) => k.blockPath === "company-name");
    const existingName = existingNameFact
      ? getFactContentText(existingNameFact)
      : org?.name;

    if (existingName && !form.isTouched("name")) {
      form.setFieldValue("name", existingName);
    }
  }, [org, facts, form]);

  const goToStep = useCallback(
    (stepId: OnboardingStepIdentifier) => {
      setSearch((s) => {
        s.set("onboardingStep", stepId);
        return s;
      });
    },
    [setSearch],
  );

  const clearStepQuery = useCallback(() => {
    setSearch((s) => {
      s.delete("onboardingStep");
      return s;
    });
  }, [setSearch]);

  const onboardingSteps = useMemo<OnboardingStepConfig[]>(
    () => [
      {
        id: OnboardingStepIdentifier.NewOrExistingProject,
        title: "Are you building a new project?",
        description:
          "We'll use this information to tailor your NOAN experience.",
        Icon: IconRocket,
        continueTo: OnboardingStepIdentifier.Name,
        skip: !!org?.name,
      },
      {
        id: OnboardingStepIdentifier.Name,
        title: "What's the name of your project?",
        description:
          "We'll use this information to tailor your NOAN experience.",
        Icon: IconRocket,
        canContinue: !!form.values.name,
        skip: !!org?.name,
      },
      {
        id: OnboardingStepIdentifier.Website,
        title: "What's your company's website?",
        description:
          "We'll use this information to automatically fill out some of your strategy for you.",
        Icon: IconLink,
        canContinue: true,
        skip:
          org?.settings.attributes?.website === null ||
          !!org?.settings.attributes?.website,
      },
      {
        id: OnboardingStepIdentifier.TeamSize,
        title: "How large is your team?",
        description: "We'll use this information to priotize NOAN blocks.",
        Icon: IconResize,
        canContinue: !!form.values.teamSize,
        skip: org?.settings.attributes?.teamSize != null,
      },
      {
        id: OnboardingStepIdentifier.Industry,
        title: "What Industry is your project in?",
        description:
          "Choose the option that best fits your company or project.",
        Icon: IconBuildingFactory,
        canContinue: !!form.values.industry,
        skip: !!org?.settings.attributes?.industry,
      },
      {
        id: OnboardingStepIdentifier.TargetType,
        title: "What's your primary type of customer?",
        description: "If you're not sure yet, choose your best guess.",
        Icon: IconTarget,
        canContinue: !!form.values.targetType,
        skip: !!org?.settings.attributes?.targetType,
      },
    ],
    [form, org],
  );

  const isChecked = useCallback<
    <T extends keyof OnboardingForm>(
      fieldName: T,
      value: OnboardingForm[T],
    ) => boolean
  >(
    (fieldName, value) => {
      return form.values[fieldName] === value;
    },
    [form],
  );

  useEffect(() => {
    if (org) {
      if (org.settings.attributes?.industry && !form.isTouched("industry")) {
        form.setFieldValue("industry", org.settings.attributes.industry);
      }

      if (
        org.settings.attributes?.targetType &&
        !form.isTouched("targetType")
      ) {
        form.setFieldValue("targetType", org.settings.attributes.targetType);
      }

      if (org.settings.attributes?.teamSize && !form.isTouched("teamSize")) {
        form.setFieldValue("teamSize", org.settings.attributes.teamSize);
      }
    }
  }, [org, form]);

  const renderStepContent = useCallback(
    (stepIdentifier: OnboardingStepIdentifier) => {
      switch (stepIdentifier) {
        case OnboardingStepIdentifier.NewOrExistingProject: {
          return (
            <Radio.Group
              onChange={(v) => {
                form.setFieldValue("newCompany", v === "new");
              }}
            >
              <SimpleGrid cols={{ base: 1, sm: 2 }}>
                <OnboardingOption
                  Icon={IconNewSection}
                  title="Yes, I'm starting from scratch"
                  description="I'm using NOAN to build a new company."
                  value="new"
                  checked={isChecked("newCompany", true)}
                />

                <OnboardingOption
                  Icon={IconChecklist}
                  title="No, I have an existing project"
                  description="I'm using NOAN to help run a company."
                  value="existing"
                  checked={isChecked("newCompany", false)}
                />
              </SimpleGrid>
            </Radio.Group>
          );
        }
        case OnboardingStepIdentifier.Name: {
          return (
            <TextInput
              size="lg"
              placeholder={
                companyNamePlaceholders[
                  Math.floor(Math.random() * companyNamePlaceholders.length)
                ]
              }
              {...form.getInputProps("name")}
            />
          );
        }
        case OnboardingStepIdentifier.Website: {
          return (
            <TextInput
              size="lg"
              placeholder={"www.yourcompany.biz"}
              {...form.getInputProps("website")}
            />
          );
        }
        case OnboardingStepIdentifier.TeamSize: {
          return (
            <Radio.Group
              onChange={(value) => {
                form.setFieldValue("teamSize", parseInt(value) as TeamSize);
              }}
            >
              <SimpleGrid cols={{ base: 1, sm: 2 }}>
                <OnboardingOption
                  Icon={IconUser}
                  title="It's just me"
                  value={`${TeamSize.One}`}
                  checked={isChecked("teamSize", TeamSize.One)}
                />

                <OnboardingOption
                  Icon={IconUsers}
                  title="2-9"
                  value={`${TeamSize.Small}`}
                  checked={isChecked("teamSize", TeamSize.Small)}
                />

                <OnboardingOption
                  Icon={IconUsersGroup}
                  title="10-49"
                  value={`${TeamSize.Medium}`}
                  checked={isChecked("teamSize", TeamSize.Medium)}
                />
                <OnboardingOption
                  Icon={IconBuilding}
                  title="50-249"
                  value={`${TeamSize.Big}`}
                  checked={isChecked("teamSize", TeamSize.Big)}
                />
                <OnboardingOption
                  Icon={IconBuildingSkyscraper}
                  title="250+"
                  value={`${TeamSize.Huge}`}
                  checked={isChecked("teamSize", TeamSize.Huge)}
                />
              </SimpleGrid>
            </Radio.Group>
          );
        }
        case OnboardingStepIdentifier.Industry: {
          return (
            <Radio.Group
              onChange={(value) => {
                form.setFieldValue("industry", value as Industry);
              }}
            >
              <SimpleGrid cols={{ base: 1, sm: 2 }}>
                {Object.entries(Industry).map(([_value, industry]) => (
                  <OnboardingOption
                    key={industry}
                    Icon={industryIcon[industry]}
                    title={industry}
                    value={industry}
                    checked={isChecked("industry", industry)}
                  />
                ))}
              </SimpleGrid>
            </Radio.Group>
          );
        }
        case OnboardingStepIdentifier.TargetType: {
          return (
            <Radio.Group {...form.getInputProps("targetType")}>
              <SimpleGrid cols={{ base: 1, sm: 2 }}>
                <OnboardingOption
                  Icon={IconUserCheck}
                  title="B2C"
                  description="I'm offering my services to end-users."
                  value={TargetType.B2C}
                  checked={isChecked("targetType", TargetType.B2C)}
                />

                <OnboardingOption
                  Icon={IconBuilding}
                  title="B2B"
                  description="I'm offering my services to other businesses."
                  value={TargetType.B2B}
                  checked={isChecked("targetType", TargetType.B2B)}
                />
              </SimpleGrid>
            </Radio.Group>
          );
        }
        default:
          assertUnreachable(stepIdentifier, `Unknown step: ${stepIdentifier}`);
      }
    },
    [form, isChecked],
  );

  const findNextStep = useCallback(
    (direction: "forward" | "back", currentStep: OnboardingStepIdentifier) => {
      let availableSteps = [...onboardingSteps];

      if (direction === "back") {
        availableSteps = availableSteps.reverse();
      }

      const currentStepIdx = availableSteps.findIndex(
        (s) => s.id === currentStep,
      )!;

      availableSteps = availableSteps.slice(currentStepIdx + 1);

      if (availableSteps) {
        return availableSteps.find((s) => !s.skip);
      }

      return null;
    },
    [onboardingSteps],
  );

  const renderStep = useCallback(
    (step: OnboardingStepIdentifier) => {
      const stepConfigIdx = onboardingSteps.findIndex((s) => s.id === step);
      const stepConfig = onboardingSteps[stepConfigIdx];

      if (!stepConfig) {
        return renderStep(OnboardingStepIdentifier.NewOrExistingProject);
      }

      const { title, description, Icon, canContinue } = stepConfig;

      const previousStep = findNextStep("back", stepConfig.id);
      const nextStep = findNextStep("forward", stepConfig.id);

      findNextStep("forward", stepConfig.id);
      findNextStep("back", stepConfig.id);

      return (
        <OnboardingStep
          title={title}
          description={description}
          canAdvance={canContinue}
          Icon={Icon}
          onClose={() => {
            notifications.show({
              message: "We'll ask you to complete these questions later.",
            });

            clearStepQuery();
            onComplete();
          }}
          onGoBack={
            previousStep
              ? () => {
                  goToStep(previousStep.id);
                }
              : undefined
          }
          onAdvance={
            nextStep
              ? () => {
                  goToStep(nextStep.id);
                }
              : () => {
                  const values = form.getTransformedValues();

                  const { name, ...attributes } = form.getTransformedValues();

                  updateOrganizationAsync(
                    {
                      name: values.name,
                      settings: {
                        attributes,
                      },
                    },
                    {
                      onSettled: () => {
                        notifications.show({
                          message: "Organization details saved.",
                          color: "green",
                        });

                        clearStepQuery();
                        onComplete();
                      },
                    },
                  );
                }
          }
          isFinalStep={nextStep == null}
        >
          {renderStepContent(step)}
        </OnboardingStep>
      );
    },
    [
      renderStepContent,
      onboardingSteps,
      goToStep,
      clearStepQuery,
      onComplete,
      form,
      updateOrganizationAsync,
      findNextStep,
    ],
  );

  if (!org) {
    return <FullPageLoader message="Loading organization details..." />;
  }

  const currentStep =
    (search.get("onboardingStep") as OnboardingStepIdentifier) ||
    onboardingSteps.find((s) => !s.skip)?.id ||
    OnboardingStepIdentifier.NewOrExistingProject;

  return <Box p="lg">{renderStep(currentStep)}</Box>;
}

export function OnboardingWizardModal({
  opened = false,
  onClose,
}: {
  opened?: boolean;
  onClose: () => void;
}) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      keepMounted={false}
      withinPortal
      withCloseButton={false}
      withOverlay
      size="xl"
      centered
    >
      <OnboardingWizard onComplete={onClose} />
    </Modal>
  );
}
