import classes from "./StackIcons.module.css";

export function RealEstateIcon() {
  return (
    <svg
      width="205"
      height="229"
      viewBox="0 0 205 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M136.584 84.8469L119.54 94.6871L119.539 134.051H102.494L102.494 153.733H85.4498L85.4496 114.369L68.4052 104.529L51.3608 114.37L51.3611 153.733H34.3164L34.3164 104.529L34.3154 104.529L68.4055 84.8476L102.494 104.528L102.494 84.8474L136.584 65.1664L170.673 84.8474L170.673 134.051L153.629 134.051L153.628 94.6878L136.584 84.8469Z"
        fill="currentColor"
      />
      <path d="M77.4043 115.21H59.4043V133.21H77.4043V115.21Z" fill="black" />
      <path
        d="M128.063 95.5279H146.063V113.528H128.063V95.5279Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.652 153.774L204.653 69.224C204.653 60.6496 200.079 52.7266 192.653 48.4394L114.385 3.25104C106.959 -1.03604 97.8107 -1.03603 90.3852 3.25107L17.161 45.5259C6.61374 51.6153 0.116231 62.8691 0.116211 75.048L0.116259 159.599C0.116279 168.173 4.69064 176.097 12.1163 180.384L90.3843 225.572C97.8099 229.859 106.959 229.859 114.384 225.572L187.607 183.296C198.155 177.207 204.652 165.953 204.652 153.774ZM98.385 18.3138L21.161 62.8979C18.6857 64.327 17.1609 66.968 17.1609 69.8262L17.161 158.996C17.161 161.854 18.6858 164.495 21.161 165.924L98.3845 210.509C100.86 211.938 103.909 211.938 106.385 210.509L183.607 165.924C186.083 164.495 187.607 161.854 187.607 158.996L187.608 69.8271C187.608 66.9689 186.084 64.3279 183.608 62.8988L106.385 18.3138C103.91 16.8848 100.86 16.8847 98.385 18.3138Z"
        fill="currentColor"
      />
    </svg>
  );
}
