import { rolesWithLimitedBlockAccess } from "api/src/auth/permissions";
import { useCallback } from "react";
import { Module } from "../../utils/usePillars";
import { useAuthContext } from "../../auth/useAuthContext";
import { useAllowedBlocks } from "../../utils/useFacts";
import { useIsFeatureEnabled } from "../../utils/useIsFeatureEnabled";
import { FeatureFlag } from "api/src/featureFlags";

export function useIsBlockReadable() {
  const canUseBlocksRestricted = useIsFeatureEnabled(
    FeatureFlag.UseBlocksRestricted,
  );
  const { identity } = useAuthContext();
  const { blocks } = useAllowedBlocks({
    role: identity.primaryRole,
  });

  /** A block is readable when:
   * - Is is available for the current logged in user 'role'
   * AND
   * - It is not restricted from Storyblok
   * - OR it is restricted from Storyblok and available to the users set from the feature flag
   */
  const isBlockReadable = useCallback(
    (block: Module) => {
      // Roles that depend on blocks permissions settings by owners or admins.
      const isRestrictedRole = rolesWithLimitedBlockAccess.includes(
        identity.primaryRole,
      );
      const canUseBlockRestrictedByPermission =
        !isRestrictedRole ||
        (isRestrictedRole && blocks?.includes(block.knowledgeslug!));

      // Most likely NOAN internal users of some beta users for testing blocks before publishing to all users.
      const canUseBlockRestrictedByFlag =
        !block.restricted || (block.restricted && canUseBlocksRestricted);

      return canUseBlockRestrictedByPermission && canUseBlockRestrictedByFlag;
    },
    [canUseBlocksRestricted, identity.primaryRole, blocks],
  );

  return { isBlockReadable };
}
