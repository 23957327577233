import {
  Button,
  Chip,
  Fieldset,
  Group,
  Input,
  Stack,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useAuthContext } from "../../auth/useAuthContext";
import { useIdentityMutation } from "../../auth/useIdentityMutation";
import { Can } from "../../components/auth/Can";

export function ProfileSection() {
  const { identity } = useAuthContext();
  const { updateIdentity, isLoadingUpdateIdentity } = useIdentityMutation();

  const profileForm = useForm({
    initialValues: {
      name: identity.name,
    },
    validate: {
      name: (n) => {
        const trimmedName = n?.trim() ?? "";

        if (trimmedName.length < 1) {
          return "Must be at least one character long";
        }

        if (trimmedName.length > 32) {
          return "Name is too long, must be no more than 32 characters long";
        }
      },
    },
  });

  return (
    <form
      onSubmit={profileForm.onSubmit((values) => {
        updateIdentity({
          id: identity.id,
          name: values.name,
        });
      })}
    >
      <Fieldset legend="Profile Details">
        <Stack gap="sm">
          <Input.Wrapper
            label="Account role"
            description="Your account role affects the content you can interact with"
          >
            <Chip readOnly color="base.9" size="sm" mt="sm" checked>
              {identity.primaryRole}
            </Chip>
          </Input.Wrapper>

          <TextInput
            label="Your name"
            description="Other people in your organization can see your name"
            {...profileForm.getInputProps("name")}
          />

          <TextInput
            value={identity.email}
            label="Your email"
            readOnly
            description="You cannot edit your email at this time"
          />
        </Stack>

        <Can execute="identity.update.profile-basic">
          <Group justify="flex-end" mt="lg">
            <Button
              disabled={!profileForm.isDirty() || !profileForm.isValid()}
              type="submit"
              loading={isLoadingUpdateIdentity}
            >
              Save
            </Button>
          </Group>
        </Can>
      </Fieldset>
    </form>
  );
}
