import { z } from "zod";

export enum FactProvenance {
  User = "user",
  StrategyGenerator = "strategyGenerator",
  SiteScraper = "siteScraper",
}

export const FactContentSchema = z.object({
  /**
   * Specifies where this content originates from - usually the user or
   * generated by something.
   */
  provenance: z.nativeEnum(FactProvenance).default(FactProvenance.User),

  /**
   * Lists references included in the content - such as block path references.
   *
   * This should be used for secondary UI, and not necessarily for queries --
   * anything we'll be querying against should be normalized out of this list.
   */
  references: z
    .array(
      z.discriminatedUnion("refType", [
        z.object({
          refType: z.literal("blockPath"),
          blockPath: z.string(),
        }),
      ]),
    )
    .optional()
    .default([]),

  /**
   * Specifies formatting options for this content - currently we only
   * have text, but later we may want to process facts differently (e.g
   * earnings as specific currency)
   */
  format: z
    .discriminatedUnion("type", [
      // Covers both plain text, and eventually rich text (e.g json markup)
      z.object({
        type: z.literal("text"),
      }),
    ])
    .default({
      type: "text",
    }),

  plainText: z.string().optional(),
});

export const FactSchema = z.object({
  id: z.string().uuid(),
  creatorId: z.string().uuid(),
  organizationId: z.string().uuid(),
  blockPath: z.string(),
  parentFactId: z.string().uuid().nullable().optional(),
  content: FactContentSchema,
  factDependencyIds: z.array(z.string().uuid()).default([]),
  createdAt: z.date(),
  creator: z.object({
    id: z.string().uuid(),
    name: z.string().nullable().optional(),
    email: z.string().nullable().optional(),
  }),
});

export const FactWithStateSchema = FactSchema.extend({
  outdatedReferenceBlockPaths: z.array(z.string()).default([]),
});

export const CreateFactSchema = FactSchema.pick({
  blockPath: true,
  parentFactId: true,
  content: true,
});

export type FactContent = z.infer<typeof FactContentSchema>;
export type Fact = z.infer<typeof FactSchema>;
export type FactWithState = z.infer<typeof FactWithStateSchema>;
export type CreateFact = z.infer<typeof CreateFactSchema>;

export function getFactContentText(fact: Fact) {
  return fact.content.plainText ?? "";
}
