import classes from "./StackIcons.module.css";

export function ContentIcon() {
  return (
    <svg
      width="206"
      height="228"
      viewBox="0 0 206 228"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <g clipPath="url(#clip0_1444_22261)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.06 3.26021L192.43 48.3302V48.3202C199.75 52.5902 204.28 60.3902 204.35 68.8602L205.09 156.78C205.16 165.29 200.71 173.21 193.4 177.58L115.09 224.37C107.68 228.8 98.46 228.9 90.95 224.65L12.17 180.04C4.65 175.78 0 167.81 0 159.16V69.1302C0 60.5202 4.62 52.5702 12.09 48.2902L91.07 3.16021C98.51 -1.08979 107.66 -1.04979 115.06 3.26021ZM18.22 163.47L101.58 211.18H101.57C102.51 211.72 103.67 211.71 104.6 211.15L186.86 161.93C187.77 161.38 188.32 160.39 188.32 159.33L187.762 85.7272L108.29 131.65C104.58 133.78 100.01 133.79 96.3001 131.65L16.71 85.8182V160.87C16.71 161.94 17.29 162.94 18.22 163.47ZM16.7555 66.1096C16.9089 65.2389 17.442 64.4736 18.22 64.0302L101.77 16.1202C102.71 15.5802 103.86 15.5902 104.79 16.1402L186.15 64.4802C186.503 64.6917 186.803 64.9693 187.039 65.2917L154.44 84.1562V64.5L136.86 74.6L136.78 56.47L119.92 65.85L119.77 45.71L102.61 55.43L102.399 58.1129L102.38 55.43L85.22 45.71L85.07 64.99L68.22 54.97L68.06 75.01L51.06 64.84L50.91 85.64L51.3295 86.0182L16.7555 66.1096ZM102.529 76.0985L102.53 76.2L68.4967 95.9035L101.4 114.85L136.592 94.4848L102.529 76.0985Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1444_22261">
          <rect width="205.09" height="227.78" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
