import {
  assistants,
  type AssistantId,
} from "api/src/generated/storyblok/assistants";

export function assistantById(assistantId: AssistantId) {
  const assistant = assistants.find((a) => a.id === assistantId);

  if (!assistant) {
    throw new Error(`invariant: could not find assistant ${assistantId}`);
  }

  return assistant;
}
