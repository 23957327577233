import classes from "./StackIcons.module.css";

export function AdvertisingIcon() {
  return (
    <svg
      width="206"
      height="228"
      viewBox="0 0 206 228"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <g clipPath="url(#clip0_1444_22262)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.06 3.26021L192.43 48.3302V48.3202C199.75 52.5902 204.28 60.3902 204.35 68.8602L205.09 156.78C205.16 165.29 200.71 173.21 193.4 177.58L115.09 224.37C107.68 228.8 98.46 228.9 90.95 224.65L12.17 180.04C4.65 175.78 0 167.81 0 159.16V69.1302C0 60.5202 4.62 52.5702 12.09 48.2902L91.07 3.16021C98.51 -1.08979 107.66 -1.04979 115.06 3.26021ZM18.22 163.47L101.58 211.18H101.57C102.51 211.72 103.67 211.71 104.6 211.15L186.86 161.93C187.77 161.38 188.32 160.39 188.32 159.33L187.62 67.0402C187.61 65.9902 187.05 65.0202 186.15 64.4802L104.79 16.1402C103.86 15.5902 102.71 15.5802 101.77 16.1202L18.22 64.0302C17.29 64.5602 16.71 65.5502 16.71 66.6302V160.87C16.71 161.94 17.29 162.94 18.22 163.47ZM114.04 49.4L40.39 91.07H40.4C36.57 93.24 34.23 97.34 34.31 101.74L34.55 114.92L34.52 127.81C34.52 132.19 36.89 136.22 40.72 138.34L79.91 159.99C83.64 162.05 88.17 161.98 91.83 159.81L103.11 153.12L137.63 172.05L137.33 133.37L165.46 117.42C169.2 115.3 171.52 111.33 171.54 107.03L171.59 94.6L120.01 124.72L120.39 143.54L103.26 134.04L86.37 143.8L34.55 114.92L120.76 65.23L171.58 94.59V82.7C171.58 78.41 169.29 74.44 165.57 72.3L125.94 49.45C122.26 47.33 117.74 47.31 114.04 49.4ZM84.7001 124.31L67.4301 114.31L119.63 84.39L137.46 94.37L84.7001 124.31Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1444_22262">
          <rect width="205.09" height="227.78" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
