export type Maybe<T> = T | null | undefined;

export type OnlyChildrenProps = {
  children: React.ReactNode;
};

const offlineCacheKey = "REACT_QUERY_OFFLINE_CACHE";

export function clearOfflineQueryCache() {
  localStorage.removeItem(offlineCacheKey);
}

// Intentional blank function
export const NOOP = () => {};

export function compact<T>(array: (T | null | undefined)[]): T[] {
  return array.filter((v) => v != null) as NonNullable<T>[];
}

export function compactString<T>(array: (T | null | undefined)[]): T[] {
  return array.filter((v) => v != null && v !== "") as NonNullable<T>[];
}
