export type LayoutVariant = "default" | "insights" | "sidebar";

export const navigationHeaderHeights: Record<LayoutVariant, number> = {
  insights: 130,
  default: 40,
  sidebar: 0,
} as const;

export const topHeaderHeights = {
  mobile: 80,
  desktop: 30,
} as const;
