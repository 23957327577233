import {
  Box,
  FloatingIndicator,
  Group,
  Loader,
  ScrollAreaAutosize,
  Stack,
  Text,
  ThemeIcon,
  Title,
  UnstyledButton,
} from "@mantine/core";
import { IconHexagon } from "@tabler/icons-react";
import { FeatureFlag } from "api/src/featureFlags";
import type { Fact } from "api/src/models/Fact";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BlockWithRequirements } from "../../components/ConfigurableBlockCard/BlockOverviewCard";
import { OnboardingWizardModal } from "../../components/OnboardingWizard";
import { Page } from "../../components/Page";
import { RestrictedFeature } from "../../components/RestrictedFeature";
import { ReturnToTop } from "../../components/ReturnToTop";
import { ScraperNotice } from "../../components/ScraperNotice";
import { Can } from "../../components/auth/Can";
import { StackKey, stackStyle } from "../../components/icons/stacks";
import { isOutdatedVersion, useFacts } from "../../utils/useFacts";
import { useIsFeatureEnabled } from "../../utils/useIsFeatureEnabled";
import { useOnboardingModal } from "../../utils/useOnboardingModal";
import { useOrganizationStacks } from "../../utils/useOrganization";
import { Pillar, type BlocksGroupedByStack } from "../../utils/usePillars";
import classes from "./Build.module.css";
import { FactsContext } from "./FactsContext";
import { LegacyBuild } from "./LegacyBuild";
import { StackGroupCard } from "./StackGroupCard";
import {
  groupModulesByStack,
  useFilteredStacksWithOnlyReadableBlocks,
  useIsStackReadable,
} from "./useIsStackReadable";
import { useStrategyBrowserFilters } from "./useStrategyBrowserFilters";

type NavItem = {
  key: string;
  label: string;
  icon?: JSX.Element;
  completed: number;
  total: number;
};

type StacksNavProps = {
  defaultKey?: string;
  items: NavItem[];
  onClickItem: (item: NavItem) => void;
};

const StacksNav = forwardRef<HTMLDivElement, StacksNavProps>(function StacksNav(
  { defaultKey, items, onClickItem },
  ref,
) {
  const [active, setActive] = useState<NavItem["key"] | undefined>(defaultKey);
  const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null);
  const [controlsRefs, setControlsRefs] = useState<
    Record<string, HTMLButtonElement | null>
  >({});

  function onClickStack(item: NavItem) {
    setActive(item.key);
    onClickItem(item);
  }

  function setControlRef(index: number) {
    return function (node: HTMLButtonElement) {
      controlsRefs[index] = node;
      setControlsRefs(controlsRefs);
    };
  }

  const controls = items.map((item, idx) => {
    const isCompleted = item.completed === item.total;
    const isActive = active === item.key;

    return (
      <UnstyledButton
        key={item.key}
        className={classes.navControl}
        ref={setControlRef(idx)}
        onClick={() => onClickStack(item)}
        mod={{ active: active === item.key }}
      >
        <Group
          key={idx}
          align="center"
          gap="xs"
          className={classes.navControlLabel}
        >
          {item.icon && (
            <ThemeIcon
              size="sm"
              variant="transparent"
              c={isActive ? "white" : undefined}
              className={classes.navControlIcon}
            >
              {item.icon}
            </ThemeIcon>
          )}
          <Text size="sm">
            {item.label} {!isCompleted && `(${item.completed}/${item.total})`}
          </Text>
        </Group>
      </UnstyledButton>
    );
  });

  return (
    <Box ref={ref}>
      <Group gap="xs" className={classes.navRoot} ref={setRootRef}>
        {controls}
        <FloatingIndicator
          target={controlsRefs[items.findIndex((i) => i.key === active)]}
          parent={rootRef}
          className={classes.navIndicator}
        />
      </Group>
    </Box>
  );
});

export function BuildMode({
  stacks: allStacks,
  facts,
}: {
  stacks: Pillar[];
  facts: Fact[];
}) {
  const navigate = useNavigate();
  const navRef = useRef<HTMLDivElement>(null);
  const { isStackReadable } = useIsStackReadable();
  const { filteredStacks, setFilteredStacks } = useStrategyBrowserFilters({
    stacks: allStacks,
    facts,
    initialDefaultStacks: [],
  });
  const {
    stacks: organizationStacks,
    isLoadingStacks: isLoadingOrganizationStacks,
  } = useOrganizationStacks();

  const allStacksGrouped = useMemo<BlocksGroupedByStack[]>(
    () => groupModulesByStack(allStacks, facts),
    [facts, allStacks],
  );
  const orgStacks = useMemo(
    () =>
      allStacksGrouped.filter((st) =>
        organizationStacks.includes(st.stack.slug),
      ),
    [allStacksGrouped, organizationStacks],
  );
  const exploreStacks = useMemo(
    () =>
      allStacksGrouped.filter(
        (st) => !organizationStacks.includes(st.stack.slug),
      ),
    [allStacksGrouped, organizationStacks],
  );

  const stackSelected = useMemo(
    () => orgStacks.find(({ stack }) => stack.slug === filteredStacks?.[0]),
    [filteredStacks, orgStacks],
  );

  function onAddStackFinished(stackSlug: string) {
    setFilteredStacks([stackSlug]);
  }

  useEffect(() => {
    if (filteredStacks.length === 0 && orgStacks.length > 0) {
      setFilteredStacks([orgStacks[0].stack.slug]);
    }
  }, [filteredStacks.length, orgStacks, setFilteredStacks]);

  if (isLoadingOrganizationStacks) return <Loader size="md" />;

  return (
    <Stack gap="xl">
      {orgStacks.length > 0 && stackSelected && (
        <StacksNav
          ref={navRef}
          defaultKey={stackSelected.stack.slug}
          items={orgStacks.map((st) => ({
            key: st.stack.slug,
            label: st.stack.name,
            icon: stackStyle[st.stack.slug.toLowerCase() as StackKey]?.icon ?? (
              <IconHexagon size="lg" />
            ),
            completed: st.blocks.filter((mod) =>
              facts.find((f) => f.blockPath === mod.knowledgeslug),
            ).length,
            total: st.blocks.length,
          }))}
          onClickItem={(item) => setFilteredStacks([item.key])}
        />
      )}

      {allStacksGrouped.length === 0 ? (
        <Text c="dimmed" size="sm" ta="center">
          No stacks are available. Please check your blocks permissions with
          your organization owner.
        </Text>
      ) : (
        <FactsContext.Provider
          value={{
            facts,
            stacks: allStacksGrouped,
          }}
        >
          <ScraperNotice />
          <Stack gap="xl">
            {stackSelected && stackSelected.blocks.length > 0 && (
              <ScrollAreaAutosize scrollbars="y" mah={380}>
                <Group align="flex-start" pb="xl">
                  <RestrictedFeature
                    title="Blocks Locked"
                    description={`The blocks from ${stackSelected.stack.name} stack are available to paid NOAN subscribers only`}
                    isRestricted={!isStackReadable(stackSelected.stack.slug)}
                  >
                    {({ restrictedClassName }) => (
                      <>
                        {stackSelected.blocks.map((blo) => {
                          const goToBlockPage = () => {
                            navigate(`/build/b/${blo.knowledgeslug}`);
                          };

                          return (
                            <Can key={blo._uid} execute="facts.read">
                              <BlockWithRequirements
                                className={restrictedClassName}
                                w={{ base: "100%", sm: "30%" }}
                                title={blo.title!}
                                content={blo.fact?.content.plainText}
                                noContentButton={{
                                  label: "Click here to start editing",
                                  onClick: goToBlockPage,
                                }}
                                isOutdatedVersion={isOutdatedVersion(blo.fact)}
                                onClickCard={goToBlockPage}
                                block={blo}
                              />
                            </Can>
                          );
                        })}
                      </>
                    )}
                  </RestrictedFeature>
                </Group>
              </ScrollAreaAutosize>
            )}
            {exploreStacks.length > 0 && (
              <Stack>
                <Title order={2} fw="bold">
                  Explore
                </Title>
                <Group>
                  {exploreStacks.map(({ stack }) => (
                    <StackGroupCard
                      key={stack.uuid}
                      stack={stack}
                      onAddStackFinished={onAddStackFinished}
                    />
                  ))}
                </Group>
              </Stack>
            )}
          </Stack>
        </FactsContext.Provider>
      )}
    </Stack>
  );
}

export function Build() {
  const isNewBuildModeEnabled = useIsFeatureEnabled(
    FeatureFlag.UseNewBuildMode,
  );
  const { onboardingModalOpened, closeOnboardingModal } = useOnboardingModal();
  const [facts, { isLoading: isLoadingFacts }] = useFacts();
  const { stacks, isLoadingStacks } = useFilteredStacksWithOnlyReadableBlocks();

  const isLoading = stacks == null || isLoadingFacts || isLoadingStacks;

  if (!isNewBuildModeEnabled) {
    return <LegacyBuild />;
  }

  return (
    <Page
      title="Build"
      description="Complete stacks to start building your business."
      fullWidth={false}
      size="md"
    >
      {isLoading ? <Loader /> : <BuildMode stacks={stacks} facts={facts} />}
      <OnboardingWizardModal
        opened={onboardingModalOpened}
        onClose={closeOnboardingModal}
      />
      <ReturnToTop />
    </Page>
  );
}
