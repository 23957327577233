import classes from "./StackIcons.module.css";

export function RetailIcon() {
  return (
    <svg
      width="205"
      height="229"
      viewBox="0 0 205 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M67.4125 104.454C72.5538 104.454 76.7216 100.286 76.7216 95.145C76.7216 90.0037 72.5538 85.8359 67.4125 85.8359C62.2713 85.8359 58.1035 90.0037 58.1035 95.145C58.1035 100.286 62.2713 104.454 67.4125 104.454Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.465 94.6141L170.466 114.295L136.253 173.312L119.332 182.916V183.18L34.1093 133.977L34.1085 85.3256C34.1084 78.8945 37.5394 72.9519 43.109 69.7366L85.2441 45.4116L170.465 94.6141ZM119.294 163.477L136.337 134L119.333 143.818L85.2431 124.137L102.288 114.296L85.2431 104.455L102.288 94.6131L136.377 114.296L119.333 124.137L136.357 133.966L153.421 104.455L85.2451 65.0926L51.1554 84.7733L51.1537 84.7746L51.1542 124.136L119.294 163.477Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.491 45.5266L114.269 3.25069C106.844 -1.03652 97.6949 -1.03653 90.2692 3.25067L12.0009 48.4388C4.57535 52.726 0.0010464 60.6489 0.00100831 69.2232L3.01043e-10 153.775C-5.10715e-05 165.954 6.49728 177.207 17.0445 183.297L90.268 225.572C97.6936 229.86 106.842 229.86 114.268 225.572L192.536 180.384C199.962 176.097 204.536 168.174 204.536 159.6L204.536 75.0489C204.536 62.87 198.039 51.6161 187.491 45.5266ZM17.0456 69.8265L17.0446 158.997C17.0446 161.855 18.5694 164.496 21.0446 165.925L98.268 210.51C100.743 211.939 103.793 211.939 106.268 210.51L183.492 165.925C185.967 164.496 187.492 161.855 187.492 158.996L187.491 69.8269C187.491 66.9688 185.966 64.3278 183.491 62.8987L106.269 18.3134C103.794 16.8843 100.744 16.8843 98.2691 18.3134L21.0456 62.8984C18.5704 64.3274 17.0456 66.9684 17.0456 69.8265Z"
        fill="currentColor"
      />
    </svg>
  );
}
