import type { PostHogConfig } from "posthog-js";

function getBaseUrl() {
  return (
    import.meta.env.VITE_BASE_URL ??
    import.meta.env.VITE_NETLIFY_URL ??
    import.meta.env.VITE_DEPLOY_URL ??
    import.meta.env.BASE_URL
  );
}

export const baseUrl = getBaseUrl();
export const isDeployed =
  import.meta.env.VITE_NETLIFY === "true" ||
  import.meta.env.VITE_ECS_TASK === "true";
export const apiBaseUrl = import.meta.env.VITE_API_BASE_URL ?? `${baseUrl}/api`;
export const trpcEndpoint = `${apiBaseUrl}/trpc`;
export const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
export const postHogApiKey = import.meta.env.VITE_POSTHOG_KEY;
export const postHogOptions: Partial<PostHogConfig> = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  autocapture: isDeployed,
  disable_session_recording: !isDeployed,
  rageclick: !isDeployed,
};
