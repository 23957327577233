import {
  Alert,
  Badge,
  Button,
  Card,
  Drawer,
  Group,
  List,
  Loader,
  Spoiler,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconBug,
  IconCalendar,
  IconCheck,
  IconHexagon,
  IconPackageImport,
  IconUser,
  IconVersions,
  IconVersionsFilled,
} from "@tabler/icons-react";
import { type PerkId } from "api/src/models/Coupon";
import {
  FactProvenance,
  type Fact,
  type FactWithState,
} from "api/src/models/Fact";
import {
  MessageRole,
  type GeneratorUntrustedInput,
} from "api/src/models/GeneratorInput";
import { Suspense, useCallback } from "react";
import { useAuthContext } from "../../auth/useAuthContext";
import { MarkdownContent } from "../../components/MarkdownContent";
import { blockPerkById } from "../../components/Perks/perks";
import { RestrictedFeature } from "../../components/RestrictedFeature";
import { StackKey, stackStyle } from "../../components/icons/stacks";
import { formatDateTime, relativeTimeFromNow } from "../../utils/date";
import { compact } from "../../utils/misc";
import {
  contentToQuestions,
  getLearningMaterialSlug,
} from "../../utils/storyblokHelpers";
import { trpc } from "../../utils/trpc";
import {
  getFactContentText,
  isFactWithState,
  useCreateFactVersion,
  useFactHeritage,
  useFactReferences,
  useRevertToFactVersion,
} from "../../utils/useFacts";
import {
  getBlockPath,
  isInputModule,
  type Module,
} from "../../utils/usePillars";
import classes from "./BlockCard.module.css";
import {
  ConfigurableModuleCard,
  type ConfigurableModuleCardProps,
} from "./ConfigurableModuleCard";
import { useFactsContext } from "./FactsContext";
import { useModuleRequirements } from "./useModuleRequirements";

export type BlockCardProps = {
  fact?: Fact | FactWithState;
  mod: Module;
  pillarName?: string;
  pillarSlug?: string;
} & Pick<ConfigurableModuleCardProps, "onOpenLearningContent">;

function FactVersion({
  fact,
  onRevertToVersion,
  isLatest = false,
}: {
  fact: Fact;
  isLatest?: boolean;
  onRevertToVersion: (factId: string) => void;
}) {
  const { identity } = useAuthContext();

  return (
    <Card shadow={"none"} p="sm">
      <RestrictedFeature withOverlay={false}>
        {({ restrictedClassName }) => (
          <Group
            className={[classes.factVersionInfo, restrictedClassName].join(" ")}
          >
            <Group flex={1} gap="xs">
              {fact.content.provenance === FactProvenance.SiteScraper && (
                <Tooltip label="This block's content was automatically imported from your website">
                  <Badge
                    size="sm"
                    color="dark"
                    leftSection={
                      <ThemeIcon variant="transparent" color="white" size="xs">
                        <IconPackageImport />
                      </ThemeIcon>
                    }
                  >
                    Imported
                  </Badge>
                </Tooltip>
              )}
              <Tooltip label={formatDateTime(fact.createdAt)}>
                <Badge
                  size="sm"
                  color="dark"
                  leftSection={
                    <ThemeIcon variant="transparent" color="white" size="xs">
                      <IconCalendar />
                    </ThemeIcon>
                  }
                >
                  {relativeTimeFromNow(fact.createdAt)}
                </Badge>
              </Tooltip>
              <Badge
                size="sm"
                color="dark"
                leftSection={
                  <ThemeIcon variant="transparent" color="white" size="xs">
                    <IconUser />
                  </ThemeIcon>
                }
              >
                {fact.creatorId === identity.id
                  ? "YOU"
                  : fact.creator.name || fact.creator.email || "Unknown"}
              </Badge>
            </Group>

            {!isLatest && (
              <Tooltip label="Create a new version based on the state of the block at this time">
                <Button
                  size="xs"
                  variant="subtle"
                  leftSection={
                    <ThemeIcon size="xs" variant="transparent">
                      <IconVersionsFilled />
                    </ThemeIcon>
                  }
                  onClick={() => onRevertToVersion(fact.id)}
                >
                  Revert to this version
                </Button>
              </Tooltip>
            )}

            {isLatest && (
              <Badge variant="subtle" size="sm" color="dark">
                CURRENT VERSION
              </Badge>
            )}
          </Group>
        )}
      </RestrictedFeature>

      <Spoiler hideLabel="Show less" showLabel="Show more" mt="sm">
        <MarkdownContent content={getFactContentText(fact)} />
      </Spoiler>
    </Card>
  );
}

export function BlockVersions({ fact }: { fact: Fact }) {
  const [factVersions] = useFactHeritage(fact.id);
  const { revertToFactVersion } = useRevertToFactVersion();
  const utils = trpc.useUtils();
  const { facts: allFacts, stacks } = useFactsContext();
  const refs = useFactReferences({
    fact,
    resolvedFacts: allFacts,
  });

  const onPickVersion = useCallback(
    (factId: string) => {
      revertToFactVersion(
        {
          factId,
        },
        {
          onSettled() {
            utils.facts.invalidate();
          },
        },
      );
    },
    [utils, revertToFactVersion],
  );

  const hasBrokenRefs = refs.brokenBlockPathReferences.length > 0;

  return (
    <Stack gap="sm">
      <RestrictedFeature>
        {hasBrokenRefs && (
          <Alert color="red" variant="light" title="Outdated Content">
            <Text>
              This block depends on other blocks, some of which have since been
              modified:
            </Text>
            <List mt="md">
              {refs.brokenBlockPathReferences.map((ref) => (
                <List.Item fw="bold" c="red" key={ref.blockPath}>
                  {getBlockPath({ blockPath: ref.blockPath, stacks }).title}
                </List.Item>
              ))}
            </List>

            <Text mt="md">
              You can resolve this by generating new content for this block.
            </Text>
          </Alert>
        )}

        <Stack gap="sm">
          {factVersions.map((f) => (
            <FactVersion
              fact={f}
              key={f.id}
              onRevertToVersion={onPickVersion}
              isLatest={f.id === fact.id}
            />
          ))}
        </Stack>
      </RestrictedFeature>
    </Stack>
  );
}

/**
 * @deprecated This component will be deleted once 'use-new-build-mode' flag is enabled.
 *
 * Replaces ModuleCard, very similar but setup to handle Facts instead
 * of Knowledge, and also includes the necessary changes to support versioning.
 */
export function BlockCard({
  mod,
  fact,
  pillarName,
  pillarSlug,
  onOpenLearningContent,
}: BlockCardProps) {
  const [
    versionsPanelOpen,
    { open: openVersionsPanel, close: closeVersionsPanel },
  ] = useDisclosure();
  const isInput = isInputModule(mod);
  const blockedByRequirements = useModuleRequirements(mod);

  const isOutdated =
    fact &&
    isFactWithState(fact) &&
    fact.outdatedReferenceBlockPaths.length > 0;

  const { createFactVersion } = useCreateFactVersion();

  /**
   * Used to show toast notifications + reference the same notification for
   * state updates.
   */
  const notificationId = `notify-${fact?.id}`;

  const mutationOptions = {
    onSuccess: () => {
      notifications.update({
        id: notificationId,
        message: "Saved!",
        icon: <IconCheck size={16} />,
        color: "green",
        autoClose: 1500,
      });
    },

    onError: () => {
      notifications.update({
        id: notificationId,
        icon: <IconBug size={16} />,
        color: "red",
        autoClose: 1500,
        message: "An unknown error ocurred while updating your strategy",
      });
    },
  };

  const generatorInput: GeneratorUntrustedInput = {
    role: MessageRole.User,
    thread: {
      blockPath: mod.knowledgeslug,
    },
  };

  const perks =
    mod.perks && mod.perks.length > 0
      ? mod.perks.filter((p) => p !== "").map((p) => blockPerkById(p as PerkId))
      : undefined;
  const blockStackStyle = pillarSlug
    ? stackStyle[pillarSlug.toLowerCase() as StackKey]
    : undefined;

  return (
    <>
      {fact && (
        <Drawer
          position="right"
          size="lg"
          title={
            <Group gap="sm">
              <ThemeIcon variant="subtle">
                <IconVersions />
              </ThemeIcon>
              <Text span size="lg">
                Version History
              </Text>
            </Group>
          }
          keepMounted={false}
          withinPortal
          opened={versionsPanelOpen}
          onClose={closeVersionsPanel}
        >
          <Suspense fallback={<Loader />}>
            {fact && <BlockVersions fact={fact} />}
          </Suspense>
        </Drawer>
      )}
      <ConfigurableModuleCard
        perks={perks ? compact(perks) : undefined}
        collapseComplete
        content={fact?.content.plainText}
        tagLine={pillarName}
        name={mod.title}
        icon={blockStackStyle?.icon ?? <IconHexagon />}
        generators={mod.aimodelgenerator}
        description={mod.description}
        contentEnhancerQuestions={contentToQuestions(mod.assessquestions)}
        learningContent={{
          guideSlug: getLearningMaterialSlug(mod.guidecontent),
          caseStudySlug: getLearningMaterialSlug(mod.casestudy),
        }}
        factId={fact?.id}
        isComplete={!!fact?.content}
        inputDescription={mod.userfacingtext}
        inputPlaceholder={mod.hint}
        truncateLines={isInput ? undefined : 12}
        allowRegenerate={!isInput}
        blockedByRequirements={blockedByRequirements}
        onOpenLearningContent={onOpenLearningContent}
        onOpenVersionsPanel={fact ? openVersionsPanel : undefined}
        isOutdatedVersion={isOutdated}
        isImported={
          fact && fact.content.provenance === FactProvenance.SiteScraper
        }
        onRequestGenerateOptions={
          isInput
            ? undefined
            : (opts) => ({
                ...(opts?.toneHint
                  ? {
                      tone: {
                        text: opts.toneHint,
                      },
                    }
                  : {}),
                ...(opts?.generator && {
                  generator:
                    opts.generator as keyof ConfigurableModuleCardProps["generators"],
                }),
                ...generatorInput,
              })
        }
        onFinishEditing={(changes?: string) => {
          if (typeof changes === "undefined") {
            return;
          }

          notifications.show({
            id: notificationId,
            message: "Saving changes to your strategy",
            color: "transparent",
            icon: <Loader size="sm" color="dimmed" />,
            // Set intentionally high, just so it doesn't get stuck in an edge case
            autoClose: 45000,
          });

          createFactVersion(
            {
              blockPath: mod.knowledgeslug!,
              parentFactId: fact?.id,
              content: {
                format: {
                  type: "text",
                },
                plainText: changes,
              },
            },
            mutationOptions,
          );
        }}
      />
    </>
  );
}
