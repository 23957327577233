import { useAuthContext } from "../../auth/useAuthContext";
import { type PromoCode } from "api/src/models/Supabase";

export function usePromoCode() {
  const { user, identity } = useAuthContext();

  const promoCode = user.user_metadata.promoCodes?.find(
    (promoCode: PromoCode) =>
      promoCode.organizationId === identity.organizationId,
  );

  return {
    promoCode: promoCode ? (promoCode as PromoCode).code : "",
  };
}
