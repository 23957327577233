import {
  ActionIcon,
  BackgroundImage,
  Badge,
  Button,
  Card,
  Container,
  CopyButton,
  Group,
  Skeleton,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { ISbStoryData, ISbStoryParams, useStoryblok } from "@storyblok/react";
import StoryblokStory from "@storyblok/react/story";
import {
  IconChevronLeft,
  IconChevronRight,
  IconCopy,
} from "@tabler/icons-react";
import { PropsWithChildren } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useOptionalAuthContext } from "../auth/useAuthContext";
import { PageMeta } from "../components/Page";
import { PageStoryblok } from "../storyblok-types";
import { compactString } from "../utils/misc";

const validVersionSymbols = ["draft", "published"];

function useIntelStorySlug() {
  const { "*": slug } = useParams();
  const [searchParams] = useSearchParams();
  // Accept providing a version constraint as ?_version=draft|published
  const versionFromParams = searchParams.get("_version");

  const userSlug =
    slug != null
      ? slug.startsWith("content/")
        ? slug
        : `intel/${slug}`
      : null;

  return {
    slug: userSlug ?? "intel-landing",
    isLanding: userSlug == null,
    version: (versionFromParams != null &&
    validVersionSymbols.includes(versionFromParams)
      ? versionFromParams
      : "published") as ISbStoryParams["version"],
  };
}

export function StoryContainer({
  children,
  isLanding,
  tags,
}: PropsWithChildren<{ isLanding: boolean; tags: string[] }>) {
  const { user } = useOptionalAuthContext();

  if (isLanding) {
    return children;
  }

  const currentUrl = new URL(window.location.toString());
  currentUrl.searchParams.set("rel", "permalink");

  if (user != null) {
    currentUrl.searchParams.set("ii", user.user_metadata?.identityId ?? "_");
  }

  return (
    <Container size={"md"} mb="xl">
      <Group mb="sm" justify="space-between">
        <Button
          component={Link}
          to="/intel"
          variant="light"
          leftSection={<IconChevronLeft size={14} />}
          color="text"
        >
          Return to The Intel
        </Button>

        {/* Show up to 3 tags for articles; exclude 'The Intel' */}
        <Group>
          {tags.length > 0 && (
            <Group visibleFrom="md" gap="xs" justify="flex-end" align="center">
              <Text size="xs" c="dimmed">
                Tags:
              </Text>

              {tags
                .filter((t) => t !== "The Intel")
                .slice(0, 3)
                .map((t) => (
                  <Badge variant="light" color="dimmed" key={t}>
                    {t}
                  </Badge>
                ))}
            </Group>
          )}
          <CopyButton value={currentUrl.toString()}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? "Copied to your clipboard!" : "Copy permalink"}
              >
                <ActionIcon
                  onClick={copy}
                  color={copied ? "green" : "teal"}
                  variant="light"
                >
                  <IconCopy size={14} />
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Group>
      </Group>
      <Card py="md" px={{ base: "xs", md: "xl" }}>
        {children}
      </Card>
      <Card p={0} bg="black" shadow="none" mt="lg">
        <BackgroundImage
          src="/bg-hero-signup-2.png"
          p="lg"
          bgsz="90%"
          bgp="90%"
        >
          <Group gap="md" justify="center" py="lg">
            <Title order={3} c="white">
              Experience the future of business building
            </Title>
            <IconChevronRight />
            <Button color="orange" component={Link} to="/join" size="lg">
              Join NOAN
            </Button>
          </Group>
        </BackgroundImage>
      </Card>
    </Container>
  );
}
/**
 * Renders open graph metatags via Helmet
 */
export function StoryMeta({ story }: { story: ISbStoryData }) {
  const content = story.content as PageStoryblok;
  const seoTags = content.seometatags ?? {};
  const meta = (content.metatags as typeof seoTags) ?? {};

  const title = compactString([
    seoTags.og_title,
    seoTags.title,
    meta.og_title,
    meta.title,
    story.name,
  ])[0];

  const image = compactString([seoTags.og_image, meta.og_image])[0];

  const description = compactString([
    seoTags.og_description,
    seoTags.description,
    meta.og_description,
    meta.description,
  ])[0];

  return (
    <>
      <Helmet>
        {image && <meta property="og:image" content={image} />}
        <meta property="og:type" content="article" />
      </Helmet>
      <PageMeta title={title} description={description} />
    </>
  );
}

export function Intel() {
  const { slug, version, isLanding } = useIntelStorySlug();
  const topSpacing = { base: 70, md: 110 };

  const story = useStoryblok(slug, {
    version,
    resolve_links: "story",
  });

  if (story.content == null || story.full_slug !== slug) {
    return isLanding ? (
      <Stack mt={topSpacing}>
        <Skeleton height={40} mb="sm" />
        <Skeleton height={180} mb="sm" />
        <Skeleton height={300} />
      </Stack>
    ) : (
      <Container size="md" mt={{ base: 160 }}>
        <Skeleton height={40} mb="sm" w={200} />
        <Skeleton height={700} />
      </Container>
    );
  }

  return (
    <BackgroundImage
      pt={topSpacing}
      src="/intel-bg.svg"
      bgsz="50%"
      bgr="no-repeat"
      bga="fixed"
      bgp="20%"
    >
      <StoryContainer isLanding={isLanding} tags={story.tag_list}>
        <StoryMeta story={story} />
        <StoryblokStory story={story} />
      </StoryContainer>
    </BackgroundImage>
  );
}
