import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Button,
  Card,
  Center,
  CheckIcon,
  Collapse,
  Flex,
  Group,
  Loader,
  Overlay,
  Spoiler,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
  Title,
  Tooltip,
  Transition,
} from "@mantine/core";
import { useDisclosure, useHover, useNetwork, useToggle } from "@mantine/hooks";
import {
  IconAlertTriangle,
  IconBook,
  IconCheck,
  IconChevronDown,
  IconCloudCheck,
  IconEdit,
  IconListTree,
  IconPlus,
  IconProgressCheck,
  IconSparkles,
  IconStarFilled,
} from "@tabler/icons-react";
import type { GeneratorUntrustedInput } from "api/src/models/GeneratorInput";
import { useEffect, useState } from "react";
import {
  BlockRequirementsAlert,
  type BlockRequirement,
} from "../../components/ConfigurableBlockCard/BlockRequirementsAlert";
import { RegenerateOptions } from "../../components/ConfigurableBlockCard/RegenerateOptions";
import {
  PROMPT_MAX_LENGTH,
  ToneHint,
} from "../../components/ConfigurableBlockCard/config";
import {
  GeneratorSwitcher,
  type GeneratorSwitcherProps,
} from "../../components/GeneratorSwitcher/GeneratorSwitcher";
import { GeneratorSwitcherButton } from "../../components/GeneratorSwitcher/GeneratorSwitcherButton";
import { MarkdownContent } from "../../components/MarkdownContent";
import { PerkDrawer } from "../../components/Perks/PerksDrawer";
import type { BlockPerk } from "../../components/Perks/perks";
import { TextCopier } from "../../components/TextCopier";
import { Can } from "../../components/auth/Can";
import type { RobotGenerativeModuleStoryblok } from "../../storyblok-types";
import {
  aiGenerators,
  useGeneratorQuery,
  type AiGeneratorOption,
} from "../../useGenerator";
import classes from "./ConfigurableModuleCard.module.css";
import type { ContentStories } from "./Content";
import { ContentEnhancer } from "./ContentEnhancer";

export interface ConfigurableModuleCardProps {
  /** The module content - likely originating from a matching Fact object */
  content?: string;
  /** The smaller text under the name - usually the pillar name */
  tagLine?: string;
  /** Description/instructions for this card  */
  description?: string;
  /** The id of the fact displayed in this card if exists */
  factId?: string;
  /** The name of this module */
  name?: string;
  /** An icon that is placed next to the description*/
  icon?: JSX.Element;
  /** The name of this module */
  generators?: RobotGenerativeModuleStoryblok["aimodelgenerator"];

  /** Indicates if this module is complete, or nothing if undefined */
  isComplete?: boolean;
  /** Indicates if content can be regenerated after the initial generation */
  allowRegenerate?: boolean;

  /** If specified, exposes links to different types of learning content */
  learningContent?: {
    guideSlug?: string;
    caseStudySlug?: string;
  };

  /** If specified, enables the given perks for this block, available from a popover */
  perks?: BlockPerk[];

  /** Is collapsed by default */
  collapsed?: boolean;

  /** Is collapsed by default IF isComplete = true */
  collapseComplete?: boolean;

  /** Description text for the editor textarea */
  inputDescription?: string;

  /** Placeholder text for the editor textarea */
  inputPlaceholder?: string;

  /** Maximum number of content lines to render */
  truncateLines?: number;

  /**
   * A list of module requirements. If not empty, prevents rendering
   * the content + content editor.
   */
  blockedByRequirements?: BlockRequirement[];

  /** A list of questions to suggest to the user in the assistant tool */
  contentEnhancerQuestions?: string[];

  /**
   * Called with undefined if no changes, or a string with the updated
   * content.
   */
  onFinishEditing?: (changes?: string) => void;

  /**
   * If generation is enabled and the user starts generating content, this
   * method is called and should return generator input.
   */
  onRequestGenerateOptions?: (options?: {
    toneHint?: ToneHint | string;
    generator?: string;
  }) => GeneratorUntrustedInput;

  /**
   * Triggers when user starts a regenerate action
   */
  onRegenerate?: (toneHint: ToneHint | string) => void;

  /**
   * Triggers when user opens the learning content panel for a block
   */
  onOpenLearningContent?: (stories: ContentStories) => void;

  onOpenVersionsPanel?: () => void;
  isOutdatedVersion?: boolean;

  /**
   * Can be used to tag a specific block as having content imported via some
   * tool.
   */
  isImported?: boolean;
}

/**
 * @deprecated This component will be deleted once 'use-new-build-mode' flag is enabled.
 *
 * View-focused component for the module card. This component mostly just implements
 * the visual aspects of the module interface, and exposes a bunch of different
 * configuration options.
 *
 * Most properties are optional, and the component will try to adapt as necessary.
 */
export function ConfigurableModuleCard({
  factId,
  name,
  icon,
  generators,
  tagLine,
  description,
  learningContent,
  isComplete,
  content,
  collapsed,
  collapseComplete,
  inputDescription,
  inputPlaceholder,
  truncateLines,
  blockedByRequirements,
  contentEnhancerQuestions,
  allowRegenerate,
  onOpenLearningContent,
  onFinishEditing,
  onRequestGenerateOptions,
  onRegenerate,
  onOpenVersionsPanel,
  perks,
  isOutdatedVersion = false,
  isImported = false,
}: ConfigurableModuleCardProps) {
  const [
    perplexityDrawerOpened,
    { open: openPerplexityDrawer, close: closePerplexityDrawer },
  ] = useDisclosure(false);

  const [
    perksDrawerOpened,
    { open: openPerksDrawer, close: closePerksDrawer },
  ] = useDisclosure(false);

  const [isEditing, toggleEditing] = useToggle();

  const hasMissingRequirements =
    blockedByRequirements != null && blockedByRequirements.length > 0;
  const isBlockedByRequirements = !content && hasMissingRequirements;

  const generatorsOptions = aiGenerators.filter((g) =>
    generators?.includes(
      g.value as keyof ConfigurableModuleCardProps["generators"],
    ),
  );

  const [regenerateInstructions, setRegenerateInstructions] =
    useState<string>();
  const [selectedGenerator, setSelectedGenerator] = useState<
    AiGeneratorOption | undefined
  >(generatorsOptions[0]);

  /**
   * The collapsing behavior is enabled by specifying a fixed value
   * for `collapsed`, or instructing the component to use the `isComplete`
   * prop to toggle the collapsed state's default value.
   *
   * If neither of these options are provided, we disable collapsing entirely.
   */
  const [expanded, { open: expand, close: collapse }] = useDisclosure(
    !(collapsed || isBlockedByRequirements || (collapseComplete && isComplete)),
  );

  // We use this check to ensure we're not collapsing newly generated content:
  const [hasGeneratedContent, toggleHasGeneratedContent] = useToggle();

  /**
   * Can regenerate if:
   *
   * - allowRegenerate = true
   * - is not editing
   * - has content
   * - has generation options provider OR a handler for onRegenerate
   *
   * MISSING: do not allow if currently generating?
   */
  const canRegenerate =
    !hasMissingRequirements &&
    allowRegenerate &&
    !isEditing &&
    (onRequestGenerateOptions != null || onRegenerate != null) &&
    content;

  /**
   * Can generate if:
   * - has generation options provider
   * AND EITHER of the following:
   * - can regenerate (see above) and has a set of instructions to regen
   * - is not editing and does not have content
   */
  const canGenerate =
    onRequestGenerateOptions != null &&
    ((canRegenerate && regenerateInstructions) || (!isEditing && !content));

  const canCollapse =
    typeof collapsed !== "undefined" ||
    (typeof collapseComplete !== "undefined" &&
      typeof isComplete !== "undefined");

  return (
    <Card
      id={factId ? factId : undefined}
      shadow="none"
      onClick={() => !expanded && expand()}
      /** Do not show the hover effect if this card is blocked: */
      className={isBlockedByRequirements ? undefined : "card-with-hover"}
      p={0}
      styles={{
        root: {
          cursor: canCollapse && !expanded ? "pointer" : undefined,
        },
      }}
    >
      <Stack p="md">
        <Flex
          direction={{ base: "column", md: "row" }}
          gap={"xs"}
          align={{ base: "flex-start", md: "center" }}
        >
          <Stack
            gap={5}
            flex={1}
            justify="center"
            className={
              canCollapse && expanded
                ? classes.blockCardCollapseCallout
                : undefined
            }
            onClick={
              canCollapse
                ? (e) => {
                    expanded ? collapse() : expand();
                    e.stopPropagation();
                  }
                : undefined
            }
          >
            <Group gap={5} preventGrowOverflow wrap="nowrap">
              {isOutdatedVersion && (
                <Tooltip label="This block depends on other blocks that have since been modified">
                  <Badge circle variant="light" size="md" color="red">
                    <Center>
                      <ThemeIcon size="xs" variant="transparent" color="red">
                        <IconAlertTriangle />
                      </ThemeIcon>
                    </Center>
                  </Badge>
                </Tooltip>
              )}
              {!isImported && !isOutdatedVersion && isComplete === true && (
                <Badge circle variant="light" size="md" color="green">
                  <Center>
                    <ThemeIcon size="xs" variant="transparent" color="green.8">
                      <IconCheck />
                    </ThemeIcon>
                  </Center>
                </Badge>
              )}

              {isImported && !isOutdatedVersion && isComplete === true && (
                <Tooltip label="The content of this block was automatically imported for you">
                  <Badge circle variant="light" size="md" color="green">
                    <Center>
                      <ThemeIcon size="xs" variant="transparent" color="green">
                        <IconCloudCheck />
                      </ThemeIcon>
                    </Center>
                  </Badge>
                </Tooltip>
              )}

              {name && (
                <Title order={4} fw="bold" textWrap="nowrap">
                  {name}
                </Title>
              )}

              {typeof isComplete !== "undefined" && (
                <>
                  {!isComplete && (
                    <Tooltip
                      label={
                        isBlockedByRequirements
                          ? "You need to complete other blocks first"
                          : "You have not completed this block yet"
                      }
                      offset={8}
                      position="right"
                    >
                      <Badge
                        size="sm"
                        variant="light"
                        color={isBlockedByRequirements ? "dimmed" : "yellow"}
                      >
                        {isBlockedByRequirements
                          ? "Not Available"
                          : "Incomplete"}
                      </Badge>
                    </Tooltip>
                  )}
                </>
              )}
            </Group>

            {tagLine && (
              <Badge
                variant="transparent"
                fw="normal"
                leftSection={
                  <ThemeIcon size="xs" variant="transparent" color="dark">
                    {icon}
                  </ThemeIcon>
                }
                px={0}
                color="dark"
              >
                {tagLine}
              </Badge>
            )}
          </Stack>

          {expanded && (
            <Group gap={2}>
              {onOpenVersionsPanel && (
                <Tooltip label={"View block history"}>
                  <ActionIcon
                    variant={isOutdatedVersion ? "light" : "subtle"}
                    color={isOutdatedVersion ? "red.4" : "dimmed"}
                    onClick={onOpenVersionsPanel}
                  >
                    <IconListTree size={18} />
                  </ActionIcon>
                </Tooltip>
              )}

              {(learningContent?.caseStudySlug ||
                learningContent?.guideSlug) && (
                <Tooltip label="Learn more about this content">
                  <ActionIcon
                    variant="subtle"
                    color="dimmed"
                    onClick={() =>
                      onOpenLearningContent &&
                      onOpenLearningContent({
                        ...(learningContent.guideSlug
                          ? {
                              guide: {
                                title: "Guide",
                                slug: learningContent.guideSlug,
                              },
                            }
                          : {}),

                        ...(learningContent.caseStudySlug
                          ? {
                              caseStudy: {
                                title: "Case Study",
                                slug: learningContent.caseStudySlug,
                              },
                            }
                          : {}),
                      })
                    }
                  >
                    <IconBook size={18} />
                  </ActionIcon>
                </Tooltip>
              )}

              {content && <TextCopier content={content} />}

              {canRegenerate && (
                <RegenerateOptions
                  onRegenerate={
                    onRegenerate ?? ((inst) => setRegenerateInstructions(inst))
                  }
                />
              )}

              {perks && perks.length > 0 && (
                <Can execute="coupon.read">
                  <>
                    <Tooltip label="This Smart Block has related perks.">
                      <ActionIcon
                        color="yellow.2"
                        variant="subtle"
                        onClick={openPerksDrawer}
                      >
                        <IconStarFilled size={14} />
                      </ActionIcon>
                    </Tooltip>
                    <PerkDrawer
                      opened={perksDrawerOpened}
                      onClose={closePerksDrawer}
                      perks={perks}
                    />
                  </>
                </Can>
              )}

              {!hasMissingRequirements &&
                (canGenerate || canRegenerate) &&
                !!generators &&
                generators.length > 1 && (
                  <Can execute="generated_result.create">
                    <GeneratorSwitcherButton
                      selectedGenerator={selectedGenerator}
                      onSelectGenerator={setSelectedGenerator}
                      options={generatorsOptions}
                    />
                  </Can>
                )}

              {factId && !!name && !!content && !hasMissingRequirements && (
                <Can execute="facts.read.assist">
                  <>
                    <Tooltip label="Assist">
                      <ActionIcon
                        color="dimmed"
                        variant="subtle"
                        onClick={openPerplexityDrawer}
                      >
                        <IconProgressCheck size={18} />
                      </ActionIcon>
                    </Tooltip>
                    <ContentEnhancer
                      factId={factId}
                      block={name}
                      content={content}
                      questions={contentEnhancerQuestions}
                      opened={perplexityDrawerOpened}
                      close={closePerplexityDrawer}
                    />
                  </>
                </Can>
              )}
            </Group>
          )}

          {canCollapse && !expanded && (
            <Badge
              color="dimmed"
              variant="light"
              size="xs"
              leftSection={<IconChevronDown size={14} />}
            >
              Show more
            </Badge>
          )}
        </Flex>

        <Collapse in={expanded} transitionDuration={100}>
          <>
            {content && hasMissingRequirements && (
              <BlockRequirementsAlert
                variant="compact"
                blockedByRequirements={blockedByRequirements}
              />
            )}
            {description && (
              <Card pb={40} px="md" pt="xs" shadow="none" withBorder>
                <Text size="sm" maw={"80ch"} mb="xs" c="base.7">
                  {description}
                </Text>
              </Card>
            )}
            <Card
              p={0}
              shadow="none"
              withBorder
              mt={description ? -40 : undefined}
            >
              {isBlockedByRequirements && !isEditing ? (
                <BlockRequirementsAlert
                  blockedByRequirements={blockedByRequirements}
                />
              ) : canGenerate ? (
                <GeneratedModuleContent
                  generatorSwitcherProps={{
                    selectedGenerator,
                    options: generatorsOptions,
                    onSelectGenerator: (gen) => {
                      setSelectedGenerator(gen);
                    },
                  }}
                  onStartGenerating={() => toggleHasGeneratedContent(true)}
                  onStartFromEmptyResult={() => {
                    toggleEditing(true);
                  }}
                  onRequestGenerateOptions={() => {
                    const opts = {
                      generator: selectedGenerator?.value,
                    };

                    return onRequestGenerateOptions(
                      regenerateInstructions
                        ? {
                            ...opts,
                            toneHint: regenerateInstructions,
                          }
                        : { ...opts },
                    );
                  }}
                  onFinishGenerating={() => {
                    // Ensure we don't store regenerate instructons across generation steps:
                    setRegenerateInstructions(undefined);
                  }}
                  startGeneratingOnMount={!!regenerateInstructions}
                />
              ) : (
                <EditableModuleContent
                  content={content}
                  inputDescription={inputDescription}
                  inputPlaceholder={inputPlaceholder}
                  onStartEditing={() => toggleEditing(true)}
                  onFinishEditing={(changes) => {
                    toggleEditing(false);
                    onFinishEditing && onFinishEditing(changes);
                  }}
                  truncateLines={truncateLines}
                  isEditing={isEditing}
                  editable={onFinishEditing != null}
                  startShowingMore={hasGeneratedContent}
                />
              )}
            </Card>
          </>
        </Collapse>
      </Stack>
    </Card>
  );
}

export function GeneratedModuleContent({
  onFinishGenerating,
  onStartFromEmptyResult,
  onRequestGenerateOptions,
  onStartGenerating,
  startGeneratingOnMount,
  generatorSwitcherProps,
}: {
  onFinishGenerating?: (result: string) => void;
  onStartFromEmptyResult?: () => void;
  onStartGenerating?: () => void;
  startGeneratingOnMount?: boolean;
  generatorSwitcherProps: GeneratorSwitcherProps;
} & {
  onRequestGenerateOptions: NonNullable<
    ConfigurableModuleCardProps["onRequestGenerateOptions"]
  >;
}) {
  /* Prevent the container from moving around too much during initial generation */
  const containerMinHeight = 200;
  const { online: isOnline } = useNetwork();
  const [isGenerating, toggleIsGenerating] = useToggle();

  const { generatedText, isLoading } = useGeneratorQuery({
    enabled: isGenerating,
    generate: onRequestGenerateOptions(),
    onEndGenerate: () => {
      toggleIsGenerating(false);
      onFinishGenerating && onFinishGenerating(generatedText!);
    },
    onStartGenerate: () => {
      onStartGenerating && onStartGenerating();
    },
  });

  useEffect(() => {
    if (startGeneratingOnMount && isOnline) {
      toggleIsGenerating(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoading && typeof generatedText === "undefined") {
    return (
      <Flex mih={containerMinHeight} direction="column" justify="center">
        <Alert icon={<IconSparkles size={23} />} variant="transparent">
          <Stack gap="xs" align="flex-start">
            <Text size="sm">
              NOAN will generate the content of this block based on your
              existing strategy.
            </Text>
            <Text size="sm">
              You will have a chance to edit this block afterwards.
            </Text>
            <Can execute="generated_result.create">
              <Group align="center" mt="sm" gap="xs">
                <GeneratorSwitcher
                  {...generatorSwitcherProps}
                  onSelectGenerator={(selection) => {
                    generatorSwitcherProps.onSelectGenerator(selection);
                    toggleIsGenerating(true);
                  }}
                >
                  {({ combobox }) => (
                    <Button
                      onClick={() => combobox.toggleDropdown()}
                      size="md"
                      variant="filled"
                      color="blue.4"
                      opacity={combobox.dropdownOpened ? 0.5 : 1}
                      radius={"xl"}
                      disabled={!isOnline}
                      rightSection={
                        <ThemeIcon
                          size="md"
                          variant="transparent"
                          color="white"
                        >
                          <IconChevronDown />
                        </ThemeIcon>
                      }
                    >
                      Generate with...
                    </Button>
                  )}
                </GeneratorSwitcher>
                {onStartFromEmptyResult && (
                  <Tooltip label="Add manually">
                    <ActionIcon
                      size="xl"
                      radius="xl"
                      variant="light"
                      color="dimmed"
                      onClick={onStartFromEmptyResult}
                    >
                      <IconPlus />
                    </ActionIcon>
                  </Tooltip>
                )}
              </Group>
            </Can>
          </Stack>
        </Alert>
      </Flex>
    );
  }

  return (
    <Box p="md">
      {generatedText ? (
        <Box>
          <MarkdownContent content={generatedText} />
          <Overlay backgroundOpacity={0.1}>
            <Flex
              direction="row"
              align="flex-end"
              justify="flex-end"
              h="100%"
              p="md"
            >
              <Loader size={"sm"} />
            </Flex>
          </Overlay>
        </Box>
      ) : (
        <Text size="xs" c="dimmed" variant="pulse">
          Getting everything ready...
        </Text>
      )}
    </Box>
  );
}

export function EditableModuleContent({
  editable,
  isEditing,
  content,
  onStartEditing,
  onFinishEditing,
  inputDescription,
  inputPlaceholder,
  truncateLines,
  startShowingMore,
}: Pick<
  ConfigurableModuleCardProps,
  | "onFinishEditing"
  | "inputDescription"
  | "inputPlaceholder"
  | "truncateLines"
  | "content"
> & {
  editable: boolean;
  isEditing: boolean;
  onStartEditing?: () => void;
  startShowingMore?: boolean;
}) {
  const { online: isOnline } = useNetwork();
  const { hovered: isHover, ref } = useHover();
  const [updatedContent, setUpdatedContent] = useState<string | undefined>(
    content,
  );

  const isDirty =
    typeof updatedContent !== "undefined" && updatedContent !== content;

  const canSave = updatedContent !== "";

  /**
   * Propagate upstream changes to the intermediate content
   * state, as long as we're not editing (to avoid losing changes):
   */
  useEffect(() => {
    if (!isEditing && isDirty) {
      setUpdatedContent(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, content]);

  return (
    <Box
      ref={ref}
      p="md"
      pos="relative"
      /**
       * If there's no content, clicking anywhere on the content editing area
       * jumps into editing mode.
       */
      onClick={content || !editable || !isOnline ? undefined : onStartEditing}
      style={{
        cursor: content || !editable ? undefined : "pointer",
      }}
    >
      {isEditing ? (
        <Textarea
          autoFocus
          autosize
          resize="vertical"
          disabled={!editable}
          value={
            typeof updatedContent === "undefined" ? content : updatedContent
          }
          onChange={(e) => setUpdatedContent(e.target.value)}
          maxRows={12}
          minRows={3}
          maxLength={PROMPT_MAX_LENGTH}
          description={
            <Stack gap="xs" component="span">
              {inputDescription && (
                <Text size="xs" span>
                  {inputDescription}
                </Text>
              )}
              <Text size="xs" span>
                {updatedContent?.length ?? 0}/{PROMPT_MAX_LENGTH}
              </Text>
            </Stack>
          }
          placeholder={inputPlaceholder}
          onBlur={() => {
            /**
             * If we don't have any pending changes, auto-close edit mode
             */
            if (!isDirty && onFinishEditing != null) {
              onFinishEditing();
            }
          }}
          onFocus={(e) => {
            // Auto-focus the end of the text:
            const contentLen = e.target.value.length;
            e.target.setSelectionRange(contentLen, contentLen);
          }}
          styles={{
            input: {
              border: "none",
              padding: 0,
            },
          }}
        />
      ) : (
        <>
          {content ? (
            <Spoiler
              hideLabel="Show less"
              showLabel="Show more"
              styles={{
                control: {
                  marginTop: 10,
                  fontSize: 12,
                },
              }}
              maxHeight={
                /** Very very very rough translation from 'lines' to maxHeight*/
                typeof truncateLines === "undefined"
                  ? undefined
                  : truncateLines * 16
              }
              initialState={
                typeof truncateLines === "undefined" || startShowingMore
              }
            >
              <MarkdownContent content={content} />
            </Spoiler>
          ) : (
            <Text size="md" c="dimmed">
              Click here to start editing
            </Text>
          )}

          {isHover && (
            <Can execute="facts.update">
              <Group pos="absolute" bottom={10} right={10} gap="xs">
                {editable && (
                  <>
                    <Button
                      leftSection={<IconEdit size={14} />}
                      size="sm"
                      variant="light"
                      disabled={!isOnline}
                      /**
                       * The button can always be clicked directly to start editing.
                       */
                      onClick={onStartEditing}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </Group>
            </Can>
          )}
        </>
      )}

      <Transition mounted={isDirty && editable} transition="slide-up">
        {(style) => (
          <Group gap="xs" style={style} justify="end" pt="xs">
            <Text c="dimmed" size="xs">
              Unsaved changes
            </Text>
            <Button
              color="dark"
              variant="default"
              onClick={() => onFinishEditing && onFinishEditing()}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              leftSection={<CheckIcon size={14} />}
              disabled={!canSave || !isOnline}
              onClick={() => onFinishEditing && onFinishEditing(updatedContent)}
            >
              Save
            </Button>
          </Group>
        )}
      </Transition>
    </Box>
  );
}
