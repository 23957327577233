import { useStoryblokApi } from "@storyblok/react";
import { useQuery } from "@tanstack/react-query";
import {
  RobotGenerativeModuleStoryblok,
  RobotInputModuleStoryblok,
  RobotModuleStoryblok,
} from "../storyblok-types";
import { Fact, FactWithState } from "api/src/models/Fact";

export type Module = RobotGenerativeModuleStoryblok | RobotInputModuleStoryblok;
export interface Pillar {
  name: string;
  uuid: string;
  slug: string;
  description?: string;
  content: {
    completionmessage: string;
    modules: Module[];
    advanced_block: boolean;
  } & Pick<RobotModuleStoryblok, "descriptive_summary">;
}

/**
 * Post-processed list of blocks, grouped by stack, potentially
 * with filtering applied. This differs from the Storyblok API object,
 * for example, by not allowing circular navigation of the blocks list
 * via the stack.
 */
export type BlocksGroupedByStack = {
  stack: Omit<Pillar, "content">;
  blocks: (Module & {
    stackName: string;
    stackSlug: string;
    fact?: Fact | FactWithState;
  })[];
};

export type UseStacksRestrictedPayload = {
  [stackSlug: string]: string[];
};

export function isInputModule(mod: Module): mod is RobotInputModuleStoryblok {
  return mod.component === "robot-input-module";
}

export function isGenerativeModule(
  mod: Module,
): mod is RobotGenerativeModuleStoryblok {
  return mod.component === "robot-generative-module";
}

/**
 * Fetches and pre-processes pillar and module data. Most of the pre-processing
 * is in terms of making our lives easier in code-land, but this also acts as
 * a logical place to handle data-inconsistencies, do feature flagging, etc.
 */
export function usePillars() {
  // We can't really rely on the useStoryblok hook here as far as I can tell,
  // so we instead use the API directly, and take the opportunity to also wrap
  // that into React Query
  const storyblok = useStoryblokApi();

  const query = useQuery(
    ["sb", "strategy:published"],
    async () => {
      return storyblok.getAll("cdn/stories", {
        starts_with: "strategy/",
        sort_by: "position:asc",
        version: "published",
      });
    },
    {
      meta: {
        persist: true,
      },
    },
  );

  return {
    pillars: query.data,
    ...query,
  };
}

export function getBlockPath({
  blockPath,
  stacks,
}: {
  blockPath: string;
  stacks: BlocksGroupedByStack[];
}) {
  const block = stacks
    .flatMap((s) => s.blocks)
    .find((b) => b.knowledgeslug === blockPath);

  return {
    block,
    stackName: block?.stackName ?? "",
    stackSlug: block?.stackSlug ?? "",
    title: block?.title ?? "",
    fact: block?.fact,
  };
}
