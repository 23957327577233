import { MantineProvider, createTheme } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, type Query } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { httpBatchLink, loggerLink } from "@trpc/client";
import { PostHogProvider } from "posthog-js/react";
import { Suspense, useMemo, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import * as superjson from "superjson";
import { AuthOptionalContext } from "./auth/AuthContext";
import { useSupabaseAuth } from "./auth/supabase";
import { useOptionalAuthContext } from "./auth/useAuthContext";
import { ConnectionStateMonitor } from "./components/ConnectionStateMonitor";
import { FullPageLoader } from "./components/FullPageLoader";
import "./components/Storyblok"; // Init storyblok
import {
  isDeployed,
  postHogApiKey,
  postHogOptions,
  trpcEndpoint,
} from "./config";
import { routeDefinitions } from "./pages";
import { trpc } from "./utils/trpc";
import { ZedIndex } from "./utils/zedIndex";

// Init mantine theme:
const theme = createTheme({
  fontFamily: "'Inter Variable', sans-serif",
  colors: {
    blue: [
      "#eaf0ff",
      "#d2dbfc",
      "#a3b4f6",
      "#718af2",
      "#4767ee",
      "#2f52ed",
      "#2146ed",
      "#1538d3",
      "#0c31bd",
      "#0029a7",
    ],
    yellow: [
      "#FBEBD5",
      "#F9CF7E",
      "#ECB837",
      "#CFA02E",
      "#AC8425",
      "#88691B",
      "#644C10",
      "#4C390C",
      "#2F240B",
      "#1A1507",
      "#1A1507",
    ],
    red: [
      "#FBEAE6",
      "#F9CBC0",
      "#F9AE9B",
      "#F8876F",
      "#F74D37",
      "#D2241B",
      "#A91A14",
      "#780F0B",
      "#481308",
      "#251006",
      "#251006",
    ],
    orange: [
      "#FBEAE2",
      "#FCCBB4",
      "#FEAC83",
      "#FE8647",
      "#EA5D04",
      "#BB4702",
      "#A33D02",
      "#692702",
      "#3F1B05",
      "#211105",
    ],
    teal: [
      "#DBFAF6",
      "#CBF7F2",
      "#8CE6DF",
      "#77D1C8",
      "#67B7AF",
      "#539891",
      "#407873",
      "#305C58",
      "#21423F",
      "#152A27",
      "#0D1E1B",
      "#0D1716",
      "#0D1716",
    ],
    base: [
      "#FFFFFF",
      "#F7F8F8",
      "#EDEEEE",
      "#D5D7D8",
      "#BFC1C3",
      "#A5A9AB",
      "#8A8C8E",
      "#6B6F70",
      "#505253",
      "#3B3D3D",
      "#252626",
      "#151616",
    ],
  },

  primaryColor: "orange",
  primaryShade: 3,
  defaultRadius: "md",
  shadows: {
    lg: "0 6px 6px rgba(0, 0, 0, .3)",
  },
  components: {
    Blockquote: {
      defaultProps: {
        p: "md",
        m: "lg",
        ta: "left",
      },
      styles: {
        root: {
          borderLeft: "none",
        },
      },
    },

    Button: {
      defaultProps: {
        loaderProps: {
          type: "oval",
        },
      },
    },

    Card: {
      defaultProps: {
        p: "lg",
        radius: "lg",
        shadow: "none",
        withBorder: true,
      },
    },

    Chip: {
      defaultProps: {
        radius: "md",
        variant: "filled",
        fw: 600,
      },
    },

    Overlay: {
      defaultProps: {
        zIndex: ZedIndex.Overlay,
      },
    },

    ProgressRoot: {
      defaultProps: {
        radius: "var(--mantine-radius-sm)",
      },
    },

    ProgressSection: {
      defaultProps: {
        bg: "teal",
      },
    },

    TextInput: {
      classNames: {
        error: "form-error",
        input: "form-textinput",
      },
      styles: {
        error: {},
        label: {
          marginBottom: "3px",
        },
      },
    },

    Title: {
      defaultProps: {
        fw: "normal",
      },
    },

    Textarea: {
      defaultProps: {
        size: "md",
      },
    },

    Tooltip: {
      defaultProps: {
        offset: 10,
        color: "black",
      },
    },
  },
});

// Init page routes:
const router = createBrowserRouter(routeDefinitions);

export function AppContainer() {
  const {
    user,
    signOut,
    isLoading: isLoadingUser,
    authError,
    jwtBearerToken,
    hasThirdPartyProvider,
    mustSetPassword,
    mustCompletePostSignup,
  } = useSupabaseAuth();

  return (
    <AuthOptionalContext.Provider
      value={{
        user: user!,
        signOut,
        isLoadingUser,
        authError,
        jwtBearerToken,
        hasThirdPartyProvider,
        mustSetPassword,
        mustCompletePostSignup,
      }}
    >
      <App />
    </AuthOptionalContext.Provider>
  );
}

export function App() {
  const { jwtBearerToken } = useOptionalAuthContext();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            cacheTime: 300000, // 5 minutes
          },
        },
      }),
  );

  const [persister] = useState(() =>
    createSyncStoragePersister({
      storage: window.localStorage,
    }),
  );

  const trpcClient = useMemo(
    () =>
      trpc.createClient({
        transformer: superjson,
        links: [
          ...(!isDeployed ? [loggerLink()] : []),

          httpBatchLink({
            url: trpcEndpoint,

            fetch(url, options) {
              return fetch(url, { ...options, credentials: "include" });
            },
            headers() {
              return {
                ...(jwtBearerToken == null
                  ? {}
                  : {
                      Authorization: jwtBearerToken,
                    }),
              };
            },
          }),
        ],
      }),
    [jwtBearerToken],
  );

  return (
    <PostHogProvider apiKey={postHogApiKey} options={postHogOptions}>
      <Suspense fallback={<FullPageLoader />}>
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
              persister,
              dehydrateOptions: {
                shouldDehydrateQuery: (query: Query) => {
                  return query.meta?.persist;
                },
              },
            }}
          >
            <MantineProvider theme={theme}>
              <Notifications position="bottom-center" />
              <RouterProvider
                router={router}
                fallbackElement={<FullPageLoader />}
                future={{
                  v7_startTransition: true,
                }}
              />
              <ConnectionStateMonitor />
            </MantineProvider>
            <ReactQueryDevtools position="bottom-right" />
          </PersistQueryClientProvider>
        </trpc.Provider>
      </Suspense>
    </PostHogProvider>
  );
}
