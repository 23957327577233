import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

type DateLike = string | number | Date | dayjs.Dayjs;

export function relativeTimeFromNow(date: DateLike) {
  return dayjs(date).from(Date.now());
}

export function dateIsToday(date: DateLike) {
  return dayjs(date).isSame(Date.now(), "day");
}

export function formatDateTime(date: DateLike) {
  return dayjs(date).toString();
}
