import { useMemo } from "react";
import { useAuthContext } from "../auth/useAuthContext";
import { trpc } from "./trpc";

export function useOrganization() {
  const { identity } = useAuthContext();
  const org = useOrganizationById(identity?.organizationId);

  return {
    ...org,
    organization: org.data,
  };
}

export function useOrganizationById(id: string = "") {
  const { user } = useAuthContext();
  const { data, ...rest } = trpc.organization.getById.useQuery(
    { id },
    { enabled: !!id },
  );

  return {
    data: data
      ? {
          ...data,
          currentUserIdentity: data.identities.find(
            (i) => i.authUserId === user.id,
          ),
          identities: data.identities,
        }
      : undefined,
    ...rest,
  };
}

export function useIsOrganizationSlugUnique(slug?: string) {
  const { user } = useAuthContext();

  return trpc.organization.isSlugUnique.useQuery(
    { slug },
    { enabled: !!user && !!slug },
  );
}

export function useClaimCoupon() {
  const utils = trpc.useUtils();

  return trpc.organization.claimCoupon.useMutation({
    onSettled() {
      utils.organization.getById.invalidate();
    },
  });
}

export function useOrganizationStacks() {
  const { organization, isLoading } = useOrganization();

  const stacks = useMemo(
    () => organization?.settings.stacks || [],
    [organization?.settings.stacks],
  );

  return { stacks, isLoadingStacks: isLoading };
}
