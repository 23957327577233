import classes from "./StackIcons.module.css";

export function ExperienceIcon() {
  return (
    <svg
      width="206"
      height="228"
      viewBox="0 0 206 228"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <g clipPath="url(#clip0_1444_22268)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.06 3.26021L192.43 48.3302V48.3202C199.75 52.5902 204.28 60.3902 204.35 68.8602L205.09 156.78C205.16 165.29 200.71 173.21 193.4 177.58L115.09 224.37C107.68 228.8 98.46 228.9 90.95 224.65L12.17 180.04C4.65 175.78 0 167.81 0 159.16V69.1302C0 60.5202 4.62 52.5702 12.09 48.2902L91.07 3.16021C98.51 -1.08979 107.66 -1.04979 115.06 3.26021ZM18.22 163.47L101.58 211.18H101.57C102.51 211.72 103.67 211.71 104.6 211.15L186.86 161.93C187.77 161.38 188.32 160.39 188.32 159.33L187.62 67.0402C187.61 65.9902 187.05 65.0202 186.15 64.4802L104.79 16.1402C103.86 15.5902 102.71 15.5802 101.77 16.1202L18.22 64.0302C17.29 64.5602 16.71 65.5502 16.71 66.6302V160.87C16.71 161.94 17.29 162.94 18.22 163.47ZM34.55 95.37L34.66 134.62L68.8501 153.72L96.7901 137.84C100.53 135.72 102.84 131.76 102.86 127.46L102.92 114.314L137.43 94.53L154.74 104.66L154.85 123.86L137.33 134.31L120.02 124.29L119.91 144.13L136.9 153.73L171.51 133.36V94.63L136.79 75.16L108.88 90.93C105.08 93.08 102.74 97.13 102.78 101.5L102.91 114.316L68.64 133.77L51.5601 123.94V105.87L68.64 95.57L85.6301 105.49L85.9801 84.6L68.64 75.53L34.5601 95.37H34.55Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1444_22268">
          <rect width="205.09" height="227.78" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
