import {
  Alert,
  Anchor,
  Box,
  Center,
  HoverCard,
  List,
  Overlay,
  Skeleton,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconCheckbox, IconSquare } from "@tabler/icons-react";

export interface BlockRequirement {
  label: string;
  blockPath?: string;
}

export function BlockRequirementsAlert({
  blockedByRequirements,
  onStartFromEmptyResult,
  variant = "full",
}: {
  blockedByRequirements: BlockRequirement[];
  onStartFromEmptyResult?: () => void;
  variant?: "full" | "compact";
}) {
  const requirementsHovercard = (
    <HoverCard keepMounted={false} withinPortal>
      <HoverCard.Target>
        {variant === "full" ? (
          <Alert
            color="black"
            variant="filled"
            icon={
              <ThemeIcon color="dark" variant="light">
                <IconCheckbox />
              </ThemeIcon>
            }
          >
            {onStartFromEmptyResult ? (
              <>
                This content isn't available for you yet, but you can{" "}
                <Anchor size="sm" onClick={onStartFromEmptyResult}>
                  add your existing strategy
                </Anchor>
              </>
            ) : (
              <>This content isn't available for you yet.</>
            )}
          </Alert>
        ) : (
          <Alert
            variant="default"
            color="dark"
            mb="sm"
            py="xs"
            icon={
              <ThemeIcon size="xs" color="dark" variant="light">
                <IconCheckbox />
              </ThemeIcon>
            }
          >
            <Text c="dimmed" size="sm">
              Additional features for this content will be unlocked once you
              complete missing blocks.
            </Text>
          </Alert>
        )}
      </HoverCard.Target>
      <HoverCard.Dropdown p="sm">
        <Title order={5} mb="sm">
          Complete the following blocks first:
        </Title>
        <List size="sm" icon={<IconSquare size={12} />}>
          {blockedByRequirements.map((i) => (
            <List.Item key={i.label}>{i.label}</List.Item>
          ))}
        </List>
      </HoverCard.Dropdown>
    </HoverCard>
  );

  return (
    <Box p={variant === "full" ? "md" : 0}>
      {variant === "full" && (
        <Stack gap="xs" opacity={0.6}>
          <Skeleton h="35" w={200} animate={false} />
          <Skeleton h="18" w={"95%"} animate={false} />
          <Skeleton h="18" w={"78%"} animate={false} />
          <Skeleton h="18" w={"32%"} animate={false} />
        </Stack>
      )}
      {variant === "full" ? (
        <Overlay backgroundOpacity={0.1} p="xl">
          <Center>{requirementsHovercard}</Center>
        </Overlay>
      ) : (
        requirementsHovercard
      )}
    </Box>
  );
}
