import {
  ActionIcon,
  CheckIcon,
  Combobox,
  Group,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  useCombobox,
} from "@mantine/core";
import { IconTag, IconX } from "@tabler/icons-react";
import { useAvailablePromoCodes } from "./useAvailablePromoCodes";

export type PromoCodeOption = {
  id: string;
  code: string;
};

export interface PromoCodesSelectorProps {
  selectedCode?: PromoCodeOption["code"];
  onSelectCode: (selection?: string) => void;
  options?: PromoCodeOption[];
}

export function PromoCodesSelector({
  selectedCode,
  onSelectCode,
}: PromoCodesSelectorProps) {
  const combobox = useCombobox();
  const { codes: promoCodes } = useAvailablePromoCodes();

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(value) => {
        onSelectCode(value);

        combobox.closeDropdown();
      }}
      withinPortal
      position="bottom-start"
      shadow="md"
    >
      <Combobox.Target>
        <TextInput
          w="fit-content"
          placeholder="Click to select a promotion code"
          rightSection={
            <ActionIcon
              onClick={() => {
                onSelectCode("");
                combobox.resetSelectedOption();
              }}
              variant="subtle"
            >
              <IconX size={18} />
            </ActionIcon>
          }
          label="Select a promotion code for this user"
          description="Can this user benefits from a promotion while subscribing to a NOAN plan?"
          value={selectedCode}
          onChange={(_event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
        />
      </Combobox.Target>
      <Combobox.Dropdown>
        <ScrollArea mah={300} scrollbars="y" component={Stack}>
          <Combobox.Options>
            {promoCodes?.map((promoCode) => (
              <Combobox.Option key={promoCode.id} value={promoCode.code}>
                <Group gap="sm">
                  <ThemeIcon size="md" variant="light" color="gray">
                    <IconTag size={18} />
                  </ThemeIcon>
                  <Text size="sm">{promoCode.code}</Text>
                  {selectedCode === promoCode.code && (
                    <ThemeIcon size="xs" color="text" variant="transparent">
                      <CheckIcon size={12} />
                    </ThemeIcon>
                  )}
                </Group>
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </ScrollArea>
      </Combobox.Dropdown>
    </Combobox>
  );
}
