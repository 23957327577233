import {
  Center,
  Chip,
  Group,
  Loader,
  Pagination,
  SimpleGrid,
  Stack,
  Text,
} from "@mantine/core";
import { usePagination } from "@mantine/hooks";
import { ConvoBucket, ConvoSource } from "api/src/models/Convo";
import { useSearchParams } from "react-router-dom";
import { Page } from "../../components/Page";
import { trpc } from "../../utils/trpc";
import { ConvoCard } from "./ConvoCard";

const convosPerPage = 15;

export function ConvoList() {
  const [search, setSearch] = useSearchParams();
  const pageFromSearch = search.has("p")
    ? parseInt(search.get("p") ?? "") || 1
    : 1;

  const { data, isLoading } = trpc.convos.getConvos.useQuery({
    source: ConvoSource.CreateAsset,
    take: convosPerPage,
    skip: pageFromSearch === 1 ? undefined : convosPerPage * pageFromSearch + 1,
    createdBy: search.get("createdBy") ?? undefined,
    inBucket: (search.get("tag") as ConvoBucket) ?? undefined,
  });

  const totalConvos = data?.pagination?.total ?? 0;
  const convos = data?.convos;

  const totalPages = Math.round(totalConvos / convosPerPage);
  const pagination = usePagination({
    total: totalPages,
    initialPage: pageFromSearch,
    onChange: (activePage) => {
      setSearch((s) => {
        s.set("p", `${activePage}`);
        return s;
      });
    },
  });

  return (
    <Page
      documentTitle="All creations"
      fullWidth={false}
      size="md"
      backButton={{
        label: "Back to Create",
      }}
    >
      <Stack gap="xs" mb="lg" pt="lg">
        <Group gap="sm">
          <Text size="xs" c="dark" span>
            Filter by creator:
          </Text>
          <Chip.Group
            onChange={(v) => {
              setSearch((s) => {
                if (v === "self") {
                  s.set("createdBy", "self");
                } else {
                  s.delete("createdBy");
                }

                s.delete("p");
                return s;
              });
            }}
          >
            <Chip
              value="self"
              checked={search?.get("createdBy") === "self"}
              size="xs"
            >
              Created by me
            </Chip>
            <Chip value="others" checked={!search?.has("createdBy")} size="xs">
              Created by anyone
            </Chip>
          </Chip.Group>
        </Group>

        <Group gap="xs">
          <Text size="xs" c="dark" span>
            Filter by type:
          </Text>

          <Chip.Group
            onChange={(v) => {
              setSearch((s) => {
                if (v === "any") {
                  s.delete("tag");
                } else {
                  s.set("tag", v as string);
                }

                s.delete("p");
                return s;
              });
            }}
          >
            <Chip value="any" checked={!search.has("tag")} size="xs">
              All
            </Chip>

            {Object.values(ConvoBucket).map((b) => (
              <Chip
                key={b}
                value={b}
                checked={search.get("tag") === b}
                size="xs"
              >
                {b}
              </Chip>
            ))}
          </Chip.Group>
        </Group>
      </Stack>

      {isLoading && <Loader />}

      {convos && convos.length > 0 && (
        <SimpleGrid cols={{ base: 1, sm: 2, md: 3 }}>
          {convos.map((c) => (
            <ConvoCard convo={c} key={c.id} />
          ))}
        </SimpleGrid>
      )}

      {convos && convos.length === 0 && (
        <Center p="xl" mt="lg">
          <Text size="sm" c="base.7">
            No results found matching your filters.
          </Text>
        </Center>
      )}

      {totalPages > 1 && (
        <Group justify="flex-end" my="md">
          <Pagination
            color="dark"
            size="sm"
            total={totalPages}
            value={pagination.active}
            onChange={pagination.setPage}
          />
        </Group>
      )}
    </Page>
  );
}
