import classes from "./StackIcons.module.css";

export function ArchitectIcon() {
  return (
    <svg
      width="205"
      height="229"
      viewBox="0 0 205 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M58.5791 56.1397L68.0809 72.5974L83.6694 63.5974L74.1676 47.1397L58.5791 56.1397Z"
        fill="currentColor"
      />
      <path
        d="M102.378 84.8122C102.378 90.9014 99.1291 96.5287 93.8556 99.5732C88.5824 102.618 82.0856 102.618 76.8123 99.5734C71.5384 96.5288 68.2894 90.9018 68.2894 84.8122C68.2893 78.723 71.538 73.096 76.8112 70.0512C82.0847 67.0061 88.5825 67.0057 93.8561 70.0505C99.1297 73.0952 102.378 78.7228 102.378 84.8122Z"
        fill="currentColor"
      />
      <path
        d="M136.468 143.857C136.468 149.946 133.219 155.573 127.946 158.618C122.672 161.663 116.174 161.663 110.901 158.618L102.378 153.698L136.468 134.016L136.468 143.857Z"
        fill="currentColor"
      />
      <path
        d="M102.378 114.335L102.379 94.6528L170.557 134.016L170.557 153.698L136.468 134.016L102.378 114.335Z"
        fill="currentColor"
      />
      <path
        d="M85.3335 104.494L102.378 114.335V193.061L85.3335 183.219L85.3335 104.494Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.536 153.738L204.537 69.1883C204.537 60.6139 199.963 52.6908 192.537 48.4036L114.269 3.21531C106.843 -1.07178 97.6945 -1.07177 90.269 3.21534L17.0448 45.4902C6.49753 51.5796 2.0604e-05 62.8334 0 75.0123L4.87946e-05 159.563C6.95476e-05 168.138 4.57443 176.061 12 180.348L90.2681 225.536C97.6937 229.823 106.842 229.823 114.268 225.536L187.491 183.261C198.038 177.171 204.536 165.917 204.536 153.738ZM98.2688 18.278L21.0448 62.8622C18.5695 64.2913 17.0447 66.9323 17.0447 69.7905L17.0448 158.96C17.0448 161.818 18.5695 164.459 21.0448 165.888L98.2683 210.474C100.743 211.903 103.793 211.903 106.268 210.474L183.491 165.888C185.966 164.459 187.491 161.818 187.491 158.96L187.492 69.7913C187.492 66.9332 185.967 64.2921 183.492 62.8631L106.269 18.2781C103.794 16.849 100.744 16.849 98.2688 18.278Z"
        fill="currentColor"
      />
    </svg>
  );
}
