import { z } from "zod";

export enum IdentityRole {
  Owner = "owner",
  Admin = "admin",
  Editor = "editor",
  Creator = "creator",
  Client = "client",
}

export const IdentityRoleSchema = z.nativeEnum(IdentityRole);

export const IdentitySchema = z.object({
  id: z.string().uuid(),
  name: z.string().nullable(),
  email: z.string(),
  primaryRole: IdentityRoleSchema,
  organizationId: z.string().uuid(),
  authUserId: z.string().nullable().optional(),
});

export const IdentityWithAccountIdSchema = IdentitySchema.merge(
  z.object({
    accountId: z.string().uuid(),
  }),
);

export const IdentitySummarySchema = z.object({
  isOperationsUser: z.boolean(),
  isAccountActive: z.boolean(),
  isPayingCustomer: z.boolean(),
  isCustomer: z.boolean(),
  hasInsightsAccess: z.boolean(),
  identity: IdentityWithAccountIdSchema.nullable(),
});

export type IdentitySummary = z.infer<typeof IdentitySummarySchema>;
export type Identity = z.infer<typeof IdentitySchema>;
export type IdentityWithAccountId = z.infer<typeof IdentityWithAccountIdSchema>;
