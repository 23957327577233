import {
  Alert,
  Anchor,
  Badge,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  Fieldset,
  Group,
  Input,
  Modal,
  NumberInput,
  Skeleton,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconAlertSquare,
  IconArrowBigDownLines,
  IconCheck,
} from "@tabler/icons-react";
import { ItemPriceTier } from "api/src/trpc_routes/account";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../auth/useAuthContext";
import { Can } from "../../components/auth/Can";
import { Maybe } from "../../utils/misc";
import { prefilCustomerPortalLink } from "../../utils/stripe";
import {
  getPrice,
  useAccountBilling,
  useIsPreviewMode,
} from "../../utils/useAccount";
import { useAccountMutation } from "../../utils/useAccountMutation";

const subscriptionConsentMessage =
  "I agree and consent to this change to the rate applied to my subscription.";

function BillingTierDetails() {
  const [openedAddSeats, { open: openAddSeats, close: closeAddSeats }] =
    useDisclosure(false);
  const [
    openedAddOrganizations,
    { open: openAddOrganizations, close: closeAddOrganizations },
  ] = useDisclosure(false);
  const {
    isLoadingGetBilling,
    currentSeats,
    seatLimit,
    currentOrganizations,
    organizationLimit,
    isSeatLimitReached,
    isOrganizationLimitReached,
    seatPrice,
    organizationPrice,
    frequency,
    seatTiers,
    organizationTiers,
    seatPriceWithDiscount,
    organizationPriceWithDiscount,
    percentOff,
  } = useAccountBilling();
  const { updateSubscriptionItemAsync, isLoadingUpdateSubscriptionItem } =
    useAccountMutation();
  const isLoading = isLoadingGetBilling || isLoadingUpdateSubscriptionItem;

  function showTierItemDetails(item: string) {
    return isLoadingGetBilling ? <Skeleton mb={5} h={40} w={70} /> : item;
  }

  return (
    <Group mt="sm">
      <Card w={{ base: "50%", xs: "200px" }} radius="md" withBorder>
        <Stack gap="xs" align="start">
          <Text c="dimmed" size="sm">
            Seats used
          </Text>
          <Title
            fw="bold"
            c={isSeatLimitReached && currentSeats !== seatLimit ? "red" : ""}
          >
            {showTierItemDetails(`${currentSeats}/${seatLimit}`)}
          </Title>
          <Stack gap={0}>
            <Group gap={0} c="gray.6" td={percentOff ? "line-through" : ""}>
              <Text
                size={percentOff ? "xs" : "md"}
                fw={!percentOff ? "bold" : ""}
              >
                ${seatPrice}/{frequency}
              </Text>
            </Group>
            {!!percentOff && (
              <Group gap={0} c="gray.6">
                <Text size="md" fw="bold">
                  ${seatPriceWithDiscount}
                </Text>
                <Text size="xs">/{frequency}</Text>
              </Group>
            )}
          </Stack>
          <Button
            size="xs"
            variant="transparent"
            p={0}
            onClick={openAddSeats}
            disabled={isLoading}
          >
            Add more
          </Button>
          {openedAddSeats && (
            <UpdateSubscriptionItemModal
              title="Add Extra Seat(s)"
              currentItemInputLabel="Seats used"
              newItemInputLabel="Seats desired"
              newItemInputDescription="Specify the total number of seats you'd like to include in your subscription"
              currentItems={currentSeats}
              itemsLimit={seatLimit}
              tiers={seatTiers}
              opened={openedAddSeats}
              close={closeAddSeats}
              onConfirm={async (quantity, consent) => {
                await updateSubscriptionItemAsync({
                  itemType: "seat",
                  quantity,
                  consentChange: {
                    message: subscriptionConsentMessage,
                    value: consent,
                  },
                });
              }}
              isLoading={isLoading}
            />
          )}
        </Stack>
      </Card>
      <Card w={{ base: "50%", xs: "200px" }} radius="md" withBorder>
        <Stack gap="xs" align="start">
          <Text c="dimmed" size="sm">
            Organizations used
          </Text>
          <Title
            fw="bold"
            c={
              isOrganizationLimitReached &&
              currentOrganizations !== organizationLimit
                ? "red"
                : ""
            }
          >
            {showTierItemDetails(
              `${currentOrganizations}/${organizationLimit}`,
            )}
          </Title>
          <Stack gap={0}>
            <Group gap={0} c="gray.6" td={percentOff ? "line-through" : ""}>
              <Text
                size={percentOff ? "xs" : "md"}
                fw={!percentOff ? "bold" : ""}
              >
                ${organizationPrice}/{frequency}
              </Text>
            </Group>
            {!!percentOff && (
              <Group gap={0} c="gray.6">
                <Text size="md" fw="bold">
                  ${organizationPriceWithDiscount}
                </Text>
                <Text size="xs">/{frequency}</Text>
              </Group>
            )}
          </Stack>
          <Button
            size="xs"
            variant="transparent"
            p={0}
            onClick={openAddOrganizations}
            disabled={isLoading}
          >
            Add more
          </Button>
          {openedAddOrganizations && (
            <UpdateSubscriptionItemModal
              title="Get Additional Organization(s)"
              currentItemInputLabel="Organizations available"
              newItemInputLabel="Number of organization(s) desired"
              newItemInputDescription="Specify the total number of organizations you'd like to include in your subscription"
              currentItems={currentOrganizations}
              itemsLimit={organizationLimit}
              tiers={organizationTiers}
              opened={openedAddOrganizations}
              close={closeAddOrganizations}
              onConfirm={async (quantity, consent) => {
                await updateSubscriptionItemAsync({
                  itemType: "organization",
                  quantity,
                  consentChange: {
                    message: subscriptionConsentMessage,
                    value: consent,
                  },
                });
              }}
              isLoading={isLoading}
            />
          )}
        </Stack>
      </Card>
    </Group>
  );
}

interface UpdateSubscriptionItemFormFields {
  totalItems: number;
  consent: boolean;
}

function UpdateSubscriptionItemModal({
  title,
  currentItemInputLabel,
  newItemInputLabel,
  newItemInputDescription,
  currentItems,
  itemsLimit,
  tiers,
  opened,
  close,
  onConfirm,
  isLoading = false,
}: {
  title: string;
  currentItemInputLabel: string;
  newItemInputLabel: string;
  newItemInputDescription: string;
  currentItems: number;
  itemsLimit: number;
  tiers: Maybe<ItemPriceTier[]>;
  isLoading?: boolean;
  opened: boolean;
  close: () => void;
  onConfirm: (quantity: number, consent: boolean) => Promise<void>;
}) {
  const { frequency, percentOff } = useAccountBilling();
  const { getInputProps, onSubmit, reset, isValid, values } =
    useForm<UpdateSubscriptionItemFormFields>({
      clearInputErrorOnChange: true,
      validateInputOnChange: true,
      validateInputOnBlur: true,
      initialValues: {
        totalItems: itemsLimit,
        consent: false,
      },
      validate: {
        totalItems: (val) =>
          val < currentItems
            ? "The new number of items must be higher than the current number of items"
            : null,
        consent: (val) => (val ? null : "*required"),
      },
    });

  function resetFormAndCloseModal() {
    reset();
    close();
  }

  async function onUpdateItems(values: UpdateSubscriptionItemFormFields) {
    await onConfirm(values.totalItems, values.consent);
    resetFormAndCloseModal();
    notifications.show({
      message: <Text>Subscription updated successfully.</Text>,
      color: "green",
      icon: <IconCheck size={18} />,
    });
  }

  return (
    <Modal
      opened={opened}
      onClose={resetFormAndCloseModal}
      size="md"
      title={
        <Text size="lg" fw="bold">
          {title}
        </Text>
      }
    >
      <form onSubmit={onSubmit(onUpdateItems)}>
        <Stack>
          <Stack gap="xs" justify="space-between" align="center" w="100%">
            <Card w="100%" shadow="none">
              <Group gap="xs">
                <Text size="md">{currentItemInputLabel}: </Text>
                <Text size="xl" fw="bold" span>
                  {currentItems}/{itemsLimit}
                </Text>
              </Group>
              <Divider size="sm" mt="md" />
              <Group gap={2} justify="flex-start">
                <Text size="xl" fw="bold">
                  ${getPrice(tiers, itemsLimit, percentOff)}
                </Text>
                <Text size="xs">/{frequency} - current plan rate</Text>
              </Group>
            </Card>
            <IconArrowBigDownLines size={24} />
            <Card w="100%" shadow="none">
              <Badge size="xs" color="green" variant="light">
                new
              </Badge>
              <NumberInput
                mt="sm"
                w="fit-content"
                data-autofocus
                label={<Text component={Group}>{newItemInputLabel} </Text>}
                description={newItemInputDescription}
                min={1}
                max={99}
                allowDecimal={false}
                {...getInputProps("totalItems")}
              />
              <Divider size="sm" mt="lg" />
              <Group gap={2} justify="flex-start">
                <Text size="xl" fw="bold">
                  ${getPrice(tiers, values.totalItems, percentOff)}
                </Text>
                <Text size="xs">/{frequency} - plan rate after update</Text>
              </Group>
              <Group gap={0}>
                <Alert
                  my="sm"
                  icon={<IconAlertSquare size={18} />}
                  variant="transparent"
                  p={0}
                >
                  New rate will be applied pro-rata for remainder of current{" "}
                  {frequency} in billing cycle, in full thereafter.
                </Alert>
                <Checkbox
                  required
                  fw="bold"
                  label={subscriptionConsentMessage}
                  {...getInputProps("consent")}
                />
              </Group>
            </Card>
          </Stack>
          <Group gap="sm" justify="center">
            <Button
              size="sm"
              onClick={resetFormAndCloseModal}
              variant="transparent"
            >
              Cancel
            </Button>
            <Button
              size="sm"
              color="red"
              variant="light"
              loading={isLoading}
              disabled={
                values.totalItems === itemsLimit || !isValid() || isLoading
              }
              type="submit"
            >
              Update
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}

export function BillingSection() {
  const { identity, isPayingCustomer } = useAuthContext();
  const isPreviewMode = useIsPreviewMode();
  const portalLink = prefilCustomerPortalLink({
    email: identity.email,
  });

  return (
    <Can execute="account.update.billing" redirect={403}>
      <Fieldset legend="Billing Overview">
        <Stack gap="sm">
          <Input.Wrapper label="Subscription Status">
            <Chip
              readOnly
              checked={isPayingCustomer}
              color="green.7"
              size="sm"
              pt="sm"
            >
              {isPayingCustomer
                ? "active"
                : isPreviewMode
                  ? "preview"
                  : "inactive"}
            </Chip>
          </Input.Wrapper>
          {isPayingCustomer && (
            <Input.Wrapper label="Subscription Tier Details">
              <BillingTierDetails />
            </Input.Wrapper>
          )}

          <Input.Wrapper
            label="Subscription and Billing"
            description="NOAN partners with Stripe to provide secure and convenient billing options."
          >
            {!isPayingCustomer ? (
              <Text size="sm" c="dimmed" py="xs">
                You are using a limited version of NOAN. To unlock all features,{" "}
                <Anchor component={Link} to={"/subscribe"}>
                  enroll
                </Anchor>{" "}
                in a NOAN subscription plan.
              </Text>
            ) : (
              <>
                <Text size="sm" c="dimmed" py="xs">
                  You can{" "}
                  <Anchor component={Link} to={portalLink} target="_blank">
                    manage your subscription
                  </Anchor>{" "}
                  through Stripe's customer portal. Use the same email address
                  you used to subscribe to NOAN.
                </Text>
                <Alert
                  variant="light"
                  icon={
                    <ThemeIcon size="lg" variant="light">
                      <IconAlertSquare />
                    </ThemeIcon>
                  }
                >
                  <Text size="sm" px="lg">
                    If you pause or cancel your subscription, your business
                    knowledge will be available when you return.
                  </Text>
                </Alert>
              </>
            )}
          </Input.Wrapper>
        </Stack>
      </Fieldset>
    </Can>
  );
}
