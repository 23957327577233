import { useAuthContext } from "../../auth/useAuthContext";
import { trpc } from "../../utils/trpc";

export function useBuildBuiltFirstJwtToken() {
  const { user } = useAuthContext();

  return trpc.identity.getBuiltFirstJwtToken.useQuery(undefined, {
    enabled: user != null,
  });
}

export function useTransferOwnership() {
  const utils = trpc.useUtils();

  const { mutate: transferOwnership, isLoading: isTransferringOwnership } =
    trpc.organization.transferOwnership.useMutation({
      onSettled() {
        utils.organization.getAll.invalidate();
        utils.identity.get.invalidate();
      },
    });

  return {
    transferOwnership,
    isTransferringOwnership,
  };
}
