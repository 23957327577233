import {
  Anchor,
  Badge,
  Button,
  Card,
  Code,
  Combobox,
  Divider,
  Fieldset,
  Group,
  Input,
  Loader,
  Menu,
  Modal,
  Paper,
  Pill,
  PillsInput,
  Radio,
  RadioGroup,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
  useCombobox,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconBug,
  IconCheck,
  IconDots,
  IconInfoSmall,
  IconMail,
  IconPlus,
  IconSend,
  IconTrash,
  IconUser,
  IconUsers,
} from "@tabler/icons-react";
import { Identity, IdentityRole } from "api/src/models/Identity";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { OrganizationProps } from ".";
import { useAuthContext } from "../../../auth/useAuthContext";
import { useSearchIdentities } from "../../../auth/useIdentity";
import { useIdentityMutation } from "../../../auth/useIdentityMutation";
import { RestrictedFeature } from "../../../components/RestrictedFeature";
import { Can } from "../../../components/auth/Can";
import { Maybe } from "../../../utils/misc";
import { useAccountBilling } from "../../../utils/useAccount";
import { useOrganizationById } from "../../../utils/useOrganization";
import { roleOptions } from "../roles";
import classes from "./OrganizationSection.module.css";
import {
  highestRole,
  isRoleHigherOrEqual,
  regexEmail,
  showError,
} from "./helpers";

interface AddMemberFormFields {
  search: string;
  members: string[];
}

function AddMemberModal({
  organization,
}: {
  organization: Maybe<OrganizationProps>;
}) {
  const [opened, { open, close }] = useDisclosure(false);
  const { saveIdentitiesAsync, isLoadingSaveIdentities } =
    useIdentityMutation();
  const [searchMember, setSearchMember] = useDebouncedState<string>("", 200);
  const { data: identities, isFetching } = useSearchIdentities({
    q: searchMember,
  });
  const { isSeatLimitReached, currentSeats, seatLimit, isLoadingGetBilling } =
    useAccountBilling();

  const {
    getInputProps,
    values,
    setFieldError,
    setFieldValue,
    setValues,
    onSubmit,
    errors,
    reset,
  } = useForm<AddMemberFormFields>({
    clearInputErrorOnChange: true,
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: {
      search: "",
      members: [],
    },
    onValuesChange(val, prev) {
      if (val.search !== prev.search) {
        setSearchMember(val.search);
      }
    },
  });
  const { search, members } = values;

  const memberSuggestions = identities
    .filter(
      (i) => !organization?.identities?.map((m) => m.email).includes(i.email),
    )
    .map((i) => i.email);
  const formHasErrors = Object.keys(errors).length > 0;
  const disableFormSubmit = members.length === 0 || formHasErrors;
  const memberSuggestionHelper = `${search} invite to ${organization?.name}`;

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  function onMemberSelect(val: string) {
    setValues({
      search: "",
      members: members.includes(val)
        ? members.filter((m) => m !== val)
        : [...members, val],
    });
  }

  function onMemberAdd(val: string) {
    if (!new RegExp(regexEmail).test(search)) {
      setFieldError("search", "Must be a valid email address.");
      return;
    }
    if (members.includes(val)) {
      setFieldError("search", `Email ${val} is already added.`);
      return;
    }
    if (organization?.identities?.find((i) => i.email === val)) {
      setFieldError("search", `${val} is already part of this organization.`);
      return;
    }
    const membersNewTotal = [...members, val].length + currentSeats;
    if (membersNewTotal > seatLimit) {
      setFieldError(
        "search",
        "Seats limit reached. Upgrade your subscription to add more members.",
      );
      return;
    }

    setValues({
      search: "",
      members: [...members, val],
    });
  }

  function onMemberRemove(val: string) {
    setFieldValue(
      "members",
      members.filter((m) => m !== val),
    );
  }

  function resetFormAndCloseModal() {
    reset();
    close();
  }

  const memberValues = members.map((item) => (
    <Tooltip label={item} key={item}>
      <Pill withRemoveButton onRemove={() => onMemberRemove(item)} maw={160}>
        {item}
      </Pill>
    </Tooltip>
  ));

  const options = memberSuggestions.map((item) => {
    const isItemAdded = members.includes(item);

    return (
      <Combobox.Option value={item} key={item} active={members.includes(item)}>
        <Group gap="sm">
          {isItemAdded ? (
            <IconCheck color="green" size={18} />
          ) : (
            <IconUser size={18} />
          )}
          <Group>
            {item}
            {!isItemAdded && <IconPlus size={16} />}
          </Group>
        </Group>
      </Combobox.Option>
    );
  });

  async function onAddMembers(values: AddMemberFormFields) {
    combobox.closeDropdown();
    await saveIdentitiesAsync({
      organizationId: organization!.id,
      emails: values.members,
    });
    resetFormAndCloseModal();
    notifications.show({
      message: (
        <Text>
          An invite has been sent to the users to join{" "}
          <b>{organization?.name}</b> organization.
        </Text>
      ),
      color: "transparent",
      icon: <IconSend size={18} />,
    });
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={resetFormAndCloseModal}
        title={
          <Text>
            Invite members to <b>{organization?.name ?? "your organization"}</b>
          </Text>
        }
      >
        <form onSubmit={onSubmit(onAddMembers)}>
          <Stack>
            <Combobox store={combobox} onOptionSubmit={onMemberSelect}>
              <Combobox.DropdownTarget>
                <PillsInput
                  onClick={() => combobox.openDropdown()}
                  {...getInputProps("search")}
                >
                  <Pill.Group>
                    {memberValues}
                    <Combobox.EventsTarget>
                      <PillsInput.Field
                        data-autofocus
                        onFocus={() => combobox.openDropdown()}
                        onBlur={() => combobox.closeDropdown()}
                        value={search}
                        placeholder="Search by email"
                        onKeyDown={(event) => {
                          if (event.key === "Enter" && search.length > 0) {
                            event.preventDefault();
                            onMemberAdd(search);
                          }
                        }}
                        onChange={(event) => {
                          combobox.updateSelectedOptionIndex();
                          setFieldValue("search", event.currentTarget.value);
                        }}
                      />
                    </Combobox.EventsTarget>
                  </Pill.Group>
                </PillsInput>
              </Combobox.DropdownTarget>

              {!formHasErrors && search.length > 0 && (
                <Combobox.Dropdown>
                  <ScrollArea mah={200} scrollbars="y" component={Stack}>
                    <Combobox.Options>
                      {options.length > 0 ? (
                        options
                      ) : (
                        <Combobox.Empty
                          component={Button}
                          color="dark"
                          w="100%"
                          onClick={() => onMemberAdd(search)}
                        >
                          {isFetching ? (
                            <Loader size={18} />
                          ) : (
                            <Group>
                              <IconMail size={16} />
                              <Text
                                title={memberSuggestionHelper}
                                size="xs"
                                truncate="end"
                                maw={"75%"}
                              >
                                {memberSuggestionHelper}
                              </Text>
                              <IconPlus size={16} />
                            </Group>
                          )}
                        </Combobox.Empty>
                      )}
                    </Combobox.Options>
                  </ScrollArea>
                </Combobox.Dropdown>
              )}
            </Combobox>

            <Button
              type="submit"
              disabled={disableFormSubmit}
              loading={isLoadingSaveIdentities}
              rightSection={<IconSend size={18} />}
            >
              Invite
            </Button>
          </Stack>
        </form>
      </Modal>
      <Tooltip
        label={
          isSeatLimitReached
            ? "You've reached the seat limit. Please upgrade to add additional members."
            : "Add members to organization"
        }
      >
        <Button
          mt="md"
          onClick={open}
          disabled={isLoadingGetBilling || isSeatLimitReached}
        >
          Add members
        </Button>
      </Tooltip>
    </>
  );
}

interface ManageAccessFormFields {
  newRole?: IdentityRole;
}

function ManageAccessModal({
  opened,
  close,
  organization,
  member,
}: {
  opened: boolean;
  close: () => void;
  organization: Maybe<OrganizationProps>;
  member: Maybe<Identity>;
}) {
  const { updateIdentity, isLoadingUpdateIdentity } = useIdentityMutation();

  const currentUserRoleIdx = roleOptions.findIndex(
    (r) => r.value === organization?.currentUserIdentity?.primaryRole,
  );

  const isMeSingleAllowed = (authUserId?: string | null) => {
    const allowed =
      organization?.identities?.filter((m) => m.primaryRole === highestRole) ??
      [];
    return allowed.length === 1 && allowed[0].authUserId === authUserId;
  };
  const memberRole = roleOptions.find((r) => member?.primaryRole === r.value);

  const { getInputProps, values, onSubmit, reset, isValid } =
    useForm<ManageAccessFormFields>({
      clearInputErrorOnChange: true,
      validateInputOnChange: true,
      validateInputOnBlur: true,
      initialValues: {
        newRole: undefined,
      },
      validate: {
        newRole: (nr) =>
          nr === memberRole?.value
            ? "Must be different from the current role"
            : null,
      },
    });

  const { newRole } = values;
  const newRoleSelected = roleOptions.find((r) => newRole === r.value);

  // Only allow to change role to lower or equal access level to current user performing the action
  const filteredRoles = roleOptions.slice(
    currentUserRoleIdx,
    roleOptions.length,
  );
  const canUpdateRole = (r: (typeof roleOptions)[number]["value"]) =>
    filteredRoles.map((f) => f.value).includes(r);

  async function onSubmitUpdateAccess(values: ManageAccessFormFields) {
    if (!member?.id || !values.newRole) {
      showError(
        "An error occurred while updating the member access. Please try again later.",
      );
      return;
    }

    if (
      values.newRole === "client" &&
      !!organization?.identities?.find((i) => i.primaryRole === "client")
    ) {
      showError(
        `You can only have one member with the role "client" per organization.`,
      );
      return;
    }

    if (
      isMeSingleAllowed(organization?.currentUserIdentity?.authUserId) &&
      member.authUserId === organization?.currentUserIdentity?.authUserId &&
      newRole !== highestRole
    ) {
      showError(
        <Text size="md">
          You are the only <b>owner</b> of the organization. Please transfer
          your access to another member to enable this action.
        </Text>,
      );
      return;
    }

    updateIdentity({
      id: member.id,
      role: values.newRole,
    });
    resetFormAndCloseModal();
    notifications.show({
      message: (
        <Text>
          Member access updated successfully to <b>{newRole}</b> in{" "}
          <b>{organization?.name}</b> organization.
        </Text>
      ),
      color: "green",
      icon: <IconCheck size={18} />,
    });
  }

  function resetFormAndCloseModal() {
    reset();
    close();
  }

  return (
    <Modal
      opened={opened}
      onClose={resetFormAndCloseModal}
      size="xl"
      title={
        <Text size="lg" title={organization?.name} fw="bold">
          Manage member access
        </Text>
      }
    >
      <form onSubmit={onSubmit(onSubmitUpdateAccess)}>
        <Stack>
          <Group align="start">
            <Stack flex={1}>
              <Code w="fit-content">{member?.email}</Code>
              <RadioGroup
                component={Stack}
                label="Select team member role"
                defaultValue={memberRole?.value}
                {...getInputProps("newRole")}
              >
                <Stack gap="xs">
                  {roleOptions.map((r) =>
                    !canUpdateRole(r.value) ? (
                      <Tooltip
                        label="You cannot upgrade the role to a higher access level than your own."
                        key={r.value}
                      >
                        <Radio disabled label={r.label} value={r.value} />
                      </Tooltip>
                    ) : (
                      <Radio
                        key={r.value}
                        label={
                          <Text size="sm" component={Group} gap="xs">
                            {r.label}
                            {r.value === memberRole?.value && (
                              <Badge size="xs" variant="light">
                                current
                              </Badge>
                            )}
                          </Text>
                        }
                        value={r.value}
                      />
                    ),
                  )}
                </Stack>
              </RadioGroup>
            </Stack>
            <Stack flex={2}>
              <Title order={5} fw="bold">
                Role description
              </Title>
              <Group wrap="nowrap" align="center" gap={0}>
                <IconInfoSmall size={22} />
                <Text size="xs">
                  The team member will get its permissions updated by this role.
                </Text>
              </Group>
              <Divider />
              <Stack gap="xs">
                <Text size="sm" fw="bold">
                  {newRoleSelected?.label ?? memberRole?.label}
                </Text>
                <Text size="sm">
                  {newRoleSelected?.description ?? memberRole?.description}
                </Text>
              </Stack>
            </Stack>
          </Group>
          <Group gap="sm" justify="center">
            <Button
              size="sm"
              onClick={resetFormAndCloseModal}
              variant="transparent"
            >
              Cancel
            </Button>
            <Button
              size="sm"
              color="red"
              variant="light"
              loading={isLoadingUpdateIdentity}
              disabled={!isValid() || !values.newRole}
              type="submit"
            >
              Update access
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}

function ConfirmDeleteMemberModal({
  member,
  opened,
  close,
}: {
  member: Identity;
  opened: boolean;
  close: () => void;
}) {
  const { deleteIdentity, isDeletingIdentity } = useIdentityMutation();
  const { user } = useAuthContext();

  const isMe = useCallback(
    (authUserId: string | null | undefined) => user.id === authUserId,
    [user.id],
  );

  function onConfirm() {
    deleteIdentity(
      {
        id: member.id,
      },
      {
        onSuccess() {
          notifications.show({
            color: "green",
            icon: <IconCheck size={18} />,
            message: `${member.email} was removed from your organization`,
          });
        },
        onError() {
          notifications.show({
            color: "red",
            icon: <IconBug size={18} />,
            message: `Failed to delete ${member.email} from your organization. Please retry later.`,
          });
        },
      },
    );
    close();
  }

  return (
    <Modal
      opened={opened}
      onClose={close}
      autoFocus
      size="md"
      title={
        <Group gap="sm">
          <IconTrash size={18} />
          {isMe(member.authUserId) ? "Leave Organization" : "Delete Member"}
        </Group>
      }
    >
      <Stack>
        <Text size="sm" c="dimmed">
          {isMe(member.authUserId) ? (
            <>Are you sure you want to leave this organization?</>
          ) : (
            <>
              Are you sure you want to delete <b>{member.email}</b> member from
              your organization?
            </>
          )}
        </Text>
        <Group gap="sm" justify="center">
          <Button size="sm" onClick={close} variant="transparent">
            Cancel
          </Button>
          <Button size="sm" disabled={isDeletingIdentity} onClick={onConfirm}>
            Confirm
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}

function TeamSkeleton() {
  return (
    <Stack align="flex-start" mt="sm">
      <Skeleton w={130} h={35} radius="md" />
      <Card radius="sm" w="100%" shadow="none">
        <Stack>
          <Group justify="space-between">
            <Group>
              <Skeleton w={200} h={35} radius="md" />
              <Skeleton w={60} h={35} radius="md" />
            </Group>
            <Group>
              <Skeleton w={100} h={35} radius="md" mr={120} />
              <Group gap={4}>
                <Skeleton w={4} h={4} radius="xl" />
                <Skeleton w={4} h={4} radius="xl" />
                <Skeleton w={4} h={4} radius="xl" />
              </Group>
            </Group>
          </Group>
          <Divider my="xs" />
          <Group justify="space-between">
            <Group>
              <Skeleton w={180} h={35} radius="md" />
            </Group>
            <Group>
              <Skeleton w={120} h={35} radius="md" mr={120} />
              <Group gap={4}>
                <Skeleton w={4} h={4} radius="xl" />
                <Skeleton w={4} h={4} radius="xl" />
                <Skeleton w={4} h={4} radius="xl" />
              </Group>
            </Group>
          </Group>
        </Stack>
      </Card>
    </Stack>
  );
}

export function TeamSubSection() {
  const { user, identity } = useAuthContext();
  const { data: organization, isLoading: isLoadingOrganization } =
    useOrganizationById(identity.organizationId);
  const [
    openedManageAccess,
    { open: openManageAccess, close: closeManageAccess },
  ] = useDisclosure(false);
  const [
    openedDeleteMember,
    { open: openDeleteMember, close: closeDeleteMember },
  ] = useDisclosure(false);
  const [memberSelected, setIdentitySelected] = useState<Identity>();

  const members = useMemo(
    () => organization?.identities ?? [],
    [organization?.identities],
  );
  const hasMembers = members.length > 0;
  const isLastMember = organization?.identities.length === 1;
  const allowedUsers = organization?.identities.filter(
    (i) => i.primaryRole === highestRole,
  );
  const isOnlyOwner = (member: Identity) =>
    allowedUsers?.length === 1 &&
    allowedUsers[0].authUserId === member.authUserId;

  const isMe = useCallback(
    (authUserId: string | null | undefined) => user.id === authUserId,
    [user.id],
  );
  const getMember = useCallback(
    (authUserId: string | null) =>
      members.find((m) => m.authUserId === authUserId),
    [members],
  );

  function onClickDeleteMember(member: Identity) {
    if (isOnlyOwner(member)) {
      showError(
        <Text size="md">
          You are the only <b>owner</b> of the organization. Please transfer
          your access to another member to enable this action.
        </Text>,
      );
      return;
    }

    setIdentitySelected(member);
    openDeleteMember();
  }

  return (
    <Stack>
      <Fieldset legend="Team" pos="relative">
        <RestrictedFeature>
          {({ restrictedClassName }) => (
            <Stack gap="sm" className={restrictedClassName}>
              <Input.Wrapper
                label={`Your team${organization?.name ? ` at ${organization.name}` : ""}`}
                description={
                  <>
                    You can increase the seats limit in your{" "}
                    <Anchor size="xs" component={Link} to="/account/billing">
                      Billing Details
                    </Anchor>
                    .
                  </>
                }
              >
                {isLoadingOrganization ? (
                  <TeamSkeleton />
                ) : (
                  <>
                    {hasMembers ? (
                      <Stack align="flex-start">
                        <Can
                          execute="identity.create"
                          userIdentity={getMember(user.id)}
                        >
                          <AddMemberModal organization={organization} />
                        </Can>
                        <ManageAccessModal
                          opened={openedManageAccess}
                          close={closeManageAccess}
                          member={memberSelected}
                          organization={organization}
                        />
                        {memberSelected && (
                          <ConfirmDeleteMemberModal
                            member={memberSelected}
                            opened={openedDeleteMember}
                            close={closeDeleteMember}
                          />
                        )}
                        <Stack w="100%" gap={0}>
                          <Paper
                            bg="rgba(0, 0, 0, .08)"
                            radius="sm"
                            p="md"
                            className={classes.tableHead}
                          >
                            <Group>
                              <IconUsers size={18} />
                              <Text fw="bold">{members.length} members</Text>
                            </Group>
                          </Paper>
                          <Table
                            withTableBorder
                            verticalSpacing="md"
                            horizontalSpacing="sm"
                            mb="lg"
                          >
                            <Table.Tbody>
                              {members.map((m, idx) => (
                                <Table.Tr key={`${m.email}-${idx}`}>
                                  <Table.Td>
                                    <Group>
                                      <Text
                                        title={m.email}
                                        variant="light"
                                        truncate
                                        maw={{ base: 115, xs: "100%" }}
                                      >
                                        {m.email}
                                      </Text>
                                      {isMe(m.authUserId) && (
                                        <Badge
                                          tt="none"
                                          variant="light"
                                          color="green"
                                        >
                                          It's you
                                        </Badge>
                                      )}
                                    </Group>
                                  </Table.Td>
                                  <Table.Td align="right">
                                    <Tooltip label="Member role">
                                      <Text c="gray.6">{m.primaryRole}</Text>
                                    </Tooltip>
                                  </Table.Td>
                                  <Can
                                    execute="identity.update.profile-advanced"
                                    userIdentity={getMember(user.id)}
                                  >
                                    <Table.Td align="right">
                                      <Menu shadow="md">
                                        <Menu.Target>
                                          <Button variant="transparent">
                                            <IconDots size={28} />
                                          </Button>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                          <Menu.Item
                                            onClick={() => {
                                              setIdentitySelected(m);
                                              openManageAccess();
                                            }}
                                            disabled={
                                              !isRoleHigherOrEqual(
                                                organization
                                                  ?.currentUserIdentity
                                                  ?.primaryRole,
                                                m.primaryRole,
                                              )
                                            }
                                          >
                                            Manage access...
                                          </Menu.Item>
                                          <Can execute="identity.delete">
                                            <Menu.Item
                                              color="red"
                                              onClick={() =>
                                                onClickDeleteMember(m)
                                              }
                                              disabled={
                                                isLastMember ||
                                                !isRoleHigherOrEqual(
                                                  organization
                                                    ?.currentUserIdentity
                                                    ?.primaryRole,
                                                  m.primaryRole,
                                                )
                                              }
                                            >
                                              {isMe(m.authUserId)
                                                ? "Leave"
                                                : "Delete member"}
                                            </Menu.Item>
                                          </Can>
                                        </Menu.Dropdown>
                                      </Menu>
                                    </Table.Td>
                                  </Can>
                                </Table.Tr>
                              ))}
                            </Table.Tbody>
                          </Table>
                        </Stack>
                      </Stack>
                    ) : (
                      <Text ta="center" p="sm">
                        There are no members yet in this organization.
                      </Text>
                    )}
                  </>
                )}
              </Input.Wrapper>
            </Stack>
          )}
        </RestrictedFeature>
      </Fieldset>
    </Stack>
  );
}
