import classes from "./StackIcons.module.css";

export function ConsultingIcon() {
  return (
    <svg
      width="205"
      height="230"
      viewBox="0 0 205 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M102.378 114.958L85.3337 105.117L102.378 95.2767L119.423 105.118L102.378 114.958Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.245 60.5591C54.6757 63.7745 51.2449 69.7168 51.2449 76.1476L51.2449 114.407C51.2449 120.838 54.6757 126.781 60.2449 129.996L68.2892 134.64V154.322L85.3338 164.162L85.3337 183.844L93.3785 188.489C98.9477 191.704 105.809 191.704 111.378 188.489L119.423 183.844L119.424 164.163L136.468 154.322L136.468 134.641L144.513 129.996C150.082 126.781 153.513 120.838 153.513 114.408L153.512 76.1482C153.513 69.7171 150.082 63.7745 144.512 60.5589L111.378 41.4296C105.809 38.2146 98.9477 38.2148 93.3787 41.43L60.245 60.5591ZM119.423 124.8L119.423 144.481L102.378 154.322L85.334 144.481L85.3337 124.841L102.378 134.681L119.423 124.842L102.378 115L85.3337 124.841L85.3338 124.799L68.2897 114.959L68.2886 75.5966L102.378 55.9145L136.467 75.5963L136.467 114.959L119.423 124.8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.536 154.363L204.537 69.8125C204.537 61.2382 199.963 53.3151 192.537 49.0279L114.269 3.83957C106.843 -0.447512 97.6945 -0.4475 90.269 3.8396L17.0448 46.1144C6.49753 52.2038 2.01768e-05 63.4576 0 75.6366L4.77098e-05 160.188C6.84045e-05 168.762 4.57443 176.685 12 180.972L90.2681 226.16C97.6937 230.448 106.842 230.448 114.268 226.16L187.491 183.885C198.038 177.795 204.536 166.542 204.536 154.363ZM98.2688 18.9023L21.0448 63.4865C18.5695 64.9155 17.0447 67.5566 17.0447 70.4147L17.0448 159.585C17.0448 162.443 18.5695 165.084 21.0447 166.513L98.2683 211.098C100.743 212.527 103.793 212.527 106.268 211.098L183.491 166.513C185.966 165.084 187.491 162.443 187.491 159.585L187.492 70.4156C187.492 67.5575 185.967 64.9164 183.492 63.4873L106.269 18.9023C103.794 17.4733 100.744 17.4733 98.2688 18.9023Z"
        fill="currentColor"
      />
    </svg>
  );
}
