import classes from "./StackIcons.module.css";

export function MembershipIcon() {
  return (
    <svg
      width="206"
      height="228"
      viewBox="0 0 206 228"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <g clipPath="url(#clip0_1444_22260)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M192.43 48.3302L115.06 3.26021C107.66 -1.04979 98.51 -1.08979 91.07 3.16021L12.09 48.2902C4.62 52.5702 0 60.5202 0 69.1302V159.16C0 167.81 4.65 175.78 12.17 180.04L90.95 224.65C98.46 228.9 107.68 228.8 115.09 224.37L193.4 177.58C200.71 173.21 205.16 165.29 205.09 156.78L204.35 68.8602C204.28 60.3902 199.75 52.5902 192.43 48.3202V48.3302ZM101.58 211.18L18.22 163.47C17.29 162.94 16.71 161.94 16.71 160.87V66.6302C16.71 65.5502 17.29 64.5602 18.22 64.0302L101.77 16.1202C102.71 15.5802 103.86 15.5902 104.79 16.1402L186.15 64.4802C187.05 65.0202 187.61 65.9902 187.62 67.0402L188.32 159.33C188.32 160.39 187.77 161.38 186.86 161.93L104.6 211.15C103.67 211.71 102.51 211.72 101.57 211.18H101.58ZM97.27 58.78L23.51 101.41C19.79 103.56 17.51 107.53 17.51 111.82V118.01C17.53 122.38 19.91 126.41 23.75 128.51L97.32 168.89C100.99 170.91 105.46 170.86 109.09 168.77L181.43 127.07C185.18 124.91 187.47 120.9 187.44 116.57L187.4 111.52C187.36 107.31 185.12 103.42 181.49 101.28L109.38 58.83C105.65 56.64 101.03 56.62 97.29 58.78H97.27ZM67.7162 133.008L34.88 114.91L103.66 75.64L136.785 94.1049L67.7162 133.008ZM86.8019 143.528L103.28 152.61L172.01 113.74L155.401 104.482L86.8019 143.528Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1444_22260">
          <rect width="205.09" height="227.78" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
