export const plans = [
  {
    title: "Basic",
    description: `Best for solopreneurs or freelancers working on single projects`,
    items: {
      month: {
        price: "99.00",
        seats: 1,
        seatPrice: "79.00",
        subtitle: `What's Included:`,
        features: [
          `Single-seat access to NOAN's Build & Create modes`,
          `Unlimited AI generations`,
          `Use OpenAI, Anthropic, Perplexity AI to create strategy`,
          `Customer support`,
          `Add seats & orgs at will`,
          `Invite users from outside your org with Client Mode`,
          `Cancel any time`,
        ],
      },
      year: {
        price: "999.00",
        seats: 1,
        seatPrice: "799.00",
        subtitle: `What's Included:`,
        features: [
          `Single-seat access to NOAN's Build & Create modes`,
          `Unlimited AI generations`,
          `Use OpenAI, Anthropic, Perplexity AI to create strategy`,
          `Customer support`,
          `Add seats & orgs at will`,
          `Invite users from outside your org with Client Mode`,
          `Cancel any time`,
        ],
      },
    },
  },
  {
    title: "Scale",
    description: `Best for teams where multiple users collaborate, and agencies who work with clients`,
    topBadge: "Best Deal",
    items: {
      month: {
        price: "199.00",
        seats: 3,
        seatPrice: "79.00",
        subtitle: "What's Included:",
        features: [
          `Three-seat access per organization`,
          `Add/remove seats, adjust permission levels at will`,
          `Add additional orgs with ease`,
          `Unlimited AI generations`,
          `Invite users from outside your org with Client Mode`,
          `Cancel any time`,
        ],
      },
      year: {
        price: "1999.00",
        seats: 3,
        seatPrice: "799.00",
        subtitle: "What's Included:",
        features: [
          `Three-seat access per organization`,
          `Add/remove seats, adjust permission levels at will`,
          `Add additional orgs with ease`,
          `Unlimited AI generations`,
          `Invite users from outside your org with Client Mode`,
          `Cancel any time`,
        ],
      },
    },
  },
];
