import {
  ActionIcon,
  Anchor,
  AppShell,
  Avatar,
  Badge,
  Box,
  Burger,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Group,
  Indicator,
  Loader,
  Menu,
  ScrollArea,
  ScrollAreaAutosize,
  Skeleton,
  Spoiler,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Title,
  Tooltip,
  getGradient,
  useComputedColorScheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useLocalStorage } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { spotlight } from "@mantine/spotlight";
import * as Sentry from "@sentry/react";
import {
  IconCategory,
  IconChevronLeft,
  IconChevronRight,
  IconHelp,
  IconLayoutSidebarLeftCollapseFilled,
  IconLayoutSidebarLeftExpandFilled,
  IconLogin2,
  IconLogout,
  IconMenu2,
  IconMoon,
  IconPresentationAnalytics,
  IconSchool,
  IconSearch,
  IconSettings,
  IconStack2,
  IconSun,
  IconSwitchHorizontal,
  IconUser,
  IconUsersGroup,
} from "@tabler/icons-react";
import { FeatureFlag } from "api/src/featureFlags";
import { IdentityRole } from "api/src/models/Identity";
import clsx from "clsx";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import {
  Link,
  Navigate,
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";
import { useAuthContext, useOptionalAuthContext } from "../auth/useAuthContext";
import { useIdentity, useIsRole } from "../auth/useIdentity";
import { FirstTimeVideoModal } from "../components/FirstTimeVideo";
import { FullPageLoader } from "../components/FullPageLoader";
import { GlobalSearch } from "../components/GlobalSearch";
import { NavLink } from "../components/NavLink";
import { NoanLogo } from "../components/icons/NoanLogo.svg";
import { NoanLogoHexagon } from "../components/icons/NoanLogoHexagon.svg";
import { isDeployed } from "../config";
import { NoanError, isNoanError } from "../utils/errors";
import { useIsFeatureEnabled } from "../utils/useIsFeatureEnabled";
import { useMetaKey } from "../utils/useMetaKey";
import { useOrganizationMutation } from "../utils/useOrganizationMutation";
import { useOrganizations } from "../utils/useOrganizations";
import { ZedIndex } from "../utils/zedIndex";
import classes from "./Layout.module.css";
import {
  navigationHeaderHeights,
  topHeaderHeights,
  type LayoutVariant,
} from "./layoutConfig";

interface NavigationLink {
  to: string;
  label: string;
  requiresSession?: boolean;
  hideIfLoggedIn?: boolean;
  hidden?: boolean;
  checkAccess?: () => boolean;
  strictPath?: boolean;
}

function isSignupPage(pathname: string) {
  return pathname === "/join";
}

function isSubscribePage(pathname: string) {
  return pathname === "/subscribe";
}

function isLoginPage(pathname: string) {
  return pathname === "/login";
}

function isIntelPage(pathname: string) {
  return pathname.startsWith("/intel");
}

function UserMenu({
  hideAccountInfo,
  hideOrganizations,
  variant = "default",
}: {
  hideAccountInfo: boolean;
  hideOrganizations?: boolean;
  variant?: "sidebar" | "default";
}) {
  const { signOut, user } = useOptionalAuthContext();
  const { identity } = useIdentity();
  const { data: organizations } = useOrganizations();
  const { switchOrganizationAsync, isLoadingSwitchOrganization } =
    useOrganizationMutation({
      onSettledSwitchOrg: () => {
        navigate("/");
      },
    });
  const navigate = useNavigate();
  const { setColorScheme } = useMantineColorScheme();

  const computedColorScheme = useComputedColorScheme("light", {
    getInitialValueInEffect: true,
  });
  const isClient = useIsRole(IdentityRole.Client);

  async function switchOrganization(orgId: string) {
    await switchOrganizationAsync({ id: orgId });

    const organization = organizations.find((o) => o.id === orgId);
    notifications.show({
      message: `Connected to organization ${organization?.name}`,
      color: "green",
      icon: <IconSwitchHorizontal size={18} />,
    });
  }

  const loggedInOrganization = organizations.find(
    (o) => identity && o.id === identity?.organizationId,
  );
  const otherOrganizations = organizations.filter(
    (o) => identity && o.id !== identity?.organizationId,
  );

  return (
    <>
      {!hideAccountInfo && (
        <Menu shadow="sm" position="bottom-end">
          <Menu.Target>
            <Button
              color={variant === "sidebar" ? "dark" : undefined}
              size={variant === "sidebar" ? "sm" : undefined}
              variant="subtle"
              leftSection={
                <ThemeIcon
                  variant="transparent"
                  color={variant === "sidebar" ? "text" : undefined}
                  size={"sm"}
                >
                  <IconUser />
                </ThemeIcon>
              }
              classNames={{
                root: classes.accountButton,
                section: classes.accountButtonSection,
                label: classes.accountButtonLabel,
              }}
            >
              Account
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {!hideOrganizations && (
              <>
                <Menu.Label tt="uppercase" fw="bold">
                  {organizations.length === 1
                    ? "Connected to"
                    : "Use NOAN as..."}
                </Menu.Label>
                <ScrollAreaAutosize mah={500}>
                  <Spoiler
                    hideLabel="Show less"
                    showLabel="Show more"
                    styles={{
                      control: {
                        marginLeft: 12,
                        fontSize: 12,
                      },
                    }}
                    maxHeight={200}
                  >
                    {!!loggedInOrganization && (
                      <Menu.Item
                        disabled
                        rightSection={
                          <Badge size="xs" variant="light">
                            Logged in
                          </Badge>
                        }
                      >
                        <Tooltip label={loggedInOrganization.name}>
                          <Text truncate="end" w="150" size="sm">
                            {loggedInOrganization.name}
                          </Text>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {otherOrganizations.map((o) => (
                      <Menu.Item
                        key={o.id}
                        disabled={isLoadingSwitchOrganization}
                        onClick={() => {
                          switchOrganization(o.id);
                        }}
                      >
                        <Tooltip label={o.name}>
                          <Text truncate="end" w="175" size="sm">
                            {o.name}
                          </Text>
                        </Tooltip>
                      </Menu.Item>
                    ))}
                  </Spoiler>
                </ScrollAreaAutosize>

                <Divider my={4} />
              </>
            )}

            {!isClient && (
              <Menu.Item
                component={Link}
                leftSection={
                  <ThemeIcon variant="transparent" color="dark" size="xs">
                    <IconSettings />
                  </ThemeIcon>
                }
                to="/account"
              >
                Settings
              </Menu.Item>
            )}

            <Menu.Item
              onClick={() =>
                setColorScheme(
                  computedColorScheme === "light" ? "dark" : "light",
                )
              }
              leftSection={
                <ThemeIcon variant="transparent" color="dark" size="xs">
                  {computedColorScheme === "light" ? <IconMoon /> : <IconSun />}
                </ThemeIcon>
              }
            >
              {computedColorScheme === "light" ? "Dark" : "Light"} mode
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                window.tidioChatApi.display(true);
                window.tidioChatApi.open();
              }}
              leftSection={
                <ThemeIcon variant="transparent" color="dark" size="xs">
                  <IconHelp />
                </ThemeIcon>
              }
            >
              Livechat support
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              color="red.4"
              leftSection={
                <ThemeIcon variant="transparent" size="xs" color="red.4">
                  <IconLogout />
                </ThemeIcon>
              }
              onClick={signOut}
            >
              Sign Out
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      )}
      {hideAccountInfo && user != null && (
        <Button
          variant="subtle"
          leftSection={<IconLogout size={18} />}
          onClick={signOut}
        >
          Sign Out
        </Button>
      )}
    </>
  );
}

function DefaultNavigation() {
  const theme = useMantineTheme();
  const { pathname } = useLocation();
  const metaKey = useMetaKey();
  const hasInsightsAccess = !!useIsFeatureEnabled("insights");

  const { user } = useOptionalAuthContext();
  const isClient = useIsRole(IdentityRole.Client);

  const navigationLinks: NavigationLink[] = [
    {
      to: "/create",
      label: "Create",
      requiresSession: true,
      strictPath: false,
      checkAccess: () => !isClient,
    },
    {
      to: "/build",
      label: "Build",
      requiresSession: true,
      checkAccess: () => !isClient,
    },
    {
      to: "/insights",
      label: "Insights",
      requiresSession: true,
      checkAccess: () => !isClient && hasInsightsAccess,
    },
  ];

  const hideAccountInfoForRoute =
    user == null || isSignupPage(pathname) || isSubscribePage(pathname);

  const renderNavigationLinks = ({ menuItem }: { menuItem?: boolean }) => {
    return navigationLinks
      .map(
        ({
          to,
          label,
          requiresSession,
          hideIfLoggedIn,
          hidden,
          checkAccess,
          strictPath,
        }) => {
          if (hidden) return null;

          const isActive = strictPath
            ? location.pathname === to
            : location.pathname.startsWith(to);

          if (requiresSession === true && hideAccountInfoForRoute) {
            return null;
          }

          if (hideIfLoggedIn && user != null) {
            return null;
          }

          if (checkAccess && !checkAccess()) {
            return null;
          }

          const itemComponent = (
            <Anchor
              key={to}
              to={to}
              component={Link}
              fw={isActive ? 600 : undefined}
              c={isActive ? "currentColor" : undefined}
              underline={menuItem ? "never" : "hover"}
            >
              {label}
            </Anchor>
          );

          return menuItem ? (
            <Menu.Item key={to} px="xl">
              {itemComponent}
            </Menu.Item>
          ) : (
            itemComponent
          );
        },
      )
      .filter((link) => link != null);
  };

  const inlineNavigationLinks = renderNavigationLinks({ menuItem: false });
  const mobileNavigationLinks = renderNavigationLinks({ menuItem: true });

  return (
    <Box role="navigation" h={navigationHeaderHeights.default}>
      <Flex
        style={{
          borderBottom: "1px solid var(--mantine-color-default-border)",
          backgroundColor: "var(--mantine-color-body)",
        }}
        p={{ base: "sm" }}
        h={{ base: "auto", sm: 80 }}
        direction="row"
      >
        <Group flex={1}>
          <Group flex={1}>
            {!isClient && (
              <Link to="/">
                <Center px={{ base: 0, md: "md" }} visibleFrom="sm">
                  <NoanLogo />
                </Center>
                <Center px={{ base: 0, md: "md" }} hiddenFrom="sm">
                  <NoanLogoHexagon />
                </Center>
              </Link>
            )}
            {mobileNavigationLinks.length > 0 && (
              <Group hiddenFrom="sm">
                <Menu>
                  <Menu.Target>
                    <ActionIcon variant="subtle">
                      <IconMenu2 size={24} />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>{mobileNavigationLinks}</Menu.Dropdown>
                </Menu>
              </Group>
            )}

            <Group gap="lg" ml={"lg"} preventGrowOverflow visibleFrom="sm">
              {inlineNavigationLinks}
            </Group>
          </Group>

          <Group>
            {!isClient && (
              <>
                <ActionIcon
                  hiddenFrom="lg"
                  onClick={spotlight.open}
                  size="md"
                  variant="subtle"
                  color="gray.6"
                >
                  <IconSearch size={20} />
                </ActionIcon>

                <TextInput
                  visibleFrom="lg"
                  onClick={spotlight.open}
                  readOnly
                  placeholder={`Search (${metaKey}+K)`}
                  leftSection={<IconSearch size={18} />}
                  size="sm"
                />
              </>
            )}

            {user == null && !isLoginPage(pathname) && (
              <Button
                to="/login"
                component={Link}
                variant="light"
                size="sm"
                leftSection={<IconLogin2 size={18} />}
              >
                Login
              </Button>
            )}

            <UserMenu hideAccountInfo={hideAccountInfoForRoute} />
          </Group>
        </Group>
      </Flex>
      {isIntelPage(pathname) && (
        <Box
          bg={getGradient(
            {
              from: "black",
              deg: 35,
              to: "dark.8",
            },
            theme,
          )}
          px="xl"
          py="md"
        >
          <Group>
            <IconCategory size={32} />
            <Anchor c="inherit" component={Link} to="/intel" underline="never">
              <Title order={3} fw="bold" c="white">
                THE INTEL
              </Title>
            </Anchor>
            <Text size="xs" ml={{ md: "lg" }} visibleFrom="md" c="white">
              NOAN's exclusive strategy zine, curated by the NOAN team.
            </Text>
          </Group>
        </Box>
      )}
    </Box>
  );
}

function InsightsNavigation() {
  return (
    <Group
      w="100%"
      bg="black"
      px="xl"
      h={navigationHeaderHeights.insights}
      align="center"
    >
      <Flex direction="row" w="100%">
        <Center w={10} mr="md">
          <Tooltip label="Return to NOAN" position="bottom-end" offset={30}>
            <ActionIcon
              h="100%"
              w="100%"
              component={Link}
              to="/"
              variant="outline"
            >
              <IconChevronLeft />
            </ActionIcon>
          </Tooltip>
        </Center>
        <Group gap="sm" align="center" flex="1">
          <Group align="center" gap={10}>
            <NoanLogoHexagon />
            <Indicator
              label="beta"
              size="sm"
              fw="bold"
              position="bottom-end"
              offset={2}
              color="blue.7"
            >
              <Title
                c="white"
                mt={5} // Added to visually align the text a bit better
                className="insights-font"
                fw={700}
              >
                INSIGHTS
              </Title>
            </Indicator>
          </Group>
        </Group>
        <Box>
          <UserMenu hideAccountInfo={false} />
        </Box>
      </Flex>
    </Group>
  );
}

function PreviewBanner() {
  return (
    <Container bg="orange.4" fluid>
      <Group py={6}>
        <Text size="xs" fw="bold" c="white">
          NOAN Preview
        </Text>
        <Text size="xs" c="white">
          You're using a limited preview of NOAN.{" "}
          <Anchor component={Link} to={"/subscribe"} c="yellow.1" fw="bold">
            Subscribe
          </Anchor>{" "}
          today to unlock all features.
        </Text>
      </Group>
    </Container>
  );
}

function OrganizationSwitcherItem({
  active = false,
  name,
  onSelectOrganization,
  rightSection,
}: {
  active?: boolean;
  name?: string;
  onSelectOrganization?: () => void;
  rightSection?: JSX.Element;
}) {
  return (
    <Group
      flex={1}
      gap="xs"
      onClick={onSelectOrganization}
      w="100%"
      className={clsx(active && classes.sidebarOrgSwitcherItemActive)}
    >
      {/* Set the icon color based on org name initials, if looking at the list of orgs */}
      <Avatar
        variant={active ? "filled" : "light"}
        color={active ? "orange" : "dimmed"}
        name={name}
      >
        <IconUsersGroup />
      </Avatar>

      <Group className={classes.sidebarOrgSwitcherItemLabelAction} flex={1}>
        <Box size="md" flex={rightSection ? 1 : undefined} pl="xs">
          {name || <Skeleton w={120} h={25} animate />}
        </Box>
        {rightSection}
      </Group>
    </Group>
  );
}

export function AuthedLayout({ variant }: { variant?: LayoutVariant }) {
  const posthog = usePostHog();
  const {
    user,
    signOut,
    isLoadingUser,
    authError,
    jwtBearerToken,
    mustSetPassword,
    hasThirdPartyProvider,
    mustCompletePostSignup,
  } = useOptionalAuthContext();
  const { pathname } = useLocation();
  const {
    identity,
    isActive,
    isCustomer,
    isPaying,
    isLoading: isLoadingIdentity,
    hasInsightsAccess,
    isError: identityError,
  } = useIdentity();
  const showPreviewBanner =
    !isLoadingIdentity && !isActive && !isSubscribePage(pathname);

  /**
   * Once we have user information, update third-party tools:
   */
  useEffect(() => {
    if (isDeployed && user != null) {
      try {
        if (window.tidioChatApi) {
          window.tidioChatApi.setVisitorData({
            distinct_id: user.id,
            email: user.email,
          });
        }

        posthog.identify(`${user.email}#${user.id.slice(0, 6)}`, {
          email: user.email,
          id: user.id,
        });

        // Sentry may be fully unavailable in some conditions, e.g if there's an
        // ad blocker preventing it from loading:
        Sentry.setUser({
          email: user.email,
          id: user.id,
          username: user.email,
        });
      } catch (err) {
        console.warn("Error while identifying user");
        console.error(err);
      }
    }
  }, [user, posthog]);

  if (identityError) {
    signOut();
    return null;
  }

  if (mustCompletePostSignup && pathname !== "/join") {
    // User must complete post-signup tasks
    return <Navigate to="/join" />;
  } else if (authError != null) {
    const errorType =
      isNoanError(authError) && authError.errorType === NoanError.NotLoggedIn
        ? "no-auth"
        : "auth-error";

    // Unable to fetch auth for some reason - might be missing user.
    return <Navigate to={`/login?reason=${errorType}`} />;
  } else if (isLoadingUser || isLoadingIdentity) {
    // Is loading user or identity data, and there's no cached identity data available
    return <FullPageLoader />;
  } else if (
    (user == null && !isLoadingUser) ||
    (identity == null && !isLoadingIdentity)
  ) {
    return <Navigate to="/login?reason=no-auth" />;
  }

  return (
    <AuthContext.Provider
      value={{
        jwtBearerToken,
        user: user!,
        identity: identity!,
        isAccountActive: isActive,
        isCustomer,
        isPayingCustomer: isPaying,
        isLoadingIdentity,
        isLoadingUser,
        authError,
        mustSetPassword,
        hasThirdPartyProvider,
        mustCompletePostSignup,
        signOut,
        hasInsightsAccess,
      }}
    >
      <Layout
        variant={variant}
        topHeader={showPreviewBanner ? <PreviewBanner /> : undefined}
      />
    </AuthContext.Provider>
  );
}

export function InsightsLayout() {
  const { hasInsightsAccess, isLoading, identity } = useIdentity();

  if (isLoading || !identity) {
    return <FullPageLoader />;
  }

  if (!hasInsightsAccess) {
    return <Navigate to="/" />;
  }

  return <AuthedLayout variant="insights" />;
}

interface LayoutProps {
  variant?: LayoutVariant;
  topHeader?: JSX.Element;
  withPadding?: boolean;
}

function SidebarLayout() {
  const navigate = useNavigate();
  const location = useLocation();

  const [
    onboardingVideoOpen,
    { open: openOnboardingVideo, close: closeOnboardingVideo },
  ] = useDisclosure();

  /**
   * Collapse/hide navigation + mobile behavior
   */
  const [navOpened, { toggle: toggleNav, close: closeNav }] = useDisclosure();
  const [navCollapsed, setNavCollapsed] = useLocalStorage({
    key: "noan-navbar-collapsed",
    defaultValue: false,
    getInitialValueInEffect: false,
  });

  /**
   * Account information + org switcher
   */
  const isClient = useIsRole(IdentityRole.Client);
  const { identity } = useAuthContext();
  const { data: organizations } = useOrganizations();
  const { switchOrganizationAsync, isLoadingSwitchOrganization } =
    useOrganizationMutation({
      onSettledSwitchOrg: () => {
        navigate("/");
      },
    });

  const activeOrganization = organizations.find(
    (o) => o.id === identity.organizationId,
  );
  const availableOrganizations = organizations.filter(
    (o) => o.id !== identity.organizationId,
  );
  const hasMultipleOrganizations =
    !isClient && availableOrganizations.length > 0;

  /**
   * Ensure the mobile nav gets toggled off when navigating elsewhere. This is important
   * if we're on mobile, otherwise the nav is over the content and you can't tell
   * we've navigated elsewhere
   */
  useEffect(() => {
    closeNav();
  }, [location, closeNav]);

  return (
    <AppShell
      layout="default"
      header={{ height: { base: 60, sm: 0 } }}
      navbar={{
        width: { base: navCollapsed ? 100 : 256 },
        breakpoint: "sm",
        collapsed: { mobile: !navOpened },
      }}
      padding={{
        base: 0,
        sm: 10,
      }}
      classNames={{
        root: classes.appShell,
      }}
      pr={0}
    >
      <AppShell.Navbar
        className={classes.sidebarNavbar}
        zIndex={navOpened ? ZedIndex.Sidebar : undefined}
      >
        {/* Desktop Logo */}
        <AppShell.Section>
          <Stack
            align="center"
            gap="md"
            justify="space-between"
            py="lg"
            px="sm"
            className={classes.sidebarLayoutLogo}
            visibleFrom="sm"
          >
            <NoanLogo />
          </Stack>

          <Group mb="sm" className={classes.sidebarExpandButton}>
            <ActionIcon
              variant="subtle"
              color="dark"
              onClick={() => setNavCollapsed(!navCollapsed)}
              size="lg"
            >
              {navCollapsed ? (
                <IconLayoutSidebarLeftExpandFilled />
              ) : (
                <IconLayoutSidebarLeftCollapseFilled />
              )}
            </ActionIcon>
          </Group>
        </AppShell.Section>

        <AppShell.Section grow component={ScrollArea}>
          <Menu
            position="bottom-start"
            offset={3}
            width={380}
            disabled={!hasMultipleOrganizations || isLoadingSwitchOrganization}
            withArrow
            arrowPosition="side"
            arrowOffset={30}
            arrowSize={10}
          >
            <Menu.Target>
              <Group
                w="100%"
                px="sm"
                py="xs"
                align="center"
                component={"button"}
                justify="flex-start"
                className={clsx([
                  classes.sidebarOrgSwitcherButton,
                  hasMultipleOrganizations &&
                    classes.sidebarOrgSwitcherButtonEnabled,
                ])}
              >
                <OrganizationSwitcherItem
                  active
                  name={activeOrganization?.name}
                  rightSection={
                    hasMultipleOrganizations ? (
                      <ThemeIcon variant="transparent" color="dimmed">
                        {isLoadingSwitchOrganization ? (
                          <Loader size="sm" color="dimmed" />
                        ) : (
                          <IconChevronRight />
                        )}
                      </ThemeIcon>
                    ) : undefined
                  }
                />
              </Group>
            </Menu.Target>

            <Menu.Dropdown>
              {availableOrganizations.map((org) => (
                <Menu.Item key={org.id}>
                  <OrganizationSwitcherItem
                    name={org.name}
                    onSelectOrganization={() => {
                      switchOrganizationAsync({
                        id: org.id,
                      });
                    }}
                    rightSection={
                      <Text size="xs" c="dimmed">
                        Switch organization
                      </Text>
                    }
                  />
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>

          {!isClient && (
            <Stack mt="lg" flex={1} gap={5} w="100%">
              <NavLink to="/build" icon={<IconStack2 />} label="Build" />
              <NavLink
                to="/create"
                icon={<IconPresentationAnalytics />}
                label="Create"
              />
            </Stack>
          )}
        </AppShell.Section>

        <Group
          align="center"
          justify="space-between"
          className={classes.accountButtonContainer}
        >
          <Tooltip label="Watch a short onboarding video">
            <ActionIcon
              onClick={() => openOnboardingVideo()}
              variant="subtle"
              size="lg"
              color="dark"
            >
              <IconSchool />
            </ActionIcon>
          </Tooltip>

          <Group flex={1} justify="flex-end">
            <UserMenu
              hideAccountInfo={false}
              hideOrganizations
              variant="sidebar"
            />
          </Group>
        </Group>
        <FirstTimeVideoModal
          opened={onboardingVideoOpen}
          onClose={() => closeOnboardingVideo()}
        />
      </AppShell.Navbar>
      <AppShell.Header hiddenFrom="sm">
        {/* Mobile Header + Logo */}
        <Group w="100%" h="100%" px="xl" justify="space-between">
          <NoanLogo width={90} />
          <Burger opened={navOpened} onClick={toggleNav} />
        </Group>
      </AppShell.Header>
      <AppShell.Main>
        <ScrollArea
          // Fancy math to get the main area to fit the viewport
          h={{
            base: "auto",
            // The - 20px value here comes from the Y padding applied to the app shell in the sm breakpoint (10 top + 10 bottom = 20)
            sm: "calc(100vh - var(--app-shell-header-height, 0px) - var(--app-shell-footer-height, 0px) - 20px)",
          }}
          className={classes.mainContainer}
          scrollbars="y"
        >
          <Box
            my={{ base: "var(--app-shell-header-height, 100px)", sm: "lg" }}
            px={{ sm: "md" }}
          >
            <Outlet />
          </Box>
          <ScrollRestoration getKey={(location) => location.pathname} />
        </ScrollArea>
      </AppShell.Main>
    </AppShell>
  );
}

function DefaultLayout({ variant, topHeader, withPadding }: LayoutProps) {
  const shellPadding = variant === "insights" ? 0 : { base: "sm", md: "xl" };
  const navigationHeaderHeight = variant ? navigationHeaderHeights[variant] : 0;
  const mainPadding = {
    base: navigationHeaderHeight + (topHeader ? topHeaderHeights.mobile : 0),
    xs: navigationHeaderHeight + (topHeader ? topHeaderHeights.desktop : 0),
  };

  return (
    <AppShell
      p={withPadding ? shellPadding : undefined}
      bg="var(--mantine-color-body)"
    >
      <AppShell.Header zIndex={ZedIndex.Header} withBorder={false}>
        {topHeader}
        {variant === "default" && <DefaultNavigation />}
        {variant === "insights" && <InsightsNavigation />}
      </AppShell.Header>

      <AppShell.Main pt={mainPadding}>
        <Outlet />
        <GlobalSearch />
        <ScrollRestoration getKey={(location) => location.pathname} />
      </AppShell.Main>
    </AppShell>
  );
}

export function Layout({ variant, topHeader }: LayoutProps) {
  const isUsingNewNav = useIsFeatureEnabled(FeatureFlag.UseNewNav);

  if (isUsingNewNav && variant === "sidebar") {
    return <SidebarLayout />;
  } else {
    return (
      <DefaultLayout
        withPadding
        variant={variant === "sidebar" ? "default" : variant}
        topHeader={topHeader}
      />
    );
  }
}
