import { z } from "zod";
import { zodJsonStringAsDate } from "../utils";
import { CouponSchema } from "./Coupon";
import { IdentitySchema } from "./Identity";

export enum TargetType {
  B2B = "B2B",
  B2C = "B2C",
}

export enum TeamSize {
  One = 1,
  Small = 9, // 2-9
  Medium = 49, // 10-49
  Big = 249, // 50-249
  Huge = 2500, // 250-2500
}

export enum Industry {
  Healthcare = "Health and Wellness",
  FinanceAndBanking = "Finance and Banking",
  Education = "Education",
  RetailAndECommerce = "Retail, DTC, and E-commerce",
  RealEstate = "Real Estate",
  Manufacturing = "Manufacturing",
  HospitalityAndTravel = "Hospitality and Travel",
  MediaAndEntertainment = "Media and Entertainment",
  Tech = "Technology and IT Services",
  Logistics = "Logistics and Supply Chain",
  ProfessionalServices = "Professional Services",
  Other = "Other",
}

export enum BrandTone {
  Neutral = "neutral",
  Playful = "playful",
  Professional = "professional",
  Informative = "informative",
  Authorative = "authorative",
  Humourous = "humourous",
}
export const OrganizationSettingsSchema = z.object({
  brandTone: z.nativeEnum(BrandTone).optional(),

  attributes: z
    .object({
      newCompany: z.boolean().optional(),
      website: z.string().url().nullable().optional(),
      industry: z.nativeEnum(Industry).optional(),
      teamSize: z.nativeEnum(TeamSize).optional(),
      targetType: z.nativeEnum(TargetType).optional(),
    })
    .optional(),

  websiteScraper: z
    .object({
      // We don't really care about having these be real dates for our purposes.
      startedAt: z.string().optional(),
      finishedAt: z.string().optional().nullable(),
      website: z.string().url().optional(),
    })
    .optional(),

  creatorPrompts: z
    .object({
      /**
       * Prisma returns this as a string, since it's a JSON value and not a db column.
       * We need to pre-process it first:
       */
      generatedAt: zodJsonStringAsDate,
      suggestions: z.array(z.string()),
    })
    .optional(),

  /**
   * Stacks whose blocks are used in the context of the interaction
   * with the assistants or any kind of AI generation.
   */
  stacks: z.array(z.string()).optional(),
});

export const OrganizationSchema = z.object({
  id: z.string().uuid(),
  slug: z.string().min(3).max(64),
  name: z.string().min(3).max(64),
  settings: OrganizationSettingsSchema.default({}),
});

export const OrganizationWithIdentitiesSchema = OrganizationSchema.merge(
  z.object({
    coupons: z.array(CouponSchema).optional(),
    identities: z.array(IdentitySchema),
  }),
);

export const CreateOrganizationSchema = OrganizationSchema.pick({
  name: true,
  slug: true,
  industry: true,
  teamSize: true,
  targetType: true,
  settings: true,
});

export const UpdateOrganizationSchema = CreateOrganizationSchema.merge(
  z.object({
    slug: CreateOrganizationSchema.shape.slug.optional(),
    name: CreateOrganizationSchema.shape.name.optional(),
    settings: CreateOrganizationSchema.shape.settings.optional(),
  }),
);

export type Organization = z.infer<typeof OrganizationSchema>;
export type OrganizationWithIdentities = z.infer<
  typeof OrganizationWithIdentitiesSchema
>;

export type OrganizationSettings = z.infer<typeof OrganizationSettingsSchema>;
