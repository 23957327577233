import {
  Alert,
  Anchor,
  BackgroundImage,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Group,
  Image,
  Modal,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure, useToggle } from "@mantine/hooks";
import {
  IconArrowRight,
  IconBrandLinkedin,
  IconCheck,
  IconInfoCircle,
  IconLink,
  IconMail,
} from "@tabler/icons-react";
import { useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { supabase } from "../../auth/supabase";
import { useOptionalAuthContext } from "../../auth/useAuthContext";
import { NoanLogo } from "../../components/icons/NoanLogo.svg";
import classes from "./Login.module.css";
import { GoogleIcon } from "../../components/icons/Google.svg";

const redirectToAfterAuth =
  (import.meta.env.AUTH_REDIRECT_TO as string | undefined) ?? "/";

interface MagicLinkFormFields {
  email: string;
}

interface PasswordFormFields {
  email: string;
  password: string;
}

function AuthForm({
  allowLinkedinLogin,
  initialEmail,
  errorMessage,
}: {
  allowLinkedinLogin?: boolean;
  initialEmail?: string;
  errorMessage?: string;
}) {
  const [magicLinkInputIsOpen, { toggle: toggleMagicLinkInput }] =
    useDisclosure();
  const [deliveredMagicLinkEmail, toggleDeliveredMagicLinkEmail] = useToggle();

  const passwordForm = useForm<Partial<PasswordFormFields>>({
    initialValues: {
      email: initialEmail ?? "",
      password: "",
    },
    onValuesChange(newValues, previous) {
      if (newValues.email && newValues.email !== previous.email) {
        magicLinkForm.setFieldValue("email", newValues.email);
      }
    },
    validate: {
      email: isEmail("Must be a valid email address."),
      password: isNotEmpty(),
    },
  });

  const magicLinkForm = useForm<Partial<MagicLinkFormFields>>({
    initialValues: {
      email: initialEmail ?? "",
    },
    validate: {
      email: isEmail("Must be a valid email address."),
    },
  });

  function onGoogleLogin() {
    supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: redirectToAfterAuth,
        skipBrowserRedirect: false,
        queryParams: {
          prompt: "consent",
          access_type: "offline",
        },
      },
    });
  }

  async function onMagicLinkLogin({ email }: MagicLinkFormFields) {
    toggleMagicLinkInput();

    if (email == null) return;

    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        shouldCreateUser: true,
        emailRedirectTo: redirectToAfterAuth,
      },
    });

    if (error != null) {
      magicLinkForm.setFieldError(
        "email",
        "There was an error delivering your magic link. Please try again.",
      );
    } else {
      toggleDeliveredMagicLinkEmail(true);
    }
  }

  async function onPasswordLogin({ email, password }: PasswordFormFields) {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error != null) {
      passwordForm.setFieldError(
        "email",
        "Credentials are not valid, or you may need to confirm your email",
      );
      passwordForm.setFieldError("password", " ");
    }
  }

  return (
    <Stack gap="lg">
      {errorMessage != null && (
        <Alert
          variant="light"
          color="warning"
          icon={<IconInfoCircle size={18} />}
        >
          {errorMessage}
        </Alert>
      )}

      <Button
        size="xl"
        variant="outline"
        color="teal.6"
        leftSection={<GoogleIcon size={22} />}
        onClick={onGoogleLogin}
      >
        <Text>Sign in with Google</Text>
      </Button>
      {allowLinkedinLogin && (
        <Button
          size="xl"
          variant="outline"
          fw="normal"
          color="teal.6"
          leftSection={<IconBrandLinkedin size={18} />}
        >
          <Text>Sign in with Linkedin</Text>
        </Button>
      )}

      <Divider
        label={
          <Text size="sm" className={classes.divider}>
            OR
          </Text>
        }
      />

      <form
        onSubmit={passwordForm.onSubmit((values) =>
          onPasswordLogin(values as PasswordFormFields),
        )}
      >
        <Stack gap="lg">
          <TextInput
            className={classes.loginFormInput}
            label="Email"
            type="email"
            placeholder="name@example.com"
            {...passwordForm.getInputProps("email")}
          />
          <PasswordInput
            className={classes.loginFormPassword}
            label="Password"
            placeholder="********"
            {...passwordForm.getInputProps("password")}
          />
          <Button
            leftSection={
              deliveredMagicLinkEmail ? (
                <IconCheck size={18} />
              ) : (
                <IconMail size={18} />
              )
            }
            variant="outline"
            size="xl"
            color={deliveredMagicLinkEmail ? "teal.6" : "orange.4"}
            onClick={() => {
              if (deliveredMagicLinkEmail) {
                toggleDeliveredMagicLinkEmail(false);
              }

              toggleMagicLinkInput();
            }}
          >
            <Text>
              {deliveredMagicLinkEmail
                ? "A magic link was sent to your email"
                : "Log in with a magic link"}
            </Text>
          </Button>
          <Button size="xl" disabled={!passwordForm.isValid()} type="submit">
            <Text>Log In</Text>
          </Button>
          <Text ta="center">
            Don't have an account?{" "}
            <Anchor
              component={Link}
              to="/join?src=login"
              c="teal.6"
              td="underline"
            >
              Sign up
            </Anchor>
          </Text>
        </Stack>
      </form>

      <Modal
        autoFocus
        size="lg"
        radius="lg"
        keepMounted={false}
        centered
        opened={magicLinkInputIsOpen}
        onClose={toggleMagicLinkInput}
      >
        <form
          onSubmit={magicLinkForm.onSubmit((values) =>
            onMagicLinkLogin(values as MagicLinkFormFields),
          )}
        >
          <Stack align="center">
            <Card
              radius="md"
              shadow="sm"
              pos="relative"
              style={{ overflow: "visible" }}
            >
              <ThemeIcon size={100} variant="transparent">
                <IconMail size={80} stroke={1} />
              </ThemeIcon>
              <ThemeIcon
                variant="transparent"
                pos="absolute"
                bottom={-12}
                right={-12}
              >
                <IconLink stroke={1.5} size={50} />
              </ThemeIcon>
            </Card>
            <Stack align="center" w="100%" maw={500} mt="xl">
              <Text className={classes.text} size="sm">
                We'll email you a magic link to access your account
              </Text>
              <TextInput
                className={classes.loginFormInput}
                data-autofocus
                flex={1}
                placeholder="name@example.com"
                disabled={deliveredMagicLinkEmail}
                w="100%"
                {...magicLinkForm.getInputProps("email")}
              />
              <Button
                my="lg"
                size="lg"
                type="submit"
                disabled={deliveredMagicLinkEmail}
              >
                Send Magic Link
              </Button>
            </Stack>
          </Stack>
        </form>
      </Modal>
    </Stack>
  );
}

function EmailConfirmNotice({
  email,
  onReturnToLogin,
}: {
  email?: string;
  onReturnToLogin: () => void;
}) {
  return (
    <Container size="sm">
      <Card p="0" withBorder={false}>
        <BackgroundImage src="/bg-hero-login.svg" p="xl" bgr="no-repeat">
          <Stack mt={150} gap="xl" justify="flex-end">
            <Title order={2} tt="uppercase" fw="bold" c="base.7">
              You're almost there!
            </Title>
            <Title order={4} c="base.9">
              Follow the confirmation instructions we sent to{" "}
              {email == null ? "your inbox" : email}
            </Title>
          </Stack>
        </BackgroundImage>
      </Card>
      <Group justify="flex-end" mt="sm">
        <Button
          variant="subtle"
          size="md"
          leftSection={<IconArrowRight size={14} />}
          onClick={onReturnToLogin}
        >
          Continue to Log In
        </Button>
      </Group>
    </Container>
  );
}

export function Login() {
  const [searchParams] = useSearchParams();
  const { user } = useOptionalAuthContext();

  const isEmailConfirmView = searchParams.get("view") === "confirm";
  const reason = searchParams.get("reason");
  const initialEmail = searchParams.get("email") ?? undefined;

  const [showEmailConfirmation, setShowEmailConfirmation] =
    useState(isEmailConfirmView);

  function errorMessage(reasonKey: string) {
    switch (reasonKey) {
      case "signed-out":
        return "You've been signed out of NOAN.";
      case "no-auth":
        return "You must login to NOAN before you continue.";
      default:
        return "There was an error accessing NOAN. Please login, or try again later.";
    }
  }

  if (showEmailConfirmation) {
    return (
      <EmailConfirmNotice
        email={initialEmail}
        onReturnToLogin={() => setShowEmailConfirmation(false)}
      />
    );
  }

  if (user != null) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Grid columns={6} gutter={0}>
        <Grid.Col span={{ base: 6, sm: 3 }}>
          <Container
            py={{ base: "xl", sm: 45 }}
            px={{ base: "xl", sm: 0 }}
            maw={390}
          >
            <NoanLogo width={110} />
            <Stack gap="xl" py="xl" mt={{ sm: 60 }}>
              <Box>
                <Title order={1} tt="uppercase" fw="bold" size={38}>
                  Welcome back
                </Title>
                <Title order={4} c="base.7">
                  Log into your account
                </Title>
              </Box>
              <AuthForm
                errorMessage={reason != null ? errorMessage(reason) : undefined}
                initialEmail={initialEmail}
                allowLinkedinLogin={searchParams.get("linkedin") != null}
              />
            </Stack>
          </Container>
        </Grid.Col>
        <Grid.Col
          visibleFrom="sm"
          span={{ base: 6, sm: 3 }}
          bg="base.11"
          p={0}
          mih="100vh"
        >
          <Stack pl={65} pt={80} gap="xl">
            <Title order={1} tt="uppercase" fw="bold" size={38} c="base.0">
              The GTM platform for one-person teams
            </Title>
            <Image src="/create-mode.png" w="100%" />
          </Stack>
        </Grid.Col>
      </Grid>
    </>
  );
}
