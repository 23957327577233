import classes from "./StackIcons.module.css";

export function DentistIcon() {
  return (
    <svg
      width="206"
      height="230"
      viewBox="0 0 206 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M51.7451 163.825L60.745 169.021C65.7229 171.895 71.8557 171.895 76.8339 169.022C82.4031 165.807 85.8339 159.864 85.8339 153.433L85.8338 134.305L102.823 124.496L119.813 134.305L119.812 153.433C119.812 159.864 123.243 165.807 128.812 169.022C133.791 171.895 139.923 171.895 144.901 169.021L153.901 163.825L136.858 153.985L136.854 124.463L119.813 114.624L102.823 124.433L85.8338 114.624L68.7922 124.463L68.7891 153.985L51.7451 163.825Z"
        fill="currentColor"
      />
      <path
        d="M94.8334 50.9334C89.2644 47.7181 82.4031 47.718 76.834 50.933L43.699 70.0629C38.1299 73.2783 34.6992 79.2205 34.6992 85.6512L34.7002 143.593C34.7002 150.024 38.131 155.966 43.7002 159.181L51.7449 163.826L51.7447 85.0998L85.8335 65.4177L93.8781 70.0622C99.4469 73.2774 106.308 73.2776 111.877 70.0628L119.923 65.4183L119.924 65.4192L154.012 85.1007L154.015 163.826L162.059 159.181C167.629 155.966 171.059 150.024 171.059 143.593L171.06 85.6512C171.06 79.2205 167.629 73.2783 162.06 70.0629L128.813 50.9334C123.244 47.7181 116.383 47.718 110.813 50.933L102.823 55.546L94.8334 50.9334Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.037 69.4755L205.036 154.026C205.036 166.205 198.538 177.458 187.991 183.548L114.768 225.823C107.342 230.111 98.1937 230.111 90.7681 225.823L12.5 180.635C5.07443 176.348 0.500068 168.425 0.500048 159.851L0.5 75.2995C0.50002 63.1206 6.99753 51.8668 17.5448 45.7774L90.769 3.50257C98.1945 -0.784536 107.343 -0.784548 114.769 3.50254L193.037 48.6909C200.463 52.9781 205.037 60.9011 205.037 69.4755ZM21.5448 63.1494L98.7688 18.5652C101.244 17.1362 104.294 17.1362 106.769 18.5653L183.992 63.1503C186.467 64.5794 187.992 67.2204 187.992 70.0786L187.991 159.248C187.991 162.106 186.466 164.747 183.991 166.176L106.768 210.761C104.293 212.19 101.243 212.19 98.7683 210.761L21.5447 166.176C19.0695 164.747 17.5448 162.106 17.5448 159.248L17.5447 70.0777C17.5447 67.2195 19.0695 64.5785 21.5448 63.1494Z"
        fill="currentColor"
      />
    </svg>
  );
}
