import type { TextSelectionAction } from "../components/MarkdownContent";

const textSelectionActionPreables: Record<TextSelectionAction["type"], string> =
  {
    simplifySelection: "make this line more concise and to the point",
    retrySelection: "have another try at the content of this line",
    removeSelection: "omit this line",
  } as const;

export function createTextSelectionTonePrompt(action: TextSelectionAction) {
  return `Repeat your previous result exactly as it was again, but ${textSelectionActionPreables[action.type]}: ${action.selectionText}`;
}
