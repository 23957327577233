import { z } from "zod";

export enum NoteProvenanceSource {
  BuildBlock = "build-block",
}

const NoteProvenanceSchema = z.object({
  source: z.nativeEnum(NoteProvenanceSource),
  blockPath: z.string().optional(),
});

export const NoteContentSchema = z.object({
  provenance: NoteProvenanceSchema,
  plainText: z.string(),
});

export const NoteSchema = z.object({
  id: z.string().uuid(),
  private: z.boolean().default(false),
  creatorId: z.string().uuid(),
  organizationId: z.string().uuid(),
  content: NoteContentSchema,
  createdAt: z.date(),
  removedAt: z.date().optional(),
});

export type Note = z.infer<typeof NoteSchema>;
export type NoteContent = z.infer<typeof NoteContentSchema>;
