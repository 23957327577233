import classes from "./StackIcons.module.css";

export function BeverageIcon() {
  return (
    <svg
      width="205"
      height="230"
      viewBox="0 0 205 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.5205 86.2756C68.5205 79.8448 71.9513 73.9025 77.5206 70.6871L92.7262 61.9067V42.1854L93.5842 41.6913C99.1443 38.4898 105.988 38.4898 111.548 41.6913L112.406 42.1854V61.8579L127.7 70.6872C133.269 73.9026 136.7 79.8448 136.7 86.2756L136.7 174.291L111.609 188.776C106.04 191.992 99.1784 191.992 93.6092 188.776L68.5206 174.291L68.5205 86.2756ZM119.654 105.405L102.609 115.245L85.5654 105.405V144.769L102.609 154.609L119.654 144.769L119.655 144.769L119.654 105.405Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.767 154.65L204.768 70.0998C204.768 61.5254 200.194 53.6023 192.768 49.3151L114.5 4.1268C107.075 -0.16028 97.926 -0.160269 90.5004 4.12684L17.2762 46.4017C6.72897 52.4911 0.231465 63.7449 0.231445 75.9238L0.231493 160.475C0.231514 169.049 4.80588 176.972 12.2315 181.259L90.4995 226.448C97.9251 230.735 107.074 230.735 114.499 226.448L187.723 184.172C198.27 178.083 204.767 166.829 204.767 154.65ZM98.5002 19.1895L21.2762 63.7737C18.8009 65.2028 17.2761 67.8438 17.2761 70.702L17.2762 159.872C17.2762 162.73 18.801 165.371 21.2762 166.8L98.4997 211.385C100.975 212.814 104.025 212.814 106.5 211.385L183.723 166.8C186.198 165.371 187.723 162.73 187.723 159.872L187.724 70.7028C187.724 67.8447 186.199 65.2036 183.724 63.7746L106.5 19.1896C104.025 17.7605 100.975 17.7605 98.5002 19.1895Z"
        fill="currentColor"
      />
    </svg>
  );
}
