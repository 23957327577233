import classes from "./StackIcons.module.css";

export function ProductIcon() {
  return (
    <svg
      width="206"
      height="228"
      viewBox="0 0 206 228"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <g clipPath="url(#clip0_1444_22267)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.06 3.26021L192.43 48.3302V48.3202C199.75 52.5902 204.28 60.3902 204.35 68.8602L205.09 156.78C205.16 165.29 200.71 173.21 193.4 177.58L115.09 224.37C107.68 228.8 98.46 228.9 90.95 224.65L12.17 180.04C4.65 175.78 0 167.81 0 159.16V69.1302C0 60.5202 4.62 52.5702 12.09 48.2902L91.07 3.16021C98.51 -1.08979 107.66 -1.04979 115.06 3.26021ZM18.22 163.47L101.58 211.18H101.57C102.51 211.72 103.67 211.71 104.6 211.15L186.86 161.93C187.77 161.38 188.32 160.39 188.32 159.33L187.62 67.0402C187.61 65.9902 187.05 65.0202 186.15 64.4802L104.79 16.1402C103.86 15.5902 102.71 15.5802 101.77 16.1202L18.22 64.0302C17.29 64.5602 16.71 65.5502 16.71 66.6302V160.87C16.71 161.94 17.29 162.94 18.22 163.47ZM40.14 72L96.61 39.55L96.58 39.54C100.28 37.41 104.84 37.41 108.54 39.54L165.03 72C168.77 74.15 171.07 78.14 171.05 82.46L170.74 147.63C170.72 151.96 168.38 155.94 164.6 158.05L108.43 189.45C104.79 191.48 100.36 191.48 96.72 189.45L40.56 158.05C36.79 155.94 34.44 151.96 34.42 147.63L34.12 82.46C34.1 78.14 36.4 74.15 40.14 72ZM102.6 172.04V172.02L154.55 143.71L154.296 104.582L102.41 133.92L50.8995 105.273L50.65 143.71L102.6 172.04ZM102.57 114.19L154.123 85.1429L136.181 74.8868L85.0438 104.315L102.57 114.19ZM68.8194 95.1734L51.0476 85.1599L102.59 55.69H102.6L119.684 65.4562L68.8194 95.1734Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1444_22267">
          <rect width="205.09" height="227.78" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
