import type { ItemPriceTier } from "api/src/trpc_routes/account";
import { useAuthContext } from "../auth/useAuthContext";
import { Maybe } from "./misc";
import { trpc } from "./trpc";

export function getPrice(
  tiers: Maybe<ItemPriceTier[]>,
  quantity: number,
  percentOff = 0,
): string {
  if (!tiers?.length || quantity <= 0) return "0.00";

  const result = tiers.reduce(
    (acc, tier) => {
      if (!tier.unit_amount_decimal || acc.remainingQuantity <= 0) {
        return acc;
      }

      const unitPrice = parseFloat(tier.unit_amount_decimal) / 100;
      const tierLimit = tier.up_to ?? Infinity; // No limit if up_to is null
      const applicableQuantity = Math.min(
        acc.remainingQuantity,
        tierLimit - acc.processedQuantity,
      );
      const tierCost = applicableQuantity * unitPrice;

      acc.totalCost += tierCost;
      acc.remainingQuantity -= applicableQuantity;
      acc.processedQuantity += applicableQuantity;

      return acc;
    },
    { totalCost: 0, remainingQuantity: quantity, processedQuantity: 0 },
  );

  const discount = (percentOff / 100) * result.totalCost;
  const finalCost = result.totalCost - discount;

  return finalCost.toFixed(2);
}

export function useAccountBilling() {
  const { user } = useAuthContext();

  const { data, isLoading: isLoadingGetBilling } =
    trpc.account.getBilling.useQuery(undefined, {
      enabled: user != null,
    });

  if (!data) {
    return {
      isLoadingGetBilling,
      currentSeats: 0,
      seatLimit: 0,
      isSeatLimitReached: false,
      currentOrganizations: 0,
      organizationLimit: 0,
      isOrganizationLimitReached: false,
      seatPrice: "0.00",
      organizationPrice: "0.00",
      frequency: undefined,
      seatTiers: [],
      organizationTiers: [],
      percentOff: 0,
    };
  }

  const {
    currentSeats,
    currentOrganizations,
    seats,
    organizations,
    seatTiers,
    organizationTiers,
    frequency,
    percentOff,
  } = data;

  const seatPrice = getPrice(seatTiers, seats);
  const seatPriceWithDiscount = getPrice(seatTiers, seats, percentOff);
  const organizationPrice = getPrice(organizationTiers, organizations);
  const organizationPriceWithDiscount = getPrice(
    organizationTiers,
    organizations,
    percentOff,
  );

  return {
    isLoadingGetBilling,
    currentSeats,
    seatLimit: seats,
    isSeatLimitReached: currentSeats >= seats,
    currentOrganizations,
    organizationLimit: organizations,
    isOrganizationLimitReached: currentOrganizations >= organizations,
    seatPrice,
    seatPriceWithDiscount,
    organizationPrice,
    organizationPriceWithDiscount,
    seatTiers,
    organizationTiers,
    frequency,
    percentOff,
  };
}

// Conditions to show limited NOAN functionalities
export function useIsPreviewMode() {
  const { isAccountActive } = useAuthContext();

  return !isAccountActive;
}

export function useGetPreviewConstraints() {
  const { identity } = useAuthContext();

  const previewConstraints = trpc.account.getPreviewConstraints.useQuery(
    undefined,
    {
      enabled: !!identity != null,
    },
  );

  return previewConstraints;
}
