import { notifications } from "@mantine/notifications";
import { useUpdateStacks } from "../../utils/useOrganizationMutation";
import { IconSquarePlus } from "@tabler/icons-react";
import { IconBug } from "@tabler/icons-react";

export function useAddStack() {
  const { updateStacks, isUpdatingStacks } = useUpdateStacks();

  function addStack({
    stack,
    onAddStackFinished,
  }: {
    stack: { slug: string; name: string };
    onAddStackFinished?: (slug: string) => void;
  }) {
    updateStacks(
      {
        stacks: [stack.slug],
      },
      {
        onSuccess: () => {
          notifications.show({
            message: `Stack ${stack.name} has been added to your strategy.`,
            icon: <IconSquarePlus size={16} />,
            color: "green",
            autoClose: 2000,
          });
          onAddStackFinished?.(stack.slug);
        },
        onError: () => {
          notifications.show({
            icon: <IconBug size={16} />,
            color: "red",
            autoClose: 2000,
            message: `An unknown error ocurred while adding ${stack.name} stack to your strategy.`,
          });
        },
      },
    );
  }

  return {
    addStack,
    isAddingStack: isUpdatingStacks,
  };
}
