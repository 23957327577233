import { Image } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { IconBrandOpenai } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { FactProvenance } from "api/src/models/Fact";

import type { GeneratorUntrustedInput } from "api/src/models/GeneratorInput";
import { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "./auth/useAuthContext";
import { AnthropicLogo } from "./components/icons/AnthropicLogo.svg";
import { apiBaseUrl } from "./config";
import { trpc } from "./utils/trpc";

export interface AiGeneratorOption {
  label: string;
  value: string;
  description: string;
  icon: JSX.Element;
}

export const aiGenerators: AiGeneratorOption[] = [
  {
    label: "OpenAI",
    value: "openai",
    description:
      "Makers of ChatGPT. Slightly more literal, logical, linear responses.",
    icon: <IconBrandOpenai size={19} />,
  },
  {
    label: "Anthropic",
    value: "anthropic",
    description: "Works best for more detailed, richer, creative output.",
    icon: <AnthropicLogo size={18} />,
  },
  {
    label: "Perplexity",
    value: "perplexity",
    description: "General-purpose model with results from updated web queries.",
    icon: <Image src="/perplexity.png" w={18} />,
  },
];

export const generators = aiGenerators.map((g) => g.value);

export function useGeneratorQuery({
  enabled = true,
  generateOnce = false,
  generate: { role, tone, generator, input, thread, skipSaving, factory },
  onStartGenerate,
  onEndGenerate,
  cacheKey,
}: {
  enabled?: boolean;
  generateOnce?: boolean;
  generate: GeneratorUntrustedInput;
  onStartGenerate?: () => void;
  onEndGenerate?: (generatorResultId: string) => void;
  cacheKey?: string;
}) {
  const [hasGenerated, toggleHasGenerated] = useToggle();
  const { jwtBearerToken, identity } = useAuthContext();
  const [generatedText, setGeneratedText] = useState<string>();
  const [resultId, setResultId] = useState<string>(() => crypto.randomUUID());
  const [isActive, toggleIsActive] = useToggle();
  const utils = trpc.useUtils();
  const [reader, setReader] = useState<
    ReadableStreamDefaultReader | undefined
  >();

  const query = useQuery({
    queryKey: [
      [
        "generator",
        cacheKey ?? {
          role,
          tone,
          generator,
          input,
          thread,
          skipSaving,
          factory,
        },
      ],
    ],
    cacheTime: 0,
    enabled: enabled && (!generateOnce || (generateOnce && !hasGenerated)),
    retry: false,
    retryOnMount: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,

    async queryFn() {
      if (!jwtBearerToken) {
        throw new Error(
          `invariant: jwt bearer token is nullish when starting generator query`,
        );
      }

      // Reset output:
      setGeneratedText("");
      onStartGenerate && onStartGenerate();
      toggleIsActive(true);
      const response = await fetch(`${apiBaseUrl}/generate`, {
        method: "post",
        cache: "no-cache",
        headers: {
          authorization: jwtBearerToken,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          input,
          role,
          thread,
          tone,
          generator:
            generator && generators.includes(generator) ? generator : "openai",
          skipSaving,
          factory,
        } as GeneratorUntrustedInput),
      });

      if (!response.ok) {
        console.error(
          "generator query error",
          response.statusText,
          response.body,
        );

        throw new Error(`Error starting generator ${response.statusText}`);
      }

      const resultId = response.headers.get("X-Noan-Generator-Result-Id");
      if (resultId) {
        setResultId(resultId);
      }

      const reader = response
        .body!.pipeThrough(new TextDecoderStream())
        .getReader();

      setReader(reader);
      return null;
    },
  });

  const readFromReader = useCallback(async () => {
    const { done, value } = await reader!.read();

    if (value) {
      setGeneratedText((r) => `${r ?? ""}${value}`);
    }

    if (done) {
      setReader(undefined);
      toggleIsActive(false);

      if (thread?.blockPath) {
        utils.facts.getAll.setData(undefined, (allFacts) => {
          const newFacts = [...(allFacts ?? [])];

          const updateIdx = newFacts.findIndex(
            (f) => f.blockPath === thread.blockPath,
          );

          if (updateIdx !== -1) {
            newFacts[updateIdx] = {
              ...newFacts[updateIdx],
              content: {
                ...newFacts[updateIdx].content,
                plainText: generatedText!,
              },
            };
          } else {
            newFacts.push({
              id: crypto.randomUUID(),
              factDependencyIds: [],
              blockPath: thread.blockPath!,
              organizationId: identity.organizationId,
              creatorId: identity.id,
              content: {
                format: {
                  type: "text",
                },
                provenance: FactProvenance.StrategyGenerator,
                references: [],
                plainText: generatedText!,
              },
              createdAt: new Date(),
              creator: {
                id: identity.id,
                name: "",
                email: identity.email,
              },
              outdatedReferenceBlockPaths: [],
            });
          }

          return newFacts;
        });

        utils.facts.getFactHeritage.invalidate();
        utils.facts.getAll.invalidate();
      }

      if (onEndGenerate) {
        onEndGenerate(resultId);
      }
      utils.account.getPreviewConstraints.invalidate();

      toggleHasGenerated();
    }
  }, [
    reader,
    toggleIsActive,
    onEndGenerate,
    generatedText,
    thread,
    utils,
    resultId,
    toggleHasGenerated,
    identity,
  ]);

  // Is specifically in the sampling phase after the original request:
  const isSampling = enabled && isActive && !query.isLoading;

  // Is at any stage of the generation process:
  const isLoadingResponse = enabled && query.isLoading && !isSampling;

  /**
   * Every render, we check if we need to get an additional chunk from
   * the stream reader:
   */
  useEffect(() => {
    if (reader) {
      readFromReader();
    }
  });

  return {
    ...query,
    isLoadingResponse,
    isSampling,

    // Overrides react query's default `isLoading` to mean being busy with
    // the request, or actually already sampling:
    isLoading: isLoadingResponse || isSampling,
    generatedText,
    resultId,
  };
}
