import { z } from "zod";
import type { ApiContext } from "./models/ApiContext";
import { IdentityRole } from "./models/Identity";

export function compact<T>(array: (T | null | undefined)[]): T[] {
  return array.filter((v) => v != null) as NonNullable<T>[];
}

export function splitFullName(fullName: string) {
  const name = fullName.split(" ");

  return {
    lastName: name[name.length - 1],
    firstName: name.slice(0, -1).join(" "),
  };
}

export function assertUnreachable(
  _: never,
  message: string = "invariant: Reached unreachable branch or condition",
): never {
  throw new Error(message);
}

export function clampText(
  text: string,
  {
    length = 32,
    suffix = "...",
  }: {
    length?: number;
    suffix?: string;
  } = {},
) {
  if (text.length <= length) {
    return text;
  }

  /**
   * Clamp the text, ensuring the suffix doesn't push the output over the desired
   * limit length:
   */
  return `${text.slice(0, length - suffix.length)}${suffix}`;
}

export const zodJsonStringAsDate = z.preprocess(
  (d) => (typeof d === "string" ? new Date(d) : d),
  z.date(),
);

export const zodJsonStringAsDateMaybe = z.preprocess(
  (d) => (typeof d === "string" ? new Date(d) : d),
  z.date().optional().nullable(),
);

export function getGlobalServiceIdentity(): ApiContext["actor"] {
  return {
    authUserId: "",
    email: "service.account@getnoan.com",
    name: "NOAN",
    organizationId: "",
    id: "",
    primaryRole: IdentityRole.Owner,
  };
}
