import { notifications } from "@mantine/notifications";
import { roleOptions } from "../roles";
import { IconX } from "@tabler/icons-react";
import {
  isGenerativeModule,
  isInputModule,
  Module,
} from "../../../utils/usePillars";

export const highestRole = roleOptions[0].value;

export const regexEmail = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/;

export function showError(message: JSX.Element | string) {
  notifications.show({
    message,
    color: "red",
    icon: <IconX size={18} />,
  });
}

export function getModuleTooltip(module: Module) {
  if (isGenerativeModule(module)) {
    return module.description;
  }
  if (isInputModule(module)) {
    return module.userfacingtext;
  }
  return undefined;
}

export function isRoleHigherOrEqual(role?: string, otherRole?: string) {
  if (!role || !otherRole) return false;

  const roleIdx = roleOptions.findIndex((r) => r.value === role);
  const otherRoleIdx = roleOptions.findIndex((r) => r.value === otherRole);

  return roleIdx <= otherRoleIdx;
}
