import {
  Accordion,
  ActionIcon,
  Alert,
  Box,
  Button,
  Divider,
  Group,
  Popover,
  ScrollAreaAutosize,
  Spoiler,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { useDisclosure, useHover } from "@mantine/hooks";
import {
  IconAlertTriangle,
  IconBulbFilled,
  IconDeviceFloppy,
  IconSquareX,
} from "@tabler/icons-react";
import { NoteProvenanceSource } from "api/src/models/Note";
import clsx from "clsx";
import { useRef, useState } from "react";
import { PROMPT_MAX_LENGTH } from "../../components/ConfigurableBlockCard/config";
import { TextCopier } from "../../components/TextCopier";
import { Can } from "../../components/auth/Can";
import { relativeTimeFromNow } from "../../utils/date";
import { useDeleteNote, useNotes, useSaveNote } from "../../utils/useNotes";
import classes from "./BlockIdeas.module.css";

function IdeaBlock({
  blockName,
  content,
  lastChangedAt,
  isDeletingIdea,
  onDeleteIdea,
  onReplaceContent,
}: {
  blockName?: string;
  lastChangedAt: Date;
  content?: string;
  isDeletingIdea: boolean;
  onDeleteIdea: () => void;
  onReplaceContent: (content: string) => void;
}) {
  const [
    deleteConfirmationOpened,
    { close: closeDeleteConfirmation, open: openDeleteConfirmation },
  ] = useDisclosure(false);
  const [
    confirmReplaceContentOpened,
    { close: closeConfirmReplaceContent, open: openConfirmReplaceContent },
  ] = useDisclosure();
  const { ref, hovered } = useHover();

  return (
    <Stack ref={ref}>
      <Group>
        <Text size="xs" c="dimmed" flex={1}>
          {relativeTimeFromNow(lastChangedAt)}
        </Text>
        <Group
          gap="xs"
          className={clsx(
            !hovered &&
              !deleteConfirmationOpened &&
              !confirmReplaceContentOpened &&
              classes.hide,
          )}
        >
          <TextCopier content={content ?? ""} disabled={!content} />
          <Popover
            width={400}
            position="bottom"
            withArrow
            shadow="md"
            opened={confirmReplaceContentOpened}
          >
            <Popover.Target>
              <Tooltip
                withArrow
                label={`Replace ${blockName} Smart Block content with content in Ideas`}
              >
                <ActionIcon
                  variant="subtle"
                  color="dimmed"
                  disabled={!content}
                  onClick={openConfirmReplaceContent}
                >
                  <IconDeviceFloppy size={14} />
                </ActionIcon>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack gap="xs">
                <Alert title="Are you sure?" icon={<IconDeviceFloppy />}>
                  Do you want to replace your Smart Block with the content of
                  this idea?
                </Alert>
                <Group gap="sm" justify="flex-end">
                  <Button
                    size="xs"
                    variant="default"
                    onClick={closeConfirmReplaceContent}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="xs"
                    disabled={isDeletingIdea}
                    onClick={() => {
                      onReplaceContent(content!);
                      closeConfirmReplaceContent();
                    }}
                  >
                    Replace
                  </Button>
                </Group>
              </Stack>
            </Popover.Dropdown>
          </Popover>
          <Popover
            width={400}
            position="bottom"
            withArrow
            shadow="md"
            opened={deleteConfirmationOpened}
          >
            <Popover.Target>
              <Tooltip withArrow label={`Delete from ideas`}>
                <ActionIcon
                  variant="subtle"
                  color="red"
                  onClick={openDeleteConfirmation}
                >
                  <IconSquareX size={14} />
                </ActionIcon>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack gap="xs">
                <Alert
                  title="Are you sure?"
                  icon={<IconAlertTriangle />}
                  color="red"
                >
                  Do you want to delete this idea? This action cannot be undone.
                </Alert>
                <Group gap="sm" justify="flex-end">
                  <Button
                    size="xs"
                    variant="default"
                    onClick={closeDeleteConfirmation}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="red"
                    size="xs"
                    disabled={isDeletingIdea}
                    onClick={() => {
                      onDeleteIdea();
                      closeDeleteConfirmation();
                    }}
                  >
                    Delete
                  </Button>
                </Group>
              </Stack>
            </Popover.Dropdown>
          </Popover>
        </Group>
      </Group>
      <Spoiler
        hideLabel="Show less"
        showLabel="Read more"
        styles={{
          control: {
            marginTop: 4,
            fontSize: 12,
          },
        }}
        maxHeight={120}
      >
        <Text size="sm">{content}</Text>
      </Spoiler>

      <Divider />
    </Stack>
  );
}

export function BlockIdeas({
  blockPath,
  blockName,
  onReplaceContent,
}: {
  blockPath: string;
  blockName?: string;
  onReplaceContent: (content: string) => void;
}) {
  const [inputValue, setInputValue] = useState<string>("");
  const { notes } = useNotes({
    source: NoteProvenanceSource.BuildBlock,
    blockPath,
  });
  const { deleteNote, isDeletingNote } = useDeleteNote();
  const { saveNote, isSavingNote } = useSaveNote();
  const ideasRef = useRef<HTMLParagraphElement>(null);

  function onSaveIdea() {
    saveNote(
      {
        content: {
          plainText: inputValue,
          provenance: {
            source: NoteProvenanceSource.BuildBlock,
            blockPath,
          },
        },
      },
      {
        onSuccess() {
          setInputValue("");
          ideasRef.current?.scroll({ top: 0, behavior: "smooth" });
        },
      },
    );
  }

  function onDeleteIdea(ideaId: string) {
    deleteNote({ id: ideaId });
  }

  return (
    <Can execute="facts.update">
      <Accordion w="100%" variant="contained" radius="md" defaultValue="ideas">
        <Accordion.Item key="ideas" value="ideas" defaultChecked>
          <Accordion.Control
            icon={<IconBulbFilled size={18} />}
            className={classes.ideasControl}
          >
            <Text>Ideas</Text>
          </Accordion.Control>
          <Accordion.Panel p={0} className={classes.ideasPane}>
            <Stack>
              <Text size="sm" c="gray.6">
                A place for any ideas or concepts you might have for this block.
              </Text>
              {notes.length > 0 && (
                <ScrollAreaAutosize mah={200} viewportRef={ideasRef}>
                  <Stack>
                    <Text size="sm" fw="bold" c="base.5">
                      Saved ideas
                    </Text>
                    {notes.map((idea) => (
                      <IdeaBlock
                        key={idea.id}
                        blockName={blockName}
                        lastChangedAt={idea.updatedAt ?? idea.createdAt}
                        content={idea.content.plainText}
                        onReplaceContent={onReplaceContent}
                        onDeleteIdea={() => onDeleteIdea(idea.id)}
                        isDeletingIdea={isDeletingNote}
                      />
                    ))}
                  </Stack>
                </ScrollAreaAutosize>
              )}

              <Textarea
                autosize
                minRows={2}
                maxRows={12}
                size="lg"
                resize="vertical"
                maxLength={PROMPT_MAX_LENGTH}
                description={
                  <Text span size="xs">
                    {inputValue.length ?? 0}/{PROMPT_MAX_LENGTH}
                  </Text>
                }
                onChange={(e) => setInputValue(e.currentTarget.value)}
                value={inputValue}
                disabled={isSavingNote || isDeletingNote}
              />
              <Group gap="xs" justify="space-between">
                <Box>
                  <TextCopier content={inputValue} disabled={!inputValue} />
                </Box>
                <Box>
                  <Tooltip withArrow label="Add idea">
                    <Button
                      size="md"
                      fw={500}
                      disabled={isSavingNote || !inputValue}
                      onClick={onSaveIdea}
                    >
                      Add
                    </Button>
                  </Tooltip>
                </Box>
              </Group>
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Can>
  );
}
