import { rolesWithLimitedBlockAccess } from "api/src/auth/permissions";
import {
  useAllowedBlocks,
  useFactPermissionsMutation,
} from "../../../utils/useFacts";
import { Pillar, usePillars } from "../../../utils/usePillars";
import { DeleteFactsModal } from "../DeleteFactsModal";
import {
  ActionIcon,
  Alert,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Fieldset,
  Group,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  ScrollArea,
  Skeleton,
  Spoiler,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { OrganizationProps } from ".";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { roleOptions } from "../roles";
import { Can } from "../../../components/auth/Can";
import { IconAlertSquare, IconRefresh } from "@tabler/icons-react";
import { IconDeviceFloppy } from "@tabler/icons-react";
import { getModuleTooltip } from "./helpers";
import { RestrictedFeature } from "../../../components/RestrictedFeature";

function StrategyBlockAccessSkeleton() {
  return (
    <Stack w="100%">
      <Group align="flex-start">
        <Stack flex={1}>
          <Skeleton w={180} h={20} radius="md" />
          <Group>
            <Skeleton w={20} h={20} radius="md" />
            <Skeleton w={120} h={20} radius="md" />
          </Group>
        </Stack>
        <Stack gap="xs" flex={2}>
          <Group>
            <Skeleton w={20} h={20} radius="md" />
            <Skeleton w={180} h={20} radius="md" />
          </Group>
          <Group>
            <Skeleton w={20} h={20} radius="md" />
            <Skeleton w={130} h={20} radius="md" />
          </Group>
          <Group>
            <Skeleton w={20} h={20} radius="md" />
            <Skeleton w={140} h={20} radius="md" />
          </Group>
          <Group>
            <Skeleton w={20} h={20} radius="md" />
            <Skeleton w={80} h={20} radius="md" />
          </Group>
          <Group>
            <Skeleton w={20} h={20} radius="md" />
            <Skeleton w={120} h={20} radius="md" />
          </Group>
          <Group>
            <Skeleton w={20} h={20} radius="md" />
            <Skeleton w={180} h={20} radius="md" />
          </Group>
        </Stack>
      </Group>
      <Skeleton w="100%" h={1} radius="md" />
      <Group align="flex-start">
        <Stack flex={1}>
          <Skeleton w={180} h={20} radius="md" />
          <Group>
            <Skeleton w={20} h={20} radius="md" />
            <Skeleton w={180} h={20} radius="md" />
          </Group>
        </Stack>
        <Stack gap="xs" flex={2}>
          <Group>
            <Skeleton w={20} h={20} radius="md" />
            <Skeleton w={170} h={20} radius="md" />
          </Group>
          <Group>
            <Skeleton w={20} h={20} radius="md" />
            <Skeleton w={130} h={20} radius="md" />
          </Group>
          <Group>
            <Skeleton w={20} h={20} radius="md" />
            <Skeleton w={120} h={20} radius="md" />
          </Group>
          <Group>
            <Skeleton w={20} h={20} radius="md" />
            <Skeleton w={180} h={20} radius="md" />
          </Group>
          <Group>
            <Skeleton w={20} h={20} radius="md" />
            <Skeleton w={180} h={20} radius="md" />
          </Group>
          <Group>
            <Skeleton w={20} h={20} radius="md" />
            <Skeleton w={180} h={20} radius="md" />
          </Group>
        </Stack>
      </Group>
      <Divider size="xs" />
    </Stack>
  );
}

interface BlockFilteringFormFields {
  role: string;
  blocks: string[];
}

export function BlocksSubSection({
  organization,
}: {
  organization: OrganizationProps;
}) {
  const [isDeleteFactsModalOpen, setIsDeleteFactsModalOpen] = useState(false);
  const { pillars, isLoading: isLoadingPillars } = usePillars();

  const { getInputProps, onSubmit, setFieldValue, values } =
    useForm<BlockFilteringFormFields>({
      clearInputErrorOnChange: true,
      validateInputOnChange: true,
      validateInputOnBlur: true,
      async onValuesChange(val, prev) {
        // Refetch blocks when selecting another role
        if (prev.role !== val.role) {
          const { data } = await refetch();
          setFieldValue(
            "blocks",
            data ? (data.flatMap((d) => d.blockPaths) as string[]) : [],
          );
        }
      },
      initialValues: {
        role: "editor",
        blocks: [],
      },
    });

  const {
    blocks: permissions,
    refetch,
    isLoadingPermissions,
  } = useAllowedBlocks({
    role: values.role,
  });
  const {
    createFactPermissions,
    isLoadingCreateFactPermissions,
    updateFactPermissions,
    isLoadingUpdateFactPermissions,
  } = useFactPermissionsMutation();

  const roles = roleOptions.filter((r) =>
    rolesWithLimitedBlockAccess.includes(r.value),
  );
  const isLoading =
    isLoadingPermissions ||
    isLoadingCreateFactPermissions ||
    isLoadingUpdateFactPermissions;

  const totalModulesCount =
    pillars?.reduce((count, pillar) => {
      return count + pillar.content.modules.length;
    }, 0) || 0;

  const isAllSelected = totalModulesCount !== values.blocks.length;
  const hasUnsavedChanges =
    JSON.stringify(values.blocks.sort()) !==
    JSON.stringify(permissions?.sort());

  useEffect(() => {
    // Set initial blocks only on load of blocks permissions
    if (!!permissions && permissions.length > 0 && values.blocks.length === 0) {
      setFieldValue("blocks", permissions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions?.length]);

  function onSubmitBlocksAccess(values: BlockFilteringFormFields) {
    const payload = {
      identityRole: values.role,
      blockPaths: values.blocks,
    };
    // When permissions is an empty array, it means that the blocks have already previously being set.
    if (permissions === undefined) {
      createFactPermissions(payload);
    } else {
      updateFactPermissions(payload);
    }
  }

  return (
    <Stack>
      <Can execute="facts.update.blocks-permissions">
        <Fieldset legend="Blocks" pos="relative">
          <RestrictedFeature>
            {({ restrictedClassName }) => (
              <Stack gap="sm" className={restrictedClassName}>
                <form onSubmit={onSubmit(onSubmitBlocksAccess)}>
                  <Stack gap="sm">
                    <Input.Wrapper
                      label={
                        <InputLabel>
                          Strategy Block Access for <b>{organization.name}</b>
                        </InputLabel>
                      }
                      description="Set the input blocks that each role can access within your organization"
                    >
                      <Group mt="sm" align="flex-start">
                        <RadioGroup {...getInputProps("role")} flex={1}>
                          <Stack>
                            {roles.map((r) => (
                              <Radio
                                key={r.value}
                                label={r.label}
                                value={r.value}
                                disabled={isLoading}
                              />
                            ))}
                          </Stack>
                        </RadioGroup>
                        <Divider orientation="vertical" />
                        <Stack flex={5} gap="xl">
                          <Group gap="xs">
                            <Tooltip label="Cancel changes">
                              <ActionIcon
                                variant="subtle"
                                disabled={isLoading}
                                onClick={() => {
                                  setFieldValue("blocks", permissions ?? []);
                                }}
                              >
                                <IconRefresh size={28} />
                              </ActionIcon>
                            </Tooltip>
                            <Tooltip
                              label={
                                isAllSelected ? "Select all" : "Unselect all"
                              }
                            >
                              <Checkbox
                                size="md"
                                indeterminate={isAllSelected}
                                checked={!isAllSelected}
                                onChange={(e) => {
                                  if (e.currentTarget.checked) {
                                    setFieldValue(
                                      "blocks",
                                      pillars
                                        ?.flatMap((p) => p.content.modules)
                                        .map((m) => m.knowledgeslug) ?? [],
                                    );
                                  } else {
                                    setFieldValue("blocks", []);
                                  }
                                }}
                                disabled={isLoadingPermissions}
                              />
                            </Tooltip>
                            <Tooltip
                              label={`Save editable blocks for "${values.role}" role`}
                            >
                              <Button
                                leftSection={<IconDeviceFloppy size={22} />}
                                disabled={!hasUnsavedChanges || isLoading}
                                size="xs"
                                type="submit"
                              >
                                Save
                              </Button>
                            </Tooltip>
                          </Group>
                          {isLoadingPillars ? (
                            <StrategyBlockAccessSkeleton />
                          ) : (
                            <ScrollArea mah="400px" component={Stack}>
                              <Stack align="start">
                                {pillars?.map((p: Pillar) => {
                                  const isAllSelected = p.content.modules
                                    .map((m) => m.knowledgeslug)
                                    .every(
                                      (s) => !!s && values.blocks.includes(s),
                                    );
                                  return (
                                    <Stack key={p.slug} w="100%">
                                      <Group align="flex-start">
                                        <Stack flex={1}>
                                          <Text fw="bold">{p.name}</Text>
                                          <Checkbox
                                            label={
                                              <Text size="sm">
                                                Select all <b>{p.name}</b>{" "}
                                                blocks
                                              </Text>
                                            }
                                            indeterminate={!isAllSelected}
                                            checked={isAllSelected}
                                            value={p.name}
                                            disabled={isLoading}
                                            onChange={(e) => {
                                              if (e.currentTarget.checked) {
                                                setFieldValue("blocks", [
                                                  ...new Set([
                                                    ...values.blocks,
                                                    ...(p.content.modules
                                                      .map(
                                                        (m) => m.knowledgeslug,
                                                      )
                                                      .filter(
                                                        Boolean,
                                                      ) as string[]),
                                                  ]),
                                                ]);
                                              } else {
                                                setFieldValue("blocks", [
                                                  ...new Set([
                                                    ...values.blocks.filter(
                                                      (b) =>
                                                        !p.content.modules
                                                          .map(
                                                            (m) =>
                                                              m.knowledgeslug,
                                                          )
                                                          .includes(b),
                                                    ),
                                                  ]),
                                                ]);
                                              }
                                            }}
                                          />
                                        </Stack>

                                        <CheckboxGroup
                                          flex={2}
                                          {...getInputProps("blocks")}
                                        >
                                          <Spoiler
                                            hideLabel="Show less"
                                            showLabel="Show more"
                                            styles={{
                                              control: {
                                                marginTop: 10,
                                                fontSize: 12,
                                              },
                                            }}
                                            maxHeight={180}
                                          >
                                            <Stack gap="xs">
                                              {p.content.modules.map((m) => {
                                                const tooltip =
                                                  getModuleTooltip(m);
                                                const moduleCheckbox = (
                                                  <Checkbox
                                                    key={m.knowledgeslug}
                                                    label={m.title}
                                                    checked={values.blocks.includes(
                                                      m.knowledgeslug!,
                                                    )}
                                                    value={m.knowledgeslug}
                                                    disabled={isLoading}
                                                  />
                                                );

                                                return tooltip ? (
                                                  <Tooltip
                                                    key={m.knowledgeslug}
                                                    refProp="rootRef"
                                                    position="top-start"
                                                    label={tooltip}
                                                    multiline
                                                    maw={{
                                                      base: "90%",
                                                      sm: "40%",
                                                    }}
                                                  >
                                                    {moduleCheckbox}
                                                  </Tooltip>
                                                ) : (
                                                  moduleCheckbox
                                                );
                                              })}
                                            </Stack>
                                          </Spoiler>
                                        </CheckboxGroup>
                                      </Group>
                                      <Divider size="xs" />
                                    </Stack>
                                  );
                                })}
                              </Stack>
                            </ScrollArea>
                          )}
                        </Stack>
                      </Group>
                    </Input.Wrapper>
                  </Stack>
                </form>
              </Stack>
            )}
          </RestrictedFeature>
        </Fieldset>
      </Can>
      <Can execute="facts.delete">
        <Fieldset legend="Advanced">
          <Input.Wrapper
            label="Reset Strategy Data"
            c="red"
            description="Delete ALL of your NOAN blocks"
          >
            <Stack>
              <Group pt="sm">
                <Button
                  color="red"
                  onClick={() => setIsDeleteFactsModalOpen(true)}
                >
                  Delete all NOAN blocks
                </Button>
              </Group>
              <Alert
                variant="light"
                icon={
                  <ThemeIcon size="lg" variant="light">
                    <IconAlertSquare />
                  </ThemeIcon>
                }
              >
                <Text size="sm" px="lg">
                  This action cannot be reversed. <b>ALL</b> your existing NOAN
                  blocks will be deleted permanently.
                </Text>
              </Alert>
              <DeleteFactsModal
                opened={isDeleteFactsModalOpen}
                close={() => setIsDeleteFactsModalOpen(false)}
              />
            </Stack>
          </Input.Wrapper>
        </Fieldset>
      </Can>
    </Stack>
  );
}
