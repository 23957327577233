import type { GetInsightsSearchInput } from "api/src/trpc_routes/insights";
import { trpc } from "../../utils/trpc";

/**
 * Avoid deeply-nested type inference error(s); this type is simple
 * enough that it's OK to redefine it here:
 */
export interface Insight {
  id: string;
  summary?: string;
  description?: string;
  title?: string;
  content: string;
  createdAt: string;
  symbols: string[];
  knowledgeBlocks: string[];
  provenance: Record<string, unknown>;
}

export function useInsights(searchQuery?: GetInsightsSearchInput) {
  const query = trpc.insights.search.useQuery(searchQuery!, {
    enabled: searchQuery != null,
  });

  return {
    ...query,
    insights: query.data ? (query.data as unknown as Insight[]) : undefined,
  };
}

export function useCreateInsightsReport() {
  const { mutate, ...mutation } = trpc.insights.createReport.useMutation();

  return {
    ...mutation,
    createInsightsReport: mutate,
  };
}
