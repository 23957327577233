import { Button, Group, Stack, Text, ThemeIcon, Tooltip } from "@mantine/core";
import { IconHexagon } from "@tabler/icons-react";
import { FeatureFlag } from "api/src/featureFlags";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BlockWithRequirements } from "../../components/ConfigurableBlockCard/BlockOverviewCard";
import { FullPageLoader } from "../../components/FullPageLoader";
import { Page } from "../../components/Page";
import { RestrictedFeature } from "../../components/RestrictedFeature";
import { ReturnToTop } from "../../components/ReturnToTop";
import { Can } from "../../components/auth/Can";
import { StackKey, stackStyle } from "../../components/icons/stacks";
import { isOutdatedVersion, useFacts } from "../../utils/useFacts";
import { useIsFeatureEnabled } from "../../utils/useIsFeatureEnabled";
import { useOrganizationStacks } from "../../utils/useOrganization";
import { BlocksGroupedByStack } from "../../utils/usePillars";
import { ErrorPage } from "../ErrorPage";
import { FactsContext } from "./FactsContext";
import { useAddStack } from "./useAddStack";
import {
  groupModulesByStack,
  useFilteredStacksWithOnlyReadableBlocks,
  useIsStackReadable,
} from "./useIsStackReadable";

export function Blocks({
  data: { stack, blocks },
  isReadOnly,
}: {
  data: BlocksGroupedByStack;
  isReadOnly: boolean;
}) {
  const { isStackReadable } = useIsStackReadable();
  const navigate = useNavigate();

  return blocks.length === 0 ? (
    <Text c="dimmed" size="sm" ta="center">
      No blocks found for this stack
    </Text>
  ) : (
    <Stack>
      <RestrictedFeature
        title={`Stack ${stack.name} Locked`}
        description="This stack is available to paid NOAN subscribers only"
        isRestricted={!isStackReadable(stack.slug) || isReadOnly}
        withOverlay={!isStackReadable(stack.slug) || !isReadOnly}
      >
        {({ restrictedClassName }) => (
          <>
            <Text size="md" fw="bold">
              {blocks.length} Block{blocks.length > 1 ? "s" : ""}
            </Text>
            <Group
              justify="flex-start"
              align="flex-start"
              className={restrictedClassName}
            >
              {blocks.map((blo) => {
                const goToBlockPage = () => {
                  navigate(`/build/b/${blo.knowledgeslug}`);
                };

                return (
                  <Can key={blo._uid} execute="facts.read">
                    <BlockWithRequirements
                      w={{ base: "100%", sm: "30%" }}
                      title={blo.title ?? ""}
                      content={blo.fact?.content.plainText}
                      noContentButton={{
                        label: "Click here to start editing",
                        disabledLabel: "Add this stack to start editing",
                        disabled: isReadOnly,
                        onClick: goToBlockPage,
                      }}
                      isOutdatedVersion={isOutdatedVersion(blo.fact)}
                      onClickCard={goToBlockPage}
                      block={blo}
                    />
                  </Can>
                );
              })}
            </Group>
          </>
        )}
      </RestrictedFeature>
    </Stack>
  );
}

export function StackPage() {
  const isNewBuildModeEnabled = useIsFeatureEnabled(
    FeatureFlag.UseNewBuildMode,
  );
  const { stackSlug } = useParams();
  const [facts, { isLoading: isLoadingFacts }] = useFacts();
  const { stacks, isLoadingStacks } = useFilteredStacksWithOnlyReadableBlocks();
  const { stacks: organizationStacks } = useOrganizationStacks();
  const { addStack, isAddingStack } = useAddStack();

  const allStacks = useMemo(() => {
    return stacks ? groupModulesByStack(stacks, facts) : [];
  }, [facts, stacks]);
  const stackData = useMemo(() => {
    return allStacks?.find((sta) => sta.stack.slug === stackSlug);
  }, [allStacks, stackSlug]);

  const isLoading = stacks == null || isLoadingFacts;

  if (!isNewBuildModeEnabled) return null;
  if (isLoading) return <FullPageLoader />;
  if (!stackData) return <ErrorPage code={404} />;

  const { stack } = stackData;
  const blockStackStyle = stackStyle[stack.slug.toLowerCase() as StackKey];
  const isReadOnly = !organizationStacks.includes(stack.slug);
  const backTo = isReadOnly
    ? `/build`
    : `/build?filtered=${stack.slug.toLowerCase()}`;

  return (
    <Page
      titleLeftSection={
        <ThemeIcon variant="transparent" size="xl" color="orange.3">
          {blockStackStyle?.icon ?? <IconHexagon size={42} />}
        </ThemeIcon>
      }
      fullWidth={false}
      size="md"
      title={stack.name}
      description={stack.description}
      backButton={{
        label: `Back to Build`,
        to: backTo,
      }}
    >
      <FactsContext.Provider
        value={{
          facts,
          stacks: allStacks,
        }}
      >
        <Stack gap="lg">
          {!isLoadingStacks && isReadOnly && (
            <Can execute="organization.update">
              <Tooltip
                withArrow
                label={`Add ${stack.name} stack to your strategy`}
              >
                <Button
                  w="fit-content"
                  variant="filled"
                  size="lg"
                  fw={500}
                  onClick={() =>
                    addStack({
                      stack,
                    })
                  }
                  disabled={isAddingStack}
                  loading={isAddingStack}
                >
                  Add Stack
                </Button>
              </Tooltip>
            </Can>
          )}
          <Blocks data={stackData} isReadOnly={isReadOnly} />
        </Stack>
      </FactsContext.Provider>
      <ReturnToTop />
    </Page>
  );
}
