import { useDebouncedState } from "@mantine/hooks";
import { Spotlight, SpotlightActionData } from "@mantine/spotlight";
import { useStoryblokApi } from "@storyblok/react";
import {
  IconArticle,
  IconCategory,
  IconHexagon,
  IconSearch,
  IconSparkles,
} from "@tabler/icons-react";
import uniqBy from "lodash.uniqby";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOptionalAuthContext } from "../auth/useAuthContext";
import { slugToIntelPath } from "../utils/storyblokHelpers";

export function GlobalSearch() {
  const navigate = useNavigate();
  const { user } = useOptionalAuthContext();
  const [query, setDebouncedQuery] = useDebouncedState<string>("", 100);
  const api = useStoryblokApi();
  const [intelResults, setIntelResults] = useState<SpotlightActionData[]>([]);

  useEffect(() => {
    async function searchStories() {
      const {
        data: { stories },
      } = await api.getStories({
        version: "published",
        search_term: query,
        by_slugs: "intel/*,content/*",
      });

      if (stories.length > 0) {
        setIntelResults(
          uniqBy(stories, (s) => s.slug).map((story) => {
            return {
              id: story.uuid,
              label: story.name,
              leftSection: <IconArticle size={18} />,
              description: "Read on The Intel",
              onClick: () => navigate(slugToIntelPath(story.full_slug)),
            };
          }),
        );
      }
    }

    if (query.length > 3) {
      searchStories();
    }
  }, [query, api, navigate]);

  return (
    <Spotlight
      limit={7}
      onQueryChange={(newQuery) => {
        setDebouncedQuery(newQuery);
      }}
      actions={[
        ...(user != null
          ? [
              {
                id: "quick-links",
                group: "Quick links",
                actions: [
                  {
                    id: "build",
                    label: "Build",
                    onClick: () => navigate("/build"),
                    leftSection: <IconHexagon size={18} />,
                  },
                  {
                    id: "creator",
                    label: "Creator",
                    onClick: () => navigate("/create"),
                    leftSection: <IconSparkles size={18} />,
                  },
                  {
                    id: "intel",
                    label: "The Intel",
                    onClick: () => navigate("/intel"),
                    leftSection: <IconCategory size={18} />,
                  },
                ],
              },
            ]
          : []),
        {
          id: "intel-search",
          group: "Articles on The Intel",
          actions: intelResults,
        },
      ]}
      nothingFound="Nothing found..."
      highlightQuery
      searchProps={{
        leftSection: <IconSearch size={22} />,
        placeholder: "Start typing to find pages and content.",
      }}
    />
  );
}
