import classes from "./StackIcons.module.css";

export function RestaurantIcon() {
  return (
    <svg
      width="206"
      height="230"
      viewBox="0 0 206 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M85.9727 85.4746L85.9727 65.7934L103.017 55.9523L103.017 95.3154L85.973 105.156L85.973 164.202C78.3976 168.575 68.9283 163.108 68.9283 154.361L68.9283 95.3163L51.8829 85.4755L51.8839 65.7939L68.9286 55.9533L68.9286 75.6347L85.9727 85.4746Z"
        fill="currentColor"
      />
      <path
        d="M120.063 65.7941L128.107 61.1501C133.676 57.9343 140.538 57.9342 146.108 61.1497L154.152 65.794L154.152 164.201C146.577 168.574 137.108 163.107 137.107 154.36L137.107 114.997L120.063 105.156L120.063 65.7941Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.175 154.401L205.176 69.8512C205.176 61.2768 200.602 53.3538 193.176 49.0666L114.908 3.87827C107.482 -0.408816 98.3337 -0.408804 90.9081 3.8783L17.684 46.1531C7.13669 52.2425 0.63918 63.4963 0.63916 75.6752L0.639208 160.226C0.639229 168.801 5.21359 176.724 12.6392 181.011L90.9072 226.199C98.3328 230.486 107.482 230.486 114.907 226.199L188.13 183.923C198.678 177.834 205.175 166.58 205.175 154.401ZM98.9079 18.941L21.6839 63.5252C19.2087 64.9542 17.6838 67.5953 17.6838 70.4534L17.6839 159.623C17.6839 162.481 19.2087 165.122 21.6839 166.551L98.9074 211.137C101.383 212.566 104.432 212.566 106.907 211.137L184.13 166.551C186.606 165.122 188.13 162.481 188.13 159.623L188.131 70.4543C188.131 67.5962 186.607 64.9551 184.131 63.526L106.908 18.941C104.433 17.512 101.383 17.512 98.9079 18.941Z"
        fill="currentColor"
      />
    </svg>
  );
}
