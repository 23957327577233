import classes from "./StackIcons.module.css";

export function SportsTeamIcon() {
  return (
    <svg
      width="207"
      height="230"
      viewBox="0 0 207 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M103.378 95.2778L86.3335 105.118L86.3343 124.799L103.378 134.639L120.423 124.8L120.422 105.118L103.378 95.2778Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.537 69.8125L205.536 154.363C205.536 166.542 199.038 177.795 188.491 183.885L115.268 226.16C107.842 230.448 98.6937 230.448 91.2681 226.16L13 180.972C5.57443 176.685 1.00007 168.762 1.00005 160.188L1 75.6365C1.00002 63.4576 7.49753 52.2038 18.0448 46.1144L91.269 3.83957C98.6945 -0.447531 107.843 -0.447542 115.269 3.83954L193.537 49.0279C200.963 53.3151 205.537 61.2381 205.537 69.8125ZM86.3325 26.3913L86.3335 46.0747L103.378 55.9144L120.423 46.0739L120.424 26.4974L171.467 55.967L154.511 65.7562L154.512 85.4368L171.556 95.2766L188.492 85.4987L188.491 144.417L171.557 134.641L154.512 144.481L154.512 164.163L171.537 173.992L120.423 203.503L120.423 183.844L103.378 174.004L86.3343 183.844L86.3335 203.525L35.1989 174.002L52.2447 164.163L52.2447 144.481L35.1999 134.64L18.1548 144.481L18.1548 85.4367L35.1995 95.2772L52.2446 85.4358L52.2447 65.7544L35.2005 55.9136L86.3325 26.3913Z"
        fill="currentColor"
      />
      <path
        d="M103.378 95.2778L86.3335 105.118L86.3343 124.799L103.378 134.639L120.423 124.8L120.422 105.118L103.378 95.2778Z"
        stroke="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.537 69.8125L205.536 154.363C205.536 166.542 199.038 177.795 188.491 183.885L115.268 226.16C107.842 230.448 98.6937 230.448 91.2681 226.16L13 180.972C5.57443 176.685 1.00007 168.762 1.00005 160.188L1 75.6365C1.00002 63.4576 7.49753 52.2038 18.0448 46.1144L91.269 3.83957C98.6945 -0.447531 107.843 -0.447542 115.269 3.83954L193.537 49.0279C200.963 53.3151 205.537 61.2381 205.537 69.8125ZM86.3325 26.3913L86.3335 46.0747L103.378 55.9144L120.423 46.0739L120.424 26.4974L171.467 55.967L154.511 65.7562L154.512 85.4368L171.556 95.2766L188.492 85.4987L188.491 144.417L171.557 134.641L154.512 144.481L154.512 164.163L171.537 173.992L120.423 203.503L120.423 183.844L103.378 174.004L86.3343 183.844L86.3335 203.525L35.1989 174.002L52.2447 164.163L52.2447 144.481L35.1999 134.64L18.1548 144.481L18.1548 85.4367L35.1995 95.2772L52.2446 85.4358L52.2447 65.7544L35.2005 55.9136L86.3325 26.3913Z"
        stroke="currentColor"
      />
    </svg>
  );
}
