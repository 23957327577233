import {
  Combobox,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
  useCombobox,
} from "@mantine/core";
import { IconHexagon } from "@tabler/icons-react";
import {
  assistants,
  type AssistantId,
} from "api/src/generated/storyblok/assistants";
import type { Assistant } from "api/src/models/Assistant";
import { useMemo } from "react";
import { assistantIcons } from "../../generated/assistantIcons";
import { assistantById } from "../../utils/assistantById";

export function AssistantSwitcher({
  disabled = false,
  selectedAssistant,
  onSelectAssistant,
  children,
}: {
  children: (args: { onToggle: () => void }) => JSX.Element;
  disabled?: boolean;
  selectedAssistant?: AssistantId;
  onSelectAssistant: (assistant: Assistant) => void;
}) {
  const combobox = useCombobox();
  const assistant = useMemo(
    () => assistantById(selectedAssistant ?? "general-assistant"),
    [selectedAssistant],
  );

  return (
    <Combobox
      store={combobox}
      withinPortal
      position="bottom-end"
      width={280}
      shadow="md"
      disabled={disabled}
      onOptionSubmit={(value) => {
        onSelectAssistant(assistantById(value as AssistantId));
        combobox.toggleDropdown();
      }}
      offset={3}
    >
      <Combobox.Target>
        <Tooltip label="Pick assistant and generate your creation">
          {children({
            onToggle: () => {
              combobox.toggleDropdown();
            },
          })}
        </Tooltip>
      </Combobox.Target>
      <Combobox.Dropdown miw={350}>
        <Combobox.Options defaultValue={assistant.id}>
          {assistants.map((a) => {
            const id = a.id as AssistantId;
            const Icon = assistantIcons[id];

            return (
              <Combobox.Option key={a.id} value={a.id}>
                <Group align="flex-start">
                  <ThemeIcon color="dark" variant="transparent">
                    {Icon ? <Icon /> : <IconHexagon />}
                  </ThemeIcon>
                  <Stack gap={5} flex={1}>
                    <Text span size="sm" fw={600}>
                      {a.name}
                    </Text>
                    <Text size="xs" c="base.7">
                      {a.description}
                    </Text>
                  </Stack>
                </Group>
              </Combobox.Option>
            );
          })}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
