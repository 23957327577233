import classes from "./StackIcons.module.css";

export function RealtorIcon() {
  return (
    <svg
      width="206"
      height="230"
      viewBox="0 0 206 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.731 50.6582L128.687 40.8183L128.686 40.8183L60.5105 80.1803L60.5106 158.906L77.5553 168.747L86.0769 163.826L86.0769 183.507L103.121 193.348L120.166 183.507L120.166 144.144L145.735 129.424L145.735 129.382L145.731 50.6582ZM77.5552 90.0202L86.077 85.1L86.077 94.3889C86.077 100.82 89.5078 106.762 95.077 109.977L103.122 114.622L86.0772 124.463L103.122 134.302L120.166 124.462L120.166 115.173C120.166 108.743 116.736 102.801 111.167 99.5851L103.123 94.9401L120.167 85.0998L103.122 75.2589L128.69 60.4969L128.69 119.584L77.5559 149.064L77.5552 90.0202Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.963837 154.026L0.962891 69.4755C0.962875 60.9011 5.53725 52.9781 12.9629 48.6909L91.2311 3.50254C98.6567 -0.784548 107.805 -0.784536 115.231 3.50257L188.455 45.7774C199.002 51.8668 205.5 63.1206 205.5 75.2995L205.5 159.851C205.5 168.425 200.925 176.348 193.5 180.635L115.232 225.823C107.806 230.111 98.6575 230.111 91.2318 225.823L18.0086 183.548C7.46138 177.458 0.963882 166.205 0.963837 154.026ZM107.231 18.5652L184.455 63.1494C186.93 64.5785 188.455 67.2195 188.455 70.0777L188.455 159.248C188.455 162.106 186.93 164.747 184.455 166.176L107.232 210.761C104.756 212.19 101.707 212.19 99.2316 210.761L22.0086 166.176C19.5334 164.747 18.0086 162.106 18.0086 159.248L18.0077 70.0786C18.0076 67.2204 19.5324 64.5794 22.0077 63.1503L99.2311 18.5653C101.706 17.1362 104.756 17.1362 107.231 18.5652Z"
        fill="currentColor"
      />
    </svg>
  );
}
