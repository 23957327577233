import type { IdentityRole } from "../models/Identity";

export type ErrorCode = 404 | 403 | 500;

export type PermissionAction = "create" | "read" | "update" | "delete";

export type PermissionTarget =
  | "account"
  | "benefit"
  | "organization"
  | "identity"
  | "facts"
  | "generated_result"
  | "insights"
  | "referral"
  | "convo"
  | "coupon";

export type PermissionLevel =
  | "billing"
  | "blocks-permissions"
  | "assist"
  | "profile-basic"
  | "profile-advanced"
  | "ownership";

export const rolesWithLimitedBlockAccess = ["editor", "creator", "client"];

export type PermissionPath =
  | `${PermissionTarget}.${PermissionAction}`
  | `${PermissionTarget}.${PermissionAction}.${PermissionLevel}`;

export const rolePermissions: {
  [role in IdentityRole]: {
    permissions: PermissionPath[];
  };
} = {
  owner: {
    permissions: [
      "account.update.billing",
      "benefit.read",
      "generated_result.create",
      "generated_result.delete",
      "identity.create",
      "identity.update.profile-basic",
      "identity.update.profile-advanced",
      "identity.delete",
      "insights.read",
      "facts.read",
      "facts.read.assist",
      "facts.create",
      "facts.update",
      "facts.delete",
      "facts.create.blocks-permissions",
      "facts.update.blocks-permissions",
      "organization.create",
      "organization.read",
      "organization.update",
      "organization.update.ownership",
      "referral.read",
      "convo.create",
      "convo.delete",
      "convo.read",
      "convo.update",
      "coupon.read",
      "coupon.update",
    ],
  },
  admin: {
    permissions: [
      "benefit.read",
      "generated_result.create",
      "identity.create",
      "identity.update.profile-basic",
      "identity.update.profile-advanced",
      "identity.delete",
      "insights.read",
      "facts.read",
      "facts.read.assist",
      "facts.create",
      "facts.update",
      "facts.create.blocks-permissions",
      "facts.update.blocks-permissions",
      "organization.create",
      "organization.read",
      "organization.update",
      "referral.read",
      "convo.create",
      "convo.delete",
      "convo.read",
      "convo.update",
      "coupon.read",
      "coupon.update",
    ],
  },
  editor: {
    permissions: [
      "benefit.read",
      "generated_result.create",
      "identity.update.profile-basic",
      "insights.read",
      "facts.read",
      "facts.read.assist",
      "facts.create",
      "facts.update",
      "organization.read",
      "referral.read",
      "convo.create",
      "convo.read",
      "convo.update",
      "coupon.read",
      "coupon.update",
    ],
  },
  creator: {
    permissions: [
      "benefit.read",
      "generated_result.create",
      "identity.update.profile-basic",
      "insights.read",
      "facts.read",
      "facts.read.assist",
      "organization.read",
      "referral.read",
      "convo.create",
      "convo.read",
      "convo.update",
    ],
  },
  client: {
    permissions: ["facts.read", "facts.update", "convo.read"],
  },
};

export function isGranted(props: {
  identityRole: IdentityRole;
  permission: PermissionPath;
}) {
  const { identityRole, permission } = props;
  const permissions = rolePermissions[identityRole]?.permissions ?? [];

  const allowedRoles = Object.keys(rolePermissions).filter((key) => {
    return rolePermissions[key as IdentityRole].permissions.includes(
      permission,
    );
  });
  return {
    hasPermission: permissions.includes(permission),
    allowedRoles,
  };
}
