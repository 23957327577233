import { ErrorCode, PermissionPath } from "api/src/auth/permissions";
import type { Identity } from "api/src/models/Identity";
import { useNavigate } from "react-router-dom";
import { useIsGranted } from "../../auth/useIsGranted";

type CanProps = {
  userIdentity?: Pick<Identity, "primaryRole">;
  execute: PermissionPath;
  redirect?: ErrorCode;
  fallback?: JSX.Element | ErrorCode;
  children: JSX.Element;
};

export function Can({
  userIdentity,
  execute,
  redirect,
  fallback,
  children,
}: CanProps) {
  const navigate = useNavigate();
  const { hasPermission } = useIsGranted({
    defaultRole: userIdentity?.primaryRole,
    permission: execute,
  });

  if (!hasPermission && redirect) {
    navigate(`/${redirect}`);
    return null;
  }

  if (!hasPermission && fallback) {
    return fallback;
  }

  return hasPermission ? <>{children}</> : null;
}
