import { useCallback, useMemo } from "react";
import { useIsPreviewMode } from "../../utils/useAccount";
import {
  BlocksGroupedByStack,
  Pillar,
  usePillars,
} from "../../utils/usePillars";
import { Fact } from "api/src/models/Fact";
import { useIsBlockReadable } from "./useIsBlockReadable";

const previewBlocks = ["foundations", "brand"];

export function useIsStackReadable() {
  const isPreviewMode = useIsPreviewMode();

  const isStackReadable = useCallback(
    (slug?: string) => {
      return (
        !!slug &&
        (!isPreviewMode || (isPreviewMode && previewBlocks.includes(slug)))
      );
    },
    [isPreviewMode],
  );

  return {
    isStackReadable,
  };
}

export function useFilteredStacksWithOnlyReadableBlocks() {
  const { pillars: stacks, isLoading, isFetching } = usePillars();
  const { isBlockReadable } = useIsBlockReadable();

  const viewableStacks = useMemo(
    () =>
      stacks
        ?.filter((st) => st.content.modules.some(isBlockReadable))
        .map((st) => {
          return {
            ...st,
            content: {
              ...st.content,
              // Only return the modules that are allowed
              modules: st.content.modules.filter(isBlockReadable),
            },
          };
        }),
    [isBlockReadable, stacks],
  );

  return { stacks: viewableStacks, isLoadingStacks: isLoading || isFetching };
}

/**
 * Mashes all the strategy data together into a convenient
 * shape:
 */
export function groupModulesByStack(
  stacks: Pillar[],
  facts: Fact[],
): BlocksGroupedByStack[] {
  return (
    stacks
      .map((sta) => ({
        stack: {
          ...sta,
          description: sta.content.descriptive_summary,
        },
        blocks: sta.content.modules.map((mod) => ({
          ...mod,
          // Match module with facts data:
          fact: facts.find(({ blockPath }) => blockPath === mod.knowledgeslug),
          stackSlug: sta.slug,
          stackName: sta.name,
        })),
      }))
      // Somewhat wastefully, remove all pillars with no modules after all filtering is done
      .filter((p) => p.blocks.length > 0)
  );
}
