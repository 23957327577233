import { type Identity } from "api/src/models/Identity";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../auth/useAuthContext";
import { Can } from "../../../components/auth/Can";
import { useOrganizationById } from "../../../utils/useOrganization";
import { AccountSubSection } from "../sections";
import { BlocksSubSection } from "./BlocksSubSection";
import { GeneralSubSection } from "./GeneralSubSection";
import { TeamSubSection } from "./TeamSubSection";

export type OrganizationProps = {
  name: string;
  id: string;
  slug: string;
  identities?: Identity[];
  currentUserIdentity?: Identity;
};

export function OrganizationSection() {
  const { subSection } = useParams();
  const { identity } = useAuthContext();
  const { data: organization } = useOrganizationById(identity.organizationId);

  function organizationSubSection() {
    switch (subSection) {
      case AccountSubSection.General:
        return organization ? (
          <GeneralSubSection organization={organization} />
        ) : null;
      case AccountSubSection.Team:
        return <TeamSubSection />;
      case AccountSubSection.Blocks:
        return organization ? (
          <BlocksSubSection organization={organization} />
        ) : null;
    }
  }

  return (
    <Can execute="organization.read">{organizationSubSection() ?? <></>}</Can>
  );
}
