import classes from "./StackIcons.module.css";

export function PersonalBrandIcon() {
  return (
    <svg
      width="205"
      height="230"
      viewBox="0 0 205 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2559 134.272L102.607 189.769L170.839 134.266L170.557 133.919L170.557 75.2591L119.424 45.7368L85.3324 45.7349L85.3325 45.7368L34.1992 75.259L34.2002 134.304L34.2559 134.272ZM51.244 123.591L51.2446 85.0997L76.8114 70.3387C71.5378 73.3834 68.2891 79.0103 68.2891 85.0997C68.289 91.1892 71.5377 96.8162 76.8114 99.8609L119.423 124.461L102.378 134.304L85.3339 124.463L68.2892 134.304L93.3782 148.789C98.9474 152.004 105.809 152.004 111.378 148.789L127.946 139.223C133.22 136.178 136.468 130.551 136.468 124.462C136.467 118.373 133.219 112.747 127.946 109.702L85.3338 85.0987L102.376 75.2595L119.424 85.0998L136.467 75.2598L153.512 85.1005L153.512 123.868L102.596 165.286L51.244 123.591Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.536 154.026L204.537 69.4755C204.537 60.9011 199.963 52.9781 192.537 48.6909L114.269 3.50254C106.843 -0.784548 97.6945 -0.784536 90.269 3.50257L17.0448 45.7774C6.49753 51.8668 2.01768e-05 63.1206 0 75.2995L4.77098e-05 159.851C6.84045e-05 168.425 4.57443 176.348 12 180.635L90.2681 225.823C97.6937 230.111 106.842 230.111 114.268 225.823L187.491 183.548C198.038 177.458 204.536 166.205 204.536 154.026ZM98.2688 18.5652L21.0448 63.1494C18.5695 64.5785 17.0447 67.2195 17.0447 70.0777L17.0448 159.248C17.0448 162.106 18.5695 164.747 21.0447 166.176L98.2683 210.761C100.743 212.19 103.793 212.19 106.268 210.761L183.491 166.176C185.966 164.747 187.491 162.106 187.491 159.248L187.492 70.0786C187.492 67.2204 185.967 64.5794 183.492 63.1503L106.269 18.5653C103.794 17.1362 100.744 17.1362 98.2688 18.5652Z"
        fill="currentColor"
      />
    </svg>
  );
}
