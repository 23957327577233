import { Fact } from "api/src/models/Fact";
import { useEffect, useMemo, useRef } from "react";
import { RobotInputModuleStoryblok } from "../../storyblok-types";
import { Pillar } from "../../utils/usePillars";
import { Anchor, Box, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useToggle } from "../../utils/useToggle";
import { useIsFeatureEnabled } from "../../utils/useIsFeatureEnabled";
import { FeatureFlag } from "api/src/featureFlags";

export type StackLockingCreateStats = Pillar & {
  completionMessage: string;
  stackPercentComplete: number;
  completeBlocks: number;
  totalBlocks: number;
};

/**
 * Figures out some quick stats for Stacks and Blocks completion, for use in
 * Strategy browser viz.
 */
export function useBlocksStats({
  stacks,
  filteredStacks,
  facts,
}: {
  stacks: Pillar[];
  filteredStacks: Pillar[];
  facts: Fact[];
}) {
  const factSlugs = useMemo<string[]>(
    () => facts.map((f) => f.blockPath),
    [facts],
  );

  const inputBlocks = useMemo<RobotInputModuleStoryblok[]>(
    () =>
      filteredStacks
        .flatMap((p) => p.content.modules)
        .filter(
          (m) => m.component === "robot-input-module",
        ) as RobotInputModuleStoryblok[],
    [filteredStacks],
  );

  const completeInputBlocks = useMemo<number>(() => {
    return inputBlocks.filter((m) => factSlugs.includes(m.knowledgeslug))
      .length;
  }, [inputBlocks, factSlugs]);

  const stacksLockingCreate = useMemo<StackLockingCreateStats[]>(
    () =>
      stacks
        .filter((p) => p.content.modules.some((m) => !!m.lockingcreator))
        .map((p) => {
          const blocksLockingCreate = p.content.modules.filter(
            (m) => !!m.lockingcreator,
          );
          const totalBlocks = blocksLockingCreate.length;
          const completeBlocks = blocksLockingCreate.filter((m) =>
            factSlugs.includes(m.knowledgeslug!),
          ).length;
          const stackPercentComplete = Math.round(
            (completeBlocks / totalBlocks) * 100,
          );

          return {
            ...p,
            completionMessage: p.content.completionmessage,
            stackPercentComplete,
            totalBlocks,
            completeBlocks: completeBlocks,
          };
        }),
    [factSlugs, stacks],
  );

  return {
    consideredStacks: filteredStacks.length,
    totalInputBlocks: inputBlocks.length,
    completeInputBlocks,
    stacksLockingCreate,
  };
}

export function useNotifyOnCreateUnlocked({
  isCreateBlocksRequiredIncomplete,
  stacksLockingCreate,
}: {
  isCreateBlocksRequiredIncomplete: boolean;
  stacksLockingCreate: StackLockingCreateStats[];
}) {
  const prevIsCreateBlocksRequiredIncomplete = useRef<boolean>();
  const prevStacksLockingCreate = useRef<StackLockingCreateStats[]>();
  const [isCreateUnlocked, setIsCreateUnlocked] = useToggle();
  const isNewBuildModeEnabled = useIsFeatureEnabled(
    FeatureFlag.UseNewBuildMode,
  );

  const showNotification = (message: React.ReactNode) => {
    notifications.show({
      message,
      color: "green",
      icon: <IconCheck size={18} />,
    });
  };

  useEffect(() => {
    // Show notification message when all Stacks completed all required Blocks to unlock NOAN Create
    if (
      !!prevIsCreateBlocksRequiredIncomplete.current &&
      !isCreateBlocksRequiredIncomplete
    ) {
      setIsCreateUnlocked(true);
      if (!isNewBuildModeEnabled) {
        showNotification(
          <Box>
            <Text size="sm" fw="bold" tt="uppercase" lts="2px" mb="xs">
              Congrats!{" "}
            </Text>
            <Text size="sm">
              You've unlocked{" "}
              <Anchor component="a" href="/create">
                Create
              </Anchor>
              , start generating content for your brand. The more you build your
              strategy the better Create gets so keep working through your
              pillars.
            </Text>
          </Box>,
        );
      }
    }
    prevIsCreateBlocksRequiredIncomplete.current =
      isCreateBlocksRequiredIncomplete;
  }, [
    isCreateBlocksRequiredIncomplete,
    isNewBuildModeEnabled,
    setIsCreateUnlocked,
  ]);

  useEffect(() => {
    // Show notification message when some Stack completed all required Blocks to unlock Create
    if (isCreateBlocksRequiredIncomplete) {
      stacksLockingCreate
        .filter((p) => p.stackPercentComplete === 100)
        .map((p, idx) => {
          const prevStack = prevStacksLockingCreate.current?.[idx];
          // Only show the notification once the required Blocks are all completed.
          // Checking the previous value avoids rendering the notification at every page render
          // when all the pillars were already previously completed.
          if (
            p.completionMessage &&
            prevStack?.stackPercentComplete &&
            prevStack.completeBlocks !== p.totalBlocks
          )
            showNotification(<Text size="sm">{p.completionMessage}</Text>);
        });
      prevStacksLockingCreate.current = stacksLockingCreate;
    }
  }, [isCreateBlocksRequiredIncomplete, stacksLockingCreate]);

  return {
    isCreateUnlocked,
  };
}
