import {
  Badge,
  Box,
  Card,
  Divider,
  Grid,
  NavLink,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { IconSparkles, IconUser } from "@tabler/icons-react";
import { Link, useParams } from "react-router-dom";
import { useAuthContext } from "../../auth/useAuthContext";
import { useIsGranted } from "../../auth/useIsGranted";
import { Page } from "../../components/Page";
import { Can } from "../../components/auth/Can";
import { BenefitsSection } from "./BenefitsSection";
import { BillingSection } from "./BillingSection";
import { OrganizationSection } from "./Organization";
import { ProfileSection } from "./ProfileSection";
import { ReferralSection } from "./ReferralSection";
import { AccountSection, accountPageSections } from "./sections";

function AccountSectionContent({ section }: { section: AccountSection }) {
  switch (section) {
    case AccountSection.Profile:
      return <ProfileSection />;
    case AccountSection.Benefits:
      return <BenefitsSection />;
    case AccountSection.Referral:
      return <ReferralSection />;
    case AccountSection.Billing:
      return <BillingSection />;
    case AccountSection.Organization:
      return <OrganizationSection />;
  }
}

export function Account() {
  const { section, subSection } = useParams();
  const isBenefitsSection = section === AccountSection.Benefits;
  const sectionDetails =
    accountPageSections[(section ?? AccountSection.Profile) as AccountSection];
  const { isPayingCustomer } = useAuthContext();
  const { hovered: isBenefitsHovered, ref } = useHover();
  const { hasPermission: canDeleteFacts } = useIsGranted({
    permission: "facts.delete",
  });
  const { hasPermission: canUpdateFactsBlocks } = useIsGranted({
    permission: "facts.update.blocks-permissions",
  });
  const canAccessBlocksSubsection = canDeleteFacts || canUpdateFactsBlocks;

  return (
    <Page title={sectionDetails.title} description={sectionDetails.description}>
      <Grid columns={6}>
        <Grid.Col
          span={{ base: 6, sm: isBenefitsSection ? 0 : 2 }}
          hiddenFrom={isBenefitsSection ? "sm" : undefined}
        >
          <Card p="xl">
            <Card.Section>
              <NavLink
                href="#personal-account"
                rightSection={<></>}
                leftSection={
                  <ThemeIcon variant="transparent" c="dimmed" size="sm">
                    <IconUser />
                  </ThemeIcon>
                }
                opened
                c="dimmed"
                label="Your account"
                styles={{
                  root: {
                    cursor: "default",
                  },
                  children: {
                    padding: 0,
                  },
                }}
              >
                <Divider mb="xs" />
                <NavLink
                  label={accountPageSections.profile.title}
                  variant="light"
                  description={accountPageSections.profile.description}
                  component={Link}
                  to={accountPageSections.profile.path}
                  active={!section || section === AccountSection.Profile}
                />
                <Can execute="organization.read">
                  <NavLink
                    label={accountPageSections.organization.title}
                    variant="light"
                    description={accountPageSections.organization.description}
                    component={Link}
                    to={accountPageSections.organization.path}
                    active={section === AccountSection.Organization}
                  >
                    <NavLink
                      label={
                        accountPageSections.organization.subSections!.general
                          .title
                      }
                      variant="light"
                      component={Link}
                      to={
                        accountPageSections.organization.subSections!.general
                          .path
                      }
                      active={
                        section === AccountSection.Organization &&
                        subSection ===
                          accountPageSections.organization.subSections!.general.title.toLowerCase()
                      }
                    />
                    <NavLink
                      label={
                        accountPageSections.organization.subSections!.team.title
                      }
                      variant="light"
                      component={Link}
                      to={
                        accountPageSections.organization.subSections!.team.path
                      }
                      active={
                        section === AccountSection.Organization &&
                        subSection ===
                          accountPageSections.organization.subSections!.team.title.toLowerCase()
                      }
                    />
                    {canAccessBlocksSubsection && (
                      <NavLink
                        label={
                          accountPageSections.organization.subSections!.blocks
                            .title
                        }
                        variant="light"
                        component={Link}
                        to={
                          accountPageSections.organization.subSections!.blocks
                            .path
                        }
                        active={
                          section === AccountSection.Organization &&
                          subSection ===
                            accountPageSections.organization.subSections!.blocks.title.toLowerCase()
                        }
                      />
                    )}
                  </NavLink>
                </Can>
                <Can execute="benefit.read">
                  <Tooltip
                    label="Member benefits available to paid NOAN subscribers only"
                    opened={isBenefitsHovered && !isPayingCustomer}
                  >
                    <Box ref={ref}>
                      <NavLink
                        disabled={!isPayingCustomer}
                        label={accountPageSections.benefits.title}
                        variant="light"
                        description={accountPageSections.benefits.description}
                        rightSection={
                          <Badge
                            color="teal"
                            size="sm"
                            opacity={isBenefitsSection ? 0.5 : 1}
                            leftSection={
                              <ThemeIcon
                                variant="transparent"
                                size="sm"
                                color="white"
                                className="animate-pulse"
                              >
                                <IconSparkles />
                              </ThemeIcon>
                            }
                          >
                            NEW
                          </Badge>
                        }
                        component={Link}
                        to={accountPageSections.benefits.path}
                        active={isBenefitsSection}
                        c={isBenefitsSection ? undefined : "teal.2"}
                      />
                    </Box>
                  </Tooltip>
                </Can>
                <Can execute="referral.read">
                  <NavLink
                    label={accountPageSections.referral.title}
                    variant="light"
                    description={accountPageSections.referral.description}
                    component={Link}
                    to={accountPageSections.referral.path}
                    active={section === AccountSection.Referral}
                  />
                </Can>
                <Can execute="account.update.billing">
                  <NavLink
                    label={accountPageSections.billing.title}
                    variant="light"
                    description={accountPageSections.billing.description}
                    component={Link}
                    to={accountPageSections.billing.path}
                    active={section === AccountSection.Billing}
                  />
                </Can>
              </NavLink>
            </Card.Section>
          </Card>
        </Grid.Col>
        <Grid.Col span={{ base: 6, sm: isBenefitsSection ? 6 : 4 }}>
          <AccountSectionContent
            section={(section ?? AccountSection.Profile) as AccountSection}
          />
        </Grid.Col>
      </Grid>
    </Page>
  );
}
