import classes from "./StackIcons.module.css";

export function EducationIcon() {
  return (
    <svg
      width="206"
      height="230"
      viewBox="0 0 206 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.7441 144.144L34.7001 153.984L34.6992 94.9405L102.877 55.5766L102.878 55.5776L171.057 94.9406L136.969 114.622L136.968 153.985L111.878 168.47C106.309 171.686 99.4474 171.686 93.8782 168.47L68.7892 153.985V114.622L51.7448 104.781L51.7439 104.782L51.7441 144.144ZM102.877 75.2576L136.967 94.9413L102.878 114.622L102.879 114.621L68.7896 94.9408L102.877 75.2576ZM85.834 144.144L85.834 124.463L102.878 134.304L119.923 124.463L119.923 144.144L102.878 153.985L85.834 144.144Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.036 154.026L205.037 69.4755C205.037 60.9011 200.463 52.9781 193.037 48.6909L114.769 3.50254C107.343 -0.784548 98.1945 -0.784536 90.769 3.50257L17.5448 45.7774C6.99753 51.8668 0.50002 63.1206 0.5 75.2995L0.500048 159.851C0.500068 168.425 5.07443 176.348 12.5 180.635L90.7681 225.823C98.1937 230.111 107.342 230.111 114.768 225.823L187.991 183.548C198.538 177.458 205.036 166.205 205.036 154.026ZM98.7688 18.5652L21.5448 63.1494C19.0695 64.5785 17.5447 67.2195 17.5447 70.0777L17.5448 159.248C17.5448 162.106 19.0695 164.747 21.5447 166.176L98.7683 210.761C101.243 212.19 104.293 212.19 106.768 210.761L183.991 166.176C186.466 164.747 187.991 162.106 187.991 159.248L187.992 70.0786C187.992 67.2204 186.467 64.5794 183.992 63.1503L106.769 18.5653C104.294 17.1362 101.244 17.1362 98.7688 18.5652Z"
        fill="currentColor"
      />
    </svg>
  );
}
