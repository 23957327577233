import {
  aiGenerators as aiGeneratorsEnum,
  type GeneratorUntrustedInput,
} from "api/src/models/GeneratorInput";
import { aiGenerators } from "../../useGenerator";

// TODO: Typing around generators is messy right now
export function isGeneratorId(
  value: string | undefined,
): value is Required<GeneratorUntrustedInput>["generator"] {
  return (
    value != null &&
    aiGeneratorsEnum.includes(value as (typeof aiGeneratorsEnum)[number])
  );
}

export function generatorFromId(value: string) {
  const genId = value === "default" ? aiGenerators.at(0)?.value : value;
  const generator = aiGenerators.find((g) => g.value === genId);

  if (generator == null) {
    throw new Error(
      `invariant: could not find ai generator with value '${value}'`,
    );
  }

  return generator;
}
