import classes from "./StackIcons.module.css";

export function AccommodationIcon() {
  return (
    <svg
      width="205"
      height="230"
      viewBox="0 0 205 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.378 41.5905C105.809 38.3755 98.9476 38.3757 93.3787 41.5909L34.2 75.7559L34.199 75.757L34.199 95.4384L51.2437 105.279L51.2451 164.323L102.378 193.846L153.512 164.322L153.512 105.279L170.556 95.438L170.556 75.758L111.378 41.5905ZM102.377 56.0755L68.2893 75.7571L102.378 95.4376L136.468 75.7574L102.377 56.0755ZM136.467 134.802L119.423 144.642L119.424 124.96L136.467 115.12L136.468 95.438L102.378 115.12L68.2893 95.4385L68.2894 154.482L85.3335 164.323V124.961L102.378 134.801L102.378 174.165L136.467 154.482L136.467 134.802Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.536 154.524L204.537 69.9734C204.537 61.399 199.963 53.476 192.537 49.1888L114.269 4.00046C106.843 -0.286623 97.6945 -0.286612 90.269 4.00049L17.0448 46.2753C6.49753 52.3647 2.01768e-05 63.6185 0 75.7974L4.77098e-05 160.349C6.84045e-05 168.923 4.57443 176.846 12 181.133L90.2681 226.321C97.6937 230.608 106.842 230.608 114.268 226.321L187.491 184.046C198.038 177.956 204.536 166.702 204.536 154.524ZM98.2688 19.0632L21.0448 63.6474C18.5695 65.0764 17.0447 67.7175 17.0447 70.5756L17.0448 159.745C17.0448 162.604 18.5695 165.245 21.0447 166.674L98.2683 211.259C100.743 212.688 103.793 212.688 106.268 211.259L183.491 166.674C185.966 165.245 187.491 162.604 187.491 159.745L187.492 70.5765C187.492 67.7183 185.967 65.0773 183.492 63.6482L106.269 19.0632C103.794 17.6342 100.744 17.6342 98.2688 19.0632Z"
        fill="currentColor"
      />
    </svg>
  );
}
