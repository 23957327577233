import {
  ActionIcon,
  Box,
  Button,
  Card,
  Divider,
  Drawer,
  Group,
  Image,
  Loader,
  Modal,
  Stack,
  Tabs,
  Text,
  Textarea,
  Title,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconBug,
  IconCheck,
  IconCopyPlus,
  IconDeviceFloppy,
  IconMessage,
  IconPencil,
  IconRefresh,
  IconRocket,
  IconSparkles,
  IconWorldSearch,
} from "@tabler/icons-react";
import { MessageRole } from "api/src/models/GeneratorInput";
import { useState } from "react";
import { PROMPT_MAX_LENGTH } from "../../components/ConfigurableBlockCard/config";
import { MarkdownContent } from "../../components/MarkdownContent";
import { RestrictedFeature } from "../../components/RestrictedFeature";
import { TextCopier } from "../../components/TextCopier";
import { Can } from "../../components/auth/Can";
import { useGeneratorQuery } from "../../useGenerator";
import { useCreateFactVersion, useFact } from "../../utils/useFacts";
import classes from "./ContentEnhancer.module.css";

function formatNonEmptyLines(
  content: (string | undefined)[],
  joinSign: string = "\n",
) {
  return content.filter(Boolean).join(joinSign);
}

interface ContentAssessorFormFields {
  promptText: string;
  newContent: string;
}

/**
 * @deprecated This will be deleted once 'use-new-build-mode' flag is enabled.
 */
export function ContentEnhancer({
  factId,
  block = "",
  content,
  questions = [],
  opened,
  close,
}: {
  factId: string;
  block?: string;
  content: string;
  questions?: string[];
  opened: boolean;
  close: () => void;
}) {
  const [
    confirmReplaceContentModalOpened,
    {
      close: closeConfirmReplaceContentModal,
      open: openConfirmReplaceContentModal,
    },
  ] = useDisclosure();
  const { values, getInputProps, setFieldValue } =
    useForm<ContentAssessorFormFields>({
      initialValues: {
        promptText: "",
        newContent: content ?? "",
      },
    });
  const { fact } = useFact({
    factId,
  });
  const { createFactVersion } = useCreateFactVersion();
  const [prompt, setPrompt] = useState<string | undefined>(undefined);
  const { generatedText, isLoading, isLoadingResponse } = useGeneratorQuery({
    enabled: !!prompt,
    generate: {
      role: MessageRole.User,
      input: prompt,
      generator: "perplexity",
      skipSaving: true,
    },
    onEndGenerate() {
      setFieldValue("answer", generatedText);
      setPrompt(undefined);
    },
  });

  function onReplaceBlockContent() {
    createFactVersion(
      {
        blockPath: fact!.blockPath,
        content: {
          format: {
            type: "text",
          },
          plainText: values.newContent,
        },
      },
      {
        onSuccess: () => {
          notifications.show({
            message: `Strategy Block ${block} updated.`,
            icon: <IconCheck size={16} />,
            color: "green",
            autoClose: 2000,
          });
        },

        onError: () => {
          notifications.show({
            icon: <IconBug size={16} />,
            color: "red",
            autoClose: 2000,
            message: `An unknown error ocurred while updating your Strategy Block ${block}`,
          });
        },
      },
    );
    closeConfirmReplaceContentModal();
  }

  function onSelectQuestion(question: string) {
    setFieldValue("promptText", question);
    setPrompt(question);
  }

  const hasContentChanged = values.newContent !== content;

  return (
    <Drawer
      size="xl"
      position="right"
      transitionProps={{
        transition: "slide-left",
        duration: 150,
        timingFunction: "linear",
      }}
      opened={opened}
      onClose={close}
      title={
        <Stack>
          <Group gap="xs">
            <Image src="/perplexity.png" w={24} />
            <Title order={4}>Assist</Title>
            <Text size="sm" c="gray.6">
              Ask questions to develop & improve your{" "}
              <Text span fw="bold">
                {block}
              </Text>
              . The answers are based on real-time internet search and anything
              you add about your business.
            </Text>
          </Group>
        </Stack>
      }
    >
      <RestrictedFeature>
        {({ restrictedClassName }) => (
          <>
            <Card mt="lg" withBorder className={restrictedClassName}>
              <Stack>
                <Textarea
                  radius="md"
                  label={
                    <Group gap="xs">
                      <IconMessage size={18} />
                      <Text size="sm">Question</Text>
                    </Group>
                  }
                  maxLength={PROMPT_MAX_LENGTH}
                  descriptionProps={{
                    size: "xs",
                    c: "gray.6",
                  }}
                  description={
                    <>
                      What would you like to know about{" "}
                      <Text span fw="bold" size="xs">
                        {block}
                      </Text>{" "}
                      strategy? {values.promptText.length}/{PROMPT_MAX_LENGTH}
                    </>
                  }
                  autosize
                  resize="vertical"
                  {...getInputProps("promptText")}
                />
                <Tooltip label="Start live search">
                  <Button
                    size="xs"
                    w="fit-content"
                    onClick={() => setPrompt(values.promptText)}
                    disabled={isLoading || !values.promptText}
                    leftSection={<IconWorldSearch size={18} />}
                  >
                    Search
                  </Button>
                </Tooltip>
                {questions.length > 0 && (
                  <Stack gap="xs">
                    <Stack gap={4}>
                      <Text size="sm">Suggested questions</Text>
                      <Divider />
                    </Stack>
                    {questions.map((q, idx) => (
                      <Button
                        key={idx}
                        radius="lg"
                        title={q}
                        w="fit-content"
                        maw="100%"
                        size="compact-xs"
                        variant="light"
                        disabled={isLoading}
                        onClick={() => onSelectQuestion(q)}
                      >
                        <Text truncate="end" size="xs" span>
                          {q}
                        </Text>
                      </Button>
                    ))}
                  </Stack>
                )}
              </Stack>
            </Card>
            <Card mt="lg" withBorder className={restrictedClassName}>
              <Group justify="space-between">
                <Stack gap={0}>
                  <Group gap="xs">
                    <IconRocket
                      className={isLoading ? classes.infinitePulse : undefined}
                      size={18}
                    />
                    <Text size="sm">Answer</Text>
                  </Group>
                </Stack>
                <Group justify="flex-end" gap={0}>
                  <TextCopier
                    content={generatedText ?? ""}
                    disabled={!generatedText}
                  />
                </Group>
              </Group>
              <Box mx="sm" my={30}>
                {generatedText ? (
                  <MarkdownContent content={generatedText} />
                ) : (
                  <Group gap={4}>
                    {isLoadingResponse ? (
                      <Loader size={18} mr="md" />
                    ) : (
                      <IconSparkles size={18} />
                    )}
                    <Text size="sm">
                      Your generated content will be shown here.
                    </Text>
                  </Group>
                )}
              </Box>
            </Card>
            <Can execute="facts.update">
              <Card mt="lg" withBorder className={restrictedClassName}>
                <Stack>
                  <Group justify="space-between">
                    <Stack gap={6}>
                      <Group gap="xs">
                        <IconPencil size={18} />
                        <Text size="sm">Scratchpad</Text>
                      </Group>
                      <Text size="xs" c="gray.6">
                        A space to update or rework the content of your current
                        Strategy Block. Add to your{" "}
                        <Text span fw="bold">
                          {block}
                        </Text>{" "}
                        block once you're happy with it.
                      </Text>
                    </Stack>
                    <Group justify="flex-end" gap={0}>
                      <Tooltip label="Reset to most recent Smart Block content">
                        <ActionIcon
                          variant="subtle"
                          disabled={!hasContentChanged}
                          onClick={() =>
                            setFieldValue("newContent", content ?? "")
                          }
                        >
                          <IconRefresh size={14} />
                        </ActionIcon>
                      </Tooltip>
                      <Tooltip label="Add scratchpad content to Assist question box">
                        <ActionIcon
                          variant="subtle"
                          color="dimmed"
                          onClick={() =>
                            setFieldValue(
                              "promptText",
                              formatNonEmptyLines([
                                values.promptText,
                                values.newContent,
                              ]),
                            )
                          }
                        >
                          <IconCopyPlus size={14} />
                        </ActionIcon>
                      </Tooltip>
                      <TextCopier
                        content={values.newContent}
                        disabled={!values.newContent}
                      />
                      <Tooltip
                        label={`Replace ${block} Smart Block content with content in Scratchpad`}
                      >
                        <ActionIcon
                          variant="subtle"
                          color="dimmed"
                          disabled={!hasContentChanged || !fact}
                          onClick={openConfirmReplaceContentModal}
                        >
                          <IconDeviceFloppy size={14} />
                        </ActionIcon>
                      </Tooltip>
                    </Group>
                  </Group>
                  <Tabs defaultValue="write">
                    <Tabs.List>
                      <Tabs.Tab value="write">Write</Tabs.Tab>
                      <Tooltip label="How your content will look in a Block">
                        <Tabs.Tab value="preview">Block Preview</Tabs.Tab>
                      </Tooltip>
                    </Tabs.List>
                    <Tabs.Panel value="write">
                      <Textarea
                        my="md"
                        autosize
                        maxRows={12}
                        maxLength={PROMPT_MAX_LENGTH}
                        description={`${values.newContent.length}/${PROMPT_MAX_LENGTH}`}
                        {...getInputProps("newContent")}
                      />
                    </Tabs.Panel>
                    <Tabs.Panel value="preview">
                      <Box mx="sm" my={30}>
                        <MarkdownContent content={values.newContent} />
                      </Box>
                    </Tabs.Panel>
                  </Tabs>
                </Stack>
              </Card>
            </Can>
            <ConfirmReplaceContentModal
              name={block}
              opened={confirmReplaceContentModalOpened}
              close={closeConfirmReplaceContentModal}
              onConfirm={onReplaceBlockContent}
            />
          </>
        )}
      </RestrictedFeature>
    </Drawer>
  );
}

function ConfirmReplaceContentModal({
  name,
  opened,
  close,
  onConfirm,
}: {
  name: string;
  opened: boolean;
  close: () => void;
  onConfirm: () => void;
}) {
  return (
    <Modal
      size="md"
      title={
        <Group gap="sm">
          <IconDeviceFloppy size={18} /> Confirm Strategy Block replacement
        </Group>
      }
      withinPortal
      keepMounted={false}
      opened={opened}
      onClose={close}
      centered
    >
      <Stack>
        <Text size="sm" c="gray">
          This will replace your current{" "}
          <Text span fw="bold">
            {name}
          </Text>{" "}
          block.
        </Text>
        <Group justify="flex-end">
          <Button variant="light" onClick={close}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Replace</Button>
        </Group>
      </Stack>
    </Modal>
  );
}
