import {
  ActionIcon,
  Affix,
  Alert,
  Badge,
  Box,
  Button,
  Card,
  Center,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  MultiSelect,
  Paper,
  SimpleGrid,
  Skeleton,
  Spoiler,
  Stack,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
  Transition,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  useDebouncedCallback,
  useDisclosure,
  useLocalStorage,
} from "@mantine/hooks";
import {
  IconAdjustments,
  IconBolt,
  IconChevronRight,
  IconReport,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import type {
  GetInsightsSearchInput,
  GetInsightsSearchValues,
} from "api/src/trpc_routes/insights";
import { createContext, useContext, useMemo, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { FullPageLoader } from "../../components/FullPageLoader";
import { MarkdownContent } from "../../components/MarkdownContent";
import { TextCopier } from "../../components/TextCopier";
import { Can } from "../../components/auth/Can";
import { dateIsToday, relativeTimeFromNow } from "../../utils/date";
import { trpc } from "../../utils/trpc";
import { ZedIndex } from "../../utils/zedIndex";
import { useInsights, type Insight } from "./hooks";

interface SearchComponent {
  searchQuery: GetInsightsSearchInput;
  // TODO: Rename me
  searchValues: GetInsightsSearchValues;
  onChangeSearch: (newProps: GetInsightsSearchInput) => void;
  disabled?: boolean;
}

const activeReportCacheKey = "noan-active-report-builder";

function useInsightsSearchValues() {
  const query = trpc.insights.getSearchValues.useQuery();

  return {
    ...query,
    searchValues: query.data,
  };
}

interface InsightReportBuilderContextProps {
  addInsightToReport: (insightId: string) => void;
  clearReport: () => void;
  goToReportReview: () => void;

  reportInsights: string[];
}

const InsightReportBuilderContext =
  createContext<InsightReportBuilderContextProps | null>(null);

function useInsightReportBuilder() {
  const ctx = useContext(InsightReportBuilderContext);

  if (ctx == null) {
    throw new Error(
      "useInsightReportBuilder must be called from InsightReportBuilderContext",
    );
  }

  return ctx;
}

function BasicSearch() {
  // Not implemented
  return null;
}

function AdvancedSearch({
  searchQuery,
  searchValues,
  onChangeSearch,
  disabled,
}: SearchComponent) {
  const companyData = useMemo(
    () =>
      searchValues.companies.map((c) => ({ label: c.name, value: c.symbol })),
    [searchValues],
  );

  const debouncedChangeHandler = useDebouncedCallback(onChangeSearch, 750);
  const form = useForm<GetInsightsSearchInput>({
    onValuesChange: debouncedChangeHandler,
    initialValues: {
      blockSlugs: searchQuery.blockSlugs,
      symbols: searchQuery.symbols,
      sectors: searchQuery.sectors,
    },
  });

  return (
    <form onSubmit={form.onSubmit(onChangeSearch)}>
      <Stack gap="md">
        <MultiSelect
          maxValues={10}
          clearable
          searchable
          hidePickedOptions
          size="md"
          label="Strategy Type"
          description="Topics, domains, and functions"
          data={searchValues.blocks}
          renderOption={({ option }) => <Text size="sm">{option.label}</Text>}
          {...form.getInputProps("blockSlugs")}
        />

        <MultiSelect
          maxValues={50}
          clearable
          searchable
          hidePickedOptions
          data={companyData}
          label="Company"
          size="md"
          limit={25}
          description="Company name or ticker symbol"
          {...form.getInputProps("symbols")}
          /**
           * We handle the options rendering slightly differently depending on
           * the viewport size, for clarity/readability
           */
          renderOption={({ option }) => (
            <Group gap="xs" preventGrowOverflow>
              <Badge size="sm" variant="filled" color="dark" visibleFrom="md">
                {option.value}
              </Badge>
              <Text truncate="end" span visibleFrom="md">
                {option.label}
              </Text>
              <Text truncate="end" span hiddenFrom="md" fw="bold">
                {option.value}
              </Text>
            </Group>
          )}
        />

        <MultiSelect
          maxValues={10}
          clearable
          searchable
          hidePickedOptions
          size="md"
          label="Business Sector"
          description="Operating and/or target sector"
          data={searchValues.sectors}
          renderOption={({ option }) => <Text size="sm">{option.label}</Text>}
          {...form.getInputProps("sectors")}
        />
        <LoadingOverlay visible={disabled} />
      </Stack>
    </form>
  );
}

function Search({
  searchQuery,
  searchValues,
  onChangeSearchQuery,
  disabled,
}: {
  searchQuery: GetInsightsSearchInput;
  searchValues: GetInsightsSearchValues;
  onChangeSearchQuery: (updatedQuery: GetInsightsSearchInput) => void;
  disabled: boolean;
}) {
  // Placeholders:
  const useAdvancedSearch = true;
  const enableModeToggle = false;

  return (
    <Stack align="flex-start" gap="sm">
      <Box w="100%">
        {useAdvancedSearch ? (
          <AdvancedSearch
            searchQuery={searchQuery}
            searchValues={searchValues}
            onChangeSearch={onChangeSearchQuery}
            disabled={disabled}
          />
        ) : (
          <BasicSearch />
        )}
      </Box>
      {enableModeToggle && (
        <Button
          variant="light"
          leftSection={<IconAdjustments size={18} />}
          size="sm"
          color="gray"
        >
          {useAdvancedSearch ? "Basic" : "Advanced"} Search
        </Button>
      )}
    </Stack>
  );
}

function AddToReportButton({
  onAddToReport,
  checked = false,
}: {
  onAddToReport: () => void;
  checked?: boolean;
}) {
  return (
    <Tooltip
      position="bottom"
      label={
        checked
          ? "Remove this insight from your report"
          : "Add details from this insight to your report"
      }
    >
      <Button
        color="blue"
        variant={checked ? "light" : "filled"}
        onClick={onAddToReport}
        leftSection={<Checkbox checked={checked} color="blue" readOnly />}
      >
        Add to Report
      </Button>
    </Tooltip>
  );
}

function InsightDescription({ description }: { description: string }) {
  return (
    <Alert
      variant="light"
      color="blue"
      mx={0}
      my="md"
      title="AT A GLANCE"
      icon={
        <ThemeIcon variant="filled" color="blue.4">
          <IconBolt />
        </ThemeIcon>
      }
    >
      {description}
    </Alert>
  );
}

function InsightContentActions({ insight }: { insight: Insight }) {
  const isFresh = useMemo(
    () => dateIsToday(insight.createdAt),
    [insight.createdAt],
  );
  const createdTimeAgo = useMemo(
    () => relativeTimeFromNow(insight.createdAt),
    [insight.createdAt],
  );

  return (
    <Stack gap="xs" flex={1}>
      <Text size="xs" c="dimmed">
        Updated {createdTimeAgo}
        {isFresh && (
          <Badge ml="sm" size="xs" variant="light">
            New!
          </Badge>
        )}
      </Text>
      <Group gap="xs">
        {insight.symbols.map((symbol) => (
          <Badge size="xl" key={symbol} p="xs" variant="outline" color="dark">
            {symbol}
          </Badge>
        ))}
      </Group>
    </Stack>
  );
}

function InsightCard({
  insight,
  onAddToReport,
  onReadInsight,
}: {
  insight: Insight;
  onAddToReport?: (insightId: string) => void;
  onReadInsight?: (insightId: string) => void;
}) {
  const { reportInsights } = useInsightReportBuilder();

  const isInReport = reportInsights.includes(insight.id);

  return (
    <Card>
      <Card.Section
        style={{
          borderBottom: "1px solid var(--mantine-color-default-border)",
        }}
      >
        <Paper p="sm" radius={0} shadow="none" bg="transparent">
          <Flex align="center" direction="row">
            <InsightContentActions insight={insight} />
            <Group>
              <TextCopier content={insight.content} />
            </Group>
          </Flex>
        </Paper>
      </Card.Section>
      <Card.Section inheritPadding py="sm" flex={1}>
        <Stack gap="sm" h="100%">
          <Title order={4} fw={"bold"}>
            {insight.title}
          </Title>

          {insight.description && (
            <InsightDescription description={insight.description} />
          )}

          <Box
            mah={{ base: "auto", md: 100 }}
            style={{
              overflow: "hidden",
            }}
            className="mask-to-transparent"
          >
            <MarkdownContent content={insight.summary ?? insight.content} />
          </Box>
        </Stack>
      </Card.Section>
      <Card.Section>
        <Group justify="flex-end" align="flex-end" p="sm">
          {onReadInsight && (
            <Button
              size="sm"
              variant="outline"
              color="dark"
              onClick={() => onReadInsight(insight.id)}
            >
              Read Full Insight
            </Button>
          )}

          {onAddToReport && (
            <AddToReportButton
              onAddToReport={() => onAddToReport(insight.id)}
              checked={isInReport}
            />
          )}
        </Group>
      </Card.Section>
    </Card>
  );
}

function InsightReader({
  open,
  onClose,
  insightId,
  onAddToReport,
}: {
  open: boolean;
  onClose: () => void;
  insightId?: string;
  onAddToReport?: (insightId: string) => void;
}) {
  const { reportInsights } = useInsightReportBuilder();
  const isInReport = !!(insightId && reportInsights.includes(insightId));

  const { data, isLoading } = trpc.insights.getInsight.useQuery(
    {
      insightId: insightId!,
    },
    {
      enabled: insightId != null,
    },
  );

  const insight = data ? (data as unknown as Insight) : null;

  return (
    <Modal.Root
      opened={!!(open && insightId)}
      onClose={onClose}
      size="xl"
      withinPortal
      keepMounted
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          {insight ? (
            <Stack gap="sm">
              <Group w="100%">
                <InsightContentActions insight={insight} />
                <Group justify="flex-end">
                  {onAddToReport && (
                    <AddToReportButton
                      checked={isInReport}
                      onAddToReport={() => onAddToReport(insight.id)}
                    />
                  )}
                  <ActionIcon variant="subtle" color="dark" onClick={onClose}>
                    <IconX />
                  </ActionIcon>
                </Group>
              </Group>
              <Title order={3} fw="bold" flex={1}>
                {insight?.title || "Read Full Insight"}{" "}
              </Title>
            </Stack>
          ) : (
            <Title order={3} c="dimmed">
              Loading Insight
            </Title>
          )}
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          {isLoading && <FullPageLoader />}
          {!isLoading && insight && (
            <Stack gap="sm">
              {insight.description && (
                <InsightDescription description={insight.description} />
              )}
              <MarkdownContent content={insight.summary} />

              <Divider label="Original content" mt="lg" />
              <Spoiler
                maxHeight={100}
                showLabel="Show more"
                hideLabel="Show less"
                color="dimmed"
              >
                <MarkdownContent content={insight.content} />
              </Spoiler>
            </Stack>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

function ReportBar() {
  const { reportInsights, goToReportReview, clearReport } =
    useInsightReportBuilder();

  return (
    <Affix w="100vw" zIndex={ZedIndex.ReportBar} position={{ top: 100 }}>
      <Flex direction="row" justify={"flex-end"} p="md">
        <Transition transition="slide-left" mounted={reportInsights.length > 0}>
          {(transitionStyles) => (
            <Card style={transitionStyles}>
              <Group>
                <ThemeIcon size="sm" variant="transparent" color="blue">
                  <IconReport />
                </ThemeIcon>
                <Text size="sm">
                  {reportInsights.length} insight
                  {reportInsights.length > 1 ? "s" : ""} in report
                </Text>
                <Tooltip label="Reset report" position="bottom">
                  <ActionIcon
                    variant="light"
                    color="base"
                    onClick={clearReport}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
                <Button
                  rightSection={
                    <ThemeIcon variant="transparent" color="white">
                      <IconChevronRight />
                    </ThemeIcon>
                  }
                  color="blue"
                  onClick={goToReportReview}
                >
                  Review Report
                </Button>
              </Group>
            </Card>
          )}
        </Transition>
      </Flex>
    </Affix>
  );
}

export function InsightsList({ insights }: { insights?: Insight[] }) {
  const [readingInsightId, setReadingInsightId] = useState<
    string | undefined
  >();
  const { addInsightToReport } = useInsightReportBuilder();

  const [
    isInsightsReaderOpen,
    { close: closeInsightsReader, open: openInsightsReader },
  ] = useDisclosure(false);

  function onAddToReport(reportId: string) {
    addInsightToReport(reportId);
  }

  function onReadInsight(insightId: string) {
    setReadingInsightId(insightId);
    openInsightsReader();
  }

  return (
    <>
      <InsightReader
        insightId={readingInsightId}
        open={isInsightsReaderOpen}
        onClose={closeInsightsReader}
        onAddToReport={onAddToReport}
      />

      {insights && insights.length > 0 && (
        <Text size="sm" mb="sm">
          Showing {insights.length} results
        </Text>
      )}
      <SimpleGrid cols={{ base: 1, lg: 2 }}>
        {insights?.map((i) => (
          <InsightCard
            insight={i}
            key={i.id}
            onReadInsight={onReadInsight}
            onAddToReport={onAddToReport}
          />
        ))}
      </SimpleGrid>

      {insights && insights.length === 0 && (
        <Center p="sm">
          <Text c="gray">No results. Try modifying your search query.</Text>
        </Center>
      )}

      <ReportBar />
    </>
  );
}

export function Insights() {
  const navigate = useNavigate();
  const [urlQuery, setUrlQuery] = useSearchParams();
  const [reportInsights, setReportInsights] = useLocalStorage<string[]>({
    key: activeReportCacheKey,
    defaultValue: [],
  });
  const { searchValues, isLoading: isLoadingSearchValues } =
    useInsightsSearchValues();

  const searchQuery = useMemo<GetInsightsSearchInput>(
    () => ({
      symbols: urlQuery.get("symbols")?.split("|") ?? undefined,
      blockSlugs: urlQuery.get("blocks")?.split("|") ?? undefined,
      sectors:
        urlQuery.get("sectors")?.replace("-", " ").split("|") ?? undefined,
    }),
    [urlQuery],
  );

  const { insights, isLoading } = useInsights(searchQuery);

  function onAddInsightToReport(insightId: string) {
    if (reportInsights.includes(insightId)) {
      setReportInsights(reportInsights.filter((i) => i !== insightId));
    } else {
      setReportInsights([...new Set([...reportInsights, insightId])]);
    }
  }

  function onChangeSearchQuery(newValues: GetInsightsSearchInput) {
    setUrlQuery((newUrl) => {
      newUrl.delete("symbols");
      newUrl.delete("blocks");
      newUrl.delete("sectors");

      if (newValues.sectors && newValues.sectors.length > 0) {
        newUrl.set("sectors", newValues.sectors.join("|").replace(" ", "-"));
      } else {
        newUrl.delete("sectors");
      }

      if (newValues.symbols && newValues.symbols.length > 0) {
        newUrl.set("symbols", newValues.symbols.join("|"));
      } else {
        newUrl.delete("symbol");
      }

      if (newValues.blockSlugs && newValues.blockSlugs.length > 0) {
        newUrl.set("blocks", newValues.blockSlugs.join("|"));
      } else {
        newUrl.delete("blocks");
      }

      return newUrl;
    });
  }

  return (
    <Can execute="insights.read" redirect={404}>
      <>
        <Stack p="xl" gap="xs">
          <Stack gap={0}>
            <Title>Search &amp; track strategies in real time</Title>
            <Text>Up-to-date insights on Russell 3000 companies</Text>
          </Stack>
        </Stack>

        <Grid columns={6} p="md">
          <Grid.Col span={{ base: 6, sm: 2 }}>
            <Card>
              {isLoadingSearchValues ? (
                <Stack gap="sm">
                  <Skeleton h={30} w={190} />
                  <Skeleton h={45} />
                  <Skeleton h={30} w={200} mt="sm" />
                  <Skeleton h={45} />
                  <Skeleton h={30} w={230} mt="sm" />
                  <Skeleton h={45} />
                </Stack>
              ) : (
                <Search
                  searchQuery={searchQuery}
                  disabled={isLoading}
                  searchValues={searchValues!}
                  onChangeSearchQuery={onChangeSearchQuery}
                />
              )}
            </Card>
          </Grid.Col>
          <Grid.Col span={{ base: 6, sm: 4 }}>
            {isLoading ? (
              <Skeleton h={600} />
            ) : (
              <InsightReportBuilderContext.Provider
                value={{
                  goToReportReview: () => {
                    localStorage.removeItem(activeReportCacheKey);

                    navigate(
                      {
                        pathname: "/insights/reports",
                        search: createSearchParams({
                          insights: reportInsights.join("+"),
                        }).toString(),
                      },
                      {
                        preventScrollReset: false,
                      },
                    );
                  },
                  clearReport: () => {
                    setReportInsights([]);
                  },
                  addInsightToReport: onAddInsightToReport,
                  reportInsights,
                }}
              >
                <InsightsList insights={insights} />
              </InsightReportBuilderContext.Provider>
            )}
          </Grid.Col>
        </Grid>
      </>
    </Can>
  );
}
