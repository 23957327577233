import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Card,
  Center,
  Container,
  Divider,
  Flex,
  Group,
  Loader,
  Modal,
  Spoiler,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
  Title,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure, useNetwork, useToggle } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconBug,
  IconCheck,
  IconChevronLeft,
  IconChevronRight,
  IconCircleCheckFilled,
  IconClock,
  IconCloudCheck,
  IconDeviceFloppy,
  IconHexagon,
  IconPencilPlus,
  IconStarFilled,
} from "@tabler/icons-react";
import { FeatureFlag } from "api/src/featureFlags";
import { AssistantId } from "api/src/generated/storyblok/assistants";
import { ConvoSource } from "api/src/models/Convo";
import { PerkId } from "api/src/models/Coupon";
import { Fact, FactProvenance, FactWithState } from "api/src/models/Fact";
import {
  GeneratorUntrustedInput,
  MessageRole,
} from "api/src/models/GeneratorInput";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../auth/useAuthContext";
import { BackButton } from "../../components/BackButton";
import { ChatAssistant } from "../../components/ChatAssistant";
import {
  BlockContentActions,
  LearnAction,
} from "../../components/ConfigurableBlockCard/BlockContentActions";
import {
  OutdatedIcon,
  RequirementsLinks,
} from "../../components/ConfigurableBlockCard/BlockOverviewCard";
import {
  PROMPT_MAX_LENGTH,
  ToneHint,
} from "../../components/ConfigurableBlockCard/config";
import { FullPageLoader } from "../../components/FullPageLoader";
import { GeneratorOptionsInline } from "../../components/GeneratorSwitcher/GeneratorOptionsInline";
import { MarkdownContent } from "../../components/MarkdownContent";
import { PerkDrawer } from "../../components/Perks/PerksDrawer";
import { blockPerkById } from "../../components/Perks/perks";
import { Can } from "../../components/auth/Can";
import { StackKey, stackStyle } from "../../components/icons/stacks";
import {
  AiGeneratorOption,
  aiGenerators,
  useGeneratorQuery,
} from "../../useGenerator";
import { assistantById } from "../../utils/assistantById";
import { compact } from "../../utils/misc";
import {
  isOutdatedVersion,
  useCreateFactVersion,
  useFacts,
} from "../../utils/useFacts";
import { useIsFeatureEnabled } from "../../utils/useIsFeatureEnabled";
import {
  BlocksGroupedByStack,
  Pillar,
  getBlockPath,
  isInputModule,
  usePillars,
} from "../../utils/usePillars";
import { BlockIdeas } from "./BlockIdeas";
import classes from "./BlockPage.module.css";
import { ConfigurableModuleCardProps } from "./ConfigurableModuleCard";
import { FactsContext } from "./FactsContext";
import { useBlocksStats, useNotifyOnCreateUnlocked } from "./useBlocksStats";
import { groupModulesByStack, useIsStackReadable } from "./useIsStackReadable";
import { useModuleRequirements } from "./useModuleRequirements";

function BlockStatus({
  fact,
  isBlockedByRequirements,
  isOutdatedVersion,
}: {
  fact?: Fact | FactWithState | null;
  isBlockedByRequirements: boolean;
  isOutdatedVersion: boolean;
}) {
  const isComplete = !!fact?.content;
  const isImported =
    !!fact && fact.content.provenance === FactProvenance.SiteScraper;

  const completionStatus = (
    <>
      {!isImported && !isOutdatedVersion && isComplete === true && (
        <Badge
          variant="filled"
          color="teal.6"
          styles={{
            root: { borderRadius: 4 },
          }}
          fw="500"
          tt="none"
          size="lg"
          leftSection={
            <ThemeIcon size={18} color="teal.6">
              <IconCircleCheckFilled />
            </ThemeIcon>
          }
        >
          Complete
        </Badge>
      )}
      {!isComplete && (
        <Tooltip
          withArrow
          label={
            isBlockedByRequirements
              ? "You need to complete other blocks first"
              : "You have not completed this block yet"
          }
        >
          <Badge
            variant="filled"
            styles={{
              root: { borderRadius: 4 },
            }}
            color={isBlockedByRequirements ? "dimmed" : "yellow.2"}
            c={isBlockedByRequirements ? "base.0" : "base.11"}
            fw="500"
            tt="none"
            size="lg"
            leftSection={
              isBlockedByRequirements ? undefined : (
                <ThemeIcon size="xs" variant="transparent" c={"base.11"}>
                  <IconClock size={18} />
                </ThemeIcon>
              )
            }
          >
            {isBlockedByRequirements ? "Not Available" : "Incomplete"}
          </Badge>
        </Tooltip>
      )}
    </>
  );

  return (
    <>
      {isOutdatedVersion && <OutdatedIcon />}
      {isImported && !isOutdatedVersion && isComplete === true && (
        <Tooltip
          withArrow
          label="The content of this block was automatically imported for you"
        >
          <Badge
            variant="light"
            size="lg"
            color="green.9"
            styles={{
              root: { borderRadius: 4 },
            }}
          >
            <Center>
              <ThemeIcon size="xs" variant="transparent" color="green.9">
                <IconCloudCheck />
              </ThemeIcon>
            </Center>
          </Badge>
        </Tooltip>
      )}
      {typeof isComplete !== "undefined" && completionStatus}
    </>
  );
}

interface BlockContentFormFields {
  inputValue: string;
  generator: AiGeneratorOption["value"];
  toneHint: string | ToneHint;
}

function BlockContent({
  isLoadingFacts,
  isCreatingFactVersion,
  isEditing,
  fact,
  block,
  assistantId,
  toggleIsEditing,
  refetch,
  onUpdateBlockContent,
}: {
  isLoadingFacts: boolean;
  isCreatingFactVersion: boolean;
  isEditing: boolean;
  fact?: Fact | FactWithState | null;
  block: BlocksGroupedByStack["blocks"][number];
  assistantId: AssistantId;
  refetch: () => void;
  toggleIsEditing: (editing: boolean) => void;
  onUpdateBlockContent: (content: string) => void;
}) {
  const content = fact?.content.plainText;
  const generatorsOptions = aiGenerators.filter(
    (g) => block.aimodelgenerator && block.aimodelgenerator.includes(g.value),
  );

  const isInput = isInputModule(block);
  const blockedByRequirements = useModuleRequirements(block);
  const { online: isOnline } = useNetwork();
  const [isGenerating, toggleIsGenerating] = useToggle();

  const { values, getInputProps, setFieldValue, onSubmit } =
    useForm<BlockContentFormFields>({
      initialValues: {
        inputValue: "",
        generator: generatorsOptions?.[0]?.value,
        toneHint: "",
      },
    });

  const generatorInput: GeneratorUntrustedInput = {
    role: MessageRole.User,
    thread: {
      blockPath: block.knowledgeslug,
      assistantPersona: assistantId,
    },
    generator:
      values.generator as keyof ConfigurableModuleCardProps["generators"],
    ...(values.toneHint && {
      tone: {
        text: values.toneHint,
      },
    }),
  };
  const { generatedText, isLoading: isGeneratorLoading } = useGeneratorQuery({
    enabled: isGenerating,
    generate: generatorInput,
    onEndGenerate() {
      if (generatedText) {
        setFieldValue("inputValue", generatedText);
      }
      refetch();
      toggleIsGenerating(false);
    },
  });

  function onSubmitFact(values: BlockContentFormFields) {
    if (isEditing) {
      onUpdateBlockContent(values.inputValue);
    } else {
      toggleIsEditing(true);
    }
  }

  const blockStackStyle = stackStyle[block.stackSlug.toLowerCase() as StackKey];
  const isLoading =
    isCreatingFactVersion || isGeneratorLoading || isLoadingFacts;
  const isGeneratingOptionsDisabled = isLoading || !isOnline;
  const hasMissingRequirements =
    blockedByRequirements != null && blockedByRequirements.length > 0;
  const isBlockedByRequirements = !content && hasMissingRequirements;

  useEffect(() => {
    if (content) {
      setFieldValue("inputValue", content);
    }
  }, [content, setFieldValue]);

  return (
    <>
      <form onSubmit={onSubmit(onSubmitFact)}>
        <Stack justify="space-between" flex={1}>
          <Group gap={"4"}>
            <Badge
              variant="filled"
              fw="normal"
              size="lg"
              color="orange.0"
              styles={{
                root: { borderRadius: 4 },
              }}
              c="base.11"
              tt="none"
              leftSection={
                <ThemeIcon size={18} variant="transparent" color="light">
                  {blockStackStyle?.icon ?? <IconHexagon />}
                </ThemeIcon>
              }
            >
              {block.stackName}
            </Badge>
            <BlockStatus
              fact={fact}
              isBlockedByRequirements={isBlockedByRequirements}
              isOutdatedVersion={isOutdatedVersion(fact)}
            />
          </Group>
          <Box>
            <LearnAction block={block} />
          </Box>
          {isBlockedByRequirements ? (
            <RequirementsLinks
              size="sm"
              requiredBlocks={blockedByRequirements}
              type="alert"
            />
          ) : (
            <>
              {!content && !isInput && !isGenerating && (
                <>
                  <Stack gap="sm">
                    <Tooltip
                      withArrow
                      label={`NOAN will generate the content of this block based on your existing strategy. You will have a chance to edit this block afterwards.`}
                      multiline
                      maw={400}
                    >
                      <Box>
                        <Text c="base.9" size="xl">
                          Generate content of this block with AI
                        </Text>
                        <Text c="dimmed" size="sm">
                          You can edit it later
                        </Text>
                      </Box>
                    </Tooltip>
                    <GeneratorOptionsInline
                      options={generatorsOptions}
                      disabled={isGeneratingOptionsDisabled}
                      onSelectGenerator={(gen) => {
                        toggleIsEditing(false);
                        setFieldValue("generator", gen.value);
                        toggleIsGenerating(true);
                      }}
                    />
                  </Stack>
                  <Divider label="or add manually" />
                </>
              )}
              {!isEditing &&
                !isGenerating &&
                !isGeneratingOptionsDisabled &&
                !content && (
                  <Button
                    mt="md"
                    size="lg"
                    variant="default"
                    color="dark"
                    fw="normal"
                    onClick={() => toggleIsEditing(true)}
                  >
                    Click here to start editing
                  </Button>
                )}
              {isGenerating && (
                <Card>
                  {generatedText ? (
                    <Box>
                      <MarkdownContent content={generatedText} />
                      <Flex
                        direction="row"
                        align="flex-end"
                        justify="flex-end"
                        h="100%"
                        p="md"
                      >
                        <Loader size={"sm"} />
                      </Flex>
                    </Box>
                  ) : (
                    <Text size="xs" c="dimmed" variant="pulse">
                      Getting everything ready...
                    </Text>
                  )}
                </Card>
              )}
              {!isEditing && !isGenerating && content && (
                <Card className={classes.blockInputReadOnly}>
                  <Spoiler
                    hideLabel="Show less"
                    showLabel="Show more"
                    styles={{
                      control: {
                        marginTop: 10,
                        fontSize: 12,
                      },
                    }}
                    maxHeight={300}
                  >
                    <MarkdownContent content={content} />
                  </Spoiler>
                </Card>
              )}
              {isEditing && !isGenerating && (
                <Textarea
                  autosize
                  minRows={2}
                  maxRows={12}
                  radius="lg"
                  className={classes.blockInput}
                  resize="vertical"
                  placeholder={block.hint ?? "Click here to start editing"}
                  maxLength={PROMPT_MAX_LENGTH}
                  {...getInputProps("inputValue")}
                  autoFocus
                  description={
                    <Text span size="xs">
                      {values.inputValue.length}/{PROMPT_MAX_LENGTH}
                    </Text>
                  }
                />
              )}
              {(isEditing || (!isGenerating && content)) && (
                <Group align="flex-start" justify="space-between">
                  <BlockContentActions
                    block={block}
                    fact={fact}
                    content={values.inputValue}
                    isLoading={isLoading}
                    isEditing={isEditing}
                    isOutdatedVersion={isOutdatedVersion(fact)}
                    generators={generatorsOptions}
                    selectedGenerator={values.generator}
                    onSelectGenerator={(gen) =>
                      setFieldValue("generator", gen.value)
                    }
                    onRegenerate={(inst) => {
                      setFieldValue("toneHint", inst);
                      toggleIsGenerating(true);
                    }}
                  />
                  <Group gap="xs">
                    {isEditing ? (
                      <>
                        <Tooltip withArrow label={"Cancel changes"}>
                          <Button
                            size="md"
                            fw={500}
                            disabled={isLoading}
                            onClick={() => {
                              if (content) {
                                setFieldValue("inputValue", content);
                              }
                              toggleIsEditing(false);
                            }}
                            color="dark"
                            variant={"outline"}
                          >
                            Cancel
                          </Button>
                        </Tooltip>
                        <Tooltip withArrow label={"Save changes"}>
                          <Button
                            size="md"
                            fw={500}
                            disabled={
                              isLoading ||
                              !values.inputValue ||
                              values.inputValue === content
                            }
                            onClick={() =>
                              onUpdateBlockContent(values.inputValue)
                            }
                            color={"orange"}
                            variant={"filled"}
                          >
                            Save
                          </Button>
                        </Tooltip>
                      </>
                    ) : (
                      <Tooltip withArrow label={"Edit block content"}>
                        <Button
                          size="md"
                          fw={500}
                          color="dark"
                          disabled={isLoading}
                          onClick={() => toggleIsEditing(true)}
                          variant={"outline"}
                        >
                          Edit
                        </Button>
                      </Tooltip>
                    )}
                  </Group>
                </Group>
              )}
            </>
          )}
        </Stack>
      </form>
    </>
  );
}

function CreateUnlockedModal({
  isOpened,
  onClose,
}: {
  isOpened: boolean;
  onClose: () => void;
}) {
  const navigate = useNavigate();
  return (
    <Modal
      size="md"
      withinPortal
      keepMounted={false}
      opened={isOpened}
      onClose={onClose}
      centered
    >
      <Stack align="center" ta="center">
        <ThemeIcon size={100} variant="outline">
          <IconPencilPlus size={40} />
        </ThemeIcon>
        <Stack align="center" gap={0}>
          <Title order={4} fw="bold">
            Great work!
          </Title>
          <Title order={4} fw="bold">
            You've unlocked Create Mode.
          </Title>
        </Stack>
        <Text c="base.8">Create assets you need to build your business.</Text>

        <Button my="lg" onClick={() => navigate("/create")}>
          Start creating
        </Button>
      </Stack>
    </Modal>
  );
}

function PrevNextBlockNav({
  block,
  stack,
  onActionFinished,
}: {
  block: BlocksGroupedByStack["blocks"][number];
  stack?: Pillar;
  onActionFinished: () => void;
}) {
  const navigate = useNavigate();
  const blockStackStyle =
    stackStyle[block?.stackSlug.toLowerCase() as StackKey];

  const blocks = stack?.content.modules ?? [];

  const current =
    blocks.findIndex((mod) => mod.knowledgeslug === block.knowledgeslug) ?? -1;
  const previous = current === 0 ? undefined : blocks[current - 1];
  const next = current === blocks.length - 1 ? undefined : blocks[current + 1];

  return (
    (previous || next) && (
      <Group w="100%" justify="space-between" mt={{ base: "xl", md: 0 }}>
        {previous ? (
          <Button
            h={{ sm: 69 }}
            size="xs"
            variant="light"
            className={classes.navButton}
            onClick={() => {
              navigate(`/build/b/${previous.knowledgeslug}`);
              onActionFinished();
            }}
          >
            <Stack gap="xs" align="flex-start">
              <Group gap={4}>
                <IconChevronLeft size={14} />
                <Text size="xs">Previous</Text>
              </Group>
              <Group
                gap="xs"
                className={classes.navButtonContent}
                visibleFrom="sm"
              >
                <ThemeIcon
                  size={24}
                  variant="transparent"
                  className={classes.navButtonContent}
                >
                  {blockStackStyle?.icon ?? <IconHexagon />}
                </ThemeIcon>
                <Text size="sm">{previous.title}</Text>
              </Group>
            </Stack>
          </Button>
        ) : (
          <Box />
        )}
        {next ? (
          <Button
            h={{ sm: 69 }}
            size="xs"
            variant="light"
            className={classes.navButton}
            onClick={() => {
              navigate(`/build/b/${next.knowledgeslug}`);
              onActionFinished();
            }}
          >
            <Stack gap="xs" align="flex-end">
              <Group gap={4}>
                <Text size="xs">Next</Text>
                <IconChevronRight size={14} />
              </Group>
              <Group
                gap="xs"
                className={classes.navButtonContent}
                visibleFrom="sm"
              >
                <ThemeIcon
                  size={24}
                  variant="transparent"
                  className={classes.navButtonContent}
                >
                  {blockStackStyle?.icon ?? <IconHexagon />}
                </ThemeIcon>
                <Text size="sm">{next.title}</Text>
              </Group>
            </Stack>
          </Button>
        ) : (
          <Box />
        )}
      </Group>
    )
  );
}

export function BlockPage() {
  const isNewBuildModeEnabled = useIsFeatureEnabled(
    FeatureFlag.UseNewBuildMode,
  );
  const { blockPath } = useParams();
  const navigate = useNavigate();

  const { pillars: stacks } = usePillars();
  const [
    facts,
    { isLoading: isLoadingFacts, refetch, isRefetching: isRefetchingFacts },
  ] = useFacts();
  const { isStackReadable } = useIsStackReadable();
  const [
    perksDrawerOpened,
    { open: openPerksDrawer, close: closePerksDrawer },
  ] = useDisclosure(false);
  const [
    notifCreateUnlockedOpened,
    { open: openNotifCreateUnlocked, close: closeNotifCreateUnlocked },
  ] = useDisclosure(false);
  const { createFactVersion, isLoading: isCreatingFactVersion } =
    useCreateFactVersion();
  const [isEditing, toggleIsEditing] = useToggle();

  const groupedStacks = stacks
    ? groupModulesByStack(stacks as Pillar[], facts)
    : [];
  const mod =
    groupedStacks &&
    getBlockPath({
      blockPath: blockPath!.toLowerCase(),
      stacks: groupedStacks,
    });
  const blockStack = stacks?.find((st: Pillar) => st.slug === mod.stackSlug);

  const { stacksLockingCreate } = useBlocksStats({
    filteredStacks: stacks ?? [],
    stacks: stacks ?? [],
    facts,
  });
  const isCreateBlocksRequiredIncomplete = stacksLockingCreate.some(
    (p) => p.stackPercentComplete !== 100,
  );
  const { isCreateUnlocked } = useNotifyOnCreateUnlocked({
    isCreateBlocksRequiredIncomplete,
    stacksLockingCreate,
  });
  const [isAssistantOpened, toggleIsAssistantOpened] = useToggle();
  const { identity } = useAuthContext();
  const [assistantId, _setAssistantId] =
    useState<AssistantId>("general-assistant");

  useEffect(() => {
    // Make page in edit mode by default when there is no content yet
    if (!mod.fact) {
      toggleIsEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCreateUnlocked) {
      openNotifCreateUnlocked();
    }
  }, [isCreateUnlocked, openNotifCreateUnlocked]);

  if (!isNewBuildModeEnabled) {
    return null;
  }

  if (isLoadingFacts) {
    return <FullPageLoader />;
  }

  if (!mod.block || !isStackReadable(mod.stackSlug)) {
    navigate("/build");
    return null;
  }

  function onUpdateBlockContent(plainText: string) {
    createFactVersion(
      {
        parentFactId: mod.fact?.id,
        blockPath: mod.block!.knowledgeslug!,
        content: {
          format: {
            type: "text",
          },
          plainText,
        },
      },
      {
        onSuccess: () => {
          notifications.show({
            title: "Your block has been saved.",
            message:
              "This block is now added to your business knowledge and is used across your content.",
            icon: <IconCheck size={16} />,
            color: "green",
            autoClose: 10000,
          });
          refetch();
          toggleIsEditing(false);
        },

        onError: () => {
          notifications.show({
            icon: <IconBug size={16} />,
            color: "red",
            autoClose: 2000,
            message: `An unknown error ocurred while updating your strategy block ${mod.block?.title}`,
          });
        },
      },
    );
  }

  const perks =
    mod.block.perks && mod.block.perks.length > 0
      ? compact(
          mod.block.perks
            .filter((p) => p !== "")
            .map((p) => blockPerkById(p as PerkId)),
        )
      : undefined;

  return (
    <Container mt="" title={mod.title} fluid>
      <Stack gap="lg" w="100%">
        <BackButton
          label="Back to Build"
          to={`/build?filtered=${mod.stackSlug}`}
          w="fit-content"
        />

        <PrevNextBlockNav
          block={mod.block}
          stack={blockStack!}
          onActionFinished={() => {
            toggleIsEditing(false);
            refetch();
          }}
        />
        <Flex
          align="flex-start"
          direction={{ base: "column", md: "row" }}
          gap="xl"
          pos="relative"
        >
          <Stack w={{ base: "100%", md: "55%" }} mb={{ md: 100 }}>
            <Group>
              <Title order={1} size={50} fw="bold">
                {mod.title}
              </Title>
              {perks && perks.length > 0 ? (
                <Can execute="coupon.read">
                  <>
                    <Tooltip label="This Smart Block has related perks.">
                      <ActionIcon
                        mt="xs"
                        color="yellow.2"
                        variant="subtle"
                        onClick={openPerksDrawer}
                      >
                        <IconStarFilled size={20} />
                      </ActionIcon>
                    </Tooltip>
                    <PerkDrawer
                      opened={perksDrawerOpened}
                      onClose={closePerksDrawer}
                      perks={perks}
                    />
                  </>
                </Can>
              ) : undefined}
            </Group>
            {(mod.block?.userfacingtext || mod.block?.description) && (
              <Spoiler
                hideLabel="Show less"
                showLabel="Learn more"
                styles={{
                  control: {
                    marginTop: "var(--mantine-spacing-md)",
                    fontSize: "var(--mantine-font-size-sm)",
                  },
                }}
                maxHeight={50}
              >
                <Text>
                  {mod.block?.userfacingtext ?? mod.block?.description}
                </Text>
              </Spoiler>
            )}
            <FactsContext.Provider
              value={{
                facts,
                stacks: groupedStacks,
              }}
            >
              <BlockContent
                fact={mod.fact}
                block={mod.block}
                assistantId={assistantId}
                isEditing={isEditing}
                isCreatingFactVersion={isCreatingFactVersion}
                isLoadingFacts={isLoadingFacts || isRefetchingFacts}
                toggleIsEditing={toggleIsEditing}
                refetch={refetch}
                onUpdateBlockContent={onUpdateBlockContent}
              />
            </FactsContext.Provider>
          </Stack>
          <Stack
            h={{ base: "100%", md: "80vh" }}
            w={{ base: "100%", md: "40%" }}
            pos={{ base: "initial", md: "absolute" }}
            right={0}
            justify="space-between"
            mb={{ base: 60, sm: "md" }}
          >
            <BlockIdeas
              blockPath={blockPath!}
              blockName={mod.block.title}
              onReplaceContent={onUpdateBlockContent}
            />
            <ChatAssistant
              type="affix"
              sourceRef={mod.block.knowledgeslug!}
              initialMessage={`Hi ${identity.name || identity.email}, I am your virtual assistant, here to assist you as a <b>${assistantById(assistantId).name}</b>. Ask me questions to assess & improve your strategy. My answers are based on the knowledge of your business and the internet.`}
              source={ConvoSource.BuildBlockAssistant}
              context={mod.fact?.content.plainText}
              assistantId={assistantId}
              isAssistantOpened={isAssistantOpened}
              toggleIsAssistantOpened={toggleIsAssistantOpened}
              outputActions={[
                {
                  tooltip: "Replace your block with this content",
                  icon: <IconDeviceFloppy size={14} />,
                  action: onUpdateBlockContent,
                },
              ]}
            />
          </Stack>
        </Flex>
      </Stack>
      <CreateUnlockedModal
        isOpened={notifCreateUnlockedOpened}
        onClose={closeNotifCreateUnlocked}
      />
    </Container>
  );
}
