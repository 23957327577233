import { NoteProvenanceSource } from "api/src/models/Note";
import { useAuthContext } from "../auth/useAuthContext";
import { trpc } from "./trpc";

export function useNotes({
  source,
  blockPath,
}: {
  source: NoteProvenanceSource;
  blockPath?: string;
}) {
  const { identity } = useAuthContext();
  const query = trpc.organization.getNotes.useQuery(
    { source },
    {
      enabled: !!identity,
    },
  );

  const notes = blockPath
    ? query.data?.filter(
        (note) => note.content.provenance.blockPath === blockPath,
      )
    : query.data;

  return {
    ...query,
    notes: notes ?? [],
  };
}

export function useDeleteNote() {
  const utils = trpc.useUtils();
  const mutation = trpc.organization.deleteNote.useMutation({
    onSettled() {
      utils.organization.getNotes.invalidate();
    },
  });

  return {
    deleteNote: mutation.mutate,
    isDeletingNote: mutation.isLoading,
  };
}

export function useSaveNote() {
  const utils = trpc.useUtils();
  const mutation = trpc.organization.createNote.useMutation({
    onSettled() {
      utils.organization.getNotes.invalidate();
    },
  });

  return {
    saveNote: mutation.mutate,
    isSavingNote: mutation.isLoading,
  };
}
