import { trpc } from "./trpc";

/**
 * Thin wrappers around react-query/trpc mutations for Organization.
 */
export function useOrganizationMutation({
  onSettledSwitchOrg,
}: {
  onSettledSwitchOrg?: () => void;
} = {}) {
  const utils = trpc.useUtils();

  const {
    mutateAsync: createOrganizationAsync,
    isLoading: isLoadingCreateOrganization,
  } = trpc.organization.create.useMutation({
    onSettled() {
      utils.organization.getAll.invalidate();
    },
  });

  const {
    mutateAsync: updateOrganizationAsync,
    isLoading: isLoadingUpdateOrganization,
  } = trpc.organization.update.useMutation({
    onSettled() {
      utils.organization.getById.invalidate();
      utils.organization.getAll.invalidate();
    },
  });

  const {
    mutateAsync: switchOrganizationAsync,
    isLoading: isLoadingSwitchOrganization,
  } = trpc.organization.switch.useMutation({
    onSettled() {
      onSettledSwitchOrg?.();

      utils.invalidate();
      location.reload();
    },
  });

  return {
    createOrganizationAsync,
    isLoadingCreateOrganization,
    updateOrganizationAsync,
    isLoadingUpdateOrganization,
    switchOrganizationAsync,
    isLoadingSwitchOrganization,
  };
}

export function useUpdateStacks() {
  const utils = trpc.useUtils();

  const { mutate: updateStacks, isLoading: isUpdatingStacks } =
    trpc.organization.updateStacks.useMutation({
      onSettled() {
        utils.organization.getById.invalidate();
      },
    });

  return {
    updateStacks,
    isUpdatingStacks,
  };
}
