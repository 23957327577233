import { ThemeIcon, Tooltip, type ThemeIconProps } from "@mantine/core";
import { IconHexagon } from "@tabler/icons-react";
import type { AssistantId } from "api/src/generated/storyblok/assistants";
import { assistantIcons } from "../../generated/assistantIcons";
import { assistantById } from "../../utils/assistantById";

export function AssistantIcon({
  assistantId,
  themeIconProps = {},
}: {
  assistantId: AssistantId;
  themeIconProps?: Partial<ThemeIconProps>;
}) {
  const assistant = assistantById(assistantId);
  const Icon = assistantIcons[assistant.id];

  return (
    <Tooltip label={assistant.name}>
      <ThemeIcon color="dark" variant="transparent" {...themeIconProps}>
        {Icon ? <Icon /> : <IconHexagon />}
      </ThemeIcon>
    </Tooltip>
  );
}
