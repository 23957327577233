import classes from "./StackIcons.module.css";

export function StartupIcon() {
  return (
    <svg
      width="206"
      height="229"
      viewBox="0 0 206 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M162.535 89.7321C167.808 92.7768 171.056 98.4033 171.056 104.492C171.056 110.581 167.808 116.208 162.534 119.253L145.969 128.817C140.399 132.032 133.538 132.033 127.969 128.817L119.924 124.173L119.924 133.462C119.924 139.893 116.493 145.835 110.924 149.05L94.8338 158.34C89.2645 161.556 82.4029 161.556 76.8336 158.34L43.2216 138.935C37.9479 135.89 34.6992 130.263 34.6992 124.174C34.6992 118.084 37.948 112.457 43.2216 109.412L68.789 94.6515L85.8333 104.492L51.7446 124.174L85.8335 143.854L102.878 134.013L85.8335 124.173L102.877 114.333L102.879 114.332L94.3556 109.411C89.0821 106.367 85.8335 100.74 85.8335 94.6506C85.8335 88.5613 89.0822 82.9345 94.3558 79.89L110.924 70.3251C116.493 67.1098 123.355 67.11 128.924 70.3256L162.535 89.7321ZM119.922 84.8108L154.011 104.493L136.969 114.332L102.878 94.6503L102.879 94.6506L119.922 84.8108Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.036 153.738L205.037 69.1882C205.037 60.6139 200.463 52.6908 193.037 48.4036L114.769 3.21528C107.343 -1.07181 98.1945 -1.0718 90.769 3.21531L17.5448 45.4901C6.99753 51.5795 0.50002 62.8333 0.5 75.0123L0.500048 159.563C0.500068 168.138 5.07443 176.061 12.5 180.348L90.7681 225.536C98.1937 229.823 107.342 229.823 114.768 225.536L187.991 183.26C198.538 177.171 205.036 165.917 205.036 153.738ZM98.7688 18.278L21.5448 62.8622C19.0695 64.2912 17.5447 66.9323 17.5447 69.7904L17.5447 84.8406L119.803 25.8033L106.769 18.278C104.294 16.849 101.244 16.849 98.7688 18.278ZM136.848 35.6441L183.992 62.863C186.467 64.2921 187.992 66.9332 187.992 69.7913L187.992 124.312L68.7622 193.149L21.5447 165.888C19.0695 164.459 17.5448 161.818 17.5448 158.96L17.5447 104.522L136.848 35.6441ZM85.8069 202.99L187.991 143.993L187.991 158.96C187.991 161.818 186.466 164.459 183.991 165.888L106.768 210.474C104.293 211.903 101.243 211.903 98.7683 210.474L85.8069 202.99Z"
        fill="currentColor"
      />
    </svg>
  );
}
