import {
  Box,
  Button,
  Card,
  Center,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { IconHexagon } from "@tabler/icons-react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { RestrictedFeature } from "../../components/RestrictedFeature";
import { Can } from "../../components/auth/Can";
import { StackKey, stackStyle } from "../../components/icons/stacks";
import { BlocksGroupedByStack } from "../../utils/usePillars";
import classes from "./StackGroupCard.module.css";
import { useAddStack } from "./useAddStack";
import { useIsStackReadable } from "./useIsStackReadable";

type StackGroupCardProps = {
  stack: BlocksGroupedByStack["stack"];
  onAddStackFinished?: (slug: string) => void;
};

export function StackGroupCard({
  stack,
  onAddStackFinished,
}: StackGroupCardProps) {
  const navigate = useNavigate();
  const { isStackReadable } = useIsStackReadable();
  const { ref: cardRef, hovered: isCardHovered } = useHover();
  const { addStack, isAddingStack } = useAddStack();

  const isRestricted = !isStackReadable(stack.slug);
  const blockStackStyle = stackStyle[stack.slug.toLowerCase() as StackKey];

  return (
    <Box
      ref={cardRef}
      pos="relative"
      w={{ base: "100%", sm: "30%" }}
      h={228}
      mb="lg"
      my="lg"
    >
      <RestrictedFeature
        title={`Stack ${stack.name} Locked`}
        description="This stack is available to paid NOAN subscribers only"
        isRestricted={isRestricted}
        minimize
      >
        {({ restrictedClassName }) => (
          <>
            <Center>
              <Card pos="absolute" w="80%" h="100%" top={-20} />
            </Center>
            <Center>
              <Card pos="absolute" w="90%" h="100%" top={-10} />
            </Center>
            <Center>
              <Card
                className={classes.card}
                pos="absolute"
                w="100%"
                h="100%"
                top={0}
                shadow="sm"
              >
                <Stack
                  gap="xs"
                  justify="center"
                  align="center"
                  h="100%"
                  className={restrictedClassName}
                >
                  <ThemeIcon
                    size={32}
                    variant="transparent"
                    className={classes.icon}
                  >
                    {blockStackStyle?.icon ?? <IconHexagon size={32} />}
                  </ThemeIcon>
                  <Text size="lg" fw={500}>
                    {stack.name}
                  </Text>
                  <Tooltip
                    withArrow
                    label={`View all blocks included in ${stack.name} stack`}
                  >
                    <Button
                      className={clsx(
                        (!isCardHovered || isRestricted) &&
                          classes.hiddenActionButtons,
                      )}
                      w={140}
                      variant="outline"
                      onClick={() => navigate(`/build/s/${stack.slug}`)}
                      color="teal.5"
                      disabled={isAddingStack}
                    >
                      View stack
                    </Button>
                  </Tooltip>
                  {onAddStackFinished && (
                    <Can execute="organization.update">
                      <Tooltip
                        withArrow
                        label={`Add ${stack.name} stack to your strategy`}
                      >
                        <Button
                          className={clsx(
                            (!isCardHovered || isRestricted) &&
                              classes.hiddenActionButtons,
                          )}
                          w={140}
                          variant="filled"
                          onClick={() =>
                            addStack({
                              stack,
                              onAddStackFinished,
                            })
                          }
                          color="teal.5"
                          disabled={isAddingStack}
                          loading={isAddingStack}
                        >
                          Add stack
                        </Button>
                      </Tooltip>
                    </Can>
                  )}
                </Stack>
              </Card>
            </Center>
          </>
        )}
      </RestrictedFeature>
    </Box>
  );
}
