import { Fact } from "api/src/models/Fact";
import { useMemo, useState, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Pillar } from "../../utils/usePillars";

/**
 * Wrapper around various filter options to expose them as query parameters,
 * as well as regular React state.
 */
export function useStrategyBrowserFilters({
  stacks,
  facts,
  initialDefaultStacks,
}: {
  stacks: Pillar[];
  facts: Fact[];
  initialDefaultStacks?: string[];
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialDefaultStackFilters = useMemo(() => {
    if (facts.length === 0) {
      return stacks.filter((p) => p.content.advanced_block).map((p) => p.slug);
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filteredStacks, setFilteredStacks] = useState<string[]>(
    () =>
      searchParams.get("filtered")?.split("+") ??
      initialDefaultStacks ??
      initialDefaultStackFilters,
  );
  const [hideCompleteInputBlocks, setHideCompleteInputBlocks] = useState(
    () => searchParams.get("hideComplete") === "yes",
  );

  const [hideInputBlocks, setHideInputBlocks] = useState(
    () => searchParams.get("hideInput") === "yes",
  );

  const updateSearchParams = useCallback(
    (opts: {
      filtered: string[] | null;
      hideComplete: boolean | null;
      hideInput: boolean | null;
    }) => {
      const newSearchParams = new URLSearchParams(searchParams);

      for (const [k, v] of Object.entries(opts)) {
        if (v == null) {
          newSearchParams.delete(k);
        } else if (typeof v === "boolean") {
          /** 'no' gets deleted to reduce noise in the url, for now at least. */
          if (v) {
            newSearchParams.set(k, "yes");
          } else {
            newSearchParams.delete(k);
          }
        } else if (Array.isArray(v)) {
          if (v.length > 0) {
            newSearchParams.set(k, v.join("+"));
          } else {
            newSearchParams.delete(k);
          }
        }
      }

      setSearchParams(newSearchParams, {
        replace: true,
      });
    },
    [searchParams, setSearchParams],
  );

  useEffect(() => {
    updateSearchParams({
      filtered: filteredStacks,
      hideComplete: hideCompleteInputBlocks,
      hideInput: hideInputBlocks,
    });
  }, [
    filteredStacks,
    hideCompleteInputBlocks,
    hideInputBlocks,
    updateSearchParams,
  ]);

  return {
    filteredStacks,
    setFilteredStacks,
    hideCompleteInputBlocks,
    setHideCompleteInputBlocks,
    hideInputBlocks,
    setHideInputBlocks,
  };
}
