import {
  ActionIcon,
  Alert,
  Badge,
  Blockquote,
  Box,
  Button,
  Card,
  Center,
  Container,
  Drawer,
  Group,
  Loader,
  Overlay,
  Popover,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
  Timeline,
  Title,
  Tooltip,
  Transition,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure, useFocusWithin } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconAlertTriangle,
  IconArrowBackUp,
  IconDeviceFloppy,
  IconEdit,
  IconLock,
  IconLockOpen,
  IconRobot,
  IconTrash,
  IconUser,
  IconVersions,
} from "@tabler/icons-react";
import {
  ConvoEntryProvenance,
  ConvoEntryType,
  ConvoSource,
  type ConvoEntry,
} from "api/src/models/Convo";
import {
  GeneratorFactory,
  MessageRole,
  type GeneratorUntrustedInput,
} from "api/src/models/GeneratorInput";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAuthContext } from "../../auth/useAuthContext";
import { useIsGranted } from "../../auth/useIsGranted";
import { AssistantIcon } from "../../components/AssistantSwitcher/AssistantIcon";
import { BackButton } from "../../components/BackButton";
import { ChatAssistant } from "../../components/ChatAssistant";
import { GeneratorSwitcherButton } from "../../components/GeneratorSwitcher/GeneratorSwitcherButton";
import {
  generatorFromId,
  isGeneratorId,
} from "../../components/GeneratorSwitcher/generatorFromId";
import {
  MarkdownContent,
  type TextSelectionAction,
} from "../../components/MarkdownContent";
import { RichTextEditor } from "../../components/RichTextEditor";
import { TextCopier } from "../../components/TextCopier";
import {
  aiGenerators,
  useGeneratorQuery,
  type AiGeneratorOption,
} from "../../useGenerator";
import { assistantById } from "../../utils/assistantById";
import { relativeTimeFromNow } from "../../utils/date";
import { createTextSelectionTonePrompt } from "../../utils/promptHelpers";
import { trpc } from "../../utils/trpc";
import { useDeleteConvo } from "../../utils/useConversation";
import { useToggle } from "../../utils/useToggle";
import classes from "./Create.module.css";
import { useCreateConvoEntry } from "./useCreateConvoEntry";

interface ConvoEntryForm {
  prompt: string;
  generator: AiGeneratorOption["value"];
}

function useConvo(
  convoId: string,
  { refetchForMetadata = false }: { refetchForMetadata?: boolean } = {},
) {
  return trpc.convos.getConvo.useSuspenseQuery(
    {
      convoId,
    },
    {
      /**
       * If refetchForMetadata is enabled, and we're still missing metadata,
       * we keep trying every 3 seconds.
       */
      refetchInterval: (data) => {
        if (
          refetchForMetadata &&
          data &&
          (!data.configuration.isTitleGenerated || !data.configuration.bucket)
        ) {
          return 3000;
        }

        return 0;
      },
    },
  );
}

function useSetConvoTitle() {
  const utils = trpc.useUtils();
  const mutation = trpc.convos.setTitle.useMutation({
    onSettled() {
      utils.convos.invalidate();
    },
  });

  return {
    ...mutation,
    setConvoTitle: mutation.mutate,
  };
}

function useToggleConvoPrivacy(convoId: string | undefined) {
  const utils = trpc.useUtils();
  const mutation = trpc.convos.togglePrivate.useMutation({
    onSettled() {
      utils.convos.getConvo.invalidate({
        convoId: convoId,
      });
    },
  });

  return {
    ...mutation,
    toggleConvoPrivacy: (newValue: boolean) => {
      if (!convoId) {
        return;
      }

      mutation.mutate({
        convoId,
        private: newValue,
      });
    },
  };
}

function ConvoHistory({
  convoId,
  opened,
  onClose,
}: {
  convoId: string;
  opened: boolean;
  onClose: () => void;
}) {
  const [convo] = useConvo(convoId);
  const utils = trpc.useUtils();
  const {
    mutate,
    isLoading: isLoadingSetVersion,
    variables: setVersionVariables,
  } = trpc.convos.setInputEntryVersion.useMutation();

  function onSetInputEntryVersion(entryId: string) {
    mutate(
      {
        convoEntryId: entryId,
        convoId,
      },
      {
        onSettled: () => {
          utils.convos.getConvo.invalidate({
            convoId,
          });

          onClose();
        },
      },
    );
  }

  /**
   * Organize entries into pairs:
   */
  const entrySets = useMemo(
    () =>
      convo.entries.reduce<ConvoEntry[][]>((set, entry) => {
        if (
          entry.content.entryType === ConvoEntryType.Initial ||
          entry.content.entryType === ConvoEntryType.Refinement
        ) {
          set.push([entry]);
        } else {
          /**
           * If this is not an initial prompt, we group it alongside whatever's available
           * in the current set. We still check that we have a set available, just in case,
           * and allow creating sets even without an initial prompt.
           */
          if (set.at(-1) == null) {
            set.push([]);
          }

          set.at(-1)!.push(entry);
        }

        return set;
      }, []),
    [convo],
  );

  return (
    <Drawer.Root opened={opened} onClose={onClose} position="right" size="lg">
      <Drawer.Overlay />
      <Drawer.Content>
        <Drawer.Header>
          <Drawer.Title component={Group}>
            <ThemeIcon variant="transparent" color="dark" size="md">
              <IconVersions />
            </ThemeIcon>
            <Title order={4}>Creation History</Title>
          </Drawer.Title>
          <Drawer.CloseButton />
        </Drawer.Header>
        <Drawer.Body>
          <Stack gap="xl" mt="lg">
            {entrySets.reverse().map((set, i) => {
              const inputEntry = set.at(0);

              return (
                <Box key={i}>
                  <Timeline
                    active={i === 0 ? set.length : undefined}
                    bulletSize={30}
                    color="teal"
                  >
                    {set.map((entry) => {
                      const generator = entry.content.provenance?.generator;
                      const source = entry.content.provenance?.source;

                      return (
                        <Timeline.Item
                          key={entry.id}
                          title={source === "user" ? "Input" : "Result"}
                          bullet={
                            source === "user" ? (
                              <IconUser />
                            ) : isGeneratorId(generator) ? (
                              generatorFromId(generator).icon
                            ) : (
                              <IconRobot />
                            )
                          }
                        >
                          {source === "user" ? (
                            <Blockquote
                              mt={"md"}
                              mb={0}
                              mx={0}
                              color="dark"
                              p="sm"
                              radius={0}
                            >
                              {entry.content.plainText}
                            </Blockquote>
                          ) : (
                            <MarkdownContent
                              lazyRendering
                              preview
                              content={entry.content.plainText}
                            />
                          )}
                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                  {inputEntry && (
                    <Group mt="md" className={classes.entrySetDetails}>
                      <Group flex={1}>
                        {relativeTimeFromNow(inputEntry.createdAt)}
                      </Group>
                      <Tooltip
                        label={
                          i === 0
                            ? "This is already the most recent version of your creation"
                            : "Revert to this version of your creation"
                        }
                      >
                        <Button
                          size="xs"
                          variant="default"
                          color="dark"
                          onClick={() => onSetInputEntryVersion(inputEntry.id)}
                          loading={
                            isLoadingSetVersion &&
                            setVersionVariables?.convoEntryId === inputEntry.id
                          }
                          disabled={true}
                        >
                          Revert to this version
                        </Button>
                      </Tooltip>
                    </Group>
                  )}
                </Box>
              );
            })}
          </Stack>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
}

function ConvoContainer({
  isAssistantOpened,
  children,
}: {
  isAssistantOpened: boolean;
  children: JSX.Element;
}) {
  return (
    <Container
      mt="xl"
      mb={60}
      size="sm"
      fluid={isAssistantOpened}
      className={isAssistantOpened ? classes.slideLeft : classes.slideCenter}
    >
      {children}
    </Container>
  );
}

export function Convo() {
  const { convoId } = useParams();
  const [searchParams] = useSearchParams();
  const [
    convoHistoryOpened,
    { open: openConvoHistory, close: closeConvoHistory },
  ] = useDisclosure(searchParams.has("history"));

  const [convo, { refetch, isFetching }] = useConvo(convoId!, {
    refetchForMetadata: false,
  });

  const { identity } = useAuthContext();
  const { ref: promptInputRef, focused: promptFocused } = useFocusWithin();
  const { toggleConvoPrivacy } = useToggleConvoPrivacy(convoId);
  const { createConvoEntry, isLoading: isCreatingConvoEntry } =
    useCreateConvoEntry();
  const { setConvoTitle, isLoading: isLoadingSetConvoTitle } =
    useSetConvoTitle();
  const { deleteConvo, isLoading: isDeleting } = useDeleteConvo({
    onSettled() {
      notifications.show({
        message: "Creation deleted!",
        icon: <IconTrash size={14} />,
      });
    },
  });
  const navigate = useNavigate();
  const [
    deleteConfirmationOpened,
    { close: closeDeleteConfirmation, open: openDeleteConfirmation },
  ] = useDisclosure(false);

  const [tone, setTone] = useState<string | undefined>();

  const titleInputRef = useRef<HTMLTextAreaElement>(null);
  const [isEditingTitle, toggleIsEditingTitle] = useToggle();
  const [isEditingResult, toggleIsEditingResult] = useToggle();
  const [isAssistantOpened, toggleIsAssistantOpened] = useToggle();

  const canEdit =
    useIsGranted({
      permission: "convo.update",
    }) && convo.creatorId === identity.id;

  const refinementOrInitialPromptEntries = convo.entries.filter(
    (e) =>
      e.content.entryType === ConvoEntryType.Initial ||
      e.content.entryType === ConvoEntryType.Refinement,
  );

  const resultEntries = convo.entries.filter(
    (e) => e.content.entryType === ConvoEntryType.Result,
  );

  const activePromptEntry = refinementOrInitialPromptEntries.at(-1);
  const activeResultEntry = resultEntries.at(-1);

  const activeResultGen = useMemo(() => {
    return (
      activeResultEntry &&
      activeResultEntry.content.provenance?.generator &&
      isGeneratorId(activeResultEntry.content.provenance.generator) &&
      generatorFromId(activeResultEntry.content.provenance.generator)
    );
  }, [activeResultEntry]);

  const [generationEnabled, setGenerationEnabled] =
    useToggle(!activeResultEntry);

  const defaultGenerator = aiGenerators.at(0)!;

  const form = useForm<ConvoEntryForm>({
    initialValues: {
      prompt: activePromptEntry?.content.plainText || "",
      generator: (activePromptEntry?.content.provenance?.generator ||
        defaultGenerator.value) as AiGeneratorOption["value"],
    },
  });

  const { generatedText, isLoading: isGeneratingText } = useGeneratorQuery({
    enabled: canEdit && generationEnabled && !isFetching && !!activePromptEntry,
    onEndGenerate: () => {
      refetch();
      setGenerationEnabled(false);
    },

    generate: {
      generator: (form.values.generator ||
        defaultGenerator.value) as GeneratorUntrustedInput["generator"],
      role: MessageRole.User,
      input: form.values.prompt,
      factory: GeneratorFactory.Create,
      tone: tone
        ? {
            text: tone,
          }
        : undefined,
      thread: {
        convoId,
        last: tone ? activeResultEntry?.content.plainText : undefined,
        assistantPersona: activePromptEntry?.content?.assistantId,
      },
    },
  });

  const onCreateEntry = useCallback(
    (values: ConvoEntryForm) => {
      setTone(undefined);
      setGenerationEnabled(true);
      createConvoEntry({
        convoId: convoId!,
        content: {
          entryType: ConvoEntryType.Refinement,
          format: {
            type: "text",
          },
          plainText: values.prompt,
        },
      });
    },
    [convoId, createConvoEntry, setTone, setGenerationEnabled],
  );

  const onSelectionAction = useCallback(
    (action: TextSelectionAction) => {
      setGenerationEnabled(true);
      setTone(createTextSelectionTonePrompt(action));
    },
    [setGenerationEnabled, setTone],
  );

  const onDeleteConvo = useCallback(() => {
    if (canEdit && convoId) {
      deleteConvo(
        {
          convoId,
        },
        {
          onSettled() {
            closeDeleteConfirmation();
            navigate("/create");
          },
        },
      );
    }
  }, [deleteConvo, convoId, navigate, canEdit, closeDeleteConfirmation]);

  const onSaveResultChanges = useCallback(
    (value: string) => {
      if (!activeResultEntry) {
        throw new Error(
          "invariant: expected active result entry when saving changes to it",
        );
      }

      createConvoEntry(
        {
          convoId: convoId!,
          content: {
            entryType: ConvoEntryType.Result,
            provenance: {
              generator:
                activeResultEntry.content.provenance?.generator ||
                form.values.generator,
              source: ConvoEntryProvenance.UserEditedResult,
            },
            assistantId: activeResultEntry.content.assistantId,
            format: {
              type: "text",
            },
            plainText: value,
          },
        },
        {
          onSuccess: () => {
            toggleIsEditingResult(false);
          },
        },
      );
    },
    [
      convoId,
      activeResultEntry,
      createConvoEntry,
      toggleIsEditingResult,
      form.values.generator,
    ],
  );

  useEffect(() => {
    const t = titleInputRef.current;

    if (t && convo.title && t.value !== convo.title) {
      t.value = convo.title;
    }
  }, [isEditingTitle, convo.title, titleInputRef]);

  if (!convoId) {
    return <Navigate to="/create" />;
  }

  const hasPromptChanges = form.isTouched("prompt");
  const resultContent = generatedText || activeResultEntry?.content.plainText;
  const canEditResult =
    !isFetching && activeResultEntry && !isGeneratingText && !isEditingResult;
  const assistant = convo.configuration.assistantId
    ? assistantById(convo.configuration.assistantId)
    : undefined;
  const showAssistant = !!activeResultEntry?.content.plainText && convoId;

  return (
    <ConvoContainer isAssistantOpened={isAssistantOpened}>
      <>
        {showAssistant && (
          <ChatAssistant
            assistantId={convo.configuration.assistantId}
            sourceRef={convoId}
            initialMessage={`Hi ${identity.name || identity.email}, I am your virtual assistant, here to assist you as a <b>${assistant?.name}</b>. Ask me questions to assess & improve your asset. My answers are based on the asset you created, my knowledge of your business and the internet.`}
            source={ConvoSource.CreateAssetAssistant}
            context={activeResultEntry.content.plainText}
            isAssistantOpened={isAssistantOpened}
            toggleIsAssistantOpened={toggleIsAssistantOpened}
            outputActions={[
              {
                tooltip: "Replace your asset with this content",
                icon: <IconDeviceFloppy size={14} />,
                action: onSaveResultChanges,
              },
            ]}
          />
        )}
        <Stack>
          <Group>
            <BackButton label="Back to Create" />
          </Group>
          <Stack gap="sm">
            <Textarea
              autosize
              rows={1}
              fw={600}
              ref={titleInputRef}
              readOnly={!isEditingTitle}
              defaultValue={convo.title ?? ""}
              classNames={{
                root: classes.convoTitleContainer,
                input: classes.convoTitleInput,
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.currentTarget.blur();
                } else if (e.key === "Escape") {
                  e.currentTarget.value = convo.title ?? "Untitled";
                  e.currentTarget.blur();
                }
              }}
              onBlur={() => {
                if (isEditingTitle) {
                  toggleIsEditingTitle(false);

                  const newTitle = titleInputRef.current?.value;
                  if (newTitle && newTitle !== convo.title) {
                    setConvoTitle({
                      convoId,
                      title: newTitle,
                    });
                  }
                }
              }}
              rightSectionWidth="auto"
              rightSectionProps={{
                className: classes.convoTitleActions,
              }}
              rightSection={
                <Group>
                  {isLoadingSetConvoTitle && <Loader size="sm" />}
                  {canEdit && !isEditingTitle && !isLoadingSetConvoTitle && (
                    <Tooltip label="Edit title">
                      <ActionIcon
                        className={classes.editTitleButton}
                        variant="transparent"
                        color="dark"
                        onClick={() => {
                          toggleIsEditingTitle();

                          if (!isEditingTitle) {
                            setTimeout(() => {
                              titleInputRef.current?.focus();
                              titleInputRef.current?.select();
                            }, 1);
                          }
                        }}
                      >
                        <IconEdit />
                      </ActionIcon>
                    </Tooltip>
                  )}
                </Group>
              }
            />

            <Group gap="sm">
              {convo.configuration.assistantId && (
                <AssistantIcon assistantId={convo.configuration.assistantId} />
              )}
              <Text span size="sm" fw="bold">
                {convo.creator.name ?? convo.creator.email}
              </Text>
              <Text span size="sm" c="dimmed">
                {relativeTimeFromNow(convo.createdAt)}
              </Text>
              {convo.configuration.bucket && (
                <Badge tt="none" variant="light" color="dark">
                  {convo.configuration.bucket}
                </Badge>
              )}

              <Group flex={1} justify="flex-end">
                {canEdit && (
                  <Tooltip
                    label={
                      convo.private
                        ? "Only you can see this"
                        : "People in your organization can see this"
                    }
                  >
                    <ActionIcon
                      size="sm"
                      color={convo.private ? "yellow" : "dark"}
                      variant="transparent"
                      onClick={
                        canEdit
                          ? () => {
                              toggleConvoPrivacy(!convo.private);
                            }
                          : undefined
                      }
                    >
                      {convo.private ? <IconLock /> : <IconLockOpen />}
                    </ActionIcon>
                  </Tooltip>
                )}
                {canEdit && (
                  <Popover
                    withArrow
                    width={400}
                    opened={deleteConfirmationOpened}
                  >
                    <Popover.Target>
                      <Tooltip label="Delete this creation">
                        <ActionIcon
                          size="sm"
                          color="dark"
                          variant="transparent"
                          onClick={() => openDeleteConfirmation()}
                        >
                          <IconTrash />
                        </ActionIcon>
                      </Tooltip>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Stack gap="xs">
                        <Alert
                          title="Are you sure?"
                          icon={<IconAlertTriangle />}
                          color="red"
                        >
                          Do you want to delete this creation? This action
                          cannot be undone.
                        </Alert>
                        <Group gap="sm" justify="flex-end">
                          <Button
                            size="xs"
                            variant="default"
                            onClick={() => closeDeleteConfirmation()}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="red"
                            size="xs"
                            onClick={onDeleteConvo}
                            loading={isDeleting}
                          >
                            Delete
                          </Button>
                        </Group>
                      </Stack>
                    </Popover.Dropdown>
                  </Popover>
                )}
              </Group>
            </Group>
            <Box mt="lg">
              <Title order={4} mb="sm" fw={600}>
                Instructions
              </Title>
              <form onSubmit={form.onSubmit(onCreateEntry)}>
                <Card p={0}>
                  <Textarea
                    minRows={3}
                    maxRows={20}
                    ref={promptInputRef}
                    autosize
                    readOnly={!canEdit}
                    disabled={isGeneratingText}
                    {...form.getInputProps("prompt")}
                    classNames={{
                      root: classes.editablePromptContainer,
                      input: classes.editablePromptInput,
                    }}
                  />

                  <Box className={classes.promptControls}>
                    <Transition
                      mounted={(promptFocused || hasPromptChanges) && canEdit}
                      duration={100}
                      transition="slide-up"
                    >
                      {(styles) => (
                        <Group
                          justify="flex-end"
                          w="100%"
                          p="sm"
                          style={styles}
                          gap="xs"
                        >
                          <Tooltip label="Undo changes">
                            <ActionIcon
                              variant="white"
                              disabled={!hasPromptChanges || isGeneratingText}
                              size="lg"
                              color="dark"
                              onClick={() => {
                                form.setFieldValue(
                                  "prompt",
                                  activePromptEntry?.content.plainText || "",
                                );
                              }}
                            >
                              <IconArrowBackUp />
                            </ActionIcon>
                          </Tooltip>
                          <GeneratorSwitcherButton
                            options={aiGenerators.slice(0, 2)}
                            selectedGenerator={
                              aiGenerators.find(
                                (g) => g.value === form.values.generator,
                              ) || defaultGenerator
                            }
                            onSelectGenerator={(option) =>
                              form.setFieldValue("generator", option.value)
                            }
                          />
                          <Button
                            size="sm"
                            color="black"
                            type="submit"
                            disabled={!hasPromptChanges || isGeneratingText}
                          >
                            Update Instructions
                          </Button>
                        </Group>
                      )}
                    </Transition>
                  </Box>
                </Card>
              </form>
            </Box>
            <Box mt="lg">
              <Group mb="sm">
                <Group flex={1} gap="xs" align="center">
                  {activeResultGen && (
                    <Tooltip
                      label={`Result generated with ${activeResultGen.label}`}
                    >
                      <ThemeIcon variant="default">
                        {activeResultGen.icon}
                      </ThemeIcon>
                    </Tooltip>
                  )}
                  <Title order={4} mb={0} fw={600}>
                    Result{" "}
                  </Title>
                  <Tooltip label="View the complete history for this creation">
                    <Button
                      size="xs"
                      color="dark"
                      variant="subtle"
                      onClick={() => openConvoHistory()}
                    >
                      Previous versions
                    </Button>
                  </Tooltip>
                </Group>

                <Group gap="xs">
                  {resultContent && (
                    <TextCopier
                      disabled={isGeneratingText}
                      content={resultContent}
                    />
                  )}

                  {canEditResult && (
                    <Tooltip label="Edit result">
                      <ActionIcon
                        size="sm"
                        color="dark"
                        variant="transparent"
                        onClick={() => toggleIsEditingResult()}
                      >
                        <IconEdit />
                      </ActionIcon>
                    </Tooltip>
                  )}
                </Group>
              </Group>
              <Card
                mih={250}
                p={0}
                styles={{
                  root: {
                    // Ensure the editor's sticky toolbar works properly:
                    overflow: "initial",
                  },
                }}
              >
                {!resultContent && isGeneratingText && (
                  <Overlay backgroundOpacity={0.1}>
                    <Center py="xl">
                      <Card p="lg">
                        <Stack>
                          <Loader />
                          <Text>Getting everything set up for you...</Text>
                        </Stack>
                      </Card>
                    </Center>
                  </Overlay>
                )}

                <Transition
                  mounted={isEditingResult}
                  keepMounted={false}
                  enterDelay={301}
                  duration={250}
                  exitDelay={0}
                  transition="fade"
                >
                  {(styles) => (
                    <RichTextEditor
                      isSaving={isCreatingConvoEntry}
                      content={resultContent}
                      onSave={onSaveResultChanges}
                      editorProps={{
                        style: styles,
                      }}
                    />
                  )}
                </Transition>
                <Transition
                  mounted={!isEditingResult}
                  keepMounted={false}
                  transition="fade"
                  exitDelay={0}
                  duration={250}
                  enterDelay={500}
                >
                  {(styles) => (
                    <Box p="lg">
                      <MarkdownContent
                        style={styles}
                        lazyRendering={false}
                        content={resultContent}
                        withSelectionActions={
                          canEdit && !isGeneratingText && !!resultContent
                        }
                        onSelectionAction={onSelectionAction}
                      />
                    </Box>
                  )}
                </Transition>
              </Card>
            </Box>
          </Stack>
        </Stack>

        <ConvoHistory
          opened={convoHistoryOpened}
          onClose={closeConvoHistory}
          convoId={convoId}
        />
      </>
    </ConvoContainer>
  );
}
