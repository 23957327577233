import classes from "./StackIcons.module.css";

export function GrowthIcon() {
  return (
    <svg
      width="206"
      height="228"
      viewBox="0 0 206 228"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <g clipPath="url(#clip0_1444_22266)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M192.43 48.3302L115.06 3.26021C107.66 -1.04979 98.51 -1.08979 91.07 3.16021L12.09 48.2902C4.62 52.5702 0 60.5202 0 69.1302V159.16C0 167.81 4.65 175.78 12.17 180.04L90.95 224.65C98.46 228.9 107.68 228.8 115.09 224.37L193.4 177.58C200.71 173.21 205.16 165.29 205.09 156.78L204.35 68.8602C204.28 60.3902 199.75 52.5902 192.43 48.3202V48.3302ZM101.58 211.18L18.22 163.47C17.29 162.94 16.71 161.94 16.71 160.87V66.6302C16.71 65.5502 17.29 64.5602 18.22 64.0302L101.77 16.1202C102.71 15.5802 103.86 15.5902 104.79 16.1402L186.15 64.4802C187.05 65.0202 187.61 65.9902 187.62 67.0402L188.32 159.33C188.32 160.39 187.77 161.38 186.86 161.93L104.6 211.15C103.67 211.71 102.51 211.72 101.57 211.18H101.58ZM171.14 74.91L143.43 59.41C139.75 57.35 135.27 57.37 131.61 59.47L104.37 75.06L86.6601 65.3L51.8301 84.99L52.0501 105.31L40.8201 111.72C37.0801 113.88 34.7901 117.88 34.8201 122.2L34.8401 154.19L62.8201 169.9C66.5301 171.98 72.5101 171.84 76.1201 169.57L86.1201 163.28L102.88 172.7L137.26 152.73L137.41 133.66L165.35 117.26C169.04 115.1 171.29 111.14 171.28 106.86L171.15 74.9L171.14 74.91ZM154.07 103.57L120.34 123.11L120.3 142.98L103.43 152.23L68.7601 133.53L68.8501 153.03L52.0101 143.88L52.0701 124.36L68.7101 134.15V95.69L87.0101 85.18L103.65 94.63L119.79 84.52L137.66 74.68L154.32 84.06L154.07 103.57ZM120.31 104.08L138.13 93.99L120.49 84.28L120.31 104.08Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1444_22266">
          <rect width="205.09" height="227.78" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
