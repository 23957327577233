import {
  ActionIcon,
  Alert,
  Anchor,
  Badge,
  Button,
  Card,
  Center,
  Group,
  HoverCard,
  MantineFontSize,
  MantineSpacing,
  Modal,
  Stack,
  StyleProp,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconAlertTriangle,
  IconCheckbox,
  IconCircleCheckFilled,
  IconEye,
  IconPencilOff,
} from "@tabler/icons-react";
import { MarkdownContent } from "../MarkdownContent";

import { useDisclosure, useHover } from "@mantine/hooks";
import clsx from "clsx";
import { Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import { useModuleRequirements } from "../../pages/Build/useModuleRequirements";
import { BlocksGroupedByStack } from "../../utils/usePillars";
import classes from "./BlockOverviewCard.module.css";
import { BlockRequirement } from "./BlockRequirementsAlert";

export function BlockWithRequirements({
  block,
  ...props
}: BlockOverviewCardProps & {
  block: BlocksGroupedByStack["blocks"][number];
}) {
  const blockedByRequirements = useModuleRequirements(block);

  return (
    <BlockOverviewCard requiredBlocks={blockedByRequirements} {...props} />
  );
}

export function RequirementsLinks({
  requiredBlocks = [],
  size = "xs",
  type = "text",
}: {
  requiredBlocks: BlockOverviewCardProps["requiredBlocks"];
  size?: MantineFontSize;
  type?: "text" | "alert";
}) {
  const textRef = useRef<HTMLParagraphElement>(null);
  const hasLinks = requiredBlocks.length > 0;
  const requirementLinks = hasLinks
    ? requiredBlocks.map((req, idx) => {
        const isLast = idx === requiredBlocks.length - 1;

        return (
          <Fragment key={req.blockPath}>
            <Anchor
              component={Link}
              to={`/build/b/${req.blockPath}`}
              className={classes.link}
              onClick={(e) => e.stopPropagation()}
            >
              {req.label}
            </Anchor>
            {!isLast && ", "}
          </Fragment>
        );
      })
    : undefined;

  const isTextClamped =
    textRef.current &&
    textRef.current.scrollHeight > textRef.current.clientHeight;

  if (!hasLinks) return null;

  const textDisplay = (
    <HoverCard
      width={280}
      shadow="md"
      disabled={!isTextClamped}
      withArrow
      position="bottom"
      classNames={{
        dropdown: classes.requirementPopover,
      }}
    >
      <HoverCard.Target>
        <Text ref={textRef} c="dimmed" size={size} lineClamp={2}>
          This block isn't available yet. Complete {requirementLinks} first.
        </Text>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text c="dimmed" size={size}>
          This block isn't available yet. Complete {requirementLinks} first.
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );

  switch (type) {
    case "alert":
      return (
        <Alert
          variant="default"
          color="dark"
          py="xs"
          icon={
            <ThemeIcon size={18} color="dark" variant="light">
              <IconCheckbox />
            </ThemeIcon>
          }
        >
          {textDisplay}
        </Alert>
      );
    case "text":
    default:
      return textDisplay;
  }
}

function ContentModal({
  title,
  content,
  isOpened,
  onClose,
}: {
  title: string;
  content: string;
  isOpened: boolean;
  onClose: () => void;
}) {
  return (
    <Modal
      opened={isOpened}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      title={
        <Group gap="xs">
          <ThemeIcon variant="transparent" className={classes.icon}>
            <IconEye />
          </ThemeIcon>
          <Title order={3} fw="bold">
            {title}
          </Title>
        </Group>
      }
      centered
      size="xl"
    >
      <MarkdownContent content={content} />
    </Modal>
  );
}

export function OutdatedIcon() {
  return (
    <Tooltip
      withArrow
      label="This block depends on other blocks that have since been modified"
    >
      <Badge circle variant="light" size="md" color="red">
        <Center>
          <ThemeIcon size="xs" variant="transparent" color="red">
            <IconAlertTriangle />
          </ThemeIcon>
        </Center>
      </Badge>
    </Tooltip>
  );
}

export type BlockOverviewCardProps = {
  className?: string;
  w?: StyleProp<MantineSpacing>;
  title: string;
  content?: string;
  noContentButton?: {
    label: string;
    disabledLabel?: string;
    disabled?: boolean;
    onClick: () => void;
  };
  requiredBlocks?: BlockRequirement[];
  isOutdatedVersion: boolean;
  onClickCard: () => void;
};

export function BlockOverviewCard({
  className,
  w,
  title,
  content,
  noContentButton,
  requiredBlocks = [],
  isOutdatedVersion,
  onClickCard,
}: BlockOverviewCardProps) {
  const hasContent = !!content;
  const isUnavailable = requiredBlocks.length > 0;
  const [
    isContentViewOpened,
    { open: openContentView, close: closeContentView },
  ] = useDisclosure();
  const { ref: refCard, hovered: isCardHovered } = useHover();

  return (
    <Card
      ref={refCard}
      shadow="sm"
      p="lg"
      radius={12}
      withBorder
      w={w}
      h={{ md: 120 }}
      className={clsx(classes.card, className)}
      onClick={() => onClickCard()}
    >
      <Stack gap="xs" h="100%" justify="space-between">
        {hasContent && (
          <Group justify="space-between" h={30}>
            <Group gap="xs">
              <Badge
                variant="filled"
                color="teal.6"
                styles={{
                  root: { borderRadius: 4 },
                }}
                size="lg"
                fw="500"
                tt="none"
                leftSection={
                  <ThemeIcon size={18} color="teal.6">
                    <IconCircleCheckFilled />
                  </ThemeIcon>
                }
              >
                Complete
              </Badge>
              {isOutdatedVersion && <OutdatedIcon />}
            </Group>
            {isCardHovered && (
              <Group gap={2}>
                <Tooltip label="Quick view">
                  <ActionIcon
                    variant="transparent"
                    className={classes.icon}
                    onClick={(e) => {
                      e.stopPropagation();
                      openContentView();
                    }}
                  >
                    <IconEye size={16} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            )}
          </Group>
        )}
        <Group gap="xs" wrap="nowrap">
          <Tooltip withArrow label={title}>
            <Text
              fw={500}
              size="lg"
              truncate="end"
              className={clsx(isUnavailable && classes.unavailableContent)}
            >
              {title}
            </Text>
          </Tooltip>
          {isUnavailable && (
            <ThemeIcon size="sm" variant="transparent" color="dimmed">
              <IconPencilOff />
            </ThemeIcon>
          )}
        </Group>
        {isUnavailable ? (
          <RequirementsLinks requiredBlocks={requiredBlocks} />
        ) : (
          !hasContent &&
          noContentButton && (
            <Stack justify="flex-end">
              <Button
                disabled={noContentButton.disabled}
                variant={noContentButton.disabled ? "light" : "default"}
                color="dark"
                fw="normal"
                onClick={noContentButton?.onClick}
              >
                {(noContentButton.disabled && noContentButton.disabledLabel) ||
                  noContentButton.label}
              </Button>
            </Stack>
          )
        )}
      </Stack>
      {hasContent && (
        <ContentModal
          title={title}
          content={content}
          isOpened={isContentViewOpened}
          onClose={closeContentView}
        />
      )}
    </Card>
  );
}
