import {
  ActionIcon,
  Button,
  Divider,
  Drawer,
  Group,
  Loader,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconListTree, IconVersions } from "@tabler/icons-react";
import { Fact, FactWithState } from "api/src/models/Fact";
import clsx from "clsx";
import { Suspense, useState } from "react";
import { BlockVersions } from "../../pages/Build/BlockCard";
import { ContentStories, StoryView } from "../../pages/Build/Content";
import { AiGeneratorOption } from "../../useGenerator";
import { getLearningMaterialSlug } from "../../utils/storyblokHelpers";
import {
  BlocksGroupedByStack,
  isGenerativeModule,
} from "../../utils/usePillars";
import { GeneratorSwitcherButton } from "../GeneratorSwitcher/GeneratorSwitcherButton";
import { TextCopier } from "../TextCopier";
import classes from "./BlockContentActions.module.css";
import { RegenerateOptions } from "./RegenerateOptions";
import { ToneHint } from "./config";

function getLearningContent(block: BlocksGroupedByStack["blocks"][number]) {
  const learnContent = {
    guideSlug: getLearningMaterialSlug(block.guidecontent),
    caseStudySlug: getLearningMaterialSlug(block.casestudy),
  };
  const hasLearningContent =
    Object.values(learnContent).filter(Boolean).length > 0;
  const learningContent = {
    ...(learnContent.guideSlug
      ? {
          guide: {
            title: "Guide",
            slug: learnContent.guideSlug,
          },
        }
      : {}),
    ...(learnContent.caseStudySlug
      ? {
          caseStudy: {
            title: "Case Study",
            slug: learnContent.caseStudySlug,
          },
        }
      : {}),
  };

  return {
    hasLearningContent,
    learningContent,
  };
}

export function LearnAction({
  block,
}: {
  block: BlocksGroupedByStack["blocks"][number];
}) {
  const { hasLearningContent, learningContent } = getLearningContent(block);
  const [isStoryViewOpen, { close: closeStoryView, open: openStoryView }] =
    useDisclosure();
  const [contentStories, setContentStories] = useState<
    ContentStories | undefined
  >();

  function onOpenLearningContent(content: ContentStories) {
    setContentStories(content);
    openStoryView();
  }

  function onHideContent() {
    closeStoryView();
    setContentStories(undefined);
  }

  if (!hasLearningContent) {
    return null;
  }

  return (
    <>
      <Tooltip label="Learn more about this content">
        <Button
          variant="transparent"
          px={0}
          className={classes.icon}
          fw={500}
          td="underline"
          size="sm"
          onClick={() => onOpenLearningContent(learningContent)}
        >
          Learn more
        </Button>
      </Tooltip>
      {contentStories != null && (
        <StoryView
          isOpen={isStoryViewOpen}
          onClose={onHideContent}
          stories={contentStories}
        />
      )}
    </>
  );
}

interface BlockContentActionsProps {
  block: BlocksGroupedByStack["blocks"][number];
  /**
   * If provided, allows to visualize the content versions history
   */
  fact?: Fact | FactWithState | null;
  isLoading?: boolean;
  isEditing?: boolean;
  isOutdatedVersion?: boolean;
  content: string;
  generators?: AiGeneratorOption[];
  selectedGenerator?: AiGeneratorOption["value"];
  onSelectGenerator?: (gen: AiGeneratorOption) => void;
  onRegenerate?: (toneHint: string | ToneHint) => void;
}

export function BlockContentActions({
  block,
  fact,
  isLoading,
  isEditing,
  isOutdatedVersion,
  content,
  generators = [],
  selectedGenerator,
  onSelectGenerator,
  onRegenerate,
}: BlockContentActionsProps) {
  const [
    versionsPanelOpen,
    { open: openVersionsPanel, close: closeVersionsPanel },
  ] = useDisclosure();

  const hasHistory = !!fact;
  const canRegenerate = hasHistory && isGenerativeModule(block);

  return (
    <Group gap="xs">
      <TextCopier
        content={content ?? ""}
        disabled={!content}
        iconProps={{
          className: content ? classes.icon : undefined,
          size: "md",
        }}
      />
      {hasHistory && (
        <>
          <Tooltip withArrow label="View block history">
            <ActionIcon
              variant={isOutdatedVersion ? "light" : "subtle"}
              color={isOutdatedVersion ? "red.4" : "dimmed"}
              className={clsx(
                classes.icon,
                isOutdatedVersion && classes.iconOutdated,
              )}
              onClick={openVersionsPanel}
            >
              <IconListTree size={18} />
            </ActionIcon>
          </Tooltip>
          <Drawer
            position="right"
            size="lg"
            title={
              <Group gap="sm">
                <ThemeIcon variant="subtle">
                  <IconVersions />
                </ThemeIcon>
                <Text span size="lg">
                  Version History
                </Text>
              </Group>
            }
            keepMounted={false}
            withinPortal
            opened={versionsPanelOpen}
            onClose={closeVersionsPanel}
          >
            <Suspense fallback={<Loader />}>
              <BlockVersions fact={fact} />
            </Suspense>
          </Drawer>
        </>
      )}
      {canRegenerate && (
        <>
          <Divider orientation="vertical" />
          {generators.length > 0 && onSelectGenerator && (
            <GeneratorSwitcherButton
              options={generators}
              iconProps={{ className: classes.icon }}
              selectedGenerator={generators.find(
                (g) => g.value === selectedGenerator,
              )}
              onSelectGenerator={onSelectGenerator}
            />
          )}
          {onRegenerate && (
            <RegenerateOptions
              isDisabled={isLoading || isEditing}
              onRegenerate={(toneHint) => onRegenerate(toneHint)}
              iconProps={{ className: !isEditing ? classes.icon : undefined }}
            />
          )}
        </>
      )}
    </Group>
  );
}
