import { Button, Fieldset, Group, Input, Stack, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../auth/useAuthContext";
import { Can } from "../../components/auth/Can";

export function ReferralSection() {
  const { identity } = useAuthContext();
  const referralLink = `https://noan.getrewardful.com/signup?email=${identity.email}`;

  return (
    <Can execute="referral.read">
      <Fieldset legend="Referral Program">
        <Input.Wrapper
          label="Earn money with our referral program!"
          description="NOAN partners with Rewardful to offer rewards every time you
            referrer someone else."
        >
          <Stack gap="xs" py="xs">
            <Group>
              <Button component={Link} to={referralLink} target="_blank">
                Join the NOAN Referral Program
              </Button>
            </Group>
            <Text c="dimmed" size="sm">
              You will be taken to Rewardful to complete your signup.
            </Text>
          </Stack>
        </Input.Wrapper>
      </Fieldset>
    </Can>
  );
}
