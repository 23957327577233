import classes from "./StackIcons.module.css";

export function EcommIcon() {
  return (
    <svg
      width="206"
      height="230"
      viewBox="0 0 206 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M119.923 105.119L102.88 95.2791L102.865 85.9885C102.865 79.5574 99.4341 73.6147 93.8646 70.3992L68.7892 55.9146C61.2138 51.5412 51.7447 57.009 51.7447 65.7562V85.4377L68.7889 75.5967L85.8204 85.4304L85.8336 105.119L68.7892 95.2785L68.7892 114.96L85.8336 124.8L102.878 114.96L102.879 154.323L85.8339 164.163L51.7454 144.483L51.745 144.481L51.745 105.119L34.7003 95.2785L34.7007 154.322L85.8339 183.845L119.924 164.164L119.924 164.161L136.967 174.003L171.057 154.322L171.057 95.2776L154.012 85.4364L154.012 76.1482C154.013 69.7171 150.582 63.7744 145.012 60.5589L119.923 46.0739C112.347 41.7002 102.878 47.1672 102.878 55.9145V75.596L119.923 65.7554L136.968 75.5968L136.968 95.2765L119.924 85.4359L119.924 105.117L136.968 114.959L154.012 105.118L154.012 144.48L136.968 154.321L119.924 144.481L119.923 105.119Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.074 69.8125L205.073 154.363C205.073 166.542 198.575 177.795 188.028 183.885L114.805 226.16C107.379 230.448 98.2305 230.448 90.8049 226.16L12.5369 180.972C5.1113 176.685 0.536934 168.762 0.536913 160.188L0.536865 75.6365C0.536885 63.4576 7.03439 52.2038 17.5817 46.1144L90.8058 3.83957C98.2314 -0.447531 107.38 -0.447542 114.806 3.83954L193.074 49.0279C200.499 53.3151 205.074 61.2381 205.074 69.8125ZM21.5816 63.4864L98.8056 18.9023C101.281 17.4732 104.33 17.4733 106.806 18.9023L184.029 63.4873C186.504 64.9164 188.029 67.5574 188.029 70.4156L188.028 159.585C188.028 162.443 186.503 165.084 184.028 166.513L106.805 211.098C104.33 212.527 101.28 212.527 98.8051 211.098L21.5816 166.513C19.1064 165.084 17.5816 162.443 17.5816 159.585L17.5815 70.4147C17.5815 67.5565 19.1064 64.9155 21.5816 63.4864Z"
        fill="currentColor"
      />
    </svg>
  );
}
