import classes from "./StackIcons.module.css";

export function CharityIcon() {
  return (
    <svg
      width="205"
      height="230"
      viewBox="0 0 205 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.3377 61.398C64.9069 58.1827 71.7684 58.1828 77.3375 61.3981L102.426 75.8834L127.516 61.3981C133.086 58.1828 139.947 58.1827 145.516 61.398L161.607 70.6872C167.176 73.9026 170.606 79.8448 170.606 86.2754L170.605 124.535C170.605 130.966 167.175 136.909 161.605 140.124L102.427 174.29L43.2483 140.124C37.6791 136.909 34.2483 130.966 34.2483 124.535L34.2473 86.2754C34.2473 79.8448 37.678 73.9026 43.247 70.6872L59.3377 61.398ZM68.3374 75.8835L51.293 85.724L51.2919 125.086L102.426 154.609L153.561 125.087L153.561 85.7241L136.515 75.8842L102.426 95.5648L68.3374 75.8835Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.584 154.65L204.585 70.0998C204.585 61.5254 200.011 53.6023 192.585 49.3151L114.317 4.1268C106.891 -0.16028 97.7426 -0.160269 90.3171 4.12684L17.0929 46.4017C6.54562 52.4911 0.0481159 63.7449 0.0480957 75.9238L0.0481434 160.475C0.0481641 169.049 4.62253 176.972 12.0481 181.259L90.3162 226.448C97.7418 230.735 106.89 230.735 114.316 226.448L187.539 184.172C198.087 178.083 204.584 166.829 204.584 154.65ZM98.3169 19.1895L21.0929 63.7737C18.6176 65.2028 17.0928 67.8438 17.0928 70.702L17.0928 159.872C17.0928 162.73 18.6176 165.371 21.0928 166.8L98.3164 211.385C100.792 212.814 103.841 212.814 106.316 211.385L183.539 166.8C186.015 165.371 187.539 162.73 187.539 159.872L187.54 70.7028C187.54 67.8447 186.016 65.2036 183.54 63.7746L106.317 19.1896C103.842 17.7605 100.792 17.7605 98.3169 19.1895Z"
        fill="currentColor"
      />
    </svg>
  );
}
