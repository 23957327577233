// Ordered from highest to lowest access-level
export const roleOptions = [
  {
    value: "owner",
    label: "Owner",
    description:
      "Has full access, can manage organization settings and billing.",
  },
  {
    value: "admin",
    label: "Admin",
    description:
      "Can add users, edit roles and edit Strategy and use Create Mode.",
  },
  {
    value: "editor",
    label: "Editor",
    description: "Can edit Strategy and use Create Mode.",
  },
  {
    value: "creator",
    label: "Creator",
    description: "Can read Strategy and use Create Mode",
  },
  {
    value: "client",
    label: "Client",
    description: "Can add to select blocks",
  },
] as const;
