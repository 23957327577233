import {
  ActionIcon,
  ThemeIcon,
  Tooltip,
  type ActionIconProps,
  type ThemeIconProps,
} from "@mantine/core";
import {
  GeneratorSwitcher,
  type GeneratorSwitcherProps,
} from "./GeneratorSwitcher";

export function GeneratorSwitcherButton(
  props: GeneratorSwitcherProps & {
    buttonProps?: ActionIconProps;
    iconProps?: ThemeIconProps;
  },
) {
  return (
    <GeneratorSwitcher {...props}>
      {({ combobox, disabled, selectedGenerator }) => (
        <Tooltip withArrow label="Generate result with..." disabled={disabled}>
          <ActionIcon
            onClick={() => combobox.toggleDropdown()}
            disabled={disabled}
            variant="subtle"
            color="dimmed"
            opacity={combobox.dropdownOpened ? 0.5 : 1}
            {...(props.buttonProps ?? {})}
          >
            <ThemeIcon variant="transparent" {...(props.iconProps ?? {})}>
              {selectedGenerator?.icon}
            </ThemeIcon>
          </ActionIcon>
        </Tooltip>
      )}
    </GeneratorSwitcher>
  );
}
