import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  Flex,
  Group,
  List,
  Loader,
  Stack,
  Switch,
  Text,
  Title,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconBug, IconCheck, IconHeart } from "@tabler/icons-react";
import { products } from "api/src/stripe";
import { useEffect, useState } from "react";
import {
  Navigate,
  redirect,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAuthContext } from "../../auth/useAuthContext";
import { Page } from "../../components/Page";
import { useAccountMutation } from "../../utils/useAccountMutation";
import classes from "./Subscribe.module.css";
import { usePromoCode } from "./hooks";
import { plans } from "./plans";

type Frequency = "month" | "year";

/**
 * Build a complete stripe pay link for a given email & plan:
 */
function buildSubscriptionUrl({
  plan,
  frequency,
  email,
  accountId,
  promoCode = import.meta.env.DEV ? "NOANINVESTOR" : "",
}: {
  plan: string;
  frequency: Frequency;

  // prefilled_email
  email: string;

  // client_reference_id
  accountId: string;

  // prefilled_promo_code
  promoCode?: string;
}) {
  const product = products.find((p) => p.name === plan);
  if (!product) return "";

  const item = product.items.find((i) => i.frequency === frequency);
  if (!item) return "";

  const baseUrl = new URL(item.paymentLink);
  baseUrl.search = new URLSearchParams({
    prefilled_email: email,
    client_reference_id: accountId,
    prefilled_promo_code: promoCode,
  }).toString();

  return baseUrl.toString();
}

function Plans() {
  const { identity, isCustomer } = useAuthContext();
  const [frequency, setFrequency] = useState<Frequency>("month");
  const [searchParams, setSearchParams] = useSearchParams();
  const { resubscribe, isResubscribing } = useAccountMutation();
  const navigate = useNavigate();
  const { promoCode } = usePromoCode();

  useEffect(() => {
    // Sets the initial selected subscription frequency
    if (searchParams.has("sf")) {
      setFrequency(searchParams.get("sf") as Frequency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Redirects to the payment link to subscribe to a NOAN plan
    if (
      identity &&
      searchParams.has("initPayment") &&
      searchParams.has("sf") &&
      searchParams.has("sn")
    ) {
      const planName = searchParams.get("sn")!;
      const planNameCapitalize =
        planName.charAt(0).toUpperCase() + planName.slice(1);

      const paymentLink = buildSubscriptionUrl({
        plan: planNameCapitalize,
        frequency: searchParams.get("sf")! as Frequency,
        accountId: identity!.accountId,
        email: identity!.email,
        promoCode,
      });
      window.open(paymentLink, "_self");
    }
  }, [promoCode, frequency, identity, isCustomer, searchParams]);

  function onSwitchFrequency() {
    const newFrequency = frequency === "month" ? "year" : "month";
    setFrequency(newFrequency);
    searchParams.set("sf", newFrequency);
    setSearchParams(searchParams);
  }

  const selectOrJoinCopy = identity ? "Select" : "Join NOAN";

  function onSelectPlan(plan: string) {
    /**
     * If users get this far without an account ready somehow, we
     * redirect them to join if they click one of the options:
     */
    if (!identity) {
      redirect("/join");
    }

    if (!isCustomer) {
      const paymentLink = buildSubscriptionUrl({
        plan,
        frequency,
        accountId: identity!.accountId,
        email: identity!.email,
        promoCode,
      });

      window.open(paymentLink, "_self");
    } else {
      resubscribe(
        {
          productName: plan,
          frequency,
        },
        {
          onSuccess() {
            navigate("/");
            notifications.show({
              message: <Text>Your subscription has been renewed!</Text>,
              color: "green",
              icon: <IconCheck size={18} />,
            });
          },
          onError() {
            notifications.show({
              message: (
                <Text>
                  An error occurred while renewing your subscription. Please
                  contact customer support.
                </Text>
              ),
              color: "red",
              icon: <IconBug size={18} />,
            });
          },
        },
      );
    }
  }

  return (
    <>
      <Center mb="lg">
        <Group>
          <Text
            size="xl"
            fw={frequency === "month" ? "bold" : undefined}
            opacity={frequency === "month" ? 1 : 0.3}
          >
            Monthly
          </Text>
          <Switch
            size="md"
            checked={frequency === "year"}
            color="teal.4"
            onChange={onSwitchFrequency}
          />
          <Text
            size="xl"
            fw={frequency === "year" ? "bold" : undefined}
            opacity={frequency === "year" ? 1 : 0.3}
          >
            Yearly
          </Text>
        </Group>
      </Center>
      <Flex
        gap="lg"
        justify="center"
        direction={{ base: "column", md: "row" }}
        py="lg"
      >
        {plans.map((p) => {
          const { price, seats, seatPrice } = p.items[frequency];
          const [priceFull] = price.split(".");

          return (
            <Card
              key={p.title}
              withBorder
              radius="md"
              shadow="none"
              w={{ base: "100%", md: "580px" }}
              px="xl"
            >
              <Stack h="100%">
                {p.topBadge && (
                  <Badge
                    color="green"
                    leftSection={<IconHeart size={14} />}
                    pos="absolute"
                    top={0}
                    right={0}
                    left={0}
                    m="auto"
                    radius={0}
                    className={classes.badge}
                  >
                    {p.topBadge}
                  </Badge>
                )}
                <Title order={1} size={40} fw="bold" mt="lg" tt="uppercase">
                  {p.title}
                </Title>
                <Flex
                  h="100%"
                  align="flex-start"
                  direction={{ base: "column", xs: "row" }}
                  gap="lg"
                >
                  <Stack flex={1} h="100%" justify="space-between">
                    <Stack>
                      <Group gap="xs" align="end">
                        <Text size="32" fw="bold" lh="initial">
                          ${priceFull}
                        </Text>
                        <Text size="sm" c="base.7">
                          per {frequency}
                        </Text>
                      </Group>
                      <Text size="sm" c="base.7">
                        {p.description}
                      </Text>
                      <Text size="xs">
                        <Text c="orange.3" span>
                          Includes {seats} team member seats
                        </Text>
                        , add new members at ${seatPrice}/{frequency}
                      </Text>
                    </Stack>
                    <Box>
                      <Button
                        autoFocus={
                          p.title.toLowerCase() === searchParams.get("sn")
                        }
                        visibleFrom="sm"
                        w="fit-content"
                        size="xl"
                        px={60}
                        onClick={() => onSelectPlan(p.title)}
                        disabled={isResubscribing}
                        loading={isResubscribing}
                        loaderProps={{
                          children: <Loader size={18} />,
                        }}
                      >
                        {selectOrJoinCopy}
                      </Button>
                    </Box>
                  </Stack>
                  <Stack flex={1}>
                    <List
                      size="sm"
                      spacing="xs"
                      center
                      icon={
                        <Box display="flex">
                          <IconCheck size={16} color="teal" />
                        </Box>
                      }
                    >
                      <Text size="lg" mb="xs">
                        {p.items[frequency].subtitle}
                      </Text>
                      {p.items[frequency].features.map((f) => (
                        <List.Item key={f}>
                          <Text size="sm" c="base.7">
                            {f}
                          </Text>
                        </List.Item>
                      ))}
                    </List>
                  </Stack>
                </Flex>
                <Button
                  autoFocus={p.title.toLowerCase() === searchParams.get("sn")}
                  hiddenFrom="sm"
                  fullWidth
                  size="xl"
                  onClick={() => onSelectPlan(p.title)}
                  disabled={isResubscribing}
                  loading={isResubscribing}
                  loaderProps={{
                    children: <Loader size={18} />,
                  }}
                >
                  {selectOrJoinCopy}
                </Button>
              </Stack>
            </Card>
          );
        })}
      </Flex>
    </>
  );
}

export function Subscribe() {
  const { isAccountActive } = useAuthContext();

  if (isAccountActive) {
    return <Navigate to="/" />;
  }

  return (
    <Page title="Pick a NOAN subscription plan">
      <Plans />
    </Page>
  );
}
